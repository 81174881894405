import styled from "styled-components";
import LandingBackground from "../images/frontpage/landingBackground.png";

export const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 99999;
  background-color: white;

`
export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  
  background: url(${LandingBackground}) no-repeat center;
  background-size: contain;
  opacity: 0.5;
`

function BlockScreen({children}){
    return (
        <BackDrop>
            <Background/>
            {children}
        </BackDrop>)
}

export default BlockScreen