import useGameRouter, {GAME_ROUTE} from "../../hooks/useGameRouter";
import {PaddedPage} from "../../components/page";
import styled from 'styled-components';
import {BackButton, NextButton} from "../../components/navButtons";
import StoryModel from "../../classes/storyModel";
import {BodyB1, TitleBtn, TitleD2, TitleD3, TitleD4Bold, TitleHL, TitleHLBold} from "../../../typography";
import {categoryDocs} from "../../data/category";
import {useNavigate} from "react-router";
import {DimContext, ROUTE} from "../../../App";
import HeaderContainer from "../../components/headerCtn";
import {useContext, useLayoutEffect, useRef, useState} from "react";
import {device} from "../../../breakpoints";
import useResponsive from "../../../hook/useResponsive";
import BottomContainer from "../../components/bottomCtn";
import ScrollDiv from "../../../pages/components/scrollDiv";
import {useTranslation} from "react-i18next";

const CICtn = styled.div`
  background-color: white;
  text-align: left;
  border-radius: 16px;
  margin-bottom: auto;
  flex: 1;
  display: flex;
  position: relative;
  padding: 56px;
  max-height: ${props => props.ratio * 666}px;

  ${device.onlyTablet} {
    margin-top: 36px;
    margin-bottom: 36px;
  }

  ${device.onlyMobile} {
    padding: 16px;
    margin-top: 24px;
    margin-bottom: 24px;
  }
`

const CIInnerCtn = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  //${props => props.height && `height: ${props.height}px`};
`

const CIListCtn = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 250px;
  position: relative;

  ${device.tabletAndMobile} {
    padding-right: 0;
  }
`

const CIMobileCtn = styled.div`
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;

  ${device.onlyMobile} {
    flex-direction: column;
  }

`

const CIImgCtn = styled.img`
  position: absolute;
  right: ${props => props.pos.right * props.ratio || 0}px;
  bottom: ${props => props.pos.bottom * props.ratio || 0}px;
  width: ${props => props.pos.width * props.ratio || 0}px;
  height: ${props => props.pos.height * props.ratio || 0}px;

  ${device.tabletAndMobile} {
    right: initial;
    bottom: initial;
    width: ${props => props.pos.width || 0}px;
    height: ${props => props.pos.height || 0}px;
    position: relative;
  }

  ${device.onlyMobile} {
    margin-top: 24px;
    align-self: center;
  }
`

const CITitle2 = styled(TitleD4Bold)`
  margin-bottom: 8px;
`
const CIPhoneTitle2 = styled(TitleHLBold)`
  margin-bottom: 8px;
`

const CIDesc = styled(TitleHL)`
  margin-top: 16px;
  color: #2E2D2E;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 36px;

  ${device.onlyTablet} {
    margin-bottom: 24px;
  }

`

const CIDescPhone = styled(BodyB1)`
  margin-top: 16px;
  color: #2E2D2E;
  margin-bottom: 24px;

`

const CIListItem = styled.li`
  margin: 8px 0 0 0;
  color: black;;
`

const CILink = styled(TitleHL)`
  color: #0969DA;
  cursor: pointer;
  margin-top: 40px;

  ${device.tabletAndMobile} {
    margin-top: 0;
    flex: 1
  }
`

const CILinkPhone = styled(TitleBtn)`
  color: #0969DA;
  cursor: pointer;
  margin-top: 40px;

  ${device.tabletAndMobile} {
    margin-top: 0;
    flex: 1
  }
`

function Link({doc}) {

    const storyModel = new StoryModel();
    const navigate = useNavigate();
    const [, useRoute] = useGameRouter();

    const [device] = useResponsive();
    const {t} = useTranslation();

    const exitEndGame = (route) => {
        storyModel.reset();
        useRoute.reset();
        navigate(route, {replace: true})
        //clear data, navigate in callback
    }
    const Font = device.isPhone ? CILinkPhone : CILink
    return <Font onClick={() => exitEndGame(`${ROUTE.KNOWLEDGE}/${doc.hubURL}`)}>{t('pregame:category_info.learn_more')} {t(`common:tags.${doc.name}`)}</Font>
}


function CategoryInfoPage() {
    const storyModel = new StoryModel();
    const doc = categoryDocs[storyModel.getSelectedCategory()];
    const {t} = useTranslation('pregame', {keyPrefix: 'category_info'})
    const {t: tB} = useTranslation('common', {keyPrefix: 'button'})

    const dim = useContext(DimContext);

    const ref = useRef(null)

    const [height, setHeight] = useState(0);

    const [device] = useResponsive();

    useLayoutEffect(() => {
        if (ref.current) setHeight(ref.current.scrollHeight);
    }, [ref])

    if (device.isMobile) {
        const Font = device.isTablet ? TitleD3 : TitleD4Bold
        const DescFont = device.isTablet ? CIDesc : CIDescPhone
        const Font2 = device.isTablet ? CITitle2 : CIPhoneTitle2

        const ListItemFont = device.isTablet ? TitleHL : BodyB1
        return (<ScrollDiv>
            <PaddedPage style={{textAlign: 'left', backgroundColor: '#E5EBF4', flexDirection: 'column'}}
                        ratio={dim.ratio}>
                <HeaderContainer hideLogo/>
                <CICtn>
                    <CIInnerCtn>
                        <Font>{t(doc.about)}</Font>
                        <DescFont>{t(doc.desc)}</DescFont>
                        <CIListCtn>
                            <Font2>{t('what_will_you_learn')}</Font2>
                            {doc.learnList.map((text, index) => <CIListItem key={index}><ListItemFont
                                as="span">{t(text)}</ListItemFont></CIListItem>)}
                        </CIListCtn>
                        <CIMobileCtn>
                            <Link doc={doc}/>
                            <CIImgCtn src={doc.plantImg} pos={doc.plantPos} ratio={dim.ratio}/>
                        </CIMobileCtn>

                    </CIInnerCtn>
                </CICtn>
                <BottomContainer
                    backgroundColor='#E5EBF4'
                    Back={<BackButton text={tB('Back')}/>}
                    Next={<NextButton text={tB('Next')} route={GAME_ROUTE.rolePage}/>}
                />

            </PaddedPage>
        </ScrollDiv>)
    }

    return (
        <PaddedPage style={{textAlign: 'left', backgroundColor: '#E5EBF4', flexDirection: 'column'}} ratio={dim.ratio}>
            <HeaderContainer hideLogo/>
            <CICtn>
                <ScrollDiv key={height} heightVal={{clientHeight: 666 * dim.ratio - (56*2)}} barNotOverlay>
                    <CIInnerCtn>
                        <TitleD2>{t(doc.about)}</TitleD2>
                        <CIDesc>{t(doc.desc)}</CIDesc>
                        <CIListCtn>
                            <CITitle2>{t('what_will_you_learn')}</CITitle2>
                            {doc.learnList.map((text, index) => <CIListItem key={index}><TitleHL
                                as="span">{t(text)}</TitleHL></CIListItem>)}
                            <Link doc={doc}/>
                            <CIImgCtn src={doc.plantImg} pos={doc.plantPos} ratio={dim.ratio}/>

                        </CIListCtn>
                    </CIInnerCtn>
                </ScrollDiv>

            </CICtn>
            <div style={{display: 'flex', justifyContent: 'space-between', flex: 0}}>
                <BackButton text={tB('Back')}/>
                <NextButton text={tB('Next')} route={GAME_ROUTE.rolePage}/>
            </div>

        </PaddedPage>
    )


}

export default CategoryInfoPage