import {CATEGORY_INFO_TYPE} from "./category";
import Pronoun1Scenes from './storyline/pronouns/1'
import Pronoun2Scenes from './storyline/pronouns/2'
import Pronoun3Scenes from './storyline/pronouns/3'
import Pronoun4Scenes from './storyline/pronouns/4'
import Gender1Scenes from './storyline/gender/1'
import Gender2Scenes from './storyline/gender/2'
import Gender3Scenes from './storyline/gender/3'
import Gender4Scenes from './storyline/gender/4'
import Sexuality1Scenes from "./storyline/sexuality/1";
import Sexuality2Scenes from "./storyline/sexuality/2";
import Sexuality3Scenes from "./storyline/sexuality/3";
import Sexuality4Scenes from "./storyline/sexuality/4";
import Relationship1Scenes from "./storyline/relationship/1";
import Relationship2Scenes from "./storyline/relationship/2";
import Relationship3Scenes from "./storyline/relationship/3";
import Relationship4Scenes from "./storyline/relationship/4";

/*
stories = {
    category: {id (CategoryId): int, type: CategoryInfoType, storyLine: SceneData[]}[]
}
 */

const stories = {
    category: [{
        id: 1,
        type: CATEGORY_INFO_TYPE.pronouns,
        storyline: [Pronoun1Scenes,Pronoun2Scenes, Pronoun3Scenes, Pronoun4Scenes]
    },{
        id: 2,
        type: CATEGORY_INFO_TYPE.gender,
        storyline: [Gender1Scenes, Gender2Scenes, Gender3Scenes, Gender4Scenes],
    },{
        id: 3,
        type: CATEGORY_INFO_TYPE.sexuality,
        storyline: [Sexuality1Scenes, Sexuality2Scenes, Sexuality3Scenes, Sexuality4Scenes],
    },{
        id: 4,
        type: CATEGORY_INFO_TYPE.relationship,
        storyline: [Relationship1Scenes, Relationship2Scenes, Relationship3Scenes, Relationship4Scenes],
    }],
}
export default stories;