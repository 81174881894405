import {END_GAME, ImageType, PositionType, SceneType} from "../../../classes/scene";

import HCP_0_03 from "../../../images/hcp/0/HCP-03.png";
import HCP_1_03 from "../../../images/hcp/1/HCP-03.png";
import HCP_2_03 from "../../../images/hcp/2/HCP-03.png";
import HCP_0_06 from "../../../images/hcp/0/HCP-06.png";
import HCP_1_06 from "../../../images/hcp/1/HCP-06.png";
import HCP_2_06 from "../../../images/hcp/2/HCP-06.png";

import {CHATICON} from "../storyGlobal";

import BGER01 from "../../../images/background/BG-ER-01.png";
import BGER02 from "../../../images/background/BG-ER-02.png";
import BGER03 from "../../../images/background/BG-ER-03.png";

import ROS08 from "../../../images/patient/ross/ROS-08.png";
import ROS09 from "../../../images/patient/ross/ROS-09.png";
import ROS11 from "../../../images/patient/ross/ROS-011.png";


const BG = {
    'ER01': {
        type: ImageType.background,
        image: BGER01,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    'ER02': {
        type: ImageType.background,
        image: BGER02,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    'ER03': {
        type: ImageType.background,
        image: BGER03,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
}



const ROS = {
    "08": {
        type: ImageType.avatar,
        image: ROS08,
        positionType: PositionType.px,
        position: {
            left: 434,
            top: 265,
            right: 757,
            bottom: 213,
        },
        style: null,
    },
}

const ROS_CENTER = (img) => {
    return (
        {
            type: ImageType.avatar,
            image: img,
            positionType: PositionType.px,
            position: {
                left: 481,
                top: 293,
                right: 466,
                bottom: -229,
            },
            style: null,
        })
}




const HCP = {
    '03' :{
        type: ImageType.avatar,
        image: [HCP_0_03, HCP_1_03, HCP_2_03],
        positionType: PositionType.px,
        position: {
            left: 899.36,
            top: 264,
            right: 369.28,
            bottom: 62,
        },
        style: null,
        pronounButton:{
            top: 236,
            left: 96.64,
            width: 44,
            // height: 30,
            clippath: null
        }
    },


    '03-2' :{
        type: ImageType.avatar,
        image: [HCP_0_03, HCP_1_03, HCP_2_03],
        positionType: PositionType.px,
        position: {
            left: 461,
            top: 166,
            right: 462,
            bottom: -791,
        },
        style: null,
        pronounButton:{
            top: 584,
            left: 232.12,
            width: 108,
            // height: 30,
            clippath: null
        }
    },
    '06' :{
        type: ImageType.avatar,
        image: [HCP_0_06, HCP_1_06, HCP_2_06],
        positionType: PositionType.px,
        position: {
            left: 461,
            top: 166,
            right: 462,
            bottom: -791,
        },
        style: null,
        pronounButton: null
    },
}

const Sexuality4Scenes = {
    id: '03-04',
    patient: 4,
    scene: [{
        id: '03-04-001-01',
        type: SceneType.description,
        images:[
            BG['ER01'],
            ROS["08"],
        ],
        bubbleText: null,
        actions: [{
            nextId: '03-04-002-01',
            text: '001-01',
        }],
        hint: null,
        isBeginning: true,
    },{
        id: '03-04-002-01',
        type: SceneType.description,
        images:[
            BG['ER01'],
            ROS["08"],
            HCP['03'],

        ],
        bubbleText: false,
        actions: [{
            nextId: '03-04-003-01',
            text: '002-01',
        }],
        hint: null,
        isBeginning: false,
    },

        {
            id: '03-04-003-01',
            type: SceneType.selection,
            images:[
                BG['ER02'],
                HCP['03-2'],
            ],
            bubbleText: false,
            actions: [{
                nextId: '03-04-004-01',
                text: 'i01-q01',
                opt: 1,
                score: 3,
            }, {
                nextId: '03-04-004-02',
                text: 'i01-q02',
                opt: 2,
                score: 2,
            }, {
                nextId: '03-04-004-03',
                text: 'i01-q03',
                opt: 3,
                score: 1,
            }, {
                nextId: '03-04-004-04',
                text: 'i01-q04',
                opt: 4,
                score: 0,
            }],
            hint: 'i01-question',
            isBeginning: false,
        },{
            id: '03-04-004-01',
            type: SceneType.chat,
            images:[
                BG['ER03'],
                ROS_CENTER(ROS08),

            ],
            bubbleText: false,
            actions: [{
                nextId: '03-04-005-01',
                text: "i01-a01",
                chatIcon: CHATICON.ROS,
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '03-04-004-02',
            type: SceneType.chat,
            images:[
                BG['ER03'],
                ROS_CENTER(ROS09),

            ],
            bubbleText: false,
            actions: [{
                nextId: '03-04-005-01',
                text: "i01-a02",
                chatIcon: CHATICON.ROS,
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '03-04-004-03',
            type: SceneType.chat,
            images:[
                BG['ER03'],
                ROS_CENTER(ROS09),

            ],
            bubbleText: false,
            actions: [{
                nextId: '03-04-005-01',
                text: "i01-a03",
                chatIcon: CHATICON.ROS,
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '03-04-004-04',
            type: SceneType.chat,
            images:[
                BG['ER03'],
                ROS_CENTER(ROS11),

            ],
            bubbleText: false,
            actions: [{
                nextId: '03-04-005-01',
                text: "i01-a04",
                chatIcon: CHATICON.ROS,
            }],
            hint: null,
            isBeginning: false,
        },



        {
            id: '03-04-005-01',
            type: SceneType.chat,
            images:[
                BG['ER02'],
                HCP['06'],
            ],
            bubbleText: false,
            actions: [{
                nextId: '03-04-006-01',
                text: 'i02-q01',
                chatIcon: CHATICON.HCP
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '03-04-006-01',
            type: SceneType.chat,
            images:[
                BG['ER03'],
                ROS_CENTER(ROS08),

            ],
            bubbleText: false,
            actions: [{
                nextId: '03-04-007-01',
                text: "i02-a01",
                chatIcon: CHATICON.ROS,
            }],
            hint: null,
            isBeginning: false,
        },


        {
            id: '03-04-007-01',
            type: SceneType.selection,
            images:[
                BG['ER02'],
                HCP['03-2'],
            ],
            bubbleText: false,
            actions: [{
                nextId: '03-04-008-01',
                text: 'i03-q01',
                opt: 1,
                score: 3,
            }, {
                nextId: '03-04-008-02',
                text: 'i03-q02',
                opt: 2,
                score: 2,
            }, {
                nextId: '03-04-008-03',
                text: 'i03-q03',
                opt: 3,
                score: 1,
            }, {
                nextId: '03-04-008-04',
                text: 'i03-q04',
                opt: 4,
                score: 0,
            }],
            hint: 'i03-question',
            isBeginning: false,
        },{
            id: '03-04-008-01',
            type: SceneType.chat,
            images:[
                BG['ER03'],
                ROS_CENTER(ROS08),

            ],
            bubbleText: false,
            actions: [{
                nextId: '03-04-009-01',
                text: "i03-a01",
                chatIcon: CHATICON.ROS,
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '03-04-008-02',
            type: SceneType.chat,
            images:[
                BG['ER03'],
                ROS_CENTER(ROS09),

            ],
            bubbleText: false,
            actions: [{
                nextId: '03-04-009-01',
                text: "i03-a02",
                chatIcon: CHATICON.ROS,
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '03-04-008-03',
            type: SceneType.chat,
            images:[
                BG['ER03'],
                ROS_CENTER(ROS09),

            ],
            bubbleText: false,
            actions: [{
                nextId: '03-04-009-01',
                text: "i03-a03",
                chatIcon: CHATICON.ROS,
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '03-04-008-04',
            type: SceneType.chat,
            images:[
                BG['ER03'],
                ROS_CENTER(ROS11),

            ],
            bubbleText: false,
            actions: [{
                nextId: '03-04-009-01',
                text: "i03-a04",
                chatIcon: CHATICON.ROS,
            }],
            hint: null,
            isBeginning: false,
        },


        {
            id: '03-04-009-01',
            type: SceneType.selection,
            images:[
                BG['ER02'],
                HCP['03-2'],
            ],
            bubbleText: false,
            actions: [{
                nextId: '03-04-010-01',
                text: 'i04-q01',
                opt: 1,
                score: 3,
            }, {
                nextId: '03-04-010-02',
                text: 'i04-q02',
                opt: 2,
                score: 2,
            }, {
                nextId: '03-04-010-03',
                text: 'i04-q03',
                opt: 3,
                score: 1,
            }, {
                nextId: '03-04-010-04',
                text: 'i04-q04',
                opt: 4,
                score: 0,
            }],
            hint: 'i04-question',
            isBeginning: false,
        },{
            id: '03-04-010-01',
            type: SceneType.chat,
            images:[
                BG['ER03'],
                ROS_CENTER(ROS08),

            ],
            bubbleText: false,
            actions: [{
                nextId: END_GAME,
                text: "i04-a01",
                chatIcon: CHATICON.ROS,
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '03-04-010-02',
            type: SceneType.chat,
            images:[
                BG['ER03'],
                ROS_CENTER(ROS09),

            ],
            bubbleText: false,
            actions: [{
                nextId: '03-04-010-02-02',
                text: "i04-a02",
                chatIcon: CHATICON.ROS,
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '03-04-010-02-02',
            type: SceneType.cutscene,
            images:[
                BG['ER03'],
                ROS_CENTER(ROS09),

            ],
            bubbleText: [{text:'i04-a02-b' , position:'left'}],
            actions: [{
                nextId: END_GAME,
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '03-04-010-03',
            type: SceneType.chat,
            images:[
                BG['ER03'],
                ROS_CENTER(ROS09),

            ],
            bubbleText: false,
            actions: [{
                nextId: '03-04-010-03-02',
                text: "i04-a03",
                chatIcon: CHATICON.ROS,
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '03-04-010-03-02',
            type: SceneType.cutscene,
            images:[
                BG['ER03'],
                ROS_CENTER(ROS09),

            ],
            bubbleText: [{text:'i04-a03-b' , position:'left'}],
            actions: [{
                nextId: END_GAME,
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '03-04-010-04',
            type: SceneType.chat,
            images:[
                BG['ER03'],
                ROS_CENTER(ROS11),

            ],
            bubbleText: false,
            actions: [{
                nextId: '03-04-010-04-02',
                text: "i04-a04",
                chatIcon: CHATICON.ROS,
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '03-04-010-04-02',
            type: SceneType.cutscene,
            images:[
                BG['ER03'],
                ROS_CENTER(ROS11),

            ],
            bubbleText: [{text:'i04-a04-b' , position:'left'}],
            actions: [{
                nextId: END_GAME,
            }],
            hint: null,
            isBeginning: false,
        },


    ]
}


export default Sexuality4Scenes