
import ProfilePicLexy from '../images/patient/lexy/profilePic.png'
import ProfilePicMax from '../images/patient/max/profilePic.png'
import ProfilePicRoss from '../images/patient/ross/profilePic.png'
import ProfilePicChetan from '../images/patient/chetan/profilePic.png'
import {EMOTION_TYPE} from "../classes/patient";


import CTN04 from '../images/patient/chetan/CTN-04-angry.png'
import CTN03 from '../images/patient/chetan/CTN-03-annoyed.png'
import CTN02 from '../images/patient/chetan/CTN-02-happy.png'
import CTN11 from '../images/patient/chetan/CTN-11-normal.png'
import CTN12 from '../images/patient/chetan/CTN-12-unsatisfied.png'
import CTN01 from '../images/patient/chetan/CTN-01-smile.png'


import LXY01 from '../images/patient/lexy/LXY-01.png'
import LXY02 from '../images/patient/lexy/LXY-02.png'
import LXY03 from '../images/patient/lexy/LXY-03.png'
import LXY04 from '../images/patient/lexy/LXY-04.png'
import LXY05 from '../images/patient/lexy/LXY-05.png'
import LXY06 from '../images/patient/lexy/LXY-06.png'

import MAX01 from '../images/patient/max/MAX-01.png'
import MAX02 from '../images/patient/max/MAX-02.png'
import MAX03 from '../images/patient/max/MAX-03.png'
import MAX04 from '../images/patient/max/MAX-04.png'
import MAX05 from '../images/patient/max/MAX-05.png'
import MAX06 from '../images/patient/max/MAX-06.png'

import ROS01 from '../images/patient/ross/ROS-01.png'
import ROS02 from '../images/patient/ross/ROS-02.png'
import ROS03 from '../images/patient/ross/ROS-03.png'
import ROS04 from '../images/patient/ross/ROS-04.png'
import ROS05 from '../images/patient/ross/ROS-05.png'
import ROS06 from '../images/patient/ross/ROS-06.png'





export const PATIENT_DATA = [
    {
        id: 1,
        name: 'Chetan',

        intro: 'chetan.intro',
        profilePic: ProfilePicChetan,
        avatar: CTN01,
        // avatarIcon: AvatarIconChetan,
        emotionsImg:{
            [EMOTION_TYPE.ANGRY]: CTN04,
            [EMOTION_TYPE.ANNOYED]: CTN03,
            [EMOTION_TYPE.HAPPY]: CTN02,
            [EMOTION_TYPE.NORMAL]: CTN11,
            [EMOTION_TYPE.SMILE]: CTN01,
            [EMOTION_TYPE.UNSATISFIED]: CTN12,
        },
        endgamePosition: {
            left: 7,
            width: 313,
            top: 296,
            mobile: {
                top: 169.7,
                width: 197
            }
        },
        isDisabled: false,
    },
    {
        id: 2,
        name: 'Lexy',
        intro: 'lexy.intro',
        profilePic: ProfilePicLexy,
        avatar: LXY01,
        // avatarIcon: null,
        emotionsImg:{
            [EMOTION_TYPE.ANGRY]: LXY06,
            [EMOTION_TYPE.ANNOYED]: LXY04,
            [EMOTION_TYPE.HAPPY]: LXY02,
            [EMOTION_TYPE.NORMAL]: LXY03,
            [EMOTION_TYPE.SMILE]: LXY01,
            [EMOTION_TYPE.UNSATISFIED]: LXY05,
        },
        endgamePosition: {
            left: -18,
            width: 392,
            top: 300,
            mobile: {
                top:171,
                left: -3,
                width: 216,
            }
        },
        isDisabled: false,
    },
    {
        id: 3,
        name: 'Max',
        intro: 'max.intro',
        profilePic: ProfilePicMax,
        avatar: MAX01,
        // avatarIcon: null,
        emotionsImg:{
            [EMOTION_TYPE.ANGRY]: MAX06,
            [EMOTION_TYPE.ANNOYED]: MAX04,
            [EMOTION_TYPE.HAPPY]: MAX02,
            [EMOTION_TYPE.NORMAL]: MAX03,
            [EMOTION_TYPE.SMILE]: MAX01,
            [EMOTION_TYPE.UNSATISFIED]: MAX05,
        },
        endgamePosition: {
            left: -3,
            width: 330,
            top: 300,
            mobile: {
                top:172,
                left: -2,
                width: 204,
            }
        },
        isDisabled: false,
    },
    {
        id: 4,
        name: 'Ross',
        intro: 'ross.intro',
        profilePic: ProfilePicRoss,
        avatar: ROS01,
        // avatarIcon: null,
        emotionsImg:{
            [EMOTION_TYPE.ANGRY]: ROS06,
            [EMOTION_TYPE.ANNOYED]: ROS04,
            [EMOTION_TYPE.HAPPY]: ROS02,
            [EMOTION_TYPE.NORMAL]: ROS03,
            [EMOTION_TYPE.SMILE]: ROS01,
            [EMOTION_TYPE.UNSATISFIED]: ROS05,
        },
        endgamePosition: {
            left: 11,
            width: 437,
            top: 288,
            mobile: {
                top:166,
                left: 1,
                width: 288,
            }
        },
        isDisabled: false,
    },
]