import styled from "styled-components";
import {TitleBtn, TitleD4, TitleSH1} from "../../typography";
import {AffirmButton, DenyButton} from "./buttons";
import Icon from '../images/misc/crossIcon.png'


const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(36, 41, 47, 0.5);
  z-index: 1000;
  display: ${props => props.isOpen ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;


`

const DrawerBase = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  background-color: white;
  position: fixed;
  top: 0;
  left: ${props => props.isOpen ? 0 : -330}px;
  bottom: 0;
  width: 300px;
  transition: left .5s cubic-bezier(0.820, 0.085, 0.395, 0.895);

  z-index: 1001;
`

const CloseButton = styled.img`
  width: 16px;
  height: 16px;
  padding: 16px;

  cursor: pointer;
  margin: 11px;

  border-radius: 8px;
  
  &:hover {
    background-color: #EFEEF1;
  }

  box-sizing: content-box;
`

export const DrawerCtn = styled.div`
  padding: 0 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  height: 100%;
  flex: 1;
`

export const DrawerItem = styled(TitleBtn)`
  margin-bottom: 16px;
  padding: 10px 0;
  width: 100%;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: #EFEEF1;
  }
`

export const DrawerBottomItem = styled.div`
  margin-top: 16px;
  width: 100%;

  &:first-of-type {
    margin-top: auto;
  }
`

function Drawer({isOpen, onClose, children}) {
    return (
        <>
            <BackDrop onClick={onClose} isOpen={isOpen}>
            </BackDrop>
            <DrawerBase isOpen={isOpen}>
                <CloseButton src={Icon} onClick={onClose}/>
                {children}
            </DrawerBase>
        </>
    )
}

export default Drawer;