import styled from "styled-components";
import {TitleBtn} from "../../typography";
import {useContext} from "react";
import {device} from "../../breakpoints";
import {DimContext} from "../../App";

export const BUTTON_TYPE = {
    AFFIRM: 'AFFIRM',
    DENY: 'DENY',
    STORY_NEXT: 'STORY_NEXT'
}

const StyledAButton = styled.div`
  padding: 10px 16px;
  border-radius: 45px;
  color: white;
  display: flex;
  background-color:${props=> props.isDisabled? '#57606A' : '#065BBF'};
  align-items: center;
  cursor: ${props=> props.isDisabled? 'default': 'pointer'};
  justify-content: center;
  white-space: nowrap;
  
  &:hover {
    background-color: ${props=> props.isDisabled? '#57606A' : '#08448B'};
  }
  
  ${device.tabletAndMobile}{
    max-width: 164px;
    flex: 1;
  }
`

const StyledStoryNextButton = styled(StyledAButton)`
  position: absolute;
  left: ${props=>props.ratio * 1265}px;
  bottom: ${props=>props.ratio * 72}px;
  z-index: 20;
  
  ${device.tabletAndMobile}{
    position: relative;
    left: initial;
    bottom: initial;
  }
  
  ${device.onlyTablet}{
    padding: 10px 50.5px;
  }
  
  ${device.onlyMobile}{
    width: 100%;
    justify-content: center;
    max-width: 100%;
  }
`


const StyledDButton = styled.div`
  padding: 7px 16px;
  border-radius: 45px;
  color: #57606A;
  display: flex;
  background-color: white;
  align-items: center;
  cursor: pointer;
  border: 3px solid #89888B;
  justify-content: center;
  &:hover {
    background-color: #FAF9FA
  }
  ${device.tabletAndMobile}{
    max-width: 164px;
    flex: 1;
  }
`

const StyledButtonText = styled(TitleBtn)`
  pointer-events: none; // for google analytics to catch the event
`
const StyledButtonIcon = styled.img`
  pointer-events: none;
`

function Button({text, onClick, leftIcon, rightIcon, type, style, isDisabled, name, id, isStopAspect,...rest}) {
    // console.log(isDisabled)
    const StyledButton = type === BUTTON_TYPE.STORY_NEXT ? StyledStoryNextButton : (type === BUTTON_TYPE.AFFIRM ? StyledAButton : StyledDButton)
    const dim = useContext(DimContext)
    return (<StyledButton style={style}
                          ratio={isStopAspect? 1 : dim.ratio}
                          onClick={() => !isDisabled && onClick && onClick()} isDisabled={isDisabled} id={id || ""} name={name}
                          {...rest}>
        {leftIcon && <StyledButtonIcon alt="left icon" src={leftIcon} width="17.5px" height="17.05px" style={{marginRight: 8}}/>}
        <StyledButtonText>{text}</StyledButtonText>
        {rightIcon && <StyledButtonIcon alt="right icon" src={rightIcon} width="17.5px" height="17.05px" style={{marginLeft: 8}}/>}
    </StyledButton>)
}


function DenyButton({...props}) {
    return <Button type={BUTTON_TYPE.DENY} {...props}/>
}

function AffirmButton({type, ...props}) {
    return <Button type={type || BUTTON_TYPE.AFFIRM} {...props}/>
}


export {DenyButton, AffirmButton}
export default Button