import Survey, {SurveyPageStyle} from "./survey";

const SurveyMetaData = {
    numOfPage: 8,
    numOfQuestion: 7,
    startOfQuestion: 1,
}

const SurveyPage = [
    {
        pageNum: 0,
        text: 'To help the Sexual and Gender Diversity in Cancer Care team improve the educational materials, we are inviting you to answer a short survey.\n\nAre you comfortable providing personal-identifiable information related to your age, race, profession, gender identity, sexual orientation and relationship status?',
        style: SurveyPageStyle.CONFIRMATION
    },
    {
        questionNum: 1,
        pageNum: 1,
        list: [{
            value: '18 - 24',
            text: '18 - 24'
        }, {
            value: '25 - 30',
            text: '25 - 30'
        }, {
            value: '31 - 40',
            text: '31 - 40'
        }, {
            value: '41 - 50',
            text: '41 - 50'
        }, {
            value: '51 - 60',
            text: '51 - 60'
        }, {
            value: '61 - 70',
            text: '61 - 70'
        }, {
            value: '71+',
            text: '71+'
        },],
        question: 'Please select your age group',
        style: SurveyPageStyle.RADIOFORM,
    },
    {
        questionNum: 2,
        pageNum: 2,
        list: [{
            value: 'Black',
            text: ['Black ', '(e.g., African, African Canadian, Afro-Caribbean descent)'],
        }, {
            value: 'East Asian',
            text: ['East Asian ', '(e.g., Chinese, Japanese, Korean, Taiwanese descent)'],
        }, {
            value: 'Indigenous',
            text: ['Indigenous ', '(e.g., First Nations, Métis, Inuk/Inuit)'],
        }, {
            value: 'Latin American',
            text: ['Latin American ', '(Hispanic or Latin American descent)'],
        }, {
            value: 'Middle Eastern',
            text: ['Middle Eastern ', '(e.g., Arab, Persian)'],
        }, {
            value: 'West Asian descent',
            text: ['West Asian descent ', '(e.g., Afghan, Egyptian, Iranian, Kurdish, Lebanese, Turkish)'],
        }, {
            value: 'South Asian descent',
            text: ['South Asian descent ', '(e.g., Bangladeshi, Indian, Indo-Caribbean, Pakistani, Sri Lankan)'],
        }, {
            value: 'Southeast Asian',
            text: ['Southeast Asian ', '(e.g., Cambodian, Filipino, Indonesian, Thai, Vietnamese, or other Southeast Asian descent)'],
        }, {
            value: 'White',
            text: ['White ', '(e.g., European descent)'],
        }, {
            value: 'Another race category',
            text: ['Another race category ', '(please specify)'],
            fillInLimit: 64
        }, {
            value: 'Do not know',
            text: 'Do not know'
        }, {
            value: 'Prefer not to answer',
            text: 'Prefer not to answer'
        },],
        question: 'In our society, people are often described by their race or racial background. Which of the following best describes your racial identity? \n' +
            'Please select all that apply:',
        style: SurveyPageStyle.RADIOFORM,
        ansFormMulti: 2
    },
    {
        questionNum: 3,
        pageNum: 3,
        list: [{
            value: 'Agender',
            text: 'Agender',
        }, {
            value: 'Bigender',
            text: 'Bigender',
        }, {
            value: 'Gender fluid',
            text: 'Gender fluid',
        }, {
            value: 'Man',
            text: 'Man',
        }, {
            value: 'Non-binary',
            text: 'Non-binary',
        }, {
            value: 'Non-conforming',
            text: 'Non-conforming',
        }, {
            value: 'Pan-gender',
            text: 'Pan-gender',
        }, {
            value: 'Questioning or unsure',
            text: 'Questioning or unsure',
        }, {
            value: 'Transgender man',
            text: 'Transgender man',
        }, {
            value: 'Transgender woman',
            text: 'Transgender woman',
        }, {
            value: 'Two-Spirit',
            text: ['Two-Spirit ', '(2S)']
        }, {
            value: 'Woman',
            text: 'Woman',
        }, {
            value: 'Not listed',
            text: ['Not listed ', '(please specify)'],
            fillInLimit: 64
        }, {
            value: 'Do not know',
            text: 'Do not know',
        }, {
            value: 'Prefer not to answer',
            text: 'Prefer not to answer',
        },],
        question: 'If you are comfortable, please let us know your gender identity. Select all that apply:',
        style: SurveyPageStyle.RADIOFORM,
        ansFormMulti: 2
    },
    {
        questionNum: 4,
        pageNum: 4,
        list: [{
            value: 'Asexual',
            text: 'Asexual',
        }, {
            value: 'Bisexual',
            text: 'Bisexual',
        }, {
            value: 'Gay',
            text: 'Gay',
        }, {
            value: 'Lesbian',
            text: 'Lesbian',
        }, {
            value: 'Pansexual',
            text: 'Pansexual',
        }, {
            value: 'Queer',
            text: 'Queer',
        }, {
            value: 'Questioning or unsure',
            text: 'Questioning or unsure',
        }, {
            value: 'Same-gender loving',
            text: 'Same-gender loving',
        }, {
            value: 'Straight (heterosexual)',
            text: 'Straight (heterosexual)',
        }, {
            value: 'Two-Spirit',
            text: ['Two-Spirit ', '(2S)']
        }, {
            value: 'Woman',
            text: 'Woman',
        }, {
            value: 'Not listed',
            text: ['Not listed ', '(please specify)'],
            fillInLimit: 64
        }, {
            value: 'Do not know',
            text: 'Do not know',
        }, {
            value: 'Prefer not to answer',
            text: 'Prefer not to answer',
        },],
        question: 'Please select the response(s) that best describe your sexual orientation:',
        style: SurveyPageStyle.RADIOFORM,
        ansFormMulti: 2
    },
    {
        questionNum: 5,
        pageNum: 5,
        list: [{
            value: 'Consensual non-monogamy',
            text: 'Consensual non-monogamy',
        }, {
            value: 'In a relationship',
            text: 'In a relationship',
        }, {
            value: 'Married/Common-law partner',
            text: 'Married/Common-law partner',
        }, {
            value: 'Polyamorous',
            text: 'Polyamorous',
        }, {
            value: 'Separated/Divorced',
            text: 'Separated/Divorced',
        }, {
            value: 'Single',
            text: 'Single',
        }, {
            value: 'Widowed',
            text: 'Widowed',
        }, {
            value: 'Not listed',
            text: ['Not listed ', '(please specify)'],
            fillInLimit: 64
        }, {
            value: 'Do not know',
            text: 'Do not know',
        }, {
            value: 'Prefer not to answer',
            text: 'Prefer not to answer',
        },],
        question: 'Please select any and all response(s) that best describe your relationship status:',
        style: SurveyPageStyle.RADIOFORM,
        ansFormMulti: 2
    },
    {
        questionNum: 6,
        pageNum: 6,
        textBox: {limit: 64},
        question: 'Please specify your current profession or role',
        style: SurveyPageStyle.INPUTBOX,
    },
    {
        questionNum: 7,
        pageNum: 7,
        list: [{
            value: 'Yes',
            text: 'Yes',
        }, {
            value: 'No',
            text: 'No',
        },],
        question: 'Are you currently working, learning or volunteering at the University Health Network?',
        style: SurveyPageStyle.RADIOFORM,
        isDone: true
    }
]

export default function PregameSurvey({isOpen, onClose, onEnd}) {

    return <Survey
        isOpen={isOpen}
        onClose={onClose}
        onEnd={onEnd}
        metaData={SurveyMetaData}
        pages={SurveyPage}
    />
}
