

const EMOTION_TYPE = {
    NORMAL: 'normal',
    ANGRY: 'angry',
    ANNOYED: 'annoyed',
    HAPPY: 'happy',
    SMILE:'smile',
    UNSATISFIED: 'unsatisfied',
}

class Patient {
    id;
    name;
    intro;
    profilePic; // just head
    avatar; // whole body
    emotionsImg = {};
    isDisabled = false;
    endgamePosition = {}

    constructor(patient) {
        this.id = patient.id;
        this.name = patient.name;
        this.intro = patient.intro;
        this.profilePic = patient.profilePic;
        this.avatar = patient.avatar;
        this.emotionsImg = patient.emotionsImg;
        this.endgamePosition = patient.endgamePosition;
        this.isDisabled = patient.isDisabled;
    }

    getSelectionData = () => {
        return {id: this.id, name: this.name, profilePic: this.profilePic, isDisabled: this.isDisabled}
    }
}

export {EMOTION_TYPE}
export default Patient