import {isDev} from "../../settings";

class Role {
    name = '';
    pronoun = ['',''];
    avatar = 0;

    constructor() {
        if(isDev()){
            this.name = "1";
            this.pronoun = ["They","Them"];
        }
    }
}
export default Role;