import styled, {css} from "styled-components";
import LandingBackground from '../images/frontpage/landingBackground.png'
import LandingBackgroundTL from '../images/frontpage/landingBackgroundTL.svg'
import LandingBackgroundBL from '../images/frontpage/landingBackgroundBL.png'
import {TitleD1, TitleD3, TitleD4, TitleHL, TitleSH1} from "../../typography";
import {device} from "../../breakpoints";
import useResponsive from "../../hook/useResponsive";
import {Base, BaseContent} from "../components/base";
import {StartButton} from "../components/startButton";
import {Trans, useTranslation} from "react-i18next";

const LandingBase = styled(BaseContent)`
  display: flex;
  padding-left: 136px;
  align-items: stretch;
  min-height: 902px;
  position: relative;
  ${device.tabletAndMobile}{
    padding-left: 0;
  }
  ${device.onlyMobile}{
    min-height: 575px;
    
  }
`
const LImage = styled.div`
  flex: 1;
  background-image: url(${LandingBackground});
  background-size: contain;
  background-position: top right;
  background-repeat: no-repeat;

  margin-bottom: 5%;
  ${device.tabletAndMobile}{
    opacity: 0.25;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: 5%;
    margin-bottom: 0;
  }
  ${device.onlyTablet}{
  }
  ${device.onlyMobile}{
    bottom: 10%;
    top: 10%;
  }
`;
const LImageTL = styled.div`
  position: absolute;
  background-image: url(${LandingBackgroundTL});
  background-size: contain;
  background-repeat: no-repeat;
  top: 10%;
  left: -24px;
  width: 146px;
  height: 159px;
  
  ${device.onlyTablet}{
    opacity: 0.25;
  }
  ${device.onlyMobile}{
    display: none;
  }
`;
const LImageBL = styled.div`
  position: absolute;
  background-image: url(${LandingBackgroundBL});
  background-size: contain;
  background-repeat: no-repeat;
  bottom: 10%;
  left: 0px;
  width: 99px;
  height: 98px;
  
  ${device.onlyTablet}{
    opacity: 0.25;
  }
  ${device.onlyMobile}{
    bottom: 0;
    opacity: 0.25;
  }
`;


const LTitleContainer = styled.div`
  width: 539px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 5;
  
  ${device.tabletAndMobile}{
    padding: 0 24px;
    width: 100%;
  }
  
  ${device.onlyMobile}{
    padding: 0 16px;
  }
`

const TitleCSS = css`
  margin-bottom: 24px;
  ${device.onlyMobile}{
    margin-bottom: 16px;
  }
`

const LTitle = styled(TitleD1)`${TitleCSS}`;
const LPhoneTitle = styled(TitleD3)`${TitleCSS}`;

const SubtitleCSS = css`
  color: #5B5A5C;
  margin-bottom: 24px;
  ${device.onlyMobile}{
    margin-bottom: 16px;
  }
  `
const LSubTitle = styled(TitleD4)`${SubtitleCSS}`;
const LPhoneSubTitle = styled(TitleHL)`${SubtitleCSS}`;

const DescCSS = css`
  color: #5B5A5C;
  margin-bottom: 24px;
`

const LDesc = styled(TitleHL)`${DescCSS}`;
const LPhoneDesc = styled(TitleSH1)`${DescCSS}`;


function Landing() {
    const [device] = useResponsive();
    const {t} = useTranslation('common', {keyPrefix: 'homepage.frontpage'})
    const isPhone = device.isPhone;

    const Title = isPhone?LPhoneTitle: LTitle;
    const SubTitle = isPhone?LPhoneSubTitle: LSubTitle;
    const Desc = isPhone? LPhoneDesc: LDesc;

    return (
        <Base>
            <LandingBase>
                <LTitleContainer>
                    <Title><Trans i18nKey="common:homepage.frontpage.title">SGDc Inclusive<br/>Cancer Care</Trans></Title>
                    <SubTitle>{t('subtitle')}</SubTitle>
                    <Desc>{t('desc')}</Desc>
                    <StartButton/>
                </LTitleContainer>
                <LImage/>
                <LImageTL/>
                <LImageBL/>
            </LandingBase>
        </Base>)
}


export default Landing;