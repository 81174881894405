import styled from "styled-components";
import {AffirmButton, DenyButton} from "../../pages/components/buttons";
import {BackButton, NextButton} from "../../game/components/navButtons";

const NavigationCtn = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`

export default function Navigation({onBack, onNext, isDone, isNextDisabled}){
    return <NavigationCtn>
        <BackButton text="Back" onClick={onBack} disableRoute isStopAspect style={{minWidth: 100,maxWidth:100}}/>
        {isDone? <AffirmButton isDisabled={isNextDisabled} text="Done" onClick={onNext} style={{minWidth: 100,maxWidth:100}}/> :
            <NextButton isDisabled={isNextDisabled} text="Next" onClick={onNext} isStopAspect style={{minWidth: 100,maxWidth:100}}/>}
    </NavigationCtn>
}