import SurveyModal from "./components/surveyModal";
import {useEffect, useReducer} from "react";
import {ConfirmationPage} from "./pages/confirmation";
import {RadioPage} from "./pages/radio";
import {InputPage} from "./pages/input";
import RatingPage from "./pages/rating";
import {TitleSH1} from "../typography";
import styled from 'styled-components'
import {ClosePage} from "./pages/close";

export const SurveyTitle = styled(TitleSH1)`
  white-space: pre-wrap;
  margin-bottom: 8px;
  font-weight: bold
`

export const SurveyContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

export const SurveyPageStyle = {
    CONFIRMATION: 'CONFIRMATION',
    RADIOFORM: 'RADIOFORM',
    INPUTBOX: 'INPUTBOX',
    RATING: 'RATING',
    CLOSE: 'CLOSE',
}
const ACTION = {
    'GO_BACK': 'GO_BACK',
    'INPUT_ANS': 'INPUT_ANS'
}

function createInputAns(page, ans, num) {
    return {
        type: ACTION.INPUT_ANS,
        page,
        ans,
        ansNum: num - 1
    }
}

function createGoBack() {
    return {
        type: ACTION.GO_BACK
    }
}

function reducer(state, action) {
    switch (action.type) {
        case ACTION.GO_BACK:
            return {
                ...state,
                page: state.history[state.history.length - 2],
                history: state.history.slice(0, state.history.length - 1)
            }
        case ACTION.INPUT_ANS:
            let ansRet = [...state.ans];
            if (action.ans) {
                ansRet[action.ansNum] = action.ans
            }
            return {
                page: action.page,
                ans: ansRet,
                history: [...state.history, action.page]

            }
        default:
            return state;
    }

}

function initState(pages) {
    const ans = [];
    pages.forEach(page => {
        if (page.questionNum) {
            if (page.ansFormMulti) {
                ans[page.questionNum - 1] = Array(page.ansFormMulti).fill('')
            } else {
                ans[page.questionNum - 1] = ['']
            }
        }
    })
    return {
        page: 0, // according to page num
        ans,
        history: [0]
    }
}

function composeAns(ans, pages) {
    const ret = [];
    pages.forEach(page => {
        if (page.questionNum) {
            if (page.ansFormMulti) {
                if (page.style === SurveyPageStyle.RADIOFORM) {
                    const selectedAns = ans[page.questionNum - 1][0];
                    if (page.list.find(item => item.value === selectedAns && item.fillInLimit)) {
                        ret[page.questionNum - 1] = ans[page.questionNum - 1].join('-');
                    } else {
                        ret[page.questionNum - 1] = ans[page.questionNum - 1][0];
                    }
                }
            } else {
                ret[page.questionNum - 1] = ans[page.questionNum - 1][0];
            }
        }
    })
    return ret;

}

function SurveyComponent({onClose, onEnd, metaData, pages, topic}) {
    const [state, dispatch] = useReducer(reducer, pages, initState)
    const page = pages[state.page]
    useEffect(() => {
        if (state.page >= metaData.numOfPage) {
            onEnd(composeAns(state.ans, pages));
        }
    }, [state.page])
    if (!page) return null;
    if (page.style === SurveyPageStyle.CONFIRMATION) {
        return <ConfirmationPage page={page} onClose={onClose} onConfirm={() => {
            dispatch(createInputAns(1))
        }}/>
    } else if (page.style === SurveyPageStyle.RADIOFORM) {
        return <RadioPage key={page.pageNum}
                          onBack={() => dispatch(createGoBack())}
                          page={page}
                          defaultAns={state.ans[page.questionNum - 1]}
                          onNext={(ans, num, qNum) => {
                              dispatch(createInputAns(num + 1, ans, qNum))
                          }}
                          numOfQuestion={metaData.numOfQuestion}
        />
    } else if (page.style === SurveyPageStyle.INPUTBOX) {
        return <InputPage key={page.pageNum}
                          onBack={() => dispatch(createGoBack())}
                          page={page}
                          defaultAns={state.ans[page.questionNum - 1]}
                          onNext={(ans, num, qNum) => {
                              dispatch(createInputAns(num + 1, ans, qNum))
                          }}
                          numOfQuestion={metaData.numOfQuestion}
        />
    } else if (page.style === SurveyPageStyle.RATING){
        return <RatingPage key={page.pageNum}
                           onBack={() => dispatch(createGoBack())}
                           page={page}
                           defaultAns={state.ans[page.questionNum - 1]}
                           onNext={(ans, num, qNum) => {
                               dispatch(createInputAns(num + 1, ans, qNum))
                           }}
                           numOfQuestion={metaData.numOfQuestion}
                           topic={topic}


        />
    } else if (page.style === SurveyPageStyle.CLOSE){
            return <ClosePage key={page.pageNum}
                               page={page}
                               onClose={() => {
                                   dispatch(createInputAns(page.pageNum + 1))
                               }}


            />
    }
}

export default function Survey({isOpen, onClose, onEnd, metaData, pages, topic}) {

    return <SurveyModal
        isOpen={isOpen}
        component={<SurveyComponent onClose={onClose} onEnd={onEnd} metaData={metaData} pages={pages} topic={topic}/>}
    />
}