import styled from 'styled-components';
import {TitleD3, TitleD4} from "./typography";
import {Input} from "./pages/components/input";
import {useEffect, useState} from "react";
import md5 from 'md5';
import BlockScreen from "./pages/components/blockScreen";

//No longer in used, for blocking access during alpha

const InputCtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  width: 100%;
  z-index: 100000;
`

function Passcode(){
    const [code, setCode] = useState('');
    const [isBlock, setIsBlock] = useState(true);
    const onInputChange = (val) => {
        if(md5(val) === 'af02cc03002c91e2fff61c857fa8dbbd'){
            document.body.style.overflow = 'auto';
            setIsBlock(false);
        } else {
            setCode(val);
        }
    }
    useEffect(()=>{
        document.body.style.overflow = 'hidden'
    },[])
    if(!isBlock){
        return null;
    }
    return (
        <BlockScreen>
            <InputCtn>
                <TitleD3>SGDc Inclusive Cancer Care</TitleD3>
                <TitleD4>Please Enter Passcode</TitleD4>
                <Input value={code} onChange={onInputChange} type='password'/>

            </InputCtn>
        </BlockScreen>
    )

}

export default Passcode;