import styled from "styled-components";
import {AffirmButton, DenyButton} from "../../pages/components/buttons";

const ConfirmCtn = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 24px;
`

export default function Confirmation({onClickPos, onClickNeg}){
    return <ConfirmCtn>
        <DenyButton text="No" onClick={onClickNeg} isStopAspect style={{minWidth: 100, maxWidth:100}}/>
        <AffirmButton text="Yes" onClick={onClickPos} isStopAspect style={{minWidth: 100, maxWidth:100}}/>
    </ConfirmCtn>
}