import styled from "styled-components";
import Icon from '../images/misc/crossIcon.png'
import {useContext, useState} from "react";
import {breakpoints} from "../../breakpoints";
import ExitModal from "../../game/components/exitModal";
import {DimContext} from "../../App";

const CloseBase = styled.div`
  float: left;
  border-radius: ${props=> props.ratio * 8}px;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    background-color: #EFEEF1;
  }
`

const CloseIcon = styled.div`
  background-image: url(${Icon});
  background-size: contain;
  background-repeat: no-repeat;
  width: ${props=> props.ratio * 24}px;
  height: ${props=> props.ratio * 24}px;
  margin: ${props=> props.ratio * 12}px;
`

function CloseButton({style}){
    const dim = useContext(DimContext)
    const [isCloseModalOpen, setIsCloseModalOpen] = useState(false)
    const buttonRatio = breakpoints.medium >= document.documentElement.clientWidth ? 1 :dim.ratio
    return (
        <>
            <CloseBase ratio={buttonRatio} style={style} onClick={()=> setIsCloseModalOpen(true)}>
                <CloseIcon  ratio={buttonRatio}/>
            </CloseBase>
            <ExitModal isOpen={isCloseModalOpen} onCancel={() => setIsCloseModalOpen(false)}/>
        </>
    )
}

export default CloseButton