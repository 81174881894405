import {PaddedPage} from "../../components/page";
import styled from "styled-components";
import useGameRouter, {GAME_ROUTE} from "../../hooks/useGameRouter";
import {BodyB1, TitleD2, TitleD3, TitleD4, TitleHL} from "../../../typography";
import StoryModel from "../../classes/storyModel";
import {CATEGORY_INFO_TYPE} from "../../data/category";
import {breakpoints, device} from "../../../breakpoints";
import HeaderContainer from "../../components/headerCtn";
import {forwardRef, useContext} from "react";
import {DimContext} from "../../../App";
import ScrollDiv from "../../../pages/components/scrollDiv";
import useResponsive from "../../../hook/useResponsive";
import {useTranslation} from "react-i18next";
import GaUtility from "../../../gaUtility";

const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
`

const InfoPageFrame = styled(PaddedPage)`
  padding: ${props=>props.ratio * 168}px ${props=>props.ratio * 152}px 0;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: white;
  z-index: 5;
  ${device.onlyMobile}{
    padding: 20px;
  }
  ${device.onlyTablet}{
    padding: 24px;
  }
`

const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  
  ${device.tabletAndMobile}{
    flex-wrap: wrap;
    gap: 24px;
  }
  ${device.onlyMobile}{
    gap: 16px;
  }
`

export const CategoryCardData = [
    {title: 'Pronouns', category:CATEGORY_INFO_TYPE.pronouns, color: '#E2584C', bColor: '#FFEDED', desc:'pronoun_desc'},
    {title: 'Gender Identity', category:CATEGORY_INFO_TYPE.gender, color: '#FA9B3F', bColor: '#FFF2E4', desc:'gender_desc'},
    {title: 'Sexual Orientation', category:CATEGORY_INFO_TYPE.sexuality, color: '#3EA736', bColor: '#E6F2E5', desc:'sexuality_desc'},
    {title: 'Relationship Diversity', category:CATEGORY_INFO_TYPE.relationship, color: '#9722BA', bColor: '#FBEFFF', desc:'relationship_desc'}
]

const CardFrame = styled.div`
  flex: 1;
  border: 8px solid #F6F8FA;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-right: 24px;
  border-radius: 16px;
  cursor: pointer;
  &:last-child{
    margin-right: 0;
  }
  
  &:hover {
    background-color: ${props=> props.bColor || 'white'};
  }
  
  &:active{
    border-color: ${props=>props.color || '#F6F8FA'};
  }
  
  ${device.tabletAndMobile}{
    margin: 0;
  }
  ${device.onlyTablet}{
    min-width: ${props=>(props.dim.width / 2) - 12}px;
  }
  ${device.onlyMobile}{
    padding: 16px;
    min-width: ${props=>(props.dim.width)}px;
  }
`

const CardCircle = styled.div`
  height: 94px;
  width: 94px;
  border-radius: 50%;
  background-color: ${(props) => props.color || 'black'};
  margin-bottom: 16px;
  pointer-events: none;
  ${device.onlyMobile}{
    height: 45px;
    width: 45px;
    margin-bottom: 8px;
  }
`

const CardTitle = styled(TitleD4)`
  letter-spacing: -0.6px;
  margin-bottom: 8px;
  pointer-events: none;
`


const CardPhoneTitle = styled(TitleHL)`
  letter-spacing: -0.6px;
`

const CardDesc = styled(BodyB1)`
  color: #6E7781;
  pointer-events: none;
`

const PhoneTitle = styled(TitleD4)`
  font-weight: 700;
  text-align: left;
  margin-top: 24px;
  margin-bottom: 24px;
`
const TabletTitle = styled(TitleD3)`
  margin-top: 36px;
  margin-bottom: 36px;
`


function Card({info:{color, title, desc, bColor, category}, onClick}){
    const {t:tC} = useTranslation('common', {keyPrefix:'tags'})
    const {t} = useTranslation('pregame', {keyPrefix:'category_selection'})
    // console.log(t);
    const dim = useContext(DimContext);
    const isPhone = window.innerWidth < breakpoints.small;
    const Title = isPhone ? CardPhoneTitle : CardTitle
    return (<CardFrame id={"topic_select_"+category}
                       name={category}
                       className="topic_card"
                       color={color}
                       bColor={bColor}
                       onClick={onClick}
                       dim={dim}>
        <CardCircle color={color}/>
        <Title>{tC(title)}</Title>
        <CardDesc>{t(desc)}</CardDesc>
    </CardFrame>)
}

const Frame = () => {

    const [, useRoute] = useGameRouter();
    const storyModel = new StoryModel();
    const dim = useContext(DimContext);
    let Font = TitleD2;
    const isPhone = window.innerWidth < breakpoints.small;
    if(isPhone){
        Font = PhoneTitle;
    } else if (window.innerWidth < breakpoints.medium){
        Font = TabletTitle
    }
    const {t} = useTranslation('pregame', {keyPrefix:'category_selection'})
    return(
        <InfoPageFrame ratio={dim.ratio}>
            <HeaderContainer hideLogo/>
            <Font style={{whiteSpace:'pre-wrap'}}>{t('title')}</Font>
            <CardContainer>
                {CategoryCardData.map(i => <Card key={i.title} info={i}
                                                 onClick={()=>{
                                                     storyModel.categorySelected(i.category);
                                                     GaUtility.pushCategory(i.category);
                                                     useRoute.changeRoute( GAME_ROUTE.categoryInfoPage)}
                                                 }/>)}
            </CardContainer>
            <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}/>
        </InfoPageFrame>

    )
}

function CategoryPage(){
    const [device] = useResponsive();

    if(device.isMobile){
        return <ScrollDiv>
            <Frame/>
        </ScrollDiv>
    }

    return (
        <>
            <Background/>
            <Frame/>
        </>)
}

export default CategoryPage;