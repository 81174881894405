import NextArrow from '../images/misc/nextArrow.png'
import BackArrow from '../images/misc/backArrow.png'
import useGameRouter from "../hooks/useGameRouter";
import {AffirmButton, DenyButton} from "../../pages/components/buttons";

//onclick takes in a callback for page navigation
// style={style} text={text}  type={type} id={id} name={name} isDisabled={isDisabled}
function NextButton({route, onClick, disableRoute, ...props}) {
    const [, useRoute] = useGameRouter()

    return <AffirmButton rightIcon={NextArrow} {...props}
                         onClick={() => {
        if(onClick){
            onClick(route && (()=>useRoute.changeRoute(route)))
        } else if(route){
            useRoute.changeRoute(route);
        }
    }}/>
}

function BackButton({text, onClick, style, disableRoute, ...props}) {
    const [, useRoute] = useGameRouter()


    return <DenyButton text={text} style={style} leftIcon={BackArrow} {...props}
                         onClick={() => {
                             !disableRoute && useRoute.goBack();
                             onClick && onClick()
                         }}/>

}

export {NextButton, BackButton}