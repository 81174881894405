import styled from 'styled-components'



// because during game, the text is also stretch/shrink according to screen
// Refactor: into nested object

function getThemeSize(props,size){
    if(!props || !props.theme || !props.theme.ratio) return size;
    return size * props.theme.ratio
}

const TitleD1= styled.div`

  font-size: ${props=>getThemeSize(props,48)}px;
  line-height: ${props=>getThemeSize(props,52)}px;
  font-weight: 700;
  color: #24292F;
`


const TitleD2= styled.div`

  font-size: ${props=>getThemeSize(props,40)}px;
  line-height: ${props=>getThemeSize(props,52)}px;
  font-weight: 700;
  letter-spacing: ${props=>getThemeSize(props,-0.6)}px;
  color: #24292F;
`


const TitleD3 = styled.div`
  font-size: ${props=>getThemeSize(props,32)}px;
  line-height: ${props=>getThemeSize(props,42)}px;
  font-weight: 700;
  letter-spacing: ${props=>getThemeSize(props,-0.6)}px;
  color: #24292F;
`

const TitleD4 = styled.div`
  font-size: ${props=>getThemeSize(props,24)}px;
  line-height: ${props=>getThemeSize(props,34)}px;
  font-weight: 500;
  letter-spacing: ${props=>getThemeSize(props,-0.6)}px;
  color: #24292F;
`

const TitleD4Bold = styled(TitleD4)`
  font-weight: 600;
`

const TitleDA = styled.div`
  font-size: ${props=>getThemeSize(props,20)}px;
  line-height: ${props=>getThemeSize(props,32)}px;
  font-weight: 400;
  letter-spacing: ${props=>getThemeSize(props,-0.6)}px;
  color: #2E2D2E;

`

const TitleHL = styled.div`
  font-weight: 400;
  font-size: ${props=>getThemeSize(props,20)}px;
  line-height: ${props=>getThemeSize(props,28)}px;
  letter-spacing: ${props=>getThemeSize(props,-0.6)}px;
  color: #24292F;
`

const TitleHLBold = styled(TitleHL)`
  font-weight: 500;
`

const TitleBtn = styled.span`
  font-weight: 600;
  font-size: ${props=>getThemeSize(props,16)}px;
  line-height: ${props=>getThemeSize(props,28)}px;
  letter-spacing: ${props=>getThemeSize(props,-0.6)}px; 
`

const TitleSH1 = styled.div`
  font-weight: 500;
  font-size: ${props=>getThemeSize(props,16)}px;
  line-height: ${props=>getThemeSize(props,28)}px;
  letter-spacing: ${props=>getThemeSize(props,-0.6)}px;
  color: #24292F;
`

const BodyB1 = styled.div`
  font-weight: 400;
  font-size: ${props=>getThemeSize(props,16)}px;
  line-height: ${props=>getThemeSize(props,24)}px;
  color: #24292F;
`
const BodyB2 = styled.div`
  font-weight: 400;
  font-size: ${props=>getThemeSize(props,14)}px;
  line-height: ${props=>getThemeSize(props,20)}px;
  color: #89888B;
`


const BodyCaption = styled.div`
  font-weight: 400;
  font-size: ${props=>getThemeSize(props,12)}px;
  line-height: ${props=>getThemeSize(props,18)}px;
`


export {TitleD1, TitleD2, TitleD3, TitleD4, TitleD4Bold, TitleDA, TitleHL, TitleHLBold, TitleBtn, TitleSH1, BodyB1, BodyB2, BodyCaption}