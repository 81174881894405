import Scene from "./scene";
import stories from "../data/fullStory";
import {CATEGORY_INFO_TYPE} from "../data/category";


function dataToStoryLines(data) {
    const lines = {}
    data.category.forEach(cat=>{
        if(!lines[cat.type]){
            lines[cat.type] = {};
        }
        cat.storyline.forEach(sl => {
            if(!lines[cat.type][sl.patient]){
                lines[cat.type][sl.patient] = {};
            }
            sl.scene.forEach(s => {
                s.images.forEach(img=> {if(!img) console.log(cat.type, sl.patient, s.id)})
                lines[cat.type][sl.patient][s.id] = new Scene(s.id,s.type,s.images, s.bubbleText, s.actions, s.hint, s.isBeginning);
            })
        })
    })

    return lines;
}


class StoryMap {
    storylines = {};
    categorySelected;
    selectedPatientId;

    constructor() {
        let lines = dataToStoryLines(stories);
        this.storylines = lines;
    }


    set category(category){
        this.categorySelected = category;
    }

    get category(){
        return this.categorySelected
    }

    set patientId(id){
        this.selectedPatientId = id;
    }

    get currentStoryLineId(){
        let ret = '';
        switch (this.categorySelected){
            case CATEGORY_INFO_TYPE.pronouns:
                ret += '01'
                break;
            case CATEGORY_INFO_TYPE.gender:
                ret += '02'
                break;
            case CATEGORY_INFO_TYPE.sexuality:
                ret += '03'
                break;
            case CATEGORY_INFO_TYPE.relationship:
                ret += '04'
                break;
            default:
                ret += '00'
                break;
        }
        ret += ('-0'+ this.selectedPatientId)
        return ret;
    }

    reset = () => {
        this.categorySelected = undefined;
        this.selectedPatientId = undefined;
    }

    getScene = (sceneId) => {
        return this.selectedPatientId && this.categorySelected ?  this.storylines[this.categorySelected][this.selectedPatientId][sceneId] : null;
    }

    getBeginningId = () => {
        return this.selectedPatientId && this.categorySelected ? Object.values(this.storylines[this.categorySelected][this.selectedPatientId]).find(scene=> scene.isBeginning).id: null;
    }

}


export default StoryMap;