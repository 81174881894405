import styled from "styled-components";
import {device} from "../../breakpoints";
import ScrollDiv from "../../pages/components/scrollDiv";


const BackDrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(36, 41, 47, 0.5);
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 90px 8px;


    ${device.tabletAndMobile} {
        padding-top: 16px;
        padding-bottom: 16px;
    }


`

const ModalBase = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    background-color: white;
    border-radius: 16px;
    max-width: 700px;
    width: 100%;
    z-index: 2001;
`

export const ContentBase = styled.div`
    padding: 24px;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    position: relative;
    max-height: calc(100vh - 244px);

    ${device.tabletAndMobile} {
        max-height: calc(100vh - 96px);
    }

`

//TODO: scroll div not able to apply to limited size
function SurveyModal({component, isOpen}) {
    if (!isOpen) {
        return null;
    }
    return (
        <BackDrop onClick={(e) => e.stopPropagation()}>
            <ModalBase>
                {component}
            </ModalBase>
        </BackDrop>
    )
}

export default SurveyModal;