import styled, {css} from "styled-components";
import {TitleD3, TitleD4Bold, TitleHLBold, TitleSH1} from "../../typography";
import HCPTopic from '../images/frontpage/HCPTopic.png';
import GameExample from '../images/frontpage/gameExample.png'

import HCP_1_01 from '../../game/images/hcp/1/HCP-01.png'
import HCP_2_03 from '../../game/images/hcp/2/HCP-03.png'
import {CategoryCardData} from "../../game/pages/pregame/categoryPage";
import {breakpoints, device} from "../../breakpoints";
import useResponsive from "../../hook/useResponsive";
import {Base, BaseContent} from "../components/base";
import {useTranslation} from "react-i18next";

const FragTitleCSS = css`
  margin-bottom: 16px;

  ${device.onlyMobile} {
    margin-bottom: 12px;
  }
`

const FragmentTitle = styled(TitleD3)`
    ${FragTitleCSS}
`

const FragmentPhoneTitle = styled(TitleD4Bold)`${FragTitleCSS}`

function FragTitle({children}) {
    const [deviceResponsive] = useResponsive();
    const Comp = deviceResponsive.isPhone ? FragmentPhoneTitle : FragmentTitle
    return <Comp>{children}</Comp>
}


const FragDescCSS = css`
  white-space: pre-wrap;
`

const FragmentDesc = styled(TitleHLBold)`
    ${FragDescCSS}
`

const FragmentPhoneDesc = styled(TitleSH1)`${FragDescCSS}`


function FragDesc({children, ...props}) {
    const [deviceResponsive] = useResponsive();
    const Comp = deviceResponsive.isPhone ? FragmentPhoneDesc : FragmentDesc
    return <Comp {...props}>{children}</Comp>
}

const VerticalCtn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const ColoredBase = styled(Base)`
  background-color: #E5EBF4;

`

const TopicCtn = styled(BaseContent)`
  display: flex;
  padding: 74px 164px;
  align-items: center;

  ${device.onlyTablet} {
    padding: 64px 36px;
  }

  ${device.onlyMobile} {
    padding: 64px 16px;
  }
`

const TopicHCP = styled.div`
  width: 140.39px;
  height: 364px;
  background: url(${HCPTopic}) center no-repeat;
  background-size: contain;
  @media only screen and (max-width: ${breakpoints.medium + 150}px) {
    display: none;
  }
`

const TopicCardCtn = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 36px;

  @media only screen and (max-width: ${breakpoints.medium + 150}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    margin-top: 24px;
  }
`

const TopicCard = styled.div`
  width: 180px;
  height: 140px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 4px solid #E4E2E7;
  background-color: white;
  border-radius: 12px;
  @media only screen and (max-width: ${breakpoints.medium + 150}px) {
    width: auto;
  }

  ${device.onlyMobile} {
    padding: 14px;
  }
`

const TopicCircle = styled.div`
  width: 67px;
  height: 67px;
  background-color: ${props => props.color};
  border-radius: 50%;
  margin-bottom: 11px;
`

const TopicTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.43px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  ${device.onlyMobile} {
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.39px;

  }
`


function TopicFragment() {
    const {t} = useTranslation('common', {keyPrefix: 'homepage.topicPage'})
    const {t:tTag} = useTranslation('common', {keyPrefix: 'tags'})
    return (
        <ColoredBase>
            <TopicCtn>
                <VerticalCtn>
                    <FragTitle>{t('title')}</FragTitle>
                    <FragDesc style={{maxWidth: 730}}>{t('subtitle')}</FragDesc>
                    <TopicCardCtn>
                        {CategoryCardData.map(data => <TopicCard key={data.title}>
                            <TopicCircle color={data.color}/>
                            <TopicTitle>{tTag(data.title)}</TopicTitle>
                        </TopicCard>)}
                    </TopicCardCtn>
                </VerticalCtn>
                <TopicHCP/>
            </TopicCtn>
        </ColoredBase>
    )
}


const ExampleCtn = styled(BaseContent)`
  display: flex;
  padding: 55px 164px;
  background-color: white;
  flex-direction: row;
  align-items: center;
  ${device.tabletAndMobile}{
    flex-direction: column-reverse;
  }
  ${device.onlyTablet} {
    padding: 64px 36px;
  }

  ${device.onlyMobile} {
    padding: 64px 16px;
  }
`
const ExampleImg = styled.img`
  width: 526px;
  margin-right: 64px;
  ${device.tabletAndMobile}{
    margin-right: 0;
    margin-top: 16px;
    width: 100%;
    max-width: 728px;
  }
`

function GameFragment() {
    const {t} = useTranslation('common', {keyPrefix: 'homepage.learnPage'})
    return (
        <Base>
            <ExampleCtn>
                <ExampleImg src={GameExample}/>
                <VerticalCtn>
                    <FragTitle>{t('title')}</FragTitle>
                    <FragDesc>{t('subtitle')}</FragDesc>
                </VerticalCtn>
            </ExampleCtn>
        </Base>
    )

}

const FeedbackCtn = styled(BaseContent)`
  display: flex;
  padding: 71px 164px;
  flex-direction: row;
  ${device.tabletAndMobile}{
    flex-direction: column;
    align-items: center;
  }
  ${device.onlyTablet} {
    padding: 64px 36px;
  }

  ${device.onlyMobile} {
    padding: 64px 16px;
  }
`

const HCPCtn = styled.div`
    display: flex;
  margin-right: 46px;
  ${device.tabletAndMobile}{
    margin-right: 0;
  }
  ${device.onlyTablet}{
    margin-top: 55px;
  }
  ${device.onlyMobile}{
    margin-top: 36px;
  }
`

const FeedbackHCP1 = styled.img`
  width: 134.48px;
  margin-right: 12px;
  ${device.onlyMobile}{
    width: 72px;
  }
`
const FeedbackHCP2 = styled.img`
  width: 134.48px;
  ${device.onlyMobile}{
    width: 72px;
  }
`

function FeedbackFragment() {
    const {t} = useTranslation('common', {keyPrefix: 'homepage.feedbackPage'})
    return (
        <ColoredBase>
            <FeedbackCtn>
                <VerticalCtn>
                    <FragTitle>{t('title')}</FragTitle>
                    <FragDesc style={{maxWidth: 659}}>{t('subtitle')}<a href="mailto:sgdc@uhn.ca"
                                               style={{color: '#0969DA'}}>sgdc@uhn.ca</a></FragDesc>
                </VerticalCtn>
                <HCPCtn>
                    <FeedbackHCP1 src={HCP_1_01}/>
                    <FeedbackHCP2 src={HCP_2_03}/>

                </HCPCtn>

            </FeedbackCtn>

        </ColoredBase>
    )

}

export {TopicFragment, GameFragment, FeedbackFragment}