import CTN01smile from "../../images/patient/chetan/CTN-01-smile.png";
import HCP_0_01 from "../../images/hcp/0/HCP-01.png";
import HCP_1_01 from "../../images/hcp/1/HCP-01.png";
import HCP_2_01 from "../../images/hcp/2/HCP-01.png";
import HCP_FB_01 from "../../images/hcp/HCP-FB-01.png";
import HCP_A_01 from "../../images/hcp/HCP-F-C01.png";
import LXY01 from '../../images/patient/lexy/LXY-01.png'
import LXYMON01 from '../../images/patient/lexy/LXY-M-01.png'
import MAX01 from '../../images/patient/max/MAX-01.png'
import EMMA01 from '../../images/patient/max/MAX-E-01.png'
import ROS01 from '../../images/patient/ross/ROS-01.png'

export const CHATICON = {
    'CTN' : {img: CTN01smile, top: 9, right: -5, bottom: -157,left: -7},
    'HCP' : {img: [HCP_0_01, HCP_1_01, HCP_2_01], top: 8, right: -6, bottom: -156,left: -6},
    'HCP-FB' : {img: HCP_FB_01, top: 8, right: -6, bottom: -156,left: -8},
    'LXY' : {img: LXY01, top: 6, right: -5, bottom: -157,left: -15},
    'LXY-MOM' : {img: LXYMON01, top: 6, right: -5, bottom: -145,left: -10},
    'MAX' : {img: MAX01, top: 6, right: -8, bottom: -157,left: -9},
    'EMMA' : {img: EMMA01, top: 0, right: -8, bottom: -157,left: -9},
    'ROS' : {img: ROS01, top: 6, right: -8, bottom: -157,left: -6},
    'HCPA' : {img: HCP_A_01, top: 8, right: -6, bottom: -156,left: -8}, // TODO: adjust
}