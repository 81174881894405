// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, getDocs, collection } from "firebase/firestore";
import {isDev} from "./settings";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Firebase related functions

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_apiKey,
    authDomain: process.env.REACT_APP_authDomain,
    projectId: process.env.REACT_APP_projectId,
    storageBucket: process.env.REACT_APP_storageBucket,
    messagingSenderId: process.env.REACT_APP_messagingSenderId,
    appId: process.env.REACT_APP_appId,
    measurementId: process.env.REACT_APP_measurementId
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

const firestore = getFirestore(app)

// object field name
const pregameArrField = [
    'age',
    'race',
    'gender',
    'sexual_orientation',
    'relationship',
    'profession',
    'is_volunteer',
]

const endgameArrField = [
'apply_content',
'provide_need',
'learn_inclusive',
'new_interpretation',
'choose_wrong',
'communicate_clearly',
'feedback',
    'story_line',
]

function surveyToObj(arr, user_id, field){
    const ret = {};
    for(let i = 0 ;i< arr.length ;i+=1){
        ret[field[i]]= arr[i]
    }
    ret.user_id = user_id
    return ret;
}

const pregamePath = isDev() ? 'dev_pre_game_survey':'pre_game_survey'
const endgamePath = isDev() ? 'dev_end_game_survey':'end_game_survey'

export function updatePregameSurvey(survey, user_id, onSuccess){
    updateSurvey(surveyToObj(survey,user_id,pregameArrField), pregamePath, onSuccess)
}

export function updateEndgameSurvey(survey, user_id, onSuccess){
    updateSurvey(surveyToObj(survey,user_id,endgameArrField), endgamePath, onSuccess)
}

// push to server
function updateSurvey(surveyObj, path, onSuccess){
    setDoc(doc(firestore, path, surveyObj.user_id+surveyObj.story_line), surveyObj,{merge:true})
        .then(()=>{
            onSuccess()
        }).catch(
            ()=> {
            })
}

function surveyToCSVLine(survey, path){
    if(!survey || !survey.user_id) return ''
    const arr = [];
    arr.push(survey.user_id);

    if(path === 'pregame'){
        for(let field of pregameArrField ){
            arr.push(survey[field] || '')
        }
    } else if (path === 'endgame'){
        for(let field of endgameArrField ){
            arr.push(survey[field] || '')
        }
    }

    return arr.join(',')
}

// get whole survey result
export async function getSurveyText(path){
    const arr = []
    try{
        const snapshot = await getDocs(collection(firestore,path=== 'pregame'? pregamePath: endgamePath))
        snapshot.forEach(doc=>{
            // console.log(doc.user_id, doc.data());
            const line = surveyToCSVLine(doc.data(), path)
            line && arr.push(line)
        })
        return arr.join('\n')
    } catch (e){
    }
}