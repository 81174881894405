const DOM = (
    <>
        <div>
            <h4>What you will learn</h4>
            <ul>
                <li>To learn about different types of sexual orientations</li>
                <li>To learn the meanings of the terms; 2SLGBTQIA+, homophobia,, transphobia, ally, and intersectional
                    identities
                </li>
                <li>To understand why and how sexual orientation relates to patient care</li>
                <li>To learn how to ask about sexual orientation and how to recover if you make a mistake</li>
            </ul>
        </div>
        <div>
            <h4>What is sexual orientation?</h4>
            <p>
                Sexual orientations are an emotional, romantic, affectional or sexual attraction or non-attraction to
                other people. It can be fluid, change over time and a variety of terms can be used to describe sexual
                orientations. Emotional and physical attraction can be 2 separate components making up a person’s sexual
                orientation.
                <br/><br/>
                The acronym 2SLGBTQIA+ is often used to refer to sexual orientation. The acronym means 2 Spirit,
                lesbian, gay, bisexual, transgender, queer, intersex, asexual, + any identities not included in the
                acronym. Not all of these terms actually solely represent sexual orientation. Transgender indicates
                gender identity, not sexual orientation. Queer can suggest either or both sexual orientation and gender
                identity. Intersex refers to sex, being born with a combination of biological traits and sex
                characteristics, internal and external genitals (testes and ovaries). It used to be referred to as
                hermaphrodite, but we do not use that term any more. As gender is not determined by physical
                characteristics or body parts, transgender, queer and intersex people can be any gender or sexual
                orientation.
                In Canada, we moved the 2S to the beginning of the acronym, to acknowledge that Two Spirit Indigenous
                people were the first sexual and gender minority people in North America, and to demonstrate solidarity
                with our Indigenes people.
                <br/><br/>
                At Princess Margaret, our program has adopted the term Sexual and Gender Diversity, (SGD) to refer to
                patients and chosen families with a range of sexual orientations and gender identities.
                <br/><br/>Terms used to describe Sexual Orientation include:
                <ul>
                    <li>Heterosexual or straight: emotional and sexual attraction to people of the opposite sex or
                        gender
                    </li>
                    <li>The term “homosexual” is not longer used and can be considered offensive to many</li>
                    <li>Gay: emotional or sexual attraction to people of the same sex or gender. Men often use this term
                        to refer to emotional or sexual attraction to other men
                    </li>
                    <li>Lesbian: women who’s emotionally or sexually attracted to women. Some women may also use
                        identify as being gay
                    </li>
                    <li>Bi or bisexual – emotional or sexual attraction to both men and women</li>
                    <li>Pansexual: emotional or sexual attraction to any gender or sex</li>
                    <li>Asexual: not sexually attracted to others and may or may not be emotionally or romantically
                        attracted others. People who are asexual may find others attractive, but are not interested in
                        having a sexual relationship with other people. Emotional intimacy may be important, but
                        closeness is achieved through means other than sex. Some asexual people don’t feel romantic
                        attraction or want to be in romantic relationships. They may identify as aromantic. Being
                        aromantic and asexual or two separate things. Sometime the abbreviation “Ace” is used for
                        asexual.
                    </li>
                    <li>Queer: a variety of sexual and gender identities that are not straight and cisgender.
                        Historically Queer was used as a derogatory term, but it has been reclaimed by the community and
                        now used with pride. Some people still find this term offensive so it is best not to use it
                        unless you know that is how someone identifies them self.
                    </li>
                </ul>
                <br/>
                Language always changing, people define themselves with many terms and in many ways. Some people don’t
                relate to any of these terms and prefer not to label themselves, or choose they may choose multiple
                terms. How a person identifies their sexual orientation does not always reflect their sexual behaviors.
                People may use many other terms to identify their romantic and sexual attractions. If you don’t
                understand or are not sure, it is ok to respectfully ask what the term means to that person.
                <br/>
                Gender does not determine sexual orientation – these are two separate issues. It is important to not
                assume how a person identifies their sexual orientation. For example, a patient who identifies as a bi
                sexual women, if she is in a relationship with a man, her sexual orientation is still bisexual. Sexual
                orientation can remain the same throughout a persons life, or it can shift and change over time. This is
                common and referred to as fluid.
            </p>
        </div>
        <div>
            <h5>Being an ally</h5>
            <p>
                The term “ally” is often used when speaking about 2SLGBTQIA people and sexual orientation. An ally is someone
                who speaks up and supports the rights of sexual and gender diverse people by challenging discrimination and
                heterosexism. An ally is often a person who identifies as heterosexual and cisgender, and takes action to
                promote equity and inclusion for all.
                <ul>
                    <li>Heterosexism is a bias that assumes that being heterosexual is the “normal” or morally correct sexual
                        orientation</li>
                    <li>Homophobia can be defined as negative attitudes and beliefs about people who identify as or who are perceived as
                        being 2SLGBTQIA+. Definitions often address these beliefs as prejudice or discrimination, hatred and irrational
                        fear.</li>
                    <li>Transphobia: negative attitudes and beliefs about people who are transgender</li>
                    <li>Biphobia: negative attitudes and beliefs about people who are bisexual</li>
                </ul>
                <br/>
                These are views that result in a great deal of harm to SGDc communities.
            </p>
        </div>
        <div>
            <h4>Why is sexual orientation important?</h4>
            <ul>
                <li>We cannot assume how someone identifies their sexual orientation based on sex, gender or who they are partnered
                    with</li>
                <li>The health care culture often makes assumptions that being heterosexual is the norm. This is called
                    “heteronormative”</li>
                <li>Heteronormative models of care can be damaging to SGDc patients</li>
                <li>This can result in individuals being excluded, and feeling that their families and chosen families are
                    disrespected</li>
            </ul>
        </div>
        <div>
            <h4>When do we need to know sexual orientation?</h4>
            <ul>
                <li>It can be important to know how someone identifies their sexual orientation so that we can provide appropriate
                    sexual health and fertility information.</li>
                <li>When interacting with patients, be aware of making assumptions. Ask questions about what health information and
                    resources would be appropriate.</li>
                <li>When asking about sexual orientation, think carefully about why you may need to know, how it relates to the
                    medical care that you are providing. Sometimes it can be helpful to explain or provide a context as to why you
                    are asking, to make patients feel more comfortable</li>
                <li>Some healthcare provider roles may require further information about sexual orientation (examples) and
                    respectful questions can be explored if needed</li>
                <li>Knowing a patient’s sexual orientation can help us to be mindful about not making assumptions, such as
                    assumptions based who a patient may bring to the appointment. Lesbians often indicate that their partner is
                    assumed to be a sister or friend, rather than their partner. Sexual orientation can also be assumed based on the
                    perceived gender of a patient’s partner. For example, a patient may identify their gender as a man, be in a
                    relationship with a man, and identify their sexual orientation as pansexual.</li>
            </ul>
        </div>
        <div>
            <h4>How can I be inclusive about sexual orientation?</h4>
            <ul>
                <li>Respect means accepting and using the terms with which people identify.</li>
                <li>It is important to respect everyone’s right to choose the sexual orientation that fits best for them</li>
                <li>Checking out assumptions and asking inclusive questions can increase inclusion for everyone</li>
                <li>If you do not know or have not asked someone how they identify their sexual orientation, it may be best to ask
                    questions rather than making assumptions. For example, “is birth control or fertility import to you?” or “would
                    information about penetrative sex be relevant to you?”.</li>
            </ul>
        </div>
        <div>
            <h4>What if I make a mistake?</h4>
            <ul>
                <li>Apologize, correct yourself and move on. Making a big deal out if it can just make the situation more
                    uncomfortable for everyone.</li>
                <li>We can all make mistakes. It can take time and practice to change our assumptions and language and be inclusive.</li>
                <li>Acknowledging, apologising, and making a commitment to do better next time, goes a long way to communicate
                    caring and respect. The important piece is the commitment to better next time.</li>
            </ul>
        </div>
        <div>
            <h4>How does using correct sexual orientation support inclusive care?</h4>
            <ul>
                <li>Understanding sexual orientation is an easy way to show respect. We can never assume sexual orientation and it
                    is not the same as gender identity. When you don’t know for sure – ask respectfully using neutral or open
                    questions about relevant information that may be important. Whether intentional or not, using the wrong sexual
                    orientation can be hurtful.</li>
                <li>Understanding sexual orientation can help us to create an inclusive and more welcoming environment where
                    everyone is valued and accepted. It helps to foster a culture of belonging. It will help us to be more aware of
                    inaccurate assumptions such as assuming the caregiver with the patient is a friend rather than a partner.</li>
            </ul>
        </div>
        <div>
            <h4>How can I incorporate sexual orientation as part of my practice of inclusive care? Key takeaways:</h4>
            <ul>
                <li>Introduce yourself with your pronouns and ask others how they would like to be addressed. This is a symbolic
                    gesture of providing inclusive care, although it may not tell you anything about sexual orientation.</li>
                <li>Avoid assuming sexual orientation based on appearance, gender or support people with the patient. Ask respectful
                    and inclusive questions when appropriate, in order to provide relevant information and resources</li>
                <li>Become familiar with various sexual orientation identities and correct terminology</li>
                <li>Be an ally and speak up if you hear colleagues making assumptions or using derogatory or outdated language.</li>
            </ul>
        </div>
        <div>
            <h6>More resources about sexual orientation:</h6>
            <ul>
                <li><a target="_blank" rel="noreferrer" href="https://www.hrc.org/resources/sexual-orientation-and-gender-identity-terminology-and-definitions">https://www.hrc.org/resources/sexual-orientation-and-gender-identity-terminology-and-definitions</a></li>
                <li><a target="_blank" rel="noreferrer" href="https://pmchosenfamily.ca">Sexual Orientation and Gender Identity Data Collection in Oncology Practice: Findings of an ASCO Survey</a></li>
                <li><a target="_blank" rel="noreferrer" href="https://pmchosenfamily.ca">Charles S. Kamen, Mandi L. Pratt-Chapman, Stephen C. Meersman, Gwendolyn P. Quinn, Matthew B. Schabath, Shail
                    Maingi, MD, Janette K. Merrill, Elizabeth Garrett-Mayer, Melinda Kaltenbaugh, Caroline Schenkel, and Shine Chang
                    </a></li>
                <li><a target="_blank" rel="noreferrer" href="https://pmchosenfamily.ca">JCO Oncology Practice 2022 18:8, e1297-e1305</a></li>
                <li><a target="_blank" rel="noreferrer" href="https://pmchosenfamily.ca">Quinn GP, Alpert AB, Sutter M, Schabath MB. What Oncologists Should Know About Treating Sexual and Gender
                    Minority Patients With Cancer. JCO Oncol Pract. 2020 Jun;16(6):309–16. doi: 10.1200/OP.20.00036. Epub 2020 Jun
                    5. PMCID: PMC7291539.</a></li>
            </ul>
        </div>









































    </>


)

export default DOM;