import Item08 from '../images/background/Item-08.png'
import Item09 from '../images/background/Item-09.png'
import Item10 from '../images/background/Item-10.png'
import Item15 from '../images/background/Item-15.png'

const CATEGORY_INFO_TYPE = {
    pronouns: 'pronouns',
    gender: 'gender',
    sexuality: 'sexuality',
    relationship: 'relationship'
}

const CATEGORY_INFO_TYPE_TO_BIT = {
    [CATEGORY_INFO_TYPE.pronouns]: 0,
    [CATEGORY_INFO_TYPE.gender]: 1,
    [CATEGORY_INFO_TYPE.sexuality]: 2,
    [CATEGORY_INFO_TYPE.relationship]: 4,
}

/*
categoryDocs = {
    [type: CategoryInfoType] : {
        title: intlString;
        about: intlString;
        desc: intlString;
        learnList: intlString[];
        plantImg: imageUrl;
        plantPos: PositionCoord;
        name: intlString;
        hubURL: url;
    }
}
 */

const categoryDocs = {
    [CATEGORY_INFO_TYPE.pronouns]: {
        title: 'Pronouns',
        about: 'about_pronouns',
        desc: 'pronoun_info',
        learnList: ['pronoun_list_1','pronoun_list_2','pronoun_list_3'],
        plantImg: Item10,
        plantPos: {bottom: -39,right: 8, width:186,height:169.64},
        name: 'Pronouns',
        hubURL: 'pronouns'

    },
    [CATEGORY_INFO_TYPE.gender]: {
        title: 'Gender Identity',
        about: 'about_gender',
        desc: 'gender_info',
        learnList: ['gender_list_1','gender_list_2','gender_list_3'],
        plantImg: Item08,
        plantPos: {bottom: -33,right: 8, width:175.39,height:263.65},
        name: 'Gender Identity',
        hubURL: 'gender'
    },
    [CATEGORY_INFO_TYPE.sexuality]: {
        title: 'Sexual Orientation',
        about: 'about_sexuality',
        desc: 'sexuality_info',
        learnList: ['sexuality_list_1','sexuality_list_2','sexuality_list_3'],
        plantImg: Item15,
        plantPos: {bottom: -39,right: 8, width:162.66,height:268.59},
        name: 'Sexual Orientation',
        hubURL: 'sexual-orientation'
    },
    [CATEGORY_INFO_TYPE.relationship]: {
        title: 'Relationship Diversity',
        about: 'about_relationship',
        desc: 'relationship_info',
        learnList: ['relationship_list_1','relationship_list_2','relationship_list_3'],
        plantImg: Item09,
        plantPos: {bottom: -45,right: 8, width:170.44,height:323.73},
        name: 'Relationship Diversity',
        hubURL: 'relationship'
    }
}
export {categoryDocs, CATEGORY_INFO_TYPE, CATEGORY_INFO_TYPE_TO_BIT};