import {useState} from "react";
import {getSurveyText} from "./firebase";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";


export default function Desd() {
    const [file, setFile] = useState('');
    const [p, setP] = useState('')

    const s = async (password, path)=>{
        const auth = getAuth();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, process.env.REACT_APP_A, password)
            const user = userCredential.user;
            if(user){
                const text = await getSurveyText(path)
                return text
            }
            return ''
        } catch(e) {
            return ''
        }
    }
    return <div style={{whiteSpace: 'pre-wrap', zIndex: 1000000, background: 'white', height: '100vh', textAlign:'left'}}>
        {!file && <input onChange={(e)=>setP(e.target.value)} value={p} type="password"/>}
        {!file && <button onClick={async() => {
            const ret = await s(p,'pregame')
            ret && setFile(ret)
        }}
        >pre-show</button>}
        {!file && <button onClick={async ()=>{
            const ret = await s(p,'pregame');
            const blob = new Blob([ret], { type: "text/plain" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            const date = new Date();
            link.download = `survey-${date.toISOString()}.csv`;
            link.href = url;
            link.click();
        }
        }>pre-download</button>}
        {!file && <button onClick={async() => {
            const ret = await s(p,'endgame')
            ret && setFile(ret)
        }}
        >end-show</button>}
        {!file && <button onClick={async ()=>{
            const ret = await s(p,'endgame');
            const blob = new Blob([ret], { type: "text/plain" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            const date = new Date();
            link.download = `survey-${date.toISOString()}.csv`;
            link.href = url;
            link.click();
        }
        }>end-download</button>}
        {file}
    </div>
}