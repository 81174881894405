import React, {useState, useCallback, useEffect, useRef, useLayoutEffect, useContext} from "react";
import "./scrollDiv.css";
import useSize from '../../hook/useSize'
import {DimContext} from "../../App";

const SCROLL_BOX_MIN_HEIGHT = 20;

export default function ScrollDiv({ children, className, heightVal, barNotOverlay, ...restProps }) {
    const dim = useContext(DimContext);
    const [isShowThumbnail, setShowThumbnail] = useState(false);
    const [scrollBoxHeight, setScrollBoxHeight] = useState(SCROLL_BOX_MIN_HEIGHT);
    const [scrollBoxTop, setScrollBoxTop] = useState(0);
    const [lastScrollThumbPosition, setScrollThumbPosition] = useState(0);
    const [isDragging, setDragging] = useState(false);
    const [showScrollBar, setShowScrollBar] = useState(false)
    const [scrollHostRef, contentSize] = useSize(false)

    const handleMouseOver = useCallback((e) => {
        setShowThumbnail(true);
    }, []);

    const handleMouseOut = useCallback(() => {
        setShowThumbnail(false);
    }, []);

    const handleDocumentMouseUp = useCallback(
        e => {
            if (isDragging) {
                e.preventDefault();
                setDragging(false);
            }
        },
        [isDragging]
    );

    const handleDocumentMouseMove = useCallback(
        e => {
            if (isDragging) {
                e.preventDefault();
                e.stopPropagation();
                const scrollHostElement = scrollHostRef.current;
                const { scrollHeight, offsetHeight } = scrollHostElement;

                let deltaY = e.clientY - lastScrollThumbPosition;
                let percentage = deltaY * (scrollHeight / offsetHeight);

                setScrollThumbPosition(e.clientY);
                setScrollBoxTop(
                    Math.min(
                        Math.max(0, scrollBoxTop + deltaY),
                        offsetHeight - scrollBoxHeight
                    )
                );
                scrollHostElement.scrollTop = Math.min(
                    scrollHostElement.scrollTop + percentage,
                    scrollHeight - offsetHeight
                );
            }
        },
        [isDragging, lastScrollThumbPosition, scrollBoxHeight, scrollBoxTop]
    );

    const handleScrollThumbMouseDown = useCallback(e => {
        e.preventDefault();
        e.stopPropagation();
        setScrollThumbPosition(e.clientY);
        setDragging(true);
    }, []);

    const handleScroll = useCallback(() => {
        if (!scrollHostRef) {
            return;
        }
        const scrollHostElement = scrollHostRef.current;
        const { scrollTop, scrollHeight, offsetHeight } = scrollHostElement;
        let newTop =
            (parseInt(scrollTop, 10) / parseInt(scrollHeight, 10)) * offsetHeight;
        newTop = Math.min(newTop, offsetHeight - scrollBoxHeight);
        setScrollBoxTop(newTop);
    }, [heightVal,scrollHostRef, scrollBoxHeight]);

    const measureHeight = useCallback(()=>{
        let clientHeight = 0;
        let scrollHeight = contentSize;
        const scrollHostElement = scrollHostRef.current;
        if(heightVal){
            clientHeight = heightVal.clientHeight;
        }else {
            clientHeight = scrollHostElement.clientHeight;
        }
        setShowScrollBar(clientHeight < scrollHeight)
        const scrollThumbPercentage = clientHeight / (scrollHeight||1);
        const scrollThumbHeight = Math.min(
            clientHeight,
            Math.max(
            scrollThumbPercentage * clientHeight,
            SCROLL_BOX_MIN_HEIGHT
        ));
        const scrollTop = (scrollHostElement.scrollTop / (scrollHeight||1)) * (clientHeight - scrollThumbHeight)

        setScrollBoxTop(Math.max(
            0,
            scrollTop
            ))
        setScrollBoxHeight(scrollThumbHeight);
    },[heightVal, setScrollBoxHeight, contentSize])

    const handleResize = useCallback(()=>{
        measureHeight();

    },[])

    useEffect(() => {
        const scrollHostElement = scrollHostRef.current;
        scrollHostElement.addEventListener("scroll", handleScroll, true);

        return function cleanup() {
            scrollHostElement.removeEventListener("scroll", handleScroll, true);
        };
    }, [dim.width,dim.height,scrollBoxHeight]);

    // initial layout
    // inner ref measure re-layout
    useLayoutEffect(()=>{
        measureHeight();
    },[heightVal, dim.width,dim.height, contentSize])

    useEffect(() => {
        //this is handle the dragging on scroll-thumb
        document.addEventListener("mousemove", handleDocumentMouseMove);
        document.addEventListener("mouseup", handleDocumentMouseUp);
        document.addEventListener("mouseleave", handleDocumentMouseUp);
        window.addEventListener("resize", handleResize);
        return function cleanup() {
            document.removeEventListener("mousemove", handleDocumentMouseMove);
            document.removeEventListener("mouseup", handleDocumentMouseUp);
            document.removeEventListener("mouseleave", handleDocumentMouseUp);
            window.removeEventListener("resize", handleResize);
        };
    }, [handleDocumentMouseMove, handleDocumentMouseUp,handleResize]);

    const style = {}
    if(heightVal?.clientHeight){
        style.height = heightVal.clientHeight;
    }

    return (
        <div
            className={"scrollhost-container"}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            key={dim.width+dim.height}
            style={style}
        >
            <div
                ref={scrollHostRef}
                className={`scrollhost ${className || ''}`}
                {...restProps}
            >
                {children}
            </div>
            {showScrollBar && <div className={"scroll-bar"} style={{ opacity: isShowThumbnail ? 1 : 0, right: barNotOverlay? -10: 0 }}>
                <div
                    className={"scroll-thumb"}
                    style={{ height: scrollBoxHeight, top: scrollBoxTop }}
                    onMouseDown={handleScrollThumbMouseDown}
                />
            </div>}
        </div>
    );
}
