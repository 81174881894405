import './App.css';
import Game from "./pages/game";
import {Route, Routes, useLocation} from "react-router";
import FrontPage from "./pages/frontPage";
import KnowledgeHub from "./pages/knowledgeHub";
import NavigationBar from "./pages/fragments/navigationBar";
import Contact from "./pages/contact";
import AboutUs from "./pages/aboutUs";
import Article from "./pages/article";
import Passcode from "./passcode";
import {isDev, isPassCode} from "./settings";
import {DIMENSION} from "./game/constants";
import {createContext, useEffect, useState} from "react";
import {breakpoints} from "./breakpoints";
import Desd from "./Desd";

const ROUTE = {
    FRONTPAGE: '/',
    GAME: '/game',
    KNOWLEDGE: '/knowledge',
    CONTACT: '/contact',
    ABOUTUS: '/aboutUs'
}

// for calculating in game aspect ratio, and for updating when resizing window
function getMaxDimension(padding) {
    //according to responsive, if scrollbar
    const baseWidth = !padding ? window.innerWidth : document.documentElement.clientWidth // isMobile?  document.documentElement.clientWidth - 19 :  document.documentElement.clientWidth
    const baseHeight = document.documentElement.clientHeight

    //basic calculation
    let width = baseWidth - padding * 2;
    let height = baseHeight;
    let maxWidth = DIMENSION.width - padding * 2
    let maxHeight = DIMENSION.height * maxWidth / DIMENSION.width
    if (width >= maxWidth && height >= maxHeight) {
        width = maxWidth;
        height = maxHeight;
    } else if (width >= maxWidth && height < maxHeight) {
        //scale down height
        width = maxWidth * height / maxHeight;
    } else if (width < maxWidth && height >= maxHeight) {
        //scale down width
        height = maxHeight * (width / maxWidth);
    } else if (width < maxWidth && height < maxHeight) {
        //compare to which side to scale down more
        if (height / maxHeight < width / maxWidth) {
            width = maxWidth * height / maxHeight;
        } else {
            height = maxHeight * (width / maxWidth);
        }
    }
    let gameWidth = DIMENSION.width < baseWidth ? DIMENSION.width : baseWidth;
    return {width, height, gameWidth, screenWidth: document.documentElement.clientWidth, ratio: width / DIMENSION.width}
}

function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

export const DimContext = createContext(getMaxDimension(0))

const PageTitle = 'PM Chosen Family'

//TODO: add theme provider
function App() {
    // Resizing
    const [dim, setDim] = useState({
        ...DIMENSION,
        screenWidth: document.documentElement.clientWidth,
        gameWidth: document.documentElement.clientWidth,
        ratio: 1
    })
    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setDim(getMaxDimension(window.innerWidth >= breakpoints.medium ? 0 : (window.innerWidth >= breakpoints.small ? 24 : 16)))
        }, 500)

        window.addEventListener('resize', debouncedHandleResize)
        debouncedHandleResize(null);


        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    }, [])
    const location = useLocation();
    const {pathname} = location;

    // rename tab title
    useEffect(() => {
        if (pathname === '/' || pathname === '') {
            document.title = PageTitle
        } else {
            let text = ''
            const path = pathname.split("/")
            for (let i = 1; i < path.length; i += 1) {
                text += (' - ' + path[i].charAt(0).toUpperCase() + path[i].slice(1));
            }
            document.title = PageTitle + text;
        }
    }, [pathname])

    return (
        <DimContext.Provider value={dim}>
            <div className="App">
                {!isDev() && isPassCode && <Passcode/>}
                <NavigationBar/>
                <Routes>
                    <Route path={ROUTE.FRONTPAGE} element={<FrontPage/>}/>
                    <Route path={ROUTE.GAME} element={<Game/>}/>
                    <Route path={ROUTE.KNOWLEDGE}>
                        <Route index element={<KnowledgeHub/>}/>
                        <Route path=":category" element={<Article/>}/>
                    </Route>
                    <Route path={ROUTE.ABOUTUS} element={<AboutUs/>}/>
                    <Route path={ROUTE.CONTACT} element={<Contact/>}/>
                    <Route path={process.env.REACT_APP_GSA} element={<Desd/>}/>
                </Routes>
            </div>

        </DimContext.Provider>
    );
}

export {ROUTE};
export default App;