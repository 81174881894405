import {PaddedPage} from "../../components/page";
import {GAME_ROUTE} from "../../hooks/useGameRouter";
import {BodyB1, TitleD3, TitleD4Bold, TitleHL, TitleSH1} from "../../../typography";
import styled from "styled-components";
import {VertCtn, VertScroller} from "../../components/containers";
import {BackButton, NextButton} from "../../components/navButtons";
import StoryModel from "../../classes/storyModel";
import LeftArrow from "../../images/misc/arrowBlackLeft.png"
import RightArrow from "../../images/misc/arrowBlackRight.png"
import Avatar0 from "../../images/hcp/0/HCP-03.png"
import Avatar1 from "../../images/hcp/1/HCP-03.png"
import Avatar2 from "../../images/hcp/2/HCP-03.png"
import {useCallback, useContext, useState} from "react";
import {Input} from "../../../pages/components/input";
import HeaderContainer from "../../components/headerCtn";
import useResponsive from "../../../hook/useResponsive";
import {device} from "../../../breakpoints";
import BottomContainer from "../../components/bottomCtn";
import {DimContext} from "../../../App";
import {cookies} from "../../../index";
import ScrollDiv from "../../../pages/components/scrollDiv";
import {useTranslation} from "react-i18next";
import {COOKIES_FLAG, FLAG_PREGAME_SURVEY_DONE} from "../../../constants";
import Familiarity from "../../components/familiarity";
import GaUtility from "../../../gaUtility";
import PregameSurvey from "../../../survey/pregameSurvey";
import {isDev} from "../../../settings";
import {updatePregameSurvey} from "../../../firebase";
import storage, {STORAGE_TYPE} from "../../../storage";

const RDesc = styled(TitleHL)`
  margin-top: 16px;
`

const RPhoneDesc = styled(BodyB1)`
  margin-top: 16px;
`


const RoleCtn = styled.div`
  flex: 1;
  margin: ${props => props.ratio * 36}px 0 0;
  padding: ${props => props.ratio * 45}px 0;
  background-color: white;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: stretch;

  ${device.onlyTablet} {
    margin: 0 0 36px;
    padding: 45px 52px;
    justify-content: space-around;
    flex: 0;
  }

  ${device.onlyMobile} {
    margin: 0 0 24px;
    padding: 24px 16px;
    flex: 0;
  }
`

const RoleScrollerCtn = styled(VertScroller)`
  margin-bottom: ${props => props.ratio * 54}px;;

`

const AvatarSetupCtn = styled.div`
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: ${props=> props.isHidden? 'none' : 'flex'};
`

const AvatarCtn = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`

const ArrowCtn = styled.div`
  width: 48px;
  height: 48px;
  background-color: #E5EBF4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
`

const RoleInfoCtn = styled.div`
  margin-left: 96px;
  max-width: 320px;
  display: ${props=> props.isHidden? 'none' : 'flex'};
  flex-direction: column;
  

  ${device.tabletAndMobile} {
    margin-left: 0;
  }
`

const PronounInputCtn = styled.div`
  width: 320px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

const AVATAR_LIST = [
    {
        id: 0, img: Avatar0, position: {
            top: 138,
            left: 55
        }
    },
    {
        id: 1, img: Avatar1, position: {
            top: 138,
            left: 55
        }
    },
    {
        id: 2, img: Avatar2, position: {
            top: 140,
            left: 55
        }
    }
]


const MobileVertCtn = styled(VertCtn)`
  margin: 36px 0;

  ${device.onlyMobile} {
    margin: 24px 0;
  }
`

//${props=>props.pronoun}
const AvatarImg = styled.div`
  margin: ${props => (props.ratio || 1) * 24}px ${props => (props.ratio || 1) * 24}px 0;
  height: ${props => (props.ratio || 1) * 380}px;
  width: ${props => (props.ratio || 1) * 142}px;
  background-image: url(${props => props.img});
  background-size: contain;
  position: relative;
  content: "text \";

  &:after {
    position: absolute;
    content: ${props => `${props.pronoun}`};
    font-size: ${props => (props.ratio || 1) * 7}px;
    line-height: ${props => (props.ratio || 1) * 7}px;
    letter-spacing: -1px;
    text-align: center;
    padding: 1px;
    top: ${props => (props.ratio || 1) * props.position?.top || 138}px;
    left: ${props => (props.ratio || 1) * props.position?.left || 55}px;
    width: ${props => (props.ratio || 1) * 24}px;
    height: ${props => (props.ratio || 1) * 15}px;
    display: flex;
    align-items: center;
    justify-content: center;


  }
`

function AvatarSetup({avatar, setAvatar, pronoun, ratio , isHidden}) {
    const {t} = useTranslation('pregame', {keyPrefix: 'role_page'})
    const pronounText = `"${pronoun[0]}/ ${pronoun[1]}"`.replaceAll("\\", "\\\\")
    return (
        <AvatarSetupCtn isHidden={isHidden}>
            <TitleSH1 style={{color: '#24292F'}}>{t('select_avatar')}</TitleSH1>
            <AvatarCtn>
                <ArrowCtn onClick={() => {
                    setAvatar(avatar ? avatar - 1 : AVATAR_LIST.length - 1)
                }}>
                    <img alt="left arrow" src={LeftArrow} height="17.5px" width="20.96px"/>
                </ArrowCtn>
                <AvatarImg id="avatar_holder" value={avatar} ratio={ratio} alt={`avatar ${avatar}`} img={AVATAR_LIST[avatar].img}
                           position={AVATAR_LIST[avatar].position}
                           pronoun={pronounText}/>
                <ArrowCtn onClick={() => {
                    setAvatar((avatar + 1) % AVATAR_LIST.length)
                }}>
                    <img alt="right arrow" src={RightArrow} height="17.5px" width="20.96px"/>
                </ArrowCtn>
            </AvatarCtn>
        </AvatarSetupCtn>
    )
}


const PronounSlash = styled(TitleSH1)`
  margin-top: 16px;
`

function NameInputArea({name, onChange, error}) {
    const {t} = useTranslation('pregame', {keyPrefix: 'role_page'})
    return <>
        <TitleSH1 style={{color: '#24292F'}}>{t('name')}</TitleSH1>
        <Input width={320} onChange={onChange} value={name} limit={64} error={error}/>

    </>
}

function PronounInputArea({pronoun, onChange, error}) {
    const {t} = useTranslation('pregame', {keyPrefix: 'role_page'})
    //console.log(pronoun);
    return <>
        <TitleSH1 style={{color: '#24292F', marginTop: 24}}>{t('pronouns')}</TitleSH1>
        <PronounInputCtn>
            <Input width={149} value={pronoun[0]} onChange={onChange(0)} limit={12} error={error[0]}/>
            <PronounSlash>/</PronounSlash>
            <Input width={149} value={pronoun[1]} onChange={onChange(1)} limit={12} error={error[1]}/>
        </PronounInputCtn>

    </>
}

const TEXT_FILED_REQUIRED = 'This field is required'

function RolePage() {
    const storyModel = new StoryModel();
    const role = storyModel.getRole()
    const dim = useContext(DimContext);
    const [device] = useResponsive();
    const [isSecondPage, setIsSecondPage] = useState(false)

    const [name, setName] = useState(role.name)
    const [pronoun, setPronoun] = useState(role.pronoun)
    const [nameErr, setNameErr] = useState('');
    const [pronounErr, setPronounErr] = useState(['', '']);
    const [avatar, setAvatar] = useState(role.avatar)
    const [familiarity, setFamiliarity] = useState(0);
    const [familiarityErr, setFamiliarityErr] = useState('');


    const {t, i18n} = useTranslation('pregame', {keyPrefix: 'role_page'})
    const {t: tB} = useTranslation('common', {keyPrefix: 'button'})
    const {t: tT} = useTranslation('common', {keyPrefix: 'tags'})
    const {t: tF} = useTranslation('common', {keyPrefix: 'familiarity'})
    const {t: tText} = useTranslation('common', {keyPrefix: 'text'})

    const familiarityTitle = tF('What is your familiarity with', {topic: tT(storyModel.getSelectedCategoryTitle())})

    const [isSurveyOpen, setIsSurveyOpen] = useState( isDev() ||
        !(((storage.get(COOKIES_FLAG, STORAGE_TYPE.INT) || 0) & FLAG_PREGAME_SURVEY_DONE)
            || i18n.language === 'fr') )

    const saveInfo = useCallback((callback) => {
        if (name && pronoun[0] && pronoun[1] && familiarity) {
            const storyModel = new StoryModel();
            storyModel.setRole({name, pronoun, avatar})
            callback && callback();
        } else {
            if (!name) {
                setNameErr(tText(TEXT_FILED_REQUIRED));
            }
            let err = ['', '']
            if (!pronoun[0]) {
                err[0] = tText(TEXT_FILED_REQUIRED);
            }
            if (!pronoun[1]) {
                err[1] = tText(TEXT_FILED_REQUIRED);
            }
            setPronounErr(err);

            if (!familiarity) {
                setFamiliarityErr(tText(TEXT_FILED_REQUIRED));
            }
        }
    }, [name, pronoun, avatar, familiarity])

    const onNameChange = (val) => {
        setName(val);
        setNameErr('')
    };

    const onPronounChange = (index) => (val) => {
        //console.log(index, val)
        let ret = [...pronoun];
        ret[index] = val;
        setPronoun(ret);
        GaUtility.onPronounChange(ret)
        if (pronounErr[index]) {
            ret = [...pronounErr];
            ret[index] = '';
            setPronounErr(ret);
        }
    }

    const onFamiliarityChange = (val) =>{
        setFamiliarity(val);
        setFamiliarityErr('');
        GaUtility.onPregameFamiliarityChange(val);
    }

    const onSurveyEnd = (ans) => {
        const setSurveyCookie = ()=>storage.set(COOKIES_FLAG, (storage.get(COOKIES_FLAG, STORAGE_TYPE.INT) || 0) | FLAG_PREGAME_SURVEY_DONE)
        if(ans){
            //connect to database
            const user_id = storage.get('gaui');
            updatePregameSurvey(ans, user_id, setSurveyCookie,'pregame')
        } else {
            setSurveyCookie()
        }
        setIsSurveyOpen(false)

    }



    if (device.isMobile) {
        const Font = device.isTablet ? TitleD3 : TitleD4Bold;
        const FontDesc = device.isTablet ? RDesc : RPhoneDesc;

        return (
            <ScrollDiv>
                <PaddedPage style={{textAlign: 'left', backgroundColor: '#E5EBF4', flexDirection: 'column'}}
                            ratio={dim.ratio}>
                    <HeaderContainer hideLogo/>
                    <PregameSurvey isOpen={isSurveyOpen}
                                   onClose={()=>onSurveyEnd(false)}
                                   onEnd={onSurveyEnd}/>
                    <MobileVertCtn>
                        <Font>{t('create_avatar')}</Font>
                        <FontDesc>{t('create_avatar_desc')}</FontDesc>
                    </MobileVertCtn>

                    <RoleCtn>
                        <AvatarSetup isHidden={!(device.isTablet || !isSecondPage)} avatar={avatar} setAvatar={setAvatar} pronoun={pronoun}/>
                        <RoleInfoCtn isHidden={!(device.isTablet || isSecondPage)}>
                            <NameInputArea name={name} onChange={onNameChange} error={nameErr}/>
                            <PronounInputArea pronoun={pronoun} onChange={onPronounChange} error={pronounErr}/>
                            <Familiarity value={familiarity}
                                         style={{marginTop: 16}}
                                         title={familiarityTitle}
                                         onClick={onFamiliarityChange}
                                         error={familiarityErr}
                            />
                        </RoleInfoCtn>
                    </RoleCtn>

                    <BottomContainer
                        backgroundColor='#E5EBF4'
                        Back={(device.isTablet || !isSecondPage) ? <BackButton text={tB('Back')} onClick={saveInfo}/> :
                            <BackButton text={tB('Back')} onClick={() => setIsSecondPage(false)} disableRoute/>}
                        Next={(device.isTablet || isSecondPage) ?
                            <NextButton text={tB('Next')} id="role_setup_next" route={GAME_ROUTE.patientSelection} onClick={saveInfo}/> :
                            <NextButton text={tB('Next')} onClick={() => setIsSecondPage(true)}/>
                        }
                    />
                </PaddedPage>

            </ScrollDiv>)

    }


    return (
        <PaddedPage style={{
            textAlign: 'left',
            backgroundColor: '#E5EBF4', flexDirection: 'column',
            paddingTop: dim.ratio * 110 ,//- (1 - dim.ratio) * (432 + 60),
            paddingBottom: dim.ratio * 72 //- (1-dim.ratio) * (432+60)
        }} ratio={dim.ratio}>
            <PregameSurvey isOpen={isSurveyOpen}
                           onClose={()=>onSurveyEnd(false)}
                           onEnd={onSurveyEnd}/>
            <HeaderContainer hideLogo/>
            <RoleScrollerCtn ratio={dim.ratio}>
                <VertCtn>
                    <TitleD3>{t('create_avatar')}</TitleD3>
                    <RDesc>{t('create_avatar_desc')}</RDesc>
                </VertCtn>

                <RoleCtn ratio={dim.ratio}>
                    <AvatarSetup ratio={dim.ratio} avatar={avatar} setAvatar={setAvatar} pronoun={pronoun}/>
                    <RoleInfoCtn>
                        <NameInputArea name={name} onChange={onNameChange} error={nameErr}/>
                        <PronounInputArea pronoun={pronoun} onChange={onPronounChange} error={pronounErr}/>
                        <Familiarity value={familiarity}
                                     style={{marginTop: 16}}
                                     title={familiarityTitle}
                                     onClick={onFamiliarityChange}
                                     error={familiarityErr}
                        />
                    </RoleInfoCtn>
                </RoleCtn>

            </RoleScrollerCtn>

            <div style={{display: 'flex', justifyContent: 'space-between', flex: 0}}>
                <BackButton text={tB('Back')} onClick={saveInfo}/>
                <NextButton text={tB('Next')} id="role_setup_next" route={GAME_ROUTE.patientSelection}
                            onClick={saveInfo}/>
            </div>
        </PaddedPage>)
}


export default RolePage;