import {cookies} from "./index";
// localStorage variable takes priority
export const STORAGE_TYPE = {
    INT: 'int',
    FLT: 'float',
    STR: 'str'
}
const storage = {
    get: (name, type = STORAGE_TYPE.STR, options)=>{
        let cookieVal = cookies.get(name, options || {})
        let localVal = localStorage.getItem(name);
        if (localVal){
            switch (type){
                case STORAGE_TYPE.INT:
                    return parseInt(localVal);
                case STORAGE_TYPE.FLT:
                    return parseFloat(localVal)
                default:
                    return localVal
            }
        }
        if(cookieVal){
            return cookieVal;
        }
        return type === STORAGE_TYPE.STR ? "" : 0;
    },
    set: (name, value, options) =>{
        cookies.set(name,value, options || {});
        localStorage.setItem(name, value)

    }
}

export default storage;