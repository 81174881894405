import StoryModel from "../classes/storyModel";
import {createGlobalState} from "react-hooks-global-state";
import {isQuickStart} from "../../settings";

const GAME_ROUTE = {
    categoryPage: 'categoryPage',
    categoryInfoPage: 'categoryInfoPage',
    rolePage: 'rolePage',
    patientSelection: 'patientSelection',
    patientInfo: 'patientInfo',
    story: 'story',
}

const getInitialState = () => {
    if(!isQuickStart){
        return {
            store:{
                route: GAME_ROUTE.categoryPage,
                sceneId: null,
                ts: Date.now(),
                extraInfo: {},
                lastRoute: [],

            }
        };
    }
    const storyModel = new StoryModel();
    return {
        store:{
            route: GAME_ROUTE.story,
            sceneId: storyModel.getInitialSceneId(),
            ts: Date.now(),
            extraInfo: {},
            lastRoute: [],

        }
    }
}

const {useGlobalState} = createGlobalState(getInitialState());

function useGameRouter(){ //TODO store is having multiple return
    const [store, setState] = useGlobalState('store');
    let useRoute = {
        changeRoute: (route) => {
            let sceneId = store.sceneId;
            if(store.route!== GAME_ROUTE.story && route === GAME_ROUTE.story){
                const storyModel = new StoryModel();
                sceneId = storyModel.getInitialSceneId();
            }
            setState({...store, route: route, lastRoute: [store.route, ...store.lastRoute], ts: Date.now(), sceneId })
        },
        changeScene:(sceneId) => {
            // console.log(sceneId)
            setState({...store, sceneId, ts: Date.now() })
        },
        changeRouteWithExtra: (info) => { // Not in use
            const {route, ...rest} = info;
            setState({...store, route:route, lastRoute: [store.route, ...store.lastRoute], extraInfo: rest, ts: Date.now() })
        },
        goBack: () => {
            if(store.lastRoute.length){
                setState({...store, route: store.lastRoute[0], ts: Date.now(), lastRoute: store.lastRoute.slice(1, store.lastRoute.length)})
            }
        },
        reset: () => {
            // console.log(getInitialState())
            setState(getInitialState().store)
        }

    }
    // console.log(store);
    return [store, useRoute]
}




export {GAME_ROUTE}
export default useGameRouter;