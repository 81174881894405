import {useCallback, useLayoutEffect, useRef, useState} from "react";



function useSize(isWidth){
    const [size, setSize] = useState(0)
    const ref = useRef();

    const getMeasure = useCallback(() =>{
        let childrenSize = 0
        if(isWidth){
            ref.current.childNodes.forEach((child)=>{
                    childrenSize += child.scrollWidth;
            })
            setSize(Math.max(ref.current.scrollWidth,childrenSize))
        }else{
            ref.current.childNodes.forEach((child)=>{
                // console.log(child.scrollHeight)
                childrenSize += child.scrollHeight;
            })
            setSize(Math.max(ref.current.scrollHeight,childrenSize))

        }
        /*const {width: rectW, height: rectH} = ref.current.getBoundingClientRect()
        const ctnSize = isWidth? rectW : rectH
        let childrenSize = 0
        ref.current.childNodes.forEach((child)=>{
            const {width:w, height:h} = child.getBoundingClientRect()
            if(isWidth) {
                childrenSize += w;
            }else {
                childrenSize += h;
            }
        })
        setSize(Math.max(ctnSize,childrenSize))*/
    },[ref,isWidth, setSize])

    useLayoutEffect(() => {
        ref?.current && getMeasure()
        const observer = new MutationObserver((mutations,observer)=>{
            getMeasure()
        });
        ref?.current && observer.observe(ref.current, {subtree:true, childList:true})
        return function cleanup(){
            observer.disconnect()
        }
    }, [ref,getMeasure])

    // console.log(size)
    return [ref, size]
}

export default useSize;