import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import './i18n'
import {sha256} from "js-sha256";
import Cookies from "universal-cookie";
import storage from "./storage";
export const cookies = new Cookies()


const dataLayer = window.dataLayer || [];
const _ga = cookies.get('_ga');
const gaui = storage.get('gaui')
// initial User Id for Google Analytics
if(gaui){
    dataLayer.push({user_id: gaui});
}else{
    // generate user_id by _ga, timestamp, user_agent
    const userAgent = navigator.userAgent;
    const date = new Date();
    const hash = sha256(userAgent+date.getTime()+_ga)
    storage.set('gaui', hash)
    dataLayer.push({user_id: hash})


}

const root = ReactDOM.createRoot(document.getElementById('root'));
//remove strict mode to prevent double render in development
// root.render(
//   <React.StrictMode>
//       <BrowserRouter>
//           <App />
//
//       </BrowserRouter>
//   </React.StrictMode>
// );


root.render(
        <BrowserRouter>
            <App />
        </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
