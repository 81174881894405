
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'
import en from './locales/en'
import fr from './locales/fr'


i18next.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        defaultNS: ['common'],
        fallbackLng: 'en',
        fallbackNS: 'common',
        ns: ['common', 'pregame', 'game'],
        resources:{
            en,
            fr,
        },
        interpolation: {
            escapeValue: false, // not needed for react!!
        },
        load: 'languageOnly',
        debug: true,
    }).then(() => {}).catch(()=>{})
