import {END_GAME, ImageType, PositionType, SceneType} from "../../../classes/scene";


import {CHATICON} from "../storyGlobal";

import BG01 from "../../../images/background/BG-01.png";
import BG02 from "../../../images/background/BG-02.png";
import BG03 from "../../../images/background/BG-03.png";

import ROS01 from "../../../images/patient/ross/ROS-01.png";
import ROS03 from "../../../images/patient/ross/ROS-03.png";
import ROS04 from "../../../images/patient/ross/ROS-04.png";
import ROS06 from "../../../images/patient/ross/ROS-06.png";
import ROS08 from "../../../images/patient/ross/ROS-08.png";
import ROS09 from "../../../images/patient/ross/ROS-09.png";
import ROS11 from "../../../images/patient/ross/ROS-011.png";
import HCP_0_05 from "../../../images/hcp/0/HCP-05.png";
import HCP_1_05 from "../../../images/hcp/1/HCP-05.png";
import HCP_2_05 from "../../../images/hcp/2/HCP-05.png";
import HCP_A_01 from "../../../images/hcp/HCP-F-C01.png";
import HCP_A_02 from "../../../images/hcp/HCP-F-C02.png";
import HCP_0_02 from "../../../images/hcp/0/HCP-02.png";
import HCP_1_02 from "../../../images/hcp/1/HCP-02.png";
import HCP_2_02 from "../../../images/hcp/2/HCP-02.png";
import {END_LEVEL} from "../../../constants";


const BG = {
    '01': {
        type: ImageType.background,
        image: BG01,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    '02': {
        type: ImageType.background,
        image: BG02,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    '03': {
        type: ImageType.background,
        image: BG03,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
}



const ROS = {
    "08": {
        type: ImageType.avatar,
        image: ROS08,
        positionType: PositionType.px,
        position: {
            left: 279,
            top: 362,
            right: 896,
            bottom: 68,
        },
        style: null,
    },
}

const ROS_CENTER = (img) => {
    return (
        {
            type: ImageType.avatar,
            image: img,
            positionType: PositionType.px,
            position: {
                left: 483,
                top: 201,
                right: 273,
                bottom: -785,
            },
            style: null,
        })
}




const HCP = {
    '05' :{
        type: ImageType.avatar,
        image: [HCP_0_05, HCP_1_05, HCP_2_05],
        positionType: PositionType.px,
        position: {
            left: 806,
            top: 394,
            right: 361,
            bottom: 44.71,
        },
        style: null,
        pronounButton:{
            top: 214,
            left: 187,
            width: 44,
            // height: 30,
            clippath: null
        }
    },


    '02' :{
        type: ImageType.avatar,
        image: [HCP_0_02, HCP_1_02, HCP_2_02],
        positionType: PositionType.px,
        position: {
            left: 462,
            top: 187,
            right: 462,
            bottom: -876,
        },
        style: null,
        pronounButton:{
            top: 570,
            left: 242,
            width: 122,
            // height: 30,
            clippath: null
        }
    },
}


const HCPA = {
    '01': {
        type: ImageType.avatar,
        image: HCP_A_01,
        positionType: PositionType.px,
        position: {
            left: 1138,
            top: 298,
            right: 125,
            bottom: 45,
        },
        style: null,
    },
    '02': {
        type: ImageType.avatar,
        image: HCP_A_02,
        positionType: PositionType.px,
        position: {
            left: 462,
            top: 187,
            right: 462,
            bottom: -876,
        },
        style: null,
    },
}
const Relationship4Scenes = {
    id: '04-04',
    patient: 4,
    scene: [{
        id: '04-04-001-01',
        type: SceneType.description,
        images:[
            BG['01'],
            ROS["08"],
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-04-002-01',
            text: '001-01',
        }],
        hint: null,
        isBeginning: true,
    },{
        id: '04-04-002-01',
        type: SceneType.description,
        images:[
            BG['01'],
            ROS["08"],
            HCP['05'],
            HCPA['01'],

        ],
        bubbleText: false,
        actions: [{
            nextId: '04-04-003-01',
            text: '002-01',
        }],
        hint: null,
        isBeginning: false,
    },

        {
            id: '04-04-003-01',
            type: SceneType.selection,
            images:[
                BG['02'],
                HCP['02'],
            ],
            bubbleText: false,
            actions: [{
                nextId: '04-04-004-01',
                text: 'i01-q01',
                opt: 1,
                score: 3,
            }, {
                nextId: '04-04-004-02',
                text: 'i01-q02',
                opt: 2,
                score: 2,
            }, {
                nextId: '04-04-004-03',
                text: 'i01-q03',
                opt: 3,
                score: 1,
            }, {
                nextId: '04-04-004-04',
                text: 'i01-q04',
                opt: 4,
                score: 0,
            }],
            hint: 'i01-question',
            isBeginning: false,
        },{
            id: '04-04-004-01',
            type: SceneType.chat,
            images:[
                BG['03'],
                ROS_CENTER(ROS01),

            ],
            bubbleText: false,
            actions: [{
                nextId: '04-04-005-01',
                text: "i01-a01",
                chatIcon: CHATICON.ROS,
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '04-04-004-02',
            type: SceneType.chat,
            images:[
                BG['03'],
                ROS_CENTER(ROS06),

            ],
            bubbleText: false,
            actions: [{
                nextId: '04-04-005-01',
                text: "i01-a02",
                chatIcon: CHATICON.ROS,
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '04-04-004-03',
            type: SceneType.chat,
            images:[
                BG['03'],
                ROS_CENTER(ROS09),

            ],
            bubbleText: false,
            actions: [{
                nextId: '04-04-005-01',
                text: "i01-a03",
                chatIcon: CHATICON.ROS,
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '04-04-004-04',
            type: SceneType.chat,
            images:[
                BG['03'],
                ROS_CENTER(ROS11),

            ],
            bubbleText: false,
            actions: [{
                nextId: '04-04-005-01',
                text: "i01-a04",
                chatIcon: CHATICON.ROS,
            }],
            hint: null,
            isBeginning: false,
        },



        {
            id: '04-04-005-01',
            type: SceneType.chat,
            images:[
                BG['02'],
                HCPA['02'],
            ],
            bubbleText: false,
            actions: [{
                nextId: '04-04-006-01',
                text: '005-01',
                chatIcon: CHATICON.HCPA
            }],
            hint: null,
            isBeginning: false,
        },

        {
            id: '04-04-006-01',
            type: SceneType.selection,
            images:[
                BG['02'],
                HCP['02'],
            ],
            bubbleText: false,
            actions: [{
                nextId: '04-04-007-01',
                text: 'i02-q01',
                opt: 1,
                score: 0,
            }, {
                nextId: '04-04-007-02',
                text: 'i02-q02',
                opt: 2,
                score: 0,
            },],
            hint: 'i02-question',
            isBeginning: false,
        },{
            id: '04-04-007-01',
            type: SceneType.chat,
            images:[
                BG['03'],
                ROS_CENTER(ROS01),

            ],
            bubbleText: false,
            actions: [{
                nextId: '04-04-008-01',
                text: "i02-a01",
                chatIcon: CHATICON.ROS,
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '04-04-007-02',
            type: SceneType.chat,
            images:[
                BG['03'],
                ROS_CENTER(ROS06),

            ],
            bubbleText: false,
            actions: [{
                nextId: END_GAME,
                text: "i02-a02",
                chatIcon: CHATICON.ROS,
                gameEndLevel: END_LEVEL.WORSE
            }],
            hint: null,
            isBeginning: false,
        },

        {
            id: '04-04-008-01',
            type: SceneType.selection,
            images:[
                BG['02'],
                HCP['02'],
            ],
            bubbleText: false,
            actions: [{
                nextId: '04-04-009-01',
                text: 'i03-q01',
                opt: 1,
                score: 3,
            }, {
                nextId: '04-04-009-02',
                text: 'i03-q02',
                opt: 2,
                score: 2,
            }, {
                nextId: '04-04-009-03',
                text: 'i03-q03',
                opt: 3,
                score: 1,
            }, {
                nextId: '04-04-009-04',
                text: 'i03-q04',
                opt: 4,
                score: 0,
            }],
            hint: 'i03-question',
            isBeginning: false,
        },{
            id: '04-04-009-01',
            type: SceneType.chat,
            images:[
                BG['03'],
                ROS_CENTER(ROS01),

            ],
            bubbleText: false,
            actions: [{
                nextId: END_GAME,
                text: "i03-a01",
                chatIcon: CHATICON.ROS,
                gameEndLevel: END_LEVEL.GREAT
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '04-04-009-02',
            type: SceneType.chat,
            images:[
                BG['03'],
                ROS_CENTER(ROS03),

            ],
            bubbleText: false,
            actions: [{
                nextId: '04-04-010-01',
                text: "i03-a02",
                chatIcon: CHATICON.ROS,
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '04-04-009-03',
            type: SceneType.chat,
            images:[
                BG['03'],
                ROS_CENTER(ROS04),

            ],
            bubbleText: false,
            actions: [{
                nextId: '04-04-010-01',
                text: "i03-a03",
                chatIcon: CHATICON.ROS,
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '04-04-009-04',
            type: SceneType.chat,
            images:[
                BG['03'],
                ROS_CENTER(ROS06),

            ],
            bubbleText: false,
            actions: [{
                nextId: '04-04-009-04-02',
                text: "i03-a04",
                chatIcon: CHATICON.ROS,
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '04-04-009-04-02',
            type: SceneType.cutscene,
            images:[
                BG['03'],
                ROS_CENTER(ROS06),

            ],
            bubbleText: [{text:'i03-a04-b'}],
            actions: [{
                nextId: END_GAME,
                gameEndLevel: END_LEVEL.WORSE
            }],
            hint: null,
            isBeginning: false,
        },


        {
            id: '04-04-010-01',
            type: SceneType.selection,
            images:[
                BG['02'],
                HCP['02'],
            ],
            bubbleText: false,
            actions: [{
                nextId: '04-04-011-01',
                text: 'i04-q01',
                opt: 1,
                score: 3,
            }, {
                nextId: '04-04-011-02',
                text: 'i04-q02',
                opt: 2,
                score: 2,
            }, {
                nextId: '04-04-011-03',
                text: 'i04-q03',
                opt: 3,
                score: 1,
            }, {
                nextId: '04-04-011-04',
                text: 'i04-q04',
                opt: 4,
                score: 0,
            }],
            hint: 'i04-question',
            isBeginning: false,
        },{
            id: '04-04-011-01',
            type: SceneType.chat,
            images:[
                BG['03'],
                ROS_CENTER(ROS01),

            ],
            bubbleText: false,
            actions: [{
                nextId: END_GAME,
                text: "i04-a01",
                chatIcon: CHATICON.ROS,
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '04-04-011-02',
            type: SceneType.chat,
            images:[
                BG['03'],
                ROS_CENTER(ROS03),

            ],
            bubbleText: false,
            actions: [{
                nextId: END_GAME,
                text: "i04-a02",
                chatIcon: CHATICON.ROS,
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '04-04-011-03',
            type: SceneType.chat,
            images:[
                BG['03'],
                ROS_CENTER(ROS04),

            ],
            bubbleText: false,
            actions: [{
                nextId: '04-04-011-03-02',
                text: "i04-a03",
                chatIcon: CHATICON.ROS,
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '04-04-011-03-02',
            type: SceneType.cutscene,
            images:[
                BG['03'],
                ROS_CENTER(ROS04),

            ],
            bubbleText: [{text:'i04-a03-b'}],
            actions: [{
                nextId: END_GAME,
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '04-04-011-04',
            type: SceneType.chat,
            images:[
                BG['03'],
                ROS_CENTER(ROS06),

            ],
            bubbleText: false,
            actions: [{
                nextId: '04-04-011-04-02',
                text: "i04-a04",
                chatIcon: CHATICON.ROS,
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '04-04-011-04-02',
            type: SceneType.cutscene,
            images:[
                BG['03'],
                ROS_CENTER(ROS06),

            ],
            bubbleText: [{text:'i04-a04-b'}],
            actions: [{
                nextId: END_GAME,
            }],
            hint: null,
            isBeginning: false,
        },


    ]
}


export default Relationship4Scenes