const DOM = (
    <>
        <div>
            <h4>What you will learn</h4>
            <ul>
                <li>To learn about different types of pronouns and what they mean</li>
                <li>To learn the meanings of the terms; heteronormativity, non binary, cisgender and gender spectrum
                </li>
                <li>To understand why and how pronouns relate to patient care</li>
                <li>To learn how to ask about pronouns and how to recover if you make a mistake</li>
            </ul>
        </div>
        <div>
            <h4>What is a pronoun?</h4>
            <ul>
                <li>A pronoun is a word that is used instead of a noun, to refer to a person</li>
                <li>Pronouns are often used in place of a person’s name. (e.g., she/her, he/him, they/them, or ze/hir ).
                    In
                    the English language, we have gendered pronouns which specifically refer to someone’s gender: he/him
                    or
                    she/her.
                </li>
                <li>Nonbinary pronouns are not gender specific and are most often used by people who identify outside of
                    a
                    gender binary – not identifying as a woman or a man. Nonbinary pronouns include ze (pronounced
                    “zee”), a
                    subjective pronoun used in place of she/him, and hir (pronounced “here”), an objective pronoun, used
                    in
                    place of her/him.
                </li>
                <li>Using pronouns like they/them or ze/zir doesn’t mean that one necessarily identifies as nonbinary;
                    some
                    people who use they/them pronouns are agender, some are Two Spirit, and some are cis men and women.
                    It’s
                    all about how someone defines their personal identity.
                </li>
                <li>Some people use multiple pronouns or ‘rolling’ pronouns, such as: She/Her, They/Them, She/They,
                    He/They,
                    He/Ze, She/Ze, etc. Sometimes gender-fluid individuals might want to use different pronouns on
                    different
                    days depending on how they are feeling about their identity. Because gender is a spectrum and people
                    can
                    feel like multiple or no genders, rolling pronouns might feel more comfortable than a singular one.
                    That
                    is why we use the term “pronouns” instead of “pronoun”
                </li>
            </ul>
            <p>People may use many other terms to identify their pronouns. If you don’t understand or are not sure, it
                is ok to respectfully ask what the term means to the patient.</p>
        </div>
        <div>
            <h4>Why are pronouns important?</h4>
            <ul>
                <li>We cannot assume pronouns or how someone identifies their gender based on their name or appearance
                </li>
                <li>We often assume the gender of others by their appearance and use gendered language, such as she/he,
                    Ms./Mr., ladies/gentlemen
                </li>
                <li>These assumptions are called “heteronormative” and can be a bias that promotes heterosexuality as
                    the
                    normal or natural sexual orientation
                </li>
                <li>Heteronormative models of care can be damaging to SGDc patients</li>
                <li>This can result in individuals, especially trans and gender-nonconforming people being mis gendered,
                    excluded, and feeling disrespected
                </li>
            </ul>
        </div>
        <div>
            <h4>What's the best way to ask about pronouns?</h4>
            <ul>
                <li>Start by sharing your own pronouns when introducing yourself, – for example, "My name is ----. My
                    pronouns are she/her. How would you like me to refer to you?”
                </li>
                <li>Some health care providers may not be out at work and would rather use he or she pronouns in their
                    work
                    place. It is important to do what feels best for you
                </li>
                <li>If a patient doesn’t want to state their pronouns (a number of reasons such as not wanting to be out
                    in
                    a particular setting or past experience of discrimination) or do not wish their pronouns to be part
                    of
                    their health record, that is ok. You can refer to that person by their name only or use a gender
                    neutral
                    pronoun such as they
                </li>
            </ul>
        </div>
        <div>
            <h4>How can I be inclusive in using and respecting gender pronouns?</h4>
            <ul>
                <li>Respect means using the gender pronouns with which people identify. Someone’s pronouns are not
                    “preferred”. “Preferred” can suggest that they are not real or should not be respected. Pronouns are
                    just how a person identifies.
                </li>
                <li>There is no need to question a person about their pronouns, to ask about, surgeries, their sex life,
                    or
                    other intrusive questions
                </li>
                <li>It is important to respect everyone’s right to use pronouns which feel right to them</li>
                <li>By using nonbinary pronouns, we can increase inclusion for everyone</li>
                <li>If you do not know or have not asked someone’s pronouns, it may be best to use neutral “they/them”
                    pronouns.
                </li>
            </ul>
        </div>
        <div>
            <h4>What if I make a mistake use the wrong pronouns?</h4>
            <ul>
                <li>Simply apologize, correct yourself and move on. Making a big deal out if it can just make the
                    situation
                    more uncomfortable for everyone.
                </li>
                <li>We can all make mistakes, especially when we are learning or referring to someone by pronouns that
                    are
                    not yet familiar. It can take time and practice
                </li>
                <li>Acknowledging, apologising, and making a commitment to do better next time, goes a long way to
                    communicate caring and respect.
                </li>
            </ul>
        </div>
        <div>
            <h4>How does using appropriate pronouns support inclusive care?</h4>
            <ul>
                <li>Properly using an individual's correct pronouns is an easy way to show respect. When you don’t know
                    someone’s pronouns, use nonbinary pronouns instead. Whether intentional or not, using the wrong
                    pronouns
                    can be hurtful.
                </li>
                <li>Listen carefully, pay attention to what pronouns people use to refer to themselves</li>
                <li>Knowing how to use nonbinary pronouns creates an inclusive more welcoming environment where everyone
                    is
                    valued and accepted. It helps to foster a culture of belonging.
                </li>
                <li>Being cisgender (when your gender identity corresponds to your sex assigned at birth) is a
                    privilege. It
                    means not having to be concerned about which pronoun someone is going to use for you based on how
                    they
                    perceive your gender. Introducing yourself with your pronouns helps to normalise pronouns for
                    everyone.
                </li>
            </ul>
        </div>
        <div>
            <h4>How can I use pronouns as part of my practice of inclusive care? Key take aways:</h4>
            <ul>
                <li>Introduce yourself with your pronouns and ask others how they would like to be addressed</li>
                <li>Wear a pronoun button. It can be a symbol that you have some awareness about pronouns and may be a
                    HCP
                    who values and practices inclusive care.
                </li>
                <li>We can not assume someone’s pronouns based on their appearance or name.</li>
                <li>Educate yourself about pronouns that you are not familiar with</li>
                <li>Be an ally and speak up when you hear colleagues using incorrect pronouns. Correct them ie “this
                    patient
                    uses these pronouns, and it’s important that we use them as well.”
                </li>
            </ul>
        </div>
        <div>
            <h6>More resources about pronouns:</h6>
            <p>
                Brown, Camille BSN, RN (Graduate Research Assistant)a; Frohard-Dourlent, Hélène PhD (Post-doctoral
                Fellow)b; Wood, Brittany A. (Graduate Research Assistant)c; Saewyc, Elizabeth PhD, RN, FSAHM, FCAHS,
                FAAN (Professor and Director)b; Eisenberg, Marla E. ScD, MPH (Associate Professor)d; Porta, Carolyn M.
                PhD, MPH, RN, FAAN (Professor)a. “It makes such a difference”: An examination of how LGBTQ youth talk
                about personal gender pronouns. Journal of the American Association of Nurse Practitioners: January 2020
                - Volume 32 - Issue 1 - p 70-80
                <br/><br/>
                doi: 10.1097/JXX.0000000000000217
                <br/><br/>
                Harris CA, Blencowe N, Telem DA. What is in a Pronoun?: Why Gender-fair Language Matters. Ann Surg. 2017
                Dec;266(6):932-933. doi: 10.1097/SLA.0000000000002505. PMID: 28902666; PMCID: PMC5774006.
            </p>
        </div>
        <div>
            <h6>Videos</h6>
            <ul>
                <li><a href="https://www.youtube.com/watch?v=3xpvricekxU" rel="noreferrer" target="_blank">What Are
                    Pronouns?</a></li>
                <li><a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=dQ9ozOHm_iM">I'll Use Your
                    Pronoun: No Big Deal</a></li>

            </ul>
        </div>
        <div>
            <h6>Posters</h6>
            <li><a target="_blank" rel="noreferrer" href="https://www.the519.org/media/download/2384">Gender-Specific
                and
                Gender Neutral Pronouns: Quick Reference Poster [PDF]</a></li>
        </div>
        <div>
            <h6>Websites</h6>
            <ul>
                <li><a target="_blank" rel="noreferrer" href="https://www.mypronouns.org/">My Pronouns</a></li>
                <li><a target="_blank" rel="noreferrer" href="https://www.practicewithpronouns.com/">Practice With
                    Pronouns</a></li>
                <li><a target="_blank" rel="noreferrer" href="https://pronounsday.org/">Pronouns Day</a></li>
            </ul>
        </div>


    </>
)

export default DOM