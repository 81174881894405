const DOM = (
    <>
        <div>
            <h4>What you will learn</h4>
            <ul>
                <li>To learn the differences between sex, gender, gender expression and different types of gender
                    identities
                </li>
                <li>To learn the meanings of the terms; cisgender, transgender, sex assigned at birth, gender fluid and
                        intersectional identities</li>
                <li>To understand why and how gender identities relate to patient care</li>
                <li>To learn how to ask about gender identity and how to recover if you make a mistake</li>
                <li>The terms sex, gender identity and gender expression are terms that are often confused.</li>
            </ul>
        </div>
        <div>
            <h5>Sex</h5>
            <ul>
                <li>Can be understood as a label</li>
                    <li>It is often assigned at birth, when a doctor or midwife declares a new born baby to be male, female or intersex.
                            Once sex is assigned, we often assign or assume a gender</li>
                    <li>The term sex assigned at birth or assigned sex (M or F) at birth,(AFAB or AMAB) acknowledges that that this was
                            determined by someone when they were born</li>
                    <li>Sex is primarily based on anatomy, hormones, and chromosomes</li>
                    <li>Sex is also a legal status, usually listed on a birth certificate</li>
                    <li>Sex can be changed in legal documents such as a health card, birth certificate, passport or drivers license</li>
                    <li>Sometimes how a patient identifies their name or sex, is not the same as their legal identification. Always go
                            with how a patient identifies</li>
            </ul>
        </div>
        <div>
            <h5>Gender identity</h5>
            <ul>
                    <li>A person’s internal sense of self and their gender, whether someone feels like a man, woman, neither or both, or
                            another gender</li>
                    <li>It may or may not be the same as their sex assigned at birth. Rather than relating to body parts, gender
                            identity refers to ways a person is expected to act, based on their sex</li>
                    <li>Gender is a social status. There are expectations, roles and assumptions about how someone “should” behave based
                            on sex. For example female infants are often dressed in pink, men shouldn’t cry, etc.</li>
                    <li>Gender is socially constructed. Gender norms vary across cultures and change over time.</li>
                    <li>Gender identity can be fluid meaning people can identify with different or multiple genders at different times.</li>
            </ul>
        </div>
        <div>
            <h5>Gender expression</h5>
            <ul>
                <li>Refers to how a person expresses their gender identity, through appearance, clothing and behaviour.</li>
                    <li>Society often classifies these expressions as masculine or feminine</li>
                    <li>When sex assigned at birth corresponds to gender identity, this is referred to as CISGENDER</li>
                    <li>When sex assigned at birth does not align to gender identity, this is referred to as TRANSGENDER. A transgender
                            man for example, is someone who was listed female at birth but whose gender identity is male. “Trans” is an
                            acceptable abbreviation that is often used.</li>
            </ul>
                <p>
                        Sex does not equal gender
                        <br/>
                <br/>
                        Gender identity can be fluid, change over time, and is a spectrum
                        <br/>
                        A Gender spectrum includes people who may identify as 2 Spirit, queer, trans, agender, bi-gender, gender fluid,
                        gender nonconforming, and non-binary.
                </p>
        </div>
        <div>
            <h5>Intersectionality</h5>
            <p>
                Intersectionality is the interconnected nature of social categorizations such as race, class, gender identity,
                sexual orientation, and disability as they apply to a given individual or group, creating overlapping and
                interdependent systems of discrimination or disadvantage.
                <br/><br/>
                These places of marginalization are not separate in creating impact but are interconnected and often compound
                one another. Intersectionality addresses how the intersection of a person's identities are culturally and
                systemically used to oppress them on multiple fronts and to inform experience.
                <br/><br/>
                Patient’s identities include far more than their sexual orientation and gender identity. Factors such as racism,
                cultural and language barriers , socioeconomic status, limited literacy and social support, etc. play an
                important role in shaping a person’s experience in cancer care. Some patients are up against many systemic forms
                of discrimination which present enormous challenges and health inequities.
            </p>
        </div>
        <div>
            <h4>Why is gender identity important?</h4>
            <ul>
                <li>We cannot assume how someone identifies their gender based on their name or appearance or sex listed on
                    documents</li>
                <li>We often assume the gender of others by their appearance and use gendered language, such as she/he, Ms./Mr.</li>
                <li>These assumptions are called “heteronormative”, assume a male/female only gender binary and can be a bias that
                    promotes heterosexuality as the normal or natural sexual orientation or gender identity</li>
                <li>Heteronormative models of care can be damaging to SGDc patients</li>
                <li>This can result in individuals, especially trans and gender-nonconforming people being mis- gendered, excluded,
                    and feeling disrespected</li>
                <li>Transgender is a always used as a pronoun – to describe a person ie. “Falyn identifies as a transgender woman”.
                    When the term transgender is used as a noun or verb, that is not correct or respectful. For example we don’t
                    refer to someone as being “transgendered”. Transgendered implies that</li>
            </ul>
        </div>
        <div>
            <h4>When do we need to know gender identity?</h4>
            <ul>
                <li>We need to know how someone identifies their gender so that we can refer to them correctly and use the correct
                    pronouns.</li>
                <li>When interacting with patients, always introduce your self with your pronouns and ask patients “how would you
                    like me to refer to you?”. This is a symbolic gesture of respect and indication of inclusive care. Pronouns may
                    or may not provide information about a patient’s gender identity.</li>
                <li>When asking more questions about gender identity, think carefully about why you may need to know, how it relates
                    to the medical care that you are providing. Sometimes it can be helpful to explain or provide a context as to
                    why you are asking, to make patients feel more comfortable</li>
                <li>Some healthcare provider roles may require further information about gender identity (examples) and respectful
                    questions can be explored if needed</li>
                <li>When a person transitions to another gender, they likely will “socially transition” which may involve taking on
                    a new name which matches their gender and sharing their new gender identity with loved ones. Transitioning may
                    or may not involve gender affirming surgeries or taking hormones.</li>
            </ul>
        </div>
        <div>
            <h4>How can I be inclusive about gender identity?</h4>
            <ul>
                <li>Respect means using the pronouns, name and gender with which people identify.</li>
                <li>It is important to respect everyone’s right to state the gender with which they identify</li>
                <li>By using nonbinary pronouns, we can increase inclusion for everyone</li>
                <li>If you do not know or have not asked someone how they identify, it may be best to use “they/them” pronouns to
                    avoid misgendering someone.</li>
            </ul>
        </div>
        <div>
            <h4>What if I make a mistake and misgender someone?</h4>
            <ul>
                <li>Apologize, correct yourself and move on. Making a big deal out if it can just make the situation more
                    uncomfortable for everyone.</li>
                <li>We can all make mistakes. It can take time and practice to change our language and be inclusive.</li>
                <li>Acknowledging, apologising, and making a commitment to do better next time, goes a long way to communicate
                    caring and respect. The important piece is the commitment to better next time.</li>
            </ul>
        </div>
        <div>
            <h4>How does using correct gender identity support inclusive care?</h4>
            <ul>
                <li>Properly using an individual's correct pronoun and gender identity is an easy way to show respect. We can never
                    assume pronouns or gender identity. When you don’t know for sure – ask or use nonbinary pronouns and language.
                    Whether intentional or not, using the wrong pronouns and gender can be hurtful.</li>
                <li>Knowing how to use gender inclusive language (neutral, nonbinary language) creates an inclusive more welcoming
                    environment where everyone is valued and accepted. It helps to foster a culture of belonging.</li>
                <li>Being cisgender (when your gender identity corresponds to your sex assigned at birth) is a privilege. It means
                    not having to be concerned about which pronoun someone is going to use for you based on how they perceive your
                    gender. Not everyone has this privilege. Introducing yourself with your pronouns helps to normalise pronouns for
                    everyone.</li>
            </ul>
        </div>
        <div>
            <h4>How can I incorporate gender identity as part of my practice of inclusive care? Key takeaways:</h4>
            <ul>
                <li>Introduce yourself with your pronouns and ask others how they would like to be addressed. Refer to the patient
                    according to their wishes. Remember: sex does not equal gender.</li>
                <li>Avoid assuming gender based on appearance, name, and sex indicated on Health Card.</li>
                <li>Educate your self about various gender identities, unconscious bias and correct terminology</li>
                <li>Be an ally and speak up if you hear colleagues misgendering a patient.</li>
            </ul>
        </div>
        <div>
            <h6>More resources about gender identity:</h6>
            <ul>
                <li><a target="_blank" rel="noreferrer" href="https://genderspectrum.org/articles/understanding-gender">https://genderspectrum.org/articles/understanding-gender</a></li>
                <li><a target="_blank" rel="noreferrer" href="https://www.thetrevorproject.org/resources/article/understanding-gender-identities/">https://www.thetrevorproject.org/resources/article/understanding-gender-identities/</a></li>
                <li><a target="_blank" rel="noreferrer" href="https://www.npr.org/2021/06/02/996319297/gender-identity-pronouns-expression-guide-lgbtq">https://www.npr.org/2021/06/02/996319297/gender-identity-pronouns-expression-guide-lgbtq</a></li>
                <li><a target="_blank" rel="noreferrer" href="https://lgbtqia.ucdavis.edu/educated/glossary">https://lgbtqia.ucdavis.edu/educated/glossary</a></li>
                <li><a target="_blank" rel="noreferrer" href="https://pmchosenfamily.ca">Critical Race Theory: The Key Writings That Formed the Movement, edited by Crenshaw, Gotanda, Peller, Thomas,
                    1996</a></li>
                <li><a target="_blank" rel="noreferrer" href="https://youtu.be/akOe5-UsQ2o"> The urgency of intersectionality, Kimberlé Crenshaw, TED Talk, October 2016</a></li>
                <li><a target="_blank" rel="noreferrer" href="https://pmchosenfamily.ca">Squires LR, Bilash T, Kamen CS, Garland SN. Psychosocial Needs and Experiences of Transgender and Gender Diverse
                    People with Cancer: A Scoping Review and Recommendations for Improved Research and Care. LGBT Health. 2022
                    Jan;9(1):8-17. doi: 10.1089/lgbt.2021.0072. Epub 2021 Sep 7. PMID: 34495755; PMCID: PMC9206485.</a></li>
            </ul>
        </div>
    </>
)

export default DOM