
import common from './common.json'
import pregame from './pregame.json'
import game from './game.json'


const translation = {
    common,
    pregame,
    game
}

export default translation