import styled, {keyframes} from "styled-components";
import {device} from "../../breakpoints";


const fadein = keyframes`
  from {opacity: 0}
  to {opacity: 1}
`

/*const fadeout = keyframes`
  from {opacity: 1}
  to {opacity: 0}
`*/

const Page = styled.div`
  width: 100%;
  // height: 100%;
  min-height: 100%;
  position: relative;
  display: flex;
  //box-sizing: border-box;
  // animation: ${fadein} 0.5s;
`

const PaddedPage = styled(Page)`
  padding: ${props=> props.ratio * 135}px ${props=> props.ratio * 152}px ${props=> props.ratio * 72}px;
  max-height: 100%;
  ${device.onlyTablet}{
    padding: 24px;
    max-height: initial;
    
}
  ${device.onlyMobile}{
    padding: 16px;
    max-height: initial;
  }
`

export {PaddedPage};
export default Page;