import {END_GAME, ImageType, PositionType, SceneType} from "../../../classes/scene";

import BG01 from "../../../images/background/BG-01.png";
import BG02 from "../../../images/background/BG-02.png";
import BG03 from "../../../images/background/BG-03.png";


import LXY08SitSmile from "../../../images/patient/lexy/LXY-08.png";

import LXY01 from "../../../images/patient/lexy/LXY-01.png";
import LXY03 from "../../../images/patient/lexy/LXY-03.png";
import LXY04 from "../../../images/patient/lexy/LXY-04.png";
import LXY05 from "../../../images/patient/lexy/LXY-05.png";
import LXY06 from "../../../images/patient/lexy/LXY-06.png";

import MOM02Sad from "../../../images/patient/lexy/LXY-M-02.png";

import HCP_0_06 from "../../../images/hcp/0/HCP-06.png";
import HCP_1_06 from "../../../images/hcp/1/HCP-06.png";
import HCP_2_06 from "../../../images/hcp/2/HCP-06.png";
import HCP_0_05 from "../../../images/hcp/0/HCP-05.png";
import HCP_1_05 from "../../../images/hcp/1/HCP-05.png";
import HCP_2_05 from "../../../images/hcp/2/HCP-05.png";

import {CHATICON} from "../storyGlobal";
import {END_LEVEL} from "../../../constants";


const BG = {
    '01': {
        type: ImageType.background,
        image: BG01,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    '01-2': {
        type: ImageType.background,
        image: BG01,
        positionType: PositionType.fullscreen,
        position: {left: -60, right: 60},
        style: null,
    },
    '02': {
        type: ImageType.background,
        image: BG02,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    '03': {
        type: ImageType.background,
        image: BG03,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
}


const LXY = {
    "08": {
        type: ImageType.avatar,
        image: LXY08SitSmile,
        positionType: PositionType.px,
        position: {
            left: 248,
            top: 395,
            right: 932,
            bottom: 64,
        },
        style: null,
    },
    "08-2": {
        type: ImageType.avatar,
        image: LXY08SitSmile,
        positionType: PositionType.px,
        position: {
            left: 189,
            top: 395,
            right: 991,
            bottom: 64,
        },
        style: null,
    },
}


const LXY_CENTER = (img) => {
    return (
        {
            type: ImageType.avatar,
            image: img,
            positionType: PositionType.px,
            position: {
                left: 421,
                top: 260,
                right: 388,
                bottom: -795,
            },
            style: null,
        })

}

const MOM = {
    "02":{
        type: ImageType.avatar,
        image: MOM02Sad,
        positionType: PositionType.px,
        position: {
            left: 391,
            top: 316,
            right: 858,
            bottom: 72,
        },
        style: null,
    },
    "02-2":{
        type: ImageType.avatar,
        image: MOM02Sad,
        positionType: PositionType.px,
        position: {
            left: 332,
            top: 316,
            right: 917,
            bottom: 72,
        },
        style: null,
    },
    "02-3":{
        type: ImageType.avatar,
        image: MOM02Sad,
        positionType: PositionType.px,
        position: {
            left: 421,
            top: 260,
            right: 388,
            bottom: -795,
        },
        style: null,
    }

}

const HCP = {
    '05' :{
        type: ImageType.avatar,
        image: [HCP_0_05, HCP_1_05, HCP_2_05],
        positionType: PositionType.px,
        position: {
            left: 746,
            top: 394,
            right: 421,
            bottom: 44.71,
        },
        style: null,
        pronounButton:{
            top: 214,
            left: 187,
            width: 44,
            // height: 30,
            clippath: null
        }
    },
    '06' :{
        type: ImageType.avatar,
        image: [HCP_0_06, HCP_1_06, HCP_2_06],
        positionType: PositionType.px,
        position: {
            left: 462.21,
            top: 187,
            right: 462.35,
            bottom: -876,
        },
        style: null,
    },

}

const Pronoun2Scenes = {
    id: '01-02',
    patient: 2,
    scene: [{
        id: '01-02-001-01',
        type: SceneType.description,
        images:[
            BG['01'],
            MOM['02'],
            LXY['08'],

        ],
        bubbleText: null,
        actions: [{
            nextId: '01-02-002-01',
            text: '001-01',
        }],
        hint: null,
        isBeginning: true,
    },{
        id: '01-02-002-01',
        type: SceneType.description,
        images:[
            BG['01-2'],
            MOM['02-2'],
            LXY['08-2'],
            HCP['05']

        ],
        bubbleText: false,
        actions: [{
            nextId: '01-02-003-01',
            text: '002-01',
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-02-003-01',
        type: SceneType.selection,
        images:[
            BG['02'],
            HCP['06']

        ],
        bubbleText: false,
        actions: [{
            nextId: '01-02-004-01',
            text: (t,args) => t(`i01-q01`, {name: args.name, pronoun: args.pronoun}),
            opt: 1,
            score: 3,
        }, {
            nextId: '01-02-004-02',
            text: (t,args) => t(`i01-q02`, {name: args.name, pronoun: args.pronoun}),
            opt: 2,
            score: 2,
        }, {
            nextId: '01-02-004-03',
            text: (t,args) => t(`i01-q03`, {name: args.name, pronoun: args.pronoun}),
            opt: 3,
            score: 1,
        }, {
            nextId: '01-02-004-04',
            text: (t,args) => t(`i01-q04`, {name: args.name, pronoun: args.pronoun}),
            opt: 4,
            score: 0,
        }],
        hint: 'i01-question',
        isBeginning: false,
    },{
        id: '01-02-004-01',
        type: SceneType.chat,
        images: [
            BG["03"],
            LXY_CENTER(LXY01),
        ],
        bubbleText: null,
        actions: [{
            nextId: '01-02-005-01',
            text: 'i01-a01',
            chatIcon: CHATICON.LXY
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-02-004-02',
        type: SceneType.chat,
        images: [
            BG["03"],
            LXY_CENTER(LXY03),
        ],
        bubbleText: null,
        actions: [{
            nextId: '01-02-005-01',
            text: 'i01-a02',
            chatIcon: CHATICON.LXY
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-02-004-03',
        type: SceneType.chat,
        images: [
            BG["03"],
            LXY_CENTER(LXY04),
        ],
        bubbleText: null,
        actions: [{
            nextId: '01-02-005-01',
            text: 'i01-a03',
            chatIcon: CHATICON.LXY
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-02-004-04',
        type: SceneType.chat,
        images: [
            BG["03"],
            LXY_CENTER(LXY05),
        ],
        bubbleText: null,
        actions: [{
            nextId: '01-02-005-01',
            text: 'i01-a04',
            chatIcon: CHATICON.LXY
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-02-005-01',
        type: SceneType.chat,
        images: [
            BG["03"],
            MOM["02-3"],
        ],
        bubbleText: null,
        actions: [{
            nextId: '01-02-006-01',
            text: '005-01',
            chatIcon: CHATICON["LXY-MOM"]
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-02-006-01',
        type: SceneType.selection,
        images:[
            BG['02'],
            HCP['06']

        ],
        bubbleText: false,
        actions: [{
            nextId: '01-02-007-01',
            text: 'i02-q01',
            opt: 1,
            score: 3,
        }, {
            nextId: '01-02-007-02',
            text: 'i02-q02',
            opt: 2,
            score: 2,
        }, {
            nextId: '01-02-007-03',
            text: 'i02-q03',
            opt: 3,
            score: 1,
        }, {
            nextId: '01-02-007-04',
            text: 'i02-q04',
            opt: 4,
            score: 0,
        }],
        hint: 'i02-question',
        isBeginning: false,
    },{
        id: '01-02-007-01',
        type: SceneType.chat,
        images: [
            BG["03"],
            LXY_CENTER(LXY01),
        ],
        bubbleText: null,
        actions: [{
            nextId: '01-02-008-01',
            text: 'i02-a01',
            chatIcon: CHATICON.LXY
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-02-007-02',
        type: SceneType.chat,
        images: [
            BG["03"],
            LXY_CENTER(LXY03),
        ],
        bubbleText: null,
        actions: [{
            nextId: '01-02-008-01',
            text: 'i02-a02',
            chatIcon: CHATICON.LXY
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-02-007-03',
        type: SceneType.chat,
        images: [
            BG["03"],
            LXY_CENTER(LXY04),
        ],
        bubbleText: null,
        actions: [{
            nextId: '01-02-008-01',
            text: 'i02-a03',
            chatIcon: CHATICON.LXY
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-02-007-04',
        type: SceneType.chat,
        images: [
            BG["03"],
            LXY_CENTER(LXY05),
        ],
        bubbleText: [{text: 'i02-a04-b'}],
        actions: [{
            nextId: END_GAME,
            text: 'i02-a04',
            chatIcon: CHATICON.LXY,
            gameEndLevel: END_LEVEL.WORSE
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-02-008-01',
        type: SceneType.selection,
        images:[
            BG['02'],
            HCP['06']

        ],
        bubbleText: false,
        actions: [{
            nextId: '01-02-009-01',
            text: 'i03-q01',
            opt: 1,
            score: 3,
        }, {
            nextId: '01-02-009-02',
            text: 'i03-q02',
            opt: 2,
            score: 2,
        }, {
            nextId: '01-02-009-03',
            text: 'i03-q03',
            opt: 3,
            score: 1,
        }, {
            nextId: '01-02-009-04',
            text: 'i03-q04',
            opt: 4,
            score: 0,
        }],
        hint: 'i03-question',
        isBeginning: false,
    },{
        id: '01-02-009-01',
        type: SceneType.chat,
        images: [
            BG["03"],
            LXY_CENTER(LXY01),
        ],
        bubbleText: null,
        actions: [{
            nextId: END_GAME,
            text: 'i03-a01',
            chatIcon: CHATICON.LXY,
            gameEndLevel: END_LEVEL.GREAT
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-02-009-02',
        type: SceneType.chat,
        images: [
            BG["03"],
            LXY_CENTER(LXY06),
        ],
        bubbleText: null,
        actions: [{
            nextId: '01-02-010-01',
            text: 'i03-a02',
            chatIcon: CHATICON.LXY
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-02-009-03',
        type: SceneType.chat,
        images: [
            BG["03"],
            LXY_CENTER(LXY04),
        ],
        bubbleText: null,
        actions: [{
            nextId: '01-02-010-01',
            text: 'i03-a03',
            chatIcon: CHATICON.LXY
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-02-009-04',
        type: SceneType.chat,
        images: [
            BG["03"],
            LXY_CENTER(LXY05),
        ],
        bubbleText: null,
        actions: [{
            nextId: '01-02-010-01',
            text: 'i03-a04',
            chatIcon: CHATICON.LXY,
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-02-010-01',
        type: SceneType.chat,
        images: [
            BG["03"],
            MOM["02-3"],
        ],
        bubbleText: null,
        actions: [{
            nextId: '01-02-011-01',
            text: '010-01',
            chatIcon: CHATICON["LXY-MOM"]
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-02-011-01',
        type: SceneType.selection,
        images:[
            BG['02'],
            HCP['06']

        ],
        bubbleText: false,
        actions: [{
            nextId: '01-02-012-01',
            text: 'i04-q01',
            opt: 1,
            score: 3,
        }, {
            nextId: '01-02-012-02',
            text: 'i04-q02',
            opt: 2,
            score: 2,
        }, {
            nextId: '01-02-012-03',
            text: 'i04-q03',
            opt: 3,
            score: 1,
        }, {
            nextId: '01-02-012-04',
            text: 'i04-q04',
            opt: 4,
            score: 0,
        }],
        hint: 'i04-question',
        isBeginning: false,
    },{
        id: '01-02-012-01',
        type: SceneType.chat,
        images: [
            BG["03"],
            LXY_CENTER(LXY01),
        ],
        bubbleText: null,
        actions: [{
            nextId: END_GAME,
            text: 'i04-a01',
            chatIcon: CHATICON.LXY,
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-02-012-02',
        type: SceneType.chat,
        images: [
            BG["03"],
            LXY_CENTER(LXY03),
        ],
        bubbleText: null,
        actions: [{
            nextId: END_GAME,
            text: 'i04-a02',
            chatIcon: CHATICON.LXY
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-02-012-03',
        type: SceneType.chat,
        images: [
            BG["03"],
            LXY_CENTER(LXY04),
        ],
        bubbleText: null,
        actions: [{
            nextId: END_GAME,
            text: 'i04-a03',
            chatIcon: CHATICON.LXY
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-02-012-04',
        type: SceneType.chat,
        images: [
            BG["03"],
            LXY_CENTER(LXY05),
        ],
        bubbleText: null,
        actions: [{
            nextId: END_GAME,
            text: 'i04-a04',
            chatIcon: CHATICON.LXY,
        }],
        hint: null,
        isBeginning: false,
    },


    ]
}


export default Pronoun2Scenes