import {END_GAME, ImageType, PositionType, SceneType} from "../../../classes/scene";

import BG01 from "../../../images/background/BG-01.png";
import BG02 from "../../../images/background/BG-02.png";
import BG03 from "../../../images/background/BG-03.png";
import BG09 from "../../../images/background/BG-09.png";
import BG10 from "../../../images/background/BG-10.png";
import BG14 from "../../../images/background/BG-14.png";
import BG12 from "../../../images/background/BG-12.png";
import BG15 from "../../../images/background/BG-15.png";


import LXY08SitSmile from "../../../images/patient/lexy/LXY-08.png";

import LXY01 from "../../../images/patient/lexy/LXY-01.png";
import LXY03 from "../../../images/patient/lexy/LXY-03.png";
import LXY04 from "../../../images/patient/lexy/LXY-04.png";
import LXY06 from "../../../images/patient/lexy/LXY-06.png";

import MOM01 from "../../../images/patient/lexy/LXY-M-01.png";
import MOM02 from "../../../images/patient/lexy/LXY-M-02.png";

import HCP_0_01 from "../../../images/hcp/0/HCP-01.png";
import HCP_1_01 from "../../../images/hcp/1/HCP-01.png";
import HCP_2_01 from "../../../images/hcp/2/HCP-01.png";
import HCP_0_03 from "../../../images/hcp/0/HCP-03.png";
import HCP_1_03 from "../../../images/hcp/1/HCP-03.png";
import HCP_2_03 from "../../../images/hcp/2/HCP-03.png";
import HCP_0_05 from "../../../images/hcp/0/HCP-05.png";
import HCP_1_05 from "../../../images/hcp/1/HCP-05.png";
import HCP_2_05 from "../../../images/hcp/2/HCP-05.png";

import Item03 from "../../../images/background/Item-03.png";

import {CHATICON} from "../storyGlobal";
import {END_LEVEL} from "../../../constants";


const BG = {
    '10': {
        type: ImageType.background,
        image: BG10,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    '12': {
        type: ImageType.background,
        image: BG12,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    '15': {
        type: ImageType.background,
        image: BG15,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    '14': {
        type: ImageType.background,
        image: BG14,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    '01-2': {
        type: ImageType.background,
        image: BG01,
        positionType: PositionType.fullscreen,
        position: {left: -60, right: 60},
        style: null,
    },
    '09': {
        type: ImageType.background,
        image: BG09,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    '02': {
        type: ImageType.background,
        image: BG02,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    '03': {
        type: ImageType.background,
        image: BG03,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
}


const LXY = {
    "08": {
        type: ImageType.avatar,
        image: LXY08SitSmile,
        positionType: PositionType.px,
        position: {
            left: 427,
            top: 394,
            right: 757,
            bottom: 70.47,
        },
        style: null,
    },
    "08-2": {
        type: ImageType.avatar,
        image: LXY08SitSmile,
        positionType: PositionType.px,
        position: {
            left: 189,
            top: 395,
            right: 991,
            bottom: 64,
        },
        style: null,
    },
    "01": {
        type: ImageType.avatar,
        image: LXY01,
        positionType: PositionType.px,
        position: {
            left: 598,
            top: 335,
            right: 603,
            bottom: -25,
            zIndex: 17,
            isFlip: true
        },
        style: null,
    },
    "01-2": {
        type: ImageType.avatar,
        image: LXY01,
        positionType: PositionType.px,
        position: {
            left: 462,
            top: 187,
            right: 462.56,
            bottom: -876,
        },
        style: null,
    },
    "06": {
        type: ImageType.avatar,
        image: LXY06,
        positionType: PositionType.px,
        position: {
            left: 403,
            top: 211,
            right: 404.59,
            bottom: -748.65,
        },
        style: null,
    },
    "06-2": {
        type: ImageType.avatar,
        image: LXY06,
        positionType: PositionType.px,
        position: {
            left: 583,
            top: 485,
            right: 666,
            bottom: -70,
        },
        style: null,
    },
}


const LXY_CENTER = (img) => {
    return (
        {
            type: ImageType.avatar,
            image: img,
            positionType: PositionType.px,
            position: {
                left: 421,
                top: 260,
                right: 388,
                bottom: -795,
            },
            style: null,
        })

}

const MOM = {
    "01":{
        type: ImageType.avatar,
        image: MOM01,
        positionType: PositionType.px,
        position: {
            left: 243,
            top: 303,
            right: 980,
            bottom: 28,
        },
        style: null,
    },
    "01-2":{
        type: ImageType.avatar,
        image: MOM01,
        positionType: PositionType.px,
        position: {
            left: 785,
            top: 296,
            right: 416,
            bottom: -23,
            zIndex: 16,
            isFlip: true
        },
        style: null,
    },
    "02":{
        type: ImageType.avatar,
        image: MOM02,
        positionType: PositionType.px,
        position: {
            left: 472,
            top: 227,
            right: 473,
            bottom: -521.58,
        },
        style: null,
    },

}

const HCP = {
    '03' :{
        type: ImageType.avatar,
        image: [HCP_0_03, HCP_1_03, HCP_2_03],
        positionType: PositionType.px,
        position: {
            left: 333,
            top: 306,
            right: 945,
            bottom: 43,
        },
        style: null,
        pronounButton:{
            top: 225,
            left: 90,
            width: 47,
            // height: 30,
            clippath: null
        }
    },
    '03-2' :{
        type: ImageType.avatar,
        image: [HCP_0_03, HCP_1_03, HCP_2_03],
        positionType: PositionType.px,
        position: {
            left: 498,
            top: 206,
            right: 501,
            bottom: -613,
        },
        style: null,
        pronounButton:{
            top: 503,
            left: 201,
            width: 97,
            // height: 30,
            clippath: null
        }
    },
    '01' :{
        type: ImageType.avatar,
        image: [HCP_0_01, HCP_1_01, HCP_2_01],
        positionType: PositionType.px,
        position: {
            left: 498,
            top: 206,
            right: 501,
            bottom: -613,
        },
        style: null,
    },
    '01-2' :{
        type: ImageType.avatar,
        image: [HCP_0_01, HCP_1_01, HCP_2_01],
        positionType: PositionType.px,
        position: {
            left: 462,
            top: 187,
            right: 462,
            bottom: -876,
        },
        style: null,
    },
    '05' :{
        type: ImageType.avatar,
        image: [HCP_0_05, HCP_1_05, HCP_2_05],
        positionType: PositionType.px,
        position: {
            left: 746,
            top: 394,
            right: 421,
            bottom: 44.71,
        },
        style: null,
        pronounButton:{
            top: 214,
            left: 187,
            width: 44,
            // height: 30,
            clippath: null
        }
    },

}
const ITEM = {
    '03': {
        type: ImageType.foreground,
        image: Item03,
        positionType: PositionType.px,
        position: {
            left: 87,
            top: 599,
            right: 733,
            bottom: 41,
        },
        style: null,
    },
    '03-2': {
        type: ImageType.foreground,
        image: Item03,
        positionType: PositionType.px,
        position: {
            left: 94,
            top: 765,
            right: 96,
            bottom: -437,
        },
        style: null,
    },
}

const Relationship2Scenes = {
    id: '04-02',
    patient: 2,
    scene: [{
        id: '04-02-001-01',
        type: SceneType.description,
        images:[
            BG['12'],
            MOM['01'],
            LXY['08'],

        ],
        bubbleText: null,
        actions: [{
            nextId: '04-02-002-01',
            text: '001-01',
        }],
        hint: null,
        isBeginning: true,
    },{
        id: '04-02-002-01',
        type: SceneType.description,
        images:[
            BG['10'],
            HCP['03'],
            ITEM['03'],
            MOM['01-2'],
            LXY['01'],

        ],
        bubbleText: false,
        actions: [{
            nextId: '04-02-003-01',
            text: '002-01',
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '04-02-003-01',
        type: SceneType.chat,
        images:[
            BG['15'],
            MOM['02'],

        ],
        bubbleText: false,
        actions: [{
            nextId: '04-02-004-01',
            text: '003-01',
            chatIcon: CHATICON["LXY-MOM"],
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '04-02-004-01',
        type: SceneType.selection,
        images:[
            BG['14'],
            HCP['03-2'],
            ITEM['03-2']

        ],
        bubbleText: false,
        actions: [{
            nextId: '04-02-005-01',
            text: 'i01-q01',
            opt: 1,
            score: 3,
        }, {
            nextId: '04-02-005-02',
            text: 'i01-q02',
            opt: 2,
            score: 2,
        }, {
            nextId: '04-02-005-03',
            text: 'i01-q03',
            opt: 3,
            score: 1,
        }, {
            nextId: '04-02-005-04',
            text: 'i01-q04',
            opt: 4,
            score: 0,
        }],
        hint: 'i01-question',
        isBeginning: false,
    },{
        id: '04-02-005-01',
        type: SceneType.chat,
        images: [
            BG["15"],
            LXY_CENTER(LXY01),
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-02-006-01',
            text: 'i01-a01',
            chatIcon: CHATICON.LXY
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '04-02-005-02',
        type: SceneType.chat,
        images: [
            BG["15"],
            LXY_CENTER(LXY01),
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-02-006-01',
            text: 'i01-a02',
            chatIcon: CHATICON.LXY
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '04-02-005-03',
        type: SceneType.chat,
        images: [
            BG["15"],
            LXY_CENTER(LXY04),
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-02-006-01',
            text: 'i01-a03',
            chatIcon: CHATICON.LXY
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '04-02-005-04',
        type: SceneType.chat,
        images: [
            BG["15"],
            LXY_CENTER(LXY06),
        ],
        bubbleText: null,
        actions: [{
            nextId: END_GAME,
            text: 'i01-a04',
            chatIcon: CHATICON.LXY,
            gameEndLevel: END_LEVEL.WORSE
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '04-02-006-01',
        type: SceneType.description,
        images:[
            BG['14'],
            HCP['01'],
            ITEM['03-2']

        ],
        bubbleText: null,
        actions: [{
            nextId: '04-02-007-01',
            text: '006-01',
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '04-02-007-01',
        type: SceneType.selection,
        images:[
            BG['14'],
            HCP['01'],
            ITEM['03-2']

        ],
        bubbleText: false,
        actions: [{
            nextId: '04-02-007-01-02',
            text: 'i02-q01',
            opt: 1,
            score: 0,
        }, {
            nextId: '04-02-008-02',
            text: 'i02-q02',
            opt: 2,
            score: 0,
        }],
        hint: 'i02-question',
        isBeginning: false,
    },{
        id: '04-02-007-01-02',
        type: SceneType.chat,
        images: [
            BG["01-2"],
            LXY["08-2"],
            HCP['05']
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-02-008-01',
            text: 'i02-a01-01',
            chatIcon: CHATICON.HCP
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '04-02-008-01',
        type: SceneType.chat,
        images: [
            BG["03"],
            LXY_CENTER(LXY01),
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-02-009-01',
            text: 'i02-a01-02',
            chatIcon: CHATICON.LXY
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '04-02-008-02',
        type: SceneType.chat,
        images: [
            BG["03"],
            LXY['06'],
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-02-008-02-02',
            text: 'i02-a02-01',
            chatIcon: CHATICON.LXY
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '04-02-008-02-02',
        type: SceneType.description,
        images: [
            BG["09"],
            LXY['06-2'],
        ],
        bubbleText: null,
        actions: [{
            nextId: END_GAME,
            text: 'i02-a02-02',
            gameEndLevel: END_LEVEL.WORSE
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '04-02-009-01',
        type: SceneType.chat,
        images: [
            BG["02"],
            HCP['01-2']
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-02-010-01',
            text: 'i03-q01',
            chatIcon: CHATICON.HCP
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '04-02-010-01',
        type: SceneType.chat,
        images: [
            BG["02"],
            LXY_CENTER(LXY01),
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-02-011-01',
            text: 'i03-a01',
            chatIcon: CHATICON.LXY
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '04-02-011-01',
        type: SceneType.selection,
        images:[
            BG['02'],
            HCP['01-2']

        ],
        bubbleText: false,
        actions: [{
            nextId: '04-02-012-01',
            text: 'i04-q01',
            opt: 1,
            score: 3,
        }, {
            nextId: '04-02-012-02',
            text: 'i04-q02',
            opt: 2,
            score: 2,
        }, {
            nextId: '04-02-012-03',
            text: 'i04-q03',
            opt: 3,
            score: 1,
        }, {
            nextId: '04-02-012-04',
            text: 'i04-q04',
            opt: 4,
            score: 0,
        }],
        hint: 'i04-question',
        isBeginning: false,
    },{
        id: '04-02-012-01',
        type: SceneType.chat,
        images: [
            BG["03"],
            LXY_CENTER(LXY01),
        ],
        bubbleText: null,
        actions: [{
            nextId: END_GAME,
            text: 'i04-a01',
            chatIcon: CHATICON.LXY,
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '04-02-012-02',
        type: SceneType.chat,
        images: [
            BG["03"],
            LXY_CENTER(LXY03),
        ],
        bubbleText: null,
        actions: [{
            nextId: END_GAME,
            text: 'i04-a02',
            chatIcon: CHATICON.LXY
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '04-02-012-03',
        type: SceneType.chat,
        images: [
            BG["03"],
            LXY_CENTER(LXY04),
        ],
        bubbleText: null,
        actions: [{
            nextId: END_GAME,
            text: 'i04-a03',
            chatIcon: CHATICON.LXY
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '04-02-012-04',
        type: SceneType.chat,
        images: [
            BG["03"],
            LXY_CENTER(LXY06),
        ],
        bubbleText: null,
        actions: [{
            nextId: END_GAME,
            text: 'i04-a04',
            chatIcon: CHATICON.LXY,
        }],
        hint: null,
        isBeginning: false,
    }
    ]
}


export default Relationship2Scenes