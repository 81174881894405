import BG05 from "../../../images/background/BG-05.png";
import BG12 from "../../../images/background/BG-12.png";
import BG01 from "../../../images/background/BG-01.png";


import CTN15sitsmile from "../../../images/patient/chetan/CTN-15-sit-smile-str8.png";
import CTN06 from "../../../images/patient/chetan/CTN-06.png";
import {END_GAME, ImageType, PositionType, SceneType} from "../../../classes/scene";

import HCP_0_01 from "../../../images/hcp/0/HCP-01.png";
import HCP_1_01 from "../../../images/hcp/1/HCP-01.png";
import HCP_2_01 from "../../../images/hcp/2/HCP-01.png";

import BG02 from "../../../images/background/BG-02.png";
import BG03 from "../../../images/background/BG-03.png";
import BG04 from "../../../images/background/BG-04.png";
import BG13 from "../../../images/background/BG-13.png"

import CTN01smile from "../../../images/patient/chetan/CTN-01-smile.png";
import CTN11normal from "../../../images/patient/chetan/CTN-11-normal.png";
import CTN03annoy from "../../../images/patient/chetan/CTN-03-annoyed.png";
import CTN04angry from "../../../images/patient/chetan/CTN-04-angry.png";

import Item19 from "../../../images/background/Item-19.png";
import Item16 from "../../../images/background/Item-16.png";

import HCP_0_06 from "../../../images/hcp/0/HCP-06.png";
import HCP_1_06 from "../../../images/hcp/1/HCP-06.png";
import HCP_2_06 from "../../../images/hcp/2/HCP-06.png";

import HCP_0_07 from "../../../images/hcp/0/HCP-07.png";
import HCP_1_07 from "../../../images/hcp/1/HCP-07.png";
import HCP_2_07 from "../../../images/hcp/2/HCP-07.png";

import Person01 from "../../../images/person/Person-01.png"
import Person02 from "../../../images/person/Person-02.png"
import {CHATICON} from "../storyGlobal";
import HCP_0_05 from "../../../images/hcp/0/HCP-05.png";
import HCP_1_05 from "../../../images/hcp/1/HCP-05.png";
import HCP_2_05 from "../../../images/hcp/2/HCP-05.png";
import HCP_A_01 from "../../../images/hcp/HCP-F-C01.png";
import HCP_A_02 from "../../../images/hcp/HCP-F-C02.png";


const POSITION = {
    searchHCP: {
        left: 550.31,
        top: 207,
        right: 262.77,
        bottom: -765,
    },
}

const BG = {
    "12" :{
        type: ImageType.background,
        image: BG12,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    "05" :{
        type: ImageType.background,
        image: BG05,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    '01': {
        type: ImageType.background,
        image: BG01,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    '02': {
        type: ImageType.background,
        image: BG02,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    '04': {
        type: ImageType.background,
        image: BG04,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    '03': {
        type: ImageType.background,
        image: BG03,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    "13" :{
        type: ImageType.background,
        image: BG13,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
}


const HCP = {
    '01': {
        type: ImageType.avatar,
        image: [HCP_0_01, HCP_1_01, HCP_2_01],
        positionType: PositionType.px,
        position: {
            left: 462,
            top: 187,
            right: 462,
            bottom: -876,
        },
        style: null,
    },
    '06': {
        type: ImageType.avatar,
        image: [HCP_0_06, HCP_1_06, HCP_2_06],
        positionType: PositionType.px,
        position: {
            left: 462,
            top: 187,
            right: 462,
            bottom: -876,
        },
        style: null,
    },
    '07': {
        type: ImageType.avatar,
        image: [HCP_0_07, HCP_1_07, HCP_2_07],
        positionType: PositionType.px,
        position: POSITION.searchHCP,
        style: null,
        pronounButton:{
            top: 558,
            left: 240,
            width: 105,
            // height: 30,
            clippath: 'polygon(0% 0%, 0% 30%, 60% 65%, 70% 100%, 100% 100%, 100% 0%)'
        }
    },


    '05' :{
        type: ImageType.avatar,
        image: [HCP_0_05, HCP_1_05, HCP_2_05],
        positionType: PositionType.px,
        position: {
            left: 805,
            top: 394,
            right: 362,
            bottom: 44.71,
        },
        style: null,
        pronounButton:{
            top: 214,
            left: 187,
            width: 44,
            // height: 30,
            clippath: null
        }
    },
}

const HCPA = {
    '01' :{
        type: ImageType.avatar,
        image: HCP_A_01,
        positionType: PositionType.px,
        position: {
            left: 1138,
            top: 298,
            right: 125,
            bottom: 45,
        },
        style: null,
    },
    '01-2' : {
        type: ImageType.avatar,
        image: HCP_A_01,
        positionType: PositionType.px,
        position: {
            left: 462,
            top: 187,
            right: 462,
            bottom: -876,
        },
    },
    '02' : {
        type: ImageType.avatar,
        image: HCP_A_02,
        positionType: PositionType.px,
        position: {
            left: 462,
            top: 187,
            right: 462,
            bottom: -876,
        },
    }
}


const CTN = {
    "15": {
        type: ImageType.avatar,
        image: CTN15sitsmile,
        positionType: PositionType.px,
        position: {
            left: 427,
            top: 418,
            right: 757,
            bottom: 48,
        },
        style: null,
    },
    "15-2": {
        type: ImageType.avatar,
        image: CTN15sitsmile,
        positionType: PositionType.px,
        position: {
            left: 262,
            top: 395,
            right: 905,
            bottom: 45.38,
        },
        style: null,
    },
    "06": {
        type: ImageType.avatar,
        image: CTN06,
        positionType: PositionType.px,
        position: {
            left: 283,
            top: 394,
            right: 981,
            bottom: -82,
        },
        style: null,
    }
}

const CTN_CENTER = (img) => {
    return (
        {
            type: ImageType.avatar,
            image: img,
            positionType: PositionType.px,
            position: {
                left: 475,
                top: 212,
                right: 475,
                bottom: -749.65,
            },
            style: null,
        })
}

const PERSON = {
    '01': {
        type: ImageType.avatar,
        image: Person01,
        positionType: PositionType.px,
        position: {
            left: 708,
            top: 290,
            right: 593,
            bottom: 48,
        },
        style: null,
    },
    '02': {
        type: ImageType.avatar,
        image: Person02,
        positionType: PositionType.px,
        position: {
            left: 266,
            top: 400,
            right: 928,
            bottom: -48,
        },
        style: null,

    }
}

const ITEM = {
    '16': {
        type: ImageType.foreground,
        image: Item16,
        positionType: PositionType.px,
        position: {
            left: -342,
            top: 343,
            right: -61,
            bottom: -678,
        },
        style: null,
    },
    '19': {
        type: ImageType.foreground,
        image: Item19,
        positionType: PositionType.px,
        position: {
            left: -282,
            top: 345,
            right: -261,
            bottom: -435,
        },
        style: null,
    },
}

const Sexuality1Scenes = {
    id: '03-01',
    patient: 1,
    scene: [{
        id: '03-01-000-01',
        type: SceneType.description,
        images: [
            BG["12"],
            PERSON["01"],
            CTN["15"],
            PERSON["02"]
        ],
        bubbleText: null,
        actions: [{
            nextId: '03-01-001-01',
            text: '000-01'
        }],
        hint: null,
        isBeginning: true,
    },{
        id: '03-01-001-01',
        type: SceneType.description,
        images: [
            BG["05"],
            CTN["06"]
        ],
        bubbleText: null,
        actions: [{
            nextId: '03-01-002-01',
            text: '001-01'
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '03-01-002-01',
        type: SceneType.description,
        images: [
            BG["01"],
            CTN["15-2"],
            HCP['05'],
            HCPA['01']
        ],
        bubbleText: null,
        actions: [{
            nextId: '03-01-002-02',
            text:'002-01',
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '03-01-002-02',
        type: SceneType.chat,
        images: [
            BG["02"],
            HCPA['01-2']
        ],
        bubbleText: null,
        actions: [{
            nextId: '03-01-003-01',
            text:'002-02',
            chatIcon: CHATICON.HCPA
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '03-01-003-01',
        type: SceneType.chat,
        images: [
            BG["13"],
            CTN_CENTER(CTN01smile),
        ],
        bubbleText: null,
        actions: [{
            nextId: '03-01-004-01',
            text: '003-01',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '03-01-004-01',
        type: SceneType.chat,
        images: [
            BG["02"],
            HCPA['02']
        ],
        bubbleText: null,
        actions: [{
            nextId: '03-01-004-02',
            text:'004-01',
            chatIcon: CHATICON.HCPA
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '03-01-004-02',
        type: SceneType.chat,
        images: [
            BG["13"],
            CTN_CENTER(CTN03annoy),
        ],
        bubbleText: null,
        actions: [{
            nextId: '03-01-005-01',
            text: '004a-01',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '03-01-005-01',
        type: SceneType.selection,
        images: [
            BG["02"],
            HCP["06"],
        ],
        bubbleText: null,
        actions: [{
            nextId: '03-01-006-01',
            text: 'i01-q01',
            opt: 1,
            score: 3,
        }, {
            nextId: '03-01-006-02',
            text: 'i01-q02',
            opt: 2,
            score: 2,
        }, {
            nextId: '03-01-006-03',
            text: 'i01-q03',
            opt: 3,
            score: 1,
        }, {
            nextId: '03-01-006-04',
            text: 'i01-q04',
            opt: 4,
            score: 0,
        }],
        hint: 'i01-question',
        isBeginning: false,
    },{
        id: '03-01-006-01',
        type: SceneType.chat,
        images: [
            BG["03"],
            CTN_CENTER(CTN01smile),
        ],
        bubbleText: null,
        actions: [{
            nextId: '03-01-007-01',
            text: 'i01-a01',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '03-01-006-02',
        type: SceneType.chat,
        images: [
            BG["03"],
            CTN_CENTER(CTN11normal),
        ],
        bubbleText: null,
        actions: [{
            nextId: '03-01-007-01',
            text: 'i01-a02',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '03-01-006-03',
        type: SceneType.chat,
        images: [
            BG["03"],
            CTN_CENTER(CTN03annoy),
        ],
        bubbleText: null,
        actions: [{
            nextId: '03-01-007-01',
            text: 'i01-a03',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '03-01-006-04',
        type: SceneType.chat,
        images: [
            BG["03"],
            CTN_CENTER(CTN04angry),
        ],
        bubbleText: null,
        actions: [{
            nextId: '03-01-007-01',
            text: 'i01-a04',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '03-01-007-01',
        type: SceneType.selection,
        images: [
            BG["02"],
            HCP['07'],
            ITEM['16']
        ],
        bubbleText: null,
        actions: [{
            nextId: '03-01-008-01',
            text: 'i02-q01',
            opt: 1,
            score: 3,
        }, {
            nextId: '03-01-008-02',
            text: 'i02-q02',
            opt: 2,
            score: 2,
        }, {
            nextId: '03-01-008-03',
            text: 'i02-q03',
            opt: 3,
            score: 1,
        }, {
            nextId: '03-01-008-04',
            text: 'i02-q04',
            opt: 4,
            score: 0,
        }],
        hint: 'i02-question',
        isBeginning: false,
    },{
        id: '03-01-008-01',
        type: SceneType.chat,
        images: [
            BG["13"],
            CTN_CENTER(CTN01smile),
        ],
        bubbleText: null,
        actions: [{
            nextId: '03-01-009-01',
            text: 'i02-a01',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '03-01-008-02',
        type: SceneType.chat,
        images: [
            BG["13"],
            CTN_CENTER(CTN11normal),
        ],
        bubbleText: null,
        actions: [{
            nextId: '03-01-009-01',
            text: 'i02-a02',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '03-01-008-03',
        type: SceneType.chat,
        images: [
            BG["13"],
            CTN_CENTER(CTN03annoy),
        ],
        bubbleText: null,
        actions: [{
            nextId: '03-01-009-01',
            text: 'i02-a03',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '03-01-008-04',
        type: SceneType.chat,
        images: [
            BG["13"],
            CTN_CENTER(CTN04angry),
        ],
        bubbleText: null,
        actions: [{
            nextId: '03-01-009-01',
            text: 'i02-a04',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '03-01-009-01',
        type: SceneType.selection,
        images: [
            BG["02"],
            HCP['01'],
        ],
        bubbleText: null,
        actions: [{
            nextId: '03-01-010-01',
            text: 'i03-q01',
            opt: 1,
            score: 3,
        }, {
            nextId: '03-01-010-02',
            text: 'i03-q02',
            opt: 2,
            score: 2,
        }, {
            nextId: '03-01-010-03',
            text: 'i03-q03',
            opt: 3,
            score: 1,
        }, {
            nextId: '03-01-010-04',
            text: 'i03-q04',
            opt: 4,
            score: 0,
        }],
        hint: 'i03-question',
        isBeginning: false,
    },{
        id: '03-01-010-01',
        type: SceneType.chat,
        images: [
            BG["13"],
            CTN_CENTER(CTN01smile),
        ],
        bubbleText: null,
        actions: [{
            nextId: END_GAME,
            text: 'i03-a01',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '03-01-010-02',
        type: SceneType.chat,
        images: [
            BG["13"],
            CTN_CENTER(CTN11normal),
        ],
        bubbleText: null,
        actions: [{
            nextId: END_GAME,
            text: 'i03-a02',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '03-01-010-03',
        type: SceneType.chat,
        images: [
            BG["13"],
            CTN_CENTER(CTN03annoy),
        ],
        bubbleText: null,
        actions: [{
            nextId: END_GAME,
            text: 'i03-a03',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '03-01-010-04',
        type: SceneType.chat,
        images: [
            BG["13"],
            CTN_CENTER(CTN04angry),
        ],
        bubbleText: null,
        actions: [{
            nextId: END_GAME,
            text: 'i03-a04',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }
    ]
}

export default Sexuality1Scenes