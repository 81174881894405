import CategoryPage from "../pages/pregame/categoryPage";
import {GAME_ROUTE} from "../hooks/useGameRouter";
import CategoryInfoPage from "../pages/pregame/categoryInfoPage";
import RolePage from "../pages/pregame/rolePage";
import PatientSelectionPage from "../pages/pregame/patientSelectionPage";
import PatientInfoPage from "../pages/pregame/patientInfoPage";
import Story from "../pages/storyline/story";
import {END_GAME} from "../classes/scene";
import Endgame from "../pages/storyline/endgame";


function GameRouter({route, sceneId}){
    switch(route){
        // case ROUTE.landingPage:
            // return <Landing/>;
        case GAME_ROUTE.categoryPage:
            return <CategoryPage/>;
        case GAME_ROUTE.categoryInfoPage:
            return <CategoryInfoPage/>
        case GAME_ROUTE.rolePage:
            return <RolePage />
        case GAME_ROUTE.patientSelection:
            return <PatientSelectionPage />
        case GAME_ROUTE.patientInfo:
            return <PatientInfoPage />
        case GAME_ROUTE.story:
            if(sceneId === END_GAME){
                return <Endgame />
            }
            return <Story sceneId={sceneId}/>
        default:
            return <CategoryPage/>;
    }
}

export default GameRouter