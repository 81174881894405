import {isQuickStart} from "../../settings";

export const VOID_SCORE_OPT = -10
export const END_GAME = 'END_GAME'


/*
==================== Index =====================

type END_LEVEL = GREAT | GOOD | BAD | WORSE

==================== Object =====================
type PositionCoord = {top: number, right: number, bottom: number, left: number}

type ChatIcon = {img: imageURL} & PositionCoord

type PronounButton =

type AvatarIndex = 0 | 1 | 2

type PatientId = number

===================== Type ======================
type TextType: noneText | desc | selection | chat

type ImageType = background | avatar | foreground

type PositionType = percentage | px | fullscreen

type HintType = desc | question

type SceneType = cutscene | selection | chat | description

type EmotionType = normal | angry | annoyed | happy | smile | unsatisfied

Type CategoryInfoType = pronouns | gender | sexuality | relationship


==================== Class =======================

---------------- Scene -------------------
class Action {
    nextId: int;
    type: TextType;
    text: string | null; // if there is text
    score: int | VOID_SCORE_OPT;
    opt: int | VOID_SCORE_OPT; // if there is an option
    chatIcon : imageURL
    gameEndLevel : END_LEVEL | null; // if jumping to the end of the game after the action
}

class Image {
    type: ImageType;
    image: imageURL;
    position : PositionCoord;
    positionType: PositionType;
    isRole: boolean
    style: {any: any}
    pronounButton: PronounButton
}

class Hint {
    text: string;
    type: HintType;
}

class BubbleText {
    text: string;
    position: PositionCoord;
    positionType: PositionType;
}

class Scene {
    id: int;
    type: SceneType;
    images: Image[];
    bubbleText: BubbleText[];
    actions: Action[];
    hint: Hint | null;
    isBeginning: boolean;
    maxScore: int;
}
--------------- Patient -------------------
class Patient {
    id: int;
    name: string;
    intro: string;
    profilePic: imageUrl; // just head
    avatar: imageUrl; // whole body
    emotionImage: {[emotion: EmotionType]: imageUrl};
    isDisabled: boolean;
    endgamePosition: PositionCoord;
}
--------------- Role ---------------------
class Role {
    name: string;
    pronoun: [string, string];
    avatar: AvatarIndex;
}

-------------- ScoreBoard ----------------
class ScoreBoard {
    score: number;
    maxScore: number;
    gameEndLevel: END_LEVEL;
    finalPercent: number;
}

--------------- StoryMap -----------------
class StoryMap {
    storyLines: Scene[CATEGORY_INFO_TYPE][PatientId]
    categorySelected: CATEGORY_INFO_TYPE | null;
    selectedPatientId: int | null;
    // actionSelected: []; history of actions, no longer needed, GA takes over
}
 */

export const TextType = {
    noneText: 'noneText',
    desc: 'desc',
    selection: 'selection',
    chat: 'chat',
}

class Action {
    nextId; //not null able
    type;
    text = null; // if text only
    score = VOID_SCORE_OPT; // if there is option
    opt = VOID_SCORE_OPT;
    chatIcon = {};
    gameEndLevel = null;

    constructor(nextId, type, text, score, opt, chatIcon, gameEndLevel) {
        this.nextId = nextId;
        this.text = text;
        this.chatIcon = chatIcon;
        if(gameEndLevel){
            this.gameEndLevel = gameEndLevel;
        }
        switch (type) {
            case SceneType.chat:
                this.type = TextType.chat;
                this.text = text;
                break;
            case SceneType.description:
                this.type = TextType.desc;
                this.text = text;
                break;
            case SceneType.selection:
                this.type = TextType.selection;
                this.text = text;
                this.score = score;
                this.opt = opt;
                break;
            case SceneType.cutscene:
            default:
                this.type = TextType.noneText;
                break;
        }

    }

}

export const ImageType = {
    background: 'background',
    avatar: 'avatar',
    foreground: 'foreground',
}

export const PositionType = {
    percentage: 'percentage',
    px: 'px',
    fullscreen: 'fullscreen'
}

export class Image {
    type;
    image;
    position; //{top/bottom/left/right}
    positionType;
    isRole = false;
    style = {};
    pronounButton;

    constructor(type, image, positionType, position, style, pronounButton) {
        this.type = type;
        this.image = image;
        this.position = position || {};
        this.positionType = positionType;
        this.pronounButton = pronounButton;
        this.isRole = Array.isArray(this.image);
        style && (this.style = style);

    }
}

export const HintType = {
    desc: 'desc',
    question: 'question',
}

class Hint {
    text;
    type;

    constructor(text, type) {
        this.text = text;
        switch (type) {
            case SceneType.selection:
                this.type = HintType.question;
                break;
            default:
                this.type = HintType.desc;
                break;
        }
    }
}

class BubbleText {
    text;
    position;
    positionType = PositionType.px;

    constructor(text, position) {
        this.text = text;
        switch (position){
            case 'left':
                this.position = {top: 140, right: 211, bottom: 525, left: 729}
                break;
            case 'right':
                this.position = {top: 130, right: 21, bottom: 588, left: 1014};
                break;
            default:
                this.position = {top: 141, right: 54, bottom: 525, left: 887};
                break;
        }

    }
}


export const SceneType = {
    cutscene: 'cutscene',
    selection: 'selection',
    chat: 'chat',
    description: 'description',
}

class Scene {
    id;
    type;
    images = [];
    bubbleText = [];
    actions = [];
    hint;
    isBeginning = false;
    maxScore = 0;

    constructor(id, type, images, bubbleText, actions, hint, isBeginning) {
        this.id = id;
        this.type = type;
        // console.log(id, images);
        this.images = images.map(image => new Image(image.type, image.image, image.positionType, image.position, image.style, image.pronounButton));

        let actionList = [...actions]
        if(!isQuickStart){
            for (let i = actionList.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [actionList[i], actionList[j]] = [actionList[j], actionList[i]];
            }
        }
        this.actions = actionList.map(act => new Action(act.nextId, type, act.text, act.score, act.opt, act.chatIcon, act.gameEndLevel));
        this.bubbleText = bubbleText ? bubbleText.map(bT => new BubbleText(bT.text, bT.position)) : []
        this.hint = hint && new Hint(hint, type);
        this.isBeginning = isBeginning;
        if (this.type === SceneType.selection) {
            this.actions.forEach(act => {
                if (act.score > this.maxScore) {
                    this.maxScore = act.score;
                }
            })
        }
    }

    getNextAction = () => {
        return this.actions[0];
    }

}

export {Action};
export default Scene;