import styled from "styled-components";
import {useContext, useEffect, useState} from "react";
import StoryModel from "../../classes/storyModel";
import Dialogue from "./dialogue";
import Image from "./image";
import Hint from "./hint";
import useGameRouter from "../../hooks/useGameRouter";
import {SceneType} from "../../classes/scene";
import {NextButton} from "../../components/navButtons";
import BubbleText from "./bubbleText";
import {ButtonFloat} from "../../../pages/game";
import {isDev} from "../../../settings";
import {device} from "../../../breakpoints";
import HeaderContainer from "../../components/headerCtn";
import useResponsive from "../../../hook/useResponsive";
import {BUTTON_TYPE} from "../../../pages/components/buttons";
import {DimContext} from "../../../App";
import ScrollDiv from "../../../pages/components/scrollDiv";
import {useTranslation} from "react-i18next";


const NextBlock = styled.div`
  position: relative;
  height: 48px;
  width: 100%;
  margin: 16px;

  ${device.onlyTablet} {
    margin: 36px 0 12px;
  }
`

function DialogueNext({onClick, shouldRender, isSelection}) {
    const [device] = useResponsive()
    const {t} = useTranslation('common', {keyPrefix: 'button'})
    if (device.isMobile) {
        return <>
            {shouldRender && <ButtonFloat backgroundColor="white">
                <NextButton type={BUTTON_TYPE.STORY_NEXT} text={t('Next')} onClick={onClick} id={isSelection && "option_next"}/>
            </ButtonFloat>}
            <NextBlock/>
        </>
    }
    return (shouldRender && <NextButton type={BUTTON_TYPE.STORY_NEXT} text={t('Next')} onClick={onClick} id={isSelection && "option_next"}/>)
}

export const StoryBase = styled.div`
  position: relative;
  display: flex;
  background-color: white;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  padding: 0; // add media query

  ${device.onlyMobile} {
    padding: 16px;
  }

  ${device.onlyTablet} {
    padding: 24px;
  }

    ${device.tabletAndMobile} {
  }
`


const StoryCanvas = styled.div`
  overflow: hidden;
  position: relative;

  min-width: ${props => props.dim.width}px;
  min-height: ${props => props.dim.height}px;

  ${device.tabletAndMobile} {
    margin-top: 16px;
  }

  ${device.onlyTablet} {
    margin-bottom: 48px;
  }

  ${device.onlyMobile} {
    margin-bottom: 24px;
  }

`

function Story({sceneId}) {

    const [, useRoute] = useGameRouter();
    const [selectedAct, setSelectedAct] = useState({})
    const dim = useContext(DimContext)

    const [device] = useResponsive();

    const storyModel = new StoryModel();
    const scene = storyModel.getScene(sceneId);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [sceneId])


    if (!scene) {
        return null;
    }
    const type = scene.type;
    const role = storyModel.getRole();


    const onClickNext = () => {
        if (type !== SceneType.selection) {
            const nextAct = scene.getNextAction()
            if(nextAct.gameEndLevel){
                storyModel.setEndLevel(nextAct.gameEndLevel);
            }
            useRoute.changeScene(nextAct.nextId);
        }
    }

    const onConfirmSelection = () => {
        if (type === SceneType.selection && selectedAct.nextId) {
            storyModel.sceneActionSelected(selectedAct, scene.maxScore)
            useRoute.changeScene(selectedAct.nextId);
        }
    }

    const dialogArgs = {name: role.name, pronoun: `${role.pronoun[0]}\\${role.pronoun[1]}`, avatarId: role.avatar}

    const pronounButtonContent = `${role.pronoun[0]}/ ${role.pronoun[1]}`.replaceAll("\\", "\\\\")

    if (device.isMobile) {
        return (
            <ScrollDiv>
                <StoryBase>
                    <HeaderContainer/>
                    <Hint hint={scene.hint}/>
                    <StoryCanvas dim={dim} onClick={() => {
                        isDev() && onClickNext()
                    }}>
                        {scene.images.map(image => <Image key={image.image} image={image} avatarId={role.avatar}
                                                          pronounButtonContent={pronounButtonContent}/>)}
                        {scene.bubbleText && scene.bubbleText.map(bT => <BubbleText key={bT} bubble={bT}/>)}
                    </StoryCanvas>
                    <Dialogue actions={scene.actions} selectedAct={selectedAct} onSelectAction={setSelectedAct}
                              args={dialogArgs}/>
                    {scene.bubbleText && scene.bubbleText.map(bT => {
                        const injectActions = [{...scene.actions[0]}];
                        injectActions[0].isBubbleText = true;
                        injectActions[0].text = `(${bT.text})`
                        return <Dialogue actions={injectActions} args={dialogArgs}/>
                    })}
                    <DialogueNext
                        isSelection={type === SceneType.selection}
                        onClick={(selectedAct.nextId && onConfirmSelection) || (type !== SceneType.selection && onClickNext)}
                        shouldRender={selectedAct.nextId || type !== SceneType.selection}/>
                </StoryBase>
            </ScrollDiv>
        )
    }

    return <StoryBase>
        <HeaderContainer/>
        <StoryCanvas dim={dim} onClick={() => {
            isDev() && onClickNext()
        }}>
            <Hint hint={scene.hint}/>
            {scene.images.map(image => <Image key={image.image} image={image} avatarId={role.avatar}
                                              pronounButtonContent={pronounButtonContent}/>)}
            <Dialogue actions={scene.actions} selectedAct={selectedAct} onSelectAction={setSelectedAct}
                      args={dialogArgs}/>
            {scene.bubbleText && scene.bubbleText.map(bT => <BubbleText key={bT} bubble={bT}/>)}
            <DialogueNext
                isSelection={type === SceneType.selection}
                onClick={(selectedAct.nextId && onConfirmSelection) || (type !== SceneType.selection && onClickNext)}
                shouldRender={selectedAct.nextId || type !== SceneType.selection}/>

        </StoryCanvas>
    </StoryBase>
}

export default Story;