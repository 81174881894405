import styled from "styled-components";
import {HintType} from "../../classes/scene";
import {TitleD3, TitleD4, TitleHL} from "../../../typography";
import {useContext} from "react";
import {device} from "../../../breakpoints";
import useResponsive from "../../../hook/useResponsive";
import {DimContext} from "../../../App";
import {useTranslation} from "react-i18next";
import StoryModel from "../../classes/storyModel";


const StyledHint = styled.div`
    display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: ${props=> props.ratio * 112}px;
  left: ${props=> props.ratio * 152}px;
  right: ${props=> props.ratio * 152}px;
  z-index: 20;
  
  ${device.tabletAndMobile}{
    top: initial;
    left: initial;
    right: initial;
    position: relative;
    margin-top: 24px;
  }
`


function getFont(device, type){
    if(device.isDesktop){
        return type === HintType.question? TitleD3: TitleD4
    }
    if(device.isTablet){
        return TitleD4
    }
    if(device.isPhone){
        return TitleHL
    }
}

function Hint({hint}){

    const dim = useContext(DimContext);
    const [device] = useResponsive()
    const storyModel = new StoryModel()
    const {t} = useTranslation('game',{keyPrefix: storyModel.getCurrentStoryLineId()})


    const Font = getFont(device, hint?.type);
    const text = hint ? t(hint.text) : '';
    if(!Font){
        return null;
    }

    return <StyledHint ratio={dim.ratio}>
        <Font style={{height: device.isMobile? '2em' : 'auto'}}>{text}</Font>
    </StyledHint>
}

export default Hint