import {PageBase} from "./frontPage";
import Footer from "./fragments/footer";
import HubBanner1 from './images/knowledgeHub/banner1.png';
import HubBanner2 from './images/knowledgeHub/banner2.png';

import styled from 'styled-components';
import {BodyB2, TitleD1, TitleD3, TitleD4, TitleHL, TitleSH1} from "../typography";
import {HUB_CONTENT_DATA} from "./data/knowledgeHub";
import {useNavigate} from "react-router";
import {device} from "../breakpoints";
import ScrollDiv from "./components/scrollDiv";
import {useTranslation} from "react-i18next";

const HubTitleCtn = styled.div`
  width: 100%;
  padding: 25.35px 156.45px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FAF9FA;

  ${device.tabletAndMobile} {
    padding: 0;
  }

  ${device.onlyTablet} {
    height: 300px;

    justify-content: center;
  }

  ${device.onlyMobile} {
    height: 320px;
    padding: 16px;
  }
`

const HubTitleImg = styled.div`
  width: ${props => props.first ? 297 : 332.09}px;
  height: ${props => props.first ? 268.42 : 249.73}px;
  background: url(${props => props.img}) no-repeat;
  background-size: contain;

  ${device.onlyTablet} {
    position: absolute;
    top: ${props => props.first ? 16 : 25}px;
    ${props => props.first ? 'left' : 'right'}: -108px;
  }

  ${device.onlyMobile} {
    position: absolute;
    ${props => props.first && 'display: none;'}
    width: 157.35px;
    height: 122px;
    top: 24px;
    right: -2.95px;
  }
`

const HubTitleTextCtn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${device.onlyMobile} {
    align-items: flex-start;
  }

  z-index: 5;
`

const HubTitle = styled(TitleD1)`
  margin-bottom: 16px;
`
const HubTitlePhone = styled(TitleD3)`
  margin-bottom: 16px;
`

const HubSubtitle = styled(TitleHL)`
`

const ListCtn = styled.div`
  max-width: 700px;
  margin: 42px 0 117px;

  ${device.onlyTablet} {
    margin: 36px 24px;
    max-width: 100%;
  }

  ${device.onlyMobile} {
    margin: 36px 16px;
    max-width: 100%;
  }
`

const HubPageBase = styled(PageBase)`
  display: flex;
  flex-direction: column;
  align-items: center;
`


const ItemCtn = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 0px #E4E2E7;
`

const ItemTitle = styled(TitleD4)`
  font-weight: 700;
  cursor: pointer;

  &:hover {
    text-decoration-line: underline;
  }
`;

const ItemBrief = styled(TitleSH1)`
  margin-top: 6px;
  margin-bottom: 14px;
`

const ItemPostInfoCtn = styled.div`
  display: flex;
  align-items: center;
`

const ItemPostIcon = styled.div`
  width: 36px;
  height: 36px;
  background: url(${props => props.icon}) no-repeat;
  background-size: contain;
  border-radius: 50%;
  border: 1px solid #E4E2E7;
`

const ItemPoster = styled(BodyB2)`
  margin: 0 12px;
  white-space: nowrap;
`

const ItemPostDate = styled(BodyB2)`
  margin: 0 0 0 12px;
  white-space: nowrap;
`

function ListItem({info}) {
    const navigate = useNavigate()
    const {title, brief, poster: {icon, name}, date, category} = info //PMCFIcon, PMChosenFamily Team, May 30, 2023
    return (
        <ItemCtn>
            <ItemTitle onClick={() => navigate(category)}>{title}</ItemTitle>
            <ItemBrief>{brief}</ItemBrief>
            <ItemPostInfoCtn>
                <ItemPostIcon icon={icon}/>
                <ItemPoster>{name}</ItemPoster>
                <BodyB2>•</BodyB2>
                <ItemPostDate>{date}</ItemPostDate>
            </ItemPostInfoCtn>
        </ItemCtn>
    )
}

export function PageHeader({title, subtitle}){
    const FontTitle = device.isPhone ? HubTitlePhone : HubTitle
    return <HubTitleCtn>
        <HubTitleImg first img={HubBanner1}/>
        <HubTitleTextCtn>
            <FontTitle>{title}</FontTitle>
            {subtitle && <HubSubtitle>{subtitle}</HubSubtitle>}
        </HubTitleTextCtn>
        <HubTitleImg img={HubBanner2}/>

    </HubTitleCtn>

}

function KnowledgeHub() {
    const {t} = useTranslation('common')
    return (
        <ScrollDiv>
            <HubPageBase>
                <PageHeader title={t('tags.Knowledge Hub')} subtitle={t('text.Knowledge Hub Subtitle')}/>
                <ListCtn>
                    {HUB_CONTENT_DATA.map(data => <ListItem key={data.title} info={data}/>)}
                </ListCtn>
                <Footer/>
            </HubPageBase>
        </ScrollDiv>
    )
}

export default KnowledgeHub