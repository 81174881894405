import styled from "styled-components";
import Icon from "../images/icon/menuIcon.png";
import StartIcon from "../images/icon/startButtonLong.png";
import {useState} from "react";
import {ROUTE} from "../../App";
import {device, deviceMin} from "../../breakpoints";
import Drawer, {DrawerBottomItem, DrawerCtn, DrawerItem} from "./drawer";
import {useNavigate} from "react-router";
import {TitleBtn} from "../../typography";
import LanguageButton from "./languageButton";
import {useTranslation} from "react-i18next";

const MenuBase = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: transparent;
  margin: 11px;
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    background-color: #EFEEF1;
  }
  ${device.onlyTablet}{
    display: none;
  }
  ${deviceMin.onlyDesktop}{
    display: none;
  }
`

const MenuIcon = styled.div`
  background-image: url(${Icon});
  background-size: contain;
  background-repeat: no-repeat;
  width: 42px;
  height: 42px;
`

const LongStart = styled.div`

  width: 100%;
  height: 48px;
  background-image: url(${StartIcon});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-image: url(${StartIcon});

  }

`

function MenuButton({style}){
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const {t} = useTranslation('common')

    const navigate = useNavigate()
    return (
        <>
            <MenuBase style={style} onClick={()=> setIsDrawerOpen(true)}>
                <MenuIcon/>
            </MenuBase>
            <Drawer isOpen={isDrawerOpen} onClose={()=>setIsDrawerOpen(false)}>
                <DrawerCtn>
                    <DrawerItem onClick={()=>{setIsDrawerOpen(false); navigate(ROUTE.FRONTPAGE)}}>{t('tags.Home')}</DrawerItem>
                    <DrawerItem onClick={()=>{setIsDrawerOpen(false); navigate(ROUTE.KNOWLEDGE)}}>{t('tags.Knowledge Hub')}</DrawerItem>
                    <DrawerItem onClick={()=>{setIsDrawerOpen(false); navigate(ROUTE.ABOUTUS)}}>{t('tags.About Us')}</DrawerItem>
                    <DrawerBottomItem>
                        <LanguageButton/>
                        <LongStart onClick={()=>{setIsDrawerOpen(false);navigate(ROUTE.GAME)}}>
                            <TitleBtn>{t('button.Get Started')}</TitleBtn>
                        </LongStart>
                    </DrawerBottomItem>
                </DrawerCtn>
            </Drawer>
        </>
    )
}

export default MenuButton