import {useState} from "react";
import {ContentBase} from "../components/surveyModal";
import {TitleSH1} from "../../typography";
import Navigation from "../components/navigation";
import PageFooter from "../components/pageFooter";
import styled from "styled-components";
import {SurveyTitle} from "../survey";

const SpectrumCtn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  width: 100%
`

const SpectrumText = styled.div`
  font-size: 14px;
  color: #5B5A5C;

`

const NumCtn = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  width: 100%
`

const NumBox = styled.div`
  cursor: pointer;
  color: ${props=>props.isSelected? 'white' : '#5B5A5C'};
  background-color: ${props=>props.isSelected? '#065BBF' : 'transparent'};
  border: solid #89888B;
  border-width: 2px 2px 2px 0;
  flex: 1;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  
  :first-child{
    border-radius: 8px 0 0 8px;
    border-left-width: 2px;
  }
  
  :last-child{
    border-radius: 0 8px 8px 0;
  }
`

export default function RatingPage({defaultAns, page, onNext, onBack, numOfQuestion,topic}){
    // console.log(defaultAns)
    const [ans, setAns] = useState(defaultAns);
    //console.log(ans, defaultAns)
    //TODO: add typeof string on question
    return <>
        <ContentBase>
            <SurveyTitle>{page.question(topic)}</SurveyTitle>

            <NumCtn>
                {Array.from({ length: page.range[1] - page.range[0] + 1 }, (v, i) => page.range[0] + i).map(val=>
                    <NumBox key={val} isSelected={ans[0]===val} onClick={()=>{setAns([val])}}>{val}</NumBox>
                )}
            </NumCtn>

            <SpectrumCtn>
                <SpectrumText>{page.spectrum[0]}</SpectrumText>
                <SpectrumText>{page.spectrum[1]}</SpectrumText>
            </SpectrumCtn>

            <Navigation onBack={onBack} onNext={() => onNext(ans, page.pageNum, page.questionNum)}
                        isNextDisabled={!ans[0]}
                        isDone={page.isDone}/>
        </ContentBase>
        <PageFooter text={`Question ${page.questionNum} of ${numOfQuestion}`}/>
    </>
}