import {sha256} from "js-sha256";
import storage from "./storage";

// Google Analytics functions object
class GaUtility {
    static dataLayer = () => window.dataLayer || [];

    static exitGameCalled = false


    static pregameStart = () =>{
        const gaui = storage.get('gaui')
        const date = new Date();
        this.dataLayer().push({
            event: 'pregame_start',
            game_id: sha256((gaui || "")+date.getTime()),
        })
    }

    static pushCategory = (category) =>{

        this.dataLayer().push({
            event: 'topic_selected',
            topic_name: category
        })
    }
    static pushPatient = (patient) =>{

        this.dataLayer().push({
            event: 'patient_selected',
            patient_name: patient
        })
    }

    static gameStart = () =>{
        const date = new Date();
        this.dataLayer().push({
            event: 'game_start',
            game_score: 0,
            time: date.toLocaleTimeString('en-US')
        })
    }

    static gameEnd = (score) =>{
        const date = new Date();
        this.dataLayer().push({
            event: 'end_screen',
            game_score: score,
            time: date.toLocaleTimeString('en-US')

        })
    }

    static onPronounChange = (pronoun) =>{
        this.dataLayer().push({
            pronoun: pronoun[0]+'/'+pronoun[1]
        })
    }

    static onPregameFamiliarityChange = (familiarity) =>{
        this.dataLayer().push({
            familiarity
        })
    }

    static onEndgameFamiliarityChange = (familiarity) =>{
        this.dataLayer().push({
            familiarity
        })
    }

    static onExitEndGame = () =>{

        !GaUtility.exitGameCalled && this.dataLayer().push({
            event: 'exit_endgame',
        })
        GaUtility.exitGameCalled = true;
    }

}

export default GaUtility