import {END_GAME, ImageType, PositionType, SceneType} from "../../../classes/scene";

import MAX01 from "../../../images/patient/max/MAX-01.png";
import MAX02 from "../../../images/patient/max/MAX-02.png";
import MAX03 from "../../../images/patient/max/MAX-03.png";
import MAX04 from "../../../images/patient/max/MAX-04.png";
import MAX06 from "../../../images/patient/max/MAX-06.png";
import MAX07 from "../../../images/patient/max/MAX-07.png";


import EMMA01 from "../../../images/patient/max/MAX-E-01.png";
import EMMA03 from "../../../images/patient/max/MAX-E-03.png";
import EMMA02 from "../../../images/patient/max/MAX-E-02.png";
import EMMA04 from "../../../images/patient/max/MAX-E-04.png";

import HCP_0_03 from "../../../images/hcp/0/HCP-03.png";
import HCP_1_03 from "../../../images/hcp/1/HCP-03.png";
import HCP_2_03 from "../../../images/hcp/2/HCP-03.png";

import {CHATICON} from "../storyGlobal";

import BG09 from "../../../images/background/BG-09.png";
import BG10 from "../../../images/background/BG-10.png";
import BG14 from "../../../images/background/BG-14.png";
import BG15 from "../../../images/background/BG-15.png";

import Item03 from "../../../images/background/Item-03.png";
import {END_LEVEL} from "../../../constants";


const BG = {
    '09': {
        type: ImageType.background,
        image: BG09,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    '10': {
        type: ImageType.background,
        image: BG10,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    '14': {
        type: ImageType.background,
        image: BG14,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    '15': {
        type: ImageType.background,
        image: BG15,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
}


const MAX = {
    "07": {
        type: ImageType.avatar,
        image: MAX07,
        positionType: PositionType.px,
        position: {
            left: 429,
            top: 414,
            right: 820,
            bottom: -188.83,
        },
        style: null,
    },
    "01F": {
        type: ImageType.avatar,
        image: MAX01,
        positionType: PositionType.px,
        position: {
            left: 743,
            top: 333,
            right: 506,
            bottom: -107.83,
            isFlip: true,
            zIndex: 16
        },
        style: null,
    },
}

const EMMA = {
    "04": {
        type: ImageType.avatar,
        image: EMMA04,
        positionType: PositionType.px,
        position: {
            left: 647,
            top: 414,
            right: 602,
            bottom: -306.4,
        },
        style: null,
    },
    "01F": {
        type: ImageType.avatar,
        image: EMMA01,
        positionType: PositionType.px,
        position: {
            left: 970,
            top: 320,
            right: 279,
            bottom: -212.4,
            isFlip: true,
            zIndex: 17
        },
        style: null,
    },
}

const ITEM = {
    '03': {
        type: ImageType.foreground,
        image: Item03,
        positionType: PositionType.px,
        position: {
            left: 87,
            top: 599,
            right: 733,
            bottom: 41,
        },
        style: null,
    },
    '03-2': {
        type: ImageType.foreground,
        image: Item03,
        positionType: PositionType.px,
        position: {
            left: 94,
            top: 765,
            right: 96,
            bottom: -437,
        },
        style: null,
    },
}




const MAX_LEFT = (img) => {
    return (
        {
            type: ImageType.avatar,
            image: img,
            positionType: PositionType.px,
            position: {
                left: 286,
                top: 227,
                right: 659,
                bottom: -875,
            },
            style: null,
        })
}

const EMMA_RIGHT = (img) => {
    return (
        {
            type: ImageType.avatar,
            image: img,
            positionType: PositionType.px,
            position: {
                left: 755,
                top: 269,
                right: 284,
                bottom: -859,
            },
            style: null,
        })
}


const HCP = {
    '03' :{
        type: ImageType.avatar,
        image: [HCP_0_03, HCP_1_03, HCP_2_03],
        positionType: PositionType.px,
        position: {
            left: 333,
            top: 306,
            right: 945,
            bottom: 43,
        },
        style: null,
        pronounButton:{
            top: 225,
            left: 90,
            width: 47,
            // height: 30,
            clippath: null
        }
    },
    '03-2': {
        type: ImageType.avatar,
        image: [HCP_0_03, HCP_1_03, HCP_2_03],
        positionType: PositionType.px,
        position: {
            left: 498.76,
            top: 206,
            right: 501.6,
            bottom: -613,
        },
        style: null,
        pronounButton:{
            top: 502,
            left: 203,
            width: 94,
            // height: 30,
            clippath: null
        }
    },

}

const Gender3Scenes = {
    id: '02-03',
    patient: 3,
    scene: [{
        id: '02-03-001-01',
        type: SceneType.description,
        images:[
            BG['09'],
            MAX['07'],
            EMMA['04'],

        ],
        bubbleText: null,
        actions: [{
            nextId: '02-03-002-01',
            text: '001-01',
        }],
        hint: null,
        isBeginning: true,
    },{
        id: '02-03-002-01',
        type: SceneType.description,
        images:[
            BG['10'],
            HCP['03'],
            ITEM['03'],
            MAX['01F'],
            EMMA['01F'],

        ],
        bubbleText: false,
        actions: [{
            nextId: '02-03-003-01',
            text: '002-01',
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '02-03-003-01',
        type: SceneType.chat,
        images:[
            BG['14'],
            HCP['03-2'],
            ITEM['03-2'],

        ],
        bubbleText: false,
        actions: [{
            nextId: '02-03-004-01',
            text: 'i01-q01',
            chatIcon: CHATICON.HCP
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '02-03-004-01',
        type: SceneType.chat,
        images:[
            BG['15'],
            MAX_LEFT(MAX01),
            EMMA_RIGHT(EMMA01),

        ],
        bubbleText: false,
        actions: [{
            nextId: '02-03-005-01',
            text: "i01-a01",
            chatIcon: CHATICON.MAX
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '02-03-005-01',
        type: SceneType.selection,
        images:[
            BG['14'],
            HCP['03-2'],
            ITEM['03-2'],

        ],
        bubbleText: false,
        actions: [{
            nextId: '02-03-006-01',
            text: 'i02-q01',
            opt: 1,
            score: 3,
        }, {
            nextId: '02-03-006-02',
            text: 'i02-q02',
            opt: 2,
            score: 2,
        }, {
            nextId: '02-03-006-03',
            text: 'i02-q03',
            opt: 3,
            score: 1,
        }, {
            nextId: '02-03-006-04',
            text: 'i02-q04',
            opt: 4,
            score: 0,
        }],
        hint: 'i02-question',
        isBeginning: false,
    },{
        id: '02-03-006-01',
        type: SceneType.chat,
        images: [
            BG["15"],
            MAX_LEFT(MAX01),
            EMMA_RIGHT(EMMA01)
        ],
        bubbleText: null,
        actions: [{
            nextId: '02-03-007-01',
            text: 'i02-a01',
            chatIcon: CHATICON.MAX
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '02-03-006-02',
        type: SceneType.chat,
        images: [
            BG["15"],
            MAX_LEFT(MAX03),
            EMMA_RIGHT(EMMA02)
        ],
        bubbleText: null,
        actions: [{
            nextId: '02-03-007-01',
            text: 'i02-a02',
            chatIcon: CHATICON.MAX
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '02-03-006-03',
        type: SceneType.chat,
        images: [
            BG["15"],
            MAX_LEFT(MAX06),
            EMMA_RIGHT(EMMA03)
        ],
        bubbleText: null,
        actions: [{
            nextId: '02-03-007-01',
            text: 'i02-a03',
            chatIcon: CHATICON.MAX
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '02-03-006-04',
        type: SceneType.chat,
        images: [
            BG["15"],
            MAX_LEFT(MAX06),
            EMMA_RIGHT(EMMA03)
        ],
        bubbleText: null,
        actions: [{
            nextId: '02-03-007-01',
            text: 'i02-a04',
            chatIcon: CHATICON.MAX
        }],
        hint: null,
        isBeginning: false,
    },

        {
            id: '02-03-007-01',
            type: SceneType.selection,
            images:[
                BG['14'],
                HCP['03-2'],
                ITEM['03-2'],

            ],
            bubbleText: false,
            actions: [{
                nextId: '02-03-008-01',
                text: 'i03-q01',
                opt: 1,
                score: 3,
            }, {
                nextId: '02-03-008-02',
                text: 'i03-q02',
                opt: 2,
                score: 2,
            }, {
                nextId: '02-03-008-03',
                text: 'i03-q03',
                opt: 3,
                score: 1,
            }, {
                nextId: '02-03-008-04',
                text: 'i03-q04',
                opt: 4,
                score: 0,
            }],
            hint: 'i03-question',
            isBeginning: false,
        },{
            id: '02-03-008-01',
            type: SceneType.chat,
            images: [
                BG["15"],
                MAX_LEFT(MAX01),
                EMMA_RIGHT(EMMA01)
            ],
            bubbleText: null,
            actions: [{
                nextId: '02-03-009-01',
                text: 'i03-a01',
                chatIcon: CHATICON.MAX
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '02-03-008-02',
            type: SceneType.chat,
            images: [
                BG["15"],
                MAX_LEFT(MAX03),
                EMMA_RIGHT(EMMA02)
            ],
            bubbleText: null,
            actions: [{
                nextId: '02-03-009-01',
                text: 'i03-a02',
                chatIcon: CHATICON.MAX
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '02-03-008-03',
            type: SceneType.chat,
            images: [
                BG["15"],
                MAX_LEFT(MAX06),
                EMMA_RIGHT(EMMA03)
            ],
            bubbleText: null,
            actions: [{
                nextId: '02-03-009-01',
                text: 'i03-a03',
                chatIcon: CHATICON.EMMA
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '02-03-008-04',
            type: SceneType.chat,
            images: [
                BG["15"],
                MAX_LEFT(MAX06),
                EMMA_RIGHT(EMMA03)
            ],
            bubbleText: null,
            actions: [{
                nextId: END_GAME,
                text: 'i03-a04',
                chatIcon: CHATICON.EMMA,
                gameEndLevel: END_LEVEL.WORSE
            }],
            hint: null,
            isBeginning: false,
        },

        {
            id: '02-03-009-01',
            type: SceneType.selection,
            images:[
                BG['14'],
                HCP['03-2'],
                ITEM['03-2'],

            ],
            bubbleText: false,
            actions: [{
                nextId: '02-03-010-01',
                text: 'i04-q01',
                opt: 1,
                score: 3,
            }, {
                nextId: '02-03-010-02',
                text: 'i04-q02',
                opt: 2,
                score: 2,
            }, {
                nextId: '02-03-010-03',
                text: 'i04-q03',
                opt: 3,
                score: 1,
            }, {
                nextId: '02-03-010-04',
                text: 'i04-q04',
                opt: 4,
                score: 0,
            }],
            hint: 'i04-question',
            isBeginning: false,
        },{
            id: '02-03-010-01',
            type: SceneType.chat,
            images: [
                BG["15"],
                MAX_LEFT(MAX02),
                EMMA_RIGHT(EMMA01)
            ],
            bubbleText: null,
            actions: [{
                nextId: END_GAME,
                text: 'i04-a01',
                chatIcon: CHATICON.MAX
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '02-03-010-02',
            type: SceneType.chat,
            images: [
                BG["15"],
                MAX_LEFT(MAX01),
                EMMA_RIGHT(EMMA01)
            ],
            bubbleText: null,
            actions: [{
                nextId: END_GAME,
                text: 'i04-a02',
                chatIcon: CHATICON.MAX
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '02-03-010-03',
            type: SceneType.chat,
            images: [
                BG["15"],
                MAX_LEFT(MAX04),
                EMMA_RIGHT(EMMA03)
            ],
            bubbleText: null,
            actions: [{
                nextId: '02-03-010-03-02',
                text: 'i04-a03',
                chatIcon: CHATICON.EMMA
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '02-03-010-03-02',
            type: SceneType.cutscene,
            images: [
                BG["15"],
                MAX_LEFT(MAX04),
                EMMA_RIGHT(EMMA03)
            ],
            bubbleText: [{text: 'i04-a03-b', position: 'right'}],
            actions: [{
                nextId: END_GAME,
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '02-03-010-04',
            type: SceneType.chat,
            images: [
                BG["15"],
                MAX_LEFT(MAX06),
                EMMA_RIGHT(EMMA03)
            ],
            bubbleText: null,
            actions: [{
                nextId: '02-03-010-04-02',
                text: 'i04-a04',
                chatIcon: CHATICON.EMMA
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '02-03-010-04-02',
            type: SceneType.cutscene,
            images: [
                BG["15"],
                MAX_LEFT(MAX06),
                EMMA_RIGHT(EMMA03)
            ],
            bubbleText: [{text: 'i04-a04-b', position: 'right'}],
            actions: [{
                nextId: END_GAME,
            }],
            hint: null,
            isBeginning: false,
        },

    ]
}


export default Gender3Scenes