import {TextType} from "../../classes/scene";
import styled from "styled-components";
import {BodyB1, TitleD4, TitleSH1} from "../../../typography";
import BlueCheck from '../../images/misc/blueCheck.png'
import {forwardRef, useContext, useLayoutEffect, useRef, useState} from "react";
import {device} from "../../../breakpoints";
import useResponsive from "../../../hook/useResponsive";
import {DimContext} from "../../../App";
import {useTranslation} from "react-i18next";
import StoryModel from "../../classes/storyModel";


const ChatCtn = styled.div`
  position: absolute;
  bottom: ${props => props.ratio * 72}px;
  left: ${props => props.ratio * 255}px;
  right: ${props => props.ratio * 271}px;

  background: #F6F8FA;
  border: 3px solid #57606A;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  text-align: left;

  z-index: 20;
  
  ${device.tabletAndMobile}{
    position: relative;
    bottom: initial;
    left: initial;
    right: initial;
  }
`

const ChatInnerCtn = styled.div`
  position: relative;
  padding: 48px;
  
  ${device.onlyMobile}{
    padding: 36px 16px
  }
`

const ChatIconCtn = styled.div`
  position: absolute;
  width: 68px;
  height: 68px;
  background: white;
  border: 3px solid #5B5A5C;
  overflow: hidden;

  border-radius: 50%;
  left: -24px;
  top: -24px;
  
  ${device.onlyMobile}{
    width:48px;
    height: 48px;
    left: -13px;
    top: -16px;
  }
`

const ChatIconInner = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`

const CIcon = styled.div`
  position: absolute;
  background: url(${props => props.img}) no-repeat;
  background-size: cover;
  ${props => props.position?.top !== undefined && `top:${props.sizing * props.position.top}px;`}
  ${props => props.position?.bottom !== undefined && `bottom:${props.sizing * props.position.bottom}px;`}
  ${props => props.position?.left !== undefined && `left:${props.sizing * props.position.left}px;`}
  ${props => props.position?.right !== undefined && `right:${props.sizing * props.position.right}px;`}
  ${props => props.position?.height !== undefined && `height:${props.sizing * props.position.height}px;`}
  ${props => props.position?.width !== undefined && `height:${props.sizing * props.position.width}px;`}
`

function ChatIcon({icon, avatarId}) {
    const img = icon ? (Array.isArray(icon.img) ? icon.img[avatarId] : icon.img) : null
    const [device] = useResponsive()
    return <ChatIconCtn>
        <ChatIconInner>
            {img && <CIcon sizing={device.isPhone? 0.706 : 1} img={img} position={icon}/>}
        </ChatIconInner>
    </ChatIconCtn>

}

function ChatDialogue({action, args}) {
    const dim = useContext(DimContext)
    const [device] = useResponsive();
    const Font = device.isMobile? BodyB1 : TitleD4
    const storyModel = new StoryModel();
    const {t} = useTranslation('game', {keyPrefix:storyModel.getCurrentStoryLineId()})
    return <ChatCtn ratio={dim.ratio}>
        <ChatInnerCtn>
            <ChatIcon icon={action.chatIcon} avatarId={args.avatarId}/>
            <Font>
                {typeof action.text === 'string' ? t(action.text) : action.text(t,args)}
            </Font>
        </ChatInnerCtn>
    </ChatCtn>

}

const DescCtn = styled.div`
  position: absolute;
  bottom: ${props => props.ratio * 72}px;
  left: ${props => props.ratio * 152}px;
  right: ${props => props.ratio * 271}px;
  padding: 32px;

  background: #F6F8FA;
  border: 2px solid #F6F8FA;
  box-shadow: 6px 6px 0 #0969DA;
  border-radius: 0 40px 0 0;

  text-align: left;

  display: flex;
  align-items: center;

  min-height: 132px;

  z-index: 20;
  
  ${device.tabletAndMobile} {
    position: relative;
    bottom: initial;
    left: initial;
    right: initial;
    
  }
  
  ${device.onlyMobile} {
    padding: 24px 16px;
  }
`

function Description({action, args}) {
    const dim = useContext(DimContext)
    const [device] = useResponsive();
    const Font = device.isMobile ? BodyB1 : TitleD4
    const storyModel = new StoryModel();
    const {t} = useTranslation('game', {keyPrefix:storyModel.getCurrentStoryLineId()})
    return <DescCtn ratio={dim.ratio}>
        <Font>
            {typeof action.text === 'string' ? t(action.text) : action.text(t,args)}
        </Font>
    </DescCtn>
}

const StyledSelectionCtn = styled.div`
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  gap: ${props => props.ratio * 24}px;
  bottom: ${props => props.ratio * 72}px;
  left: ${props => props.ratio * 275}px;
  right: ${props => props.ratio * 275}px;
  z-index: 20;
  
  ${device.tabletAndMobile} {
    position: relative;
    bottom: initial;
    left: initial;
    right: initial;
  }
  ${device.onlyTablet}{
    gap: 16px;
  }
  
  ${device.onlyMobile}{
    flex-direction: column;
    gap: 0;
  }
`

function SelectionCtn({actions, onSelectAction, selectedAct, args}) {
    const [height, setHeight] = useState(0)
    const ref = useRef([])
    const dim = useContext(DimContext)
    useLayoutEffect(() => {
        let maxHeight = 0;
        for (let i = 0; i < actions.length; i += 1) {
            let h = ref.current[i].getBoundingClientRect().height;
            if (h > maxHeight) {
                maxHeight = h;
            }
        }
        setHeight(maxHeight)
    }, [actions.length])
    return (
        <StyledSelectionCtn ratio={dim.ratio}>
            {actions.map((act, i) =>
                <Selection ref={r => ref.current[i] = r}
                           id={act.nextId}
                           height={height}
                           key={act.opt}
                           text={act.text}
                           isSelected={act.opt === selectedAct.opt}
                           args={args}
                           onClick={() => {
                               onSelectAction(selectedAct.opt === act.opt ? {} : act)
                           }}/>)}
        </StyledSelectionCtn>
    )
}


const StyledSelection = styled.div`
  flex-basis: calc(50% - 12px);

  ${props => props.height ? `height:${props.height}px;` : ''}

  padding: 24px 32px;
  border-radius: 16px;
  border: 4px solid #${props => props.checked ? '0969DA' : 'ADADAD'};
  display: flex;
  cursor: pointer;
  text-align: left;
  background-color: white;
  word-break: break-word;

  &:hover {
    background-color: #FAF9FA;
  }
  ${device.onlyTablet}{
    flex-basis: calc(50% - 8px);
  }
  
  ${device.tabletAndMobile}{
    padding: 16px;
  }
  
  ${device.onlyMobile}{
    margin-bottom: 12px;
    &:last-child{
      margin-bottom: 0;
    }
  }
`

const CheckCircle = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 40px;
  background: ${props => props.checked ? `url(${BlueCheck})` : 'white'};
  background-size: contain;
  border: ${props => props.checked ? 'none' : '2.5px solid #6E7781'};
  margin-right: 16px;
`

function SelectionBase({isSelected, onClick, text, args, height, id}, ref) {
    const [device] = useResponsive();
    const storyModel = new StoryModel();
    const {t} = useTranslation('game', {keyPrefix:storyModel.getCurrentStoryLineId()})
    return (
        <StyledSelection  checked={isSelected} onClick={onClick} ref={ref} height={height} id={id} className={isSelected? 'opt_selected' : ''}>
            {!device.isPhone && <CheckCircle checked={isSelected}/>}
            <TitleSH1>{typeof text === 'string' ? t(text) : text(t, args)}</TitleSH1>
        </StyledSelection>
    )
}

const Selection = forwardRef(SelectionBase)

function Dialogue({actions, onSelectAction, selectedAct, args}) {
    switch (actions[0].type) {
        case TextType.chat:
            return <ChatDialogue action={actions[0]} args={args}/>
        case TextType.desc:
            return <Description action={actions[0]} args={args}/>
        case TextType.selection:
            return <SelectionCtn actions={actions} onSelectAction={onSelectAction} selectedAct={selectedAct}
                                 args={args}/>
        case TextType.noneText:
        default:
            if(actions[0].isBubbleText){
                return <ChatDialogue action={actions[0]} args={args}/>
            }
            return null;

    }
}

export default Dialogue;