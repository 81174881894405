import {ContentBase} from "../components/surveyModal";
import {TitleHL} from "../../typography";
import styled from 'styled-components'
import {DenyButton} from "../../pages/components/buttons";

const CloseBase = styled(ContentBase)`
    align-items: center;
  text-align: center;
`

export function ClosePage({onClose, page}) {
    return <CloseBase >
        <TitleHL style={{whiteSpace: 'pre-wrap', fontWeight: 'bold', marginBottom: 24}}>{page.text}</TitleHL>

        <DenyButton text="Close" onClick={onClose} isStopAspect style={{minWidth: 100, maxWidth:100}}/>
    </CloseBase>
}