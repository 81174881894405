import styled from "styled-components";
import {useLocation, useNavigate} from "react-router";
import {ROUTE} from "../../App";
import Logo1 from '../images/icon/Logo-white.png'
import {TitleBtn} from "../../typography";
import {device} from "../../breakpoints";
import MenuButton from "../components/menuButton";
import {Base, BaseContent} from "../components/base";
import LanguageButton from "../components/languageButton";
import {StartButton} from "../components/startButton";
import {useTranslation} from "react-i18next";

export const NAV_HEIGHT = 64

const NavBase = styled(Base)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${NAV_HEIGHT}px;
  background: #E5EBF4;
  z-index: 40;

`

const NavCtn = styled(BaseContent)`
  padding: 8px 24px;
  display: flex;
  align-items: center;

  ${device.onlyMobile} {
    justify-content: center;
  }
`

const Logo = styled.img`
  height: 48px;
  cursor: pointer;
`

const NavItem = styled(TitleBtn)`
  cursor: pointer;
  margin-left: 36px;
  color: ${props => props.isSelected ? '#0969DA' : 'inherit'};

  &:hover {
    text-decoration-line: underline;
  }

  ${device.onlyMobile} {
    display: none;
  }
`


function NavigationBar() {
    const navigate = useNavigate()
    const location = useLocation();

    const {t} = useTranslation('common')
    if (location.pathname === ROUTE.GAME) {
        return null
    }
    return (
        <NavBase>
            <NavCtn>
                <MenuButton/>
                <Logo onClick={() => navigate(ROUTE.FRONTPAGE)} src={Logo1}/>
                <NavItem onClick={() => navigate(ROUTE.KNOWLEDGE)}
                         isSelected={location.pathname === ROUTE.KNOWLEDGE}>{t('tags.Knowledge Hub')}</NavItem>
                <NavItem onClick={()=>navigate(ROUTE.ABOUTUS)}
                         isSelected={location.pathname === ROUTE.ABOUTUS}>{t('tags.About Us')}</NavItem>
                <LanguageButton isNav style={{marginLeft: 'auto'}}/>
                <StartButton style={{marginLeft: 16}} isNav/>
            </NavCtn>
        </NavBase>
    )
}


export default NavigationBar