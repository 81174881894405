import styled from 'styled-components'
import {TitleSH1} from "../../typography";
import {ErrorMsg, InputSubMsg} from "../../pages/components/input";
import {useTransition} from "react";
import {useTranslation} from "react-i18next";

const FamiliarityCtn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const SpectrumCtn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  width: 100%
`

const SpectrumText = styled.div`
  font-size: 14px;
  color: #5B5A5C;

`

const NumCtn = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  width: 100%
`

const NumBox = styled.div`
  cursor: pointer;
  color: ${props=>props.isSelected? 'white' : '#5B5A5C'};
  background-color: ${props=>props.isSelected? '#065BBF' : 'transparent'};
  border: solid ${props=>props.isError? 'red' : '#89888B'};
  border-width: 2px 2px 2px 0;
  flex: 1;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  
  :first-child{
    border-radius: 8px 0 0 8px;
    border-left-width: 2px;
  }
  
  :last-child{
    border-radius: 0 8px 8px 0;
  }
`

function Familiarity({style, title, onClick, value, error}) {
    const {t} = useTranslation('common', {keyPrefix: 'familiarity'})
    return <FamiliarityCtn style={style}>
        <TitleSH1>{title}</TitleSH1>

        <NumCtn>
            {Array.from({ length: 10 }, (v, i) => i+1).map(val=>
                <NumBox key={val} isError={!!error} isSelected={value===val} onClick={()=>{onClick(val)}}>{val}</NumBox>
            )}
        </NumCtn>

        <SpectrumCtn>
            <SpectrumText>{t('Not At All Familiar')}</SpectrumText>
            <SpectrumText>{t('Very Familiar')}</SpectrumText>
        </SpectrumCtn>
        {!!error && <InputSubMsg>
            <ErrorMsg isError>{error}</ErrorMsg>
        </InputSubMsg>}

    </FamiliarityCtn>
}

export default Familiarity