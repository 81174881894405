const DOM = (
    <>
        <div>
            <h4>What you will learn</h4>
            <ul>
                <li>To learn about different types of relationships, partnerships and chosen families</li>
                <li>To learn the meanings of the terms; monogamous, consensual or ethical non monogamy, polyamorous and
                    microaggression
                </li>
                <li>To understand why and how diverse relationships relates to patient care</li>
                <li>To learn how to ask about relationships and how to recover if you make a mistake</li>
            </ul>
        </div>
        <div>
            <h4>What is relationship diversity?</h4>
            <ul>
                <li>The term “relationship status” or “relationship” is a broad term referring to an association,
                    connection or commitment that a person has with another. It often refers to a specific type of close
                    relationship, which can include emotional, romantic and/or physical intimacy. That being said,
                    relationships can take on many forms.
                </li>
                <li>Our society often constructs relationships in simplistic, heteronormative and binary ways, assuming
                    that being in a monogamous heterosexual relationship is the norm or the only type of relationship.
                </li>
                <li>Many relationships follow a traditional monogamous style; being romantic and sexual exclusivity with
                    one partner. It is often perceived to be the morally superior way to practice partnership
                </li>
                <li>Relationship status on forms often provides the categories of single, divorced, married or windowed
                    – leaving out many types of relationships.
                </li>
                <li>SGD communities (among others) have expanded possibilities in relationships, challenging
                    patriarchal, cisgender, and heteronormative views of relationships (Cormier-Otano & Davies, 2017)
                </li>
                <li>There can be countless possibilities of relationship styles, including non monogamous relationships
                    where being exclusive is not desired or an expectation. These relationships are often called
                    consensual non-monogamy (CNM) or ethical non-monogamy (ENM).
                </li>
                <li>This approach to relationships involves a person having more than one romantic or sexual partner at
                    a time, and everybody involved is aware and consents. Polyamory, open relationships, and swinging
                    are all forms of ethically non-monogamous relationships
                </li>
                <li>Relationship diversity can include different styles and structures of connection, have different
                    degrees of openness to sexual and emotional engagement with others, have different mutual agreements
                    with those involved. Relationship diversity can also include connections with others in a variety of
                    erotic practices or roleplaying, kink, sex work relationships and people in multi-partnered
                    relationships.
                </li>
            </ul>
        </div>
        <div>
            <h4>Why is relationship diversity important?
            </h4>
            <ul>
                <li>Heteronormative views can lead to assumptions about someone’s relationships or how it is configured,
                    not considering the incredible diversity of relationships
                </li>
                <li>Sometimes, SGD folks are hesitant to discuss their unique relationships due to the moral judgements
                    that may follow. Judgements, discrimination and biases, can compound internalised oppression, shame,
                    distress, anxiety and minority stress (Cormier-Otano & Davies, 2017)
                </li>
                <li>Sometimes these judgements are in the forms of microaggressions.
                </li>
                <li>Microaggressions are subtle or indirect comments or actions that communicate a derogatory or hostile
                    message. Microaggressions can lead to others feeling uneasy, culturally out of place, unwelcome, or
                    even physically unsafe. For example lt must be nice to not have to make a commitment and do what you
                    want”.
                </li>
            </ul>
        </div>
        <div>
            <h4>When and how much do we need to know someone’s relationships?
            </h4>
            <ul>
                <li>There are times when we need to know about the important people in a patient’s life, who they
                    consider their family, their partners
                </li>
                <li>This information helps healthcare providers to involve the appropriate people in their care.
                </li>
                <li>Different disciplines such as palliative care teams, psychiatrists, etc. may find this information
                    especially important to the care that they are providing.
                </li>
                <li>In a cancer care setting, this issue can be important to discussions about designated substitute
                    decision makers and power of attorney
                </li>
                <li>When asking about relationships and families, keep questions very open, such as who are the people
                    in your life that support you, what is their relationship to you?
                </li>
                <li>When asking, think carefully about why you may need to know, how it relates to the medical or
                    psychosocial care that you are providing. Sometimes it can be helpful to explain or provide a
                    context as to why you are asking, to make patients feel more comfortable. Sometimes, HCP may not
                    need to know details
                </li>
            </ul>
        </div>
        <div>
            <h4>How can I be inclusive about relationship diversity?
            </h4>
            <ul>
                <li>Respect means acknowledging the relationships that people create in their lives, their families and
                    chosen families, in their many diverse configurations
                </li>
                <li>It is important to respect everyone’s right to have the relationships that brings them meaning and
                    to use the terms that a patient uses when talking about their relationships
                </li>
                <li>By using inclusive language like partner or partners, we can increase inclusion for everyone, of all
                    families
                </li>
                <li>If you do not know or have not asked someone about their important relationships, be especially
                    aware of your assumptions
                </li>
            </ul>
        </div>
        <div>
            <h4>What if I make a mistake about relationship diversity?</h4>
            <ul>
                <li>Apologize, correct yourself and move on. Making a big deal out if it can just make the situation
                    more uncomfortable for everyone.
                </li>
                <li>We can all make mistakes. It can take time and practice to change our assumptions and language and
                    to be more inclusive. Be aware of ways our sex negative culture shames people for human needs,
                    desires and sexuality.
                </li>
                <li>Acknowledging, apologising, and making a commitment to do better next time, goes a long way to
                    communicate caring and respect. The important piece is the commitment to better next time.
                </li>
            </ul>
        </div>
        <div>
            <h4>How does understanding more about relationship diversity support inclusive care?
            </h4>
            <ul>
                <li>Understanding and being inclusive of diverse relationships allows us to create an inclusive culture
                    of safety and respect
                </li>
                <li>This understanding reminds us to be welcoming and inclusive to all families and chosen families.
                </li>
            </ul>
        </div>
        <div>
            <h4>How can I incorporate relationship diversity into my practice of inclusive care? Key takeaways:
            </h4>
            <ul>
                <li>Avoid assumptions about people’s relationships. Ask open and inclusive questions such as “tell me
                    about the people in your life who support you? “
                </li>
                <li>Be aware of unconscious biases and microaggressions. Value all types of relationships without
                    judgement
                </li>
                <li>Be an ally inclusive of all forms of families and chosen families
                </li>
            </ul>
        </div>
        <div>
            <h6>More resources about sexual orientation:
            </h6>
            <ul>
                <li><a target="_blank" rel="noreferrer"
                       href="https://www.rewriting-the-rules.com/wp-content/uploads/2017/12/13-11-17v2-GPaCPs-Gender-Sexual-and-Relationship-Diversity-001_AW-2.pdf">https://www.rewriting-the-rules.com/wp-content/uploads/2017/12/13-11-17v2-GPaCPs-Gender-Sexual-and-Relationship-Diversity-001_AW-2.pdf
                </a></li>
                <li>Gender, Sexual and Relationship Diversity Therapy</li>
                <li>2017, Sage Handbook for Counselling and Psychotherapy</li>
                <li>Olivier Cormier-Otaño and Dominic Davies <a target="_blank" rel="noreferrer" href="https://pmchosenfamily.ca">Gender_Sexual_and_Relationship_Diversity.pdf
                </a></li>
                <li><a target="_blank" rel="noreferrer" href="https://taimi.com/wiki">https://taimi.com/wiki</a> LGBTQ+
                    wiki, excellent definitions
                </li>
                <li>Nadal KL. A Decade of Microaggression Research and LGBTQ Communities: An Introduction to the Special
                    Issue. J
                    Homosex. 2019;66(10):1309-1316. doi: 10.1080/00918369.2018.1539582. Epub 2018 Nov 7. PMID: 30403569.
                </li>
            </ul>

        </div>
    </>
)

export default DOM