import {END_GAME, ImageType, PositionType, SceneType} from "../../../classes/scene";

import HCP_0_03 from "../../../images/hcp/0/HCP-03.png";
import HCP_1_03 from "../../../images/hcp/1/HCP-03.png";
import HCP_2_03 from "../../../images/hcp/2/HCP-03.png";
import HCP_A_01 from "../../../images/hcp/HCP-F-C01.png";
import HCP_A_02 from "../../../images/hcp/HCP-F-C02.png";
import HCP_A_03 from "../../../images/hcp/HCP-F-C03.png";

import {CHATICON} from "../storyGlobal";

import BGIW01 from "../../../images/background/BG-IW-01.png";
import BGIW02 from "../../../images/background/BG-IW-02.png";
import BGIW03 from "../../../images/background/BG-IW-03.png";

import {END_LEVEL} from "../../../constants";


const BG = {
    'IW01': {
        type: ImageType.background,
        image: BGIW01,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    'IW02': {
        type: ImageType.background,
        image: BGIW02,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    'IW03': {
        type: ImageType.background,
        image: BGIW03,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
}



const HCPA_CENTER = (img) => {
    return (
        {
            type: ImageType.avatar,
            image: img,
            positionType: PositionType.px,
            position: {
                left: 500,
                top: 234,
                right: 501,
                bottom: -626,
            },
            style: null,
        })
}



const HCP = {
    '03' :{
        type: ImageType.avatar,
        image: [HCP_0_03, HCP_1_03, HCP_2_03],
        positionType: PositionType.px,
        position: {
            left: 792,
            top: 297,
            right: 476,
            bottom: 29,
        },
        style: null,
        pronounButton:{
            top: 236,
            left: 96.64,
            width: 44,
            // height: 30,
            clippath: null
        }
    },
    '03-2': {
        type: ImageType.avatar,
        image: [HCP_0_03, HCP_1_03, HCP_2_03],
        positionType: PositionType.px,
        position: {
            left: 281,
            top: 234,
            right: 720,
            bottom: -626,
        },
        style: null,
    },
    '03-3': {
        type: ImageType.avatar,
        image: [HCP_0_03, HCP_1_03, HCP_2_03],
        positionType: PositionType.px,
        position: {
            left: 500,
            top: 234,
            right: 501,
            bottom: -626,
        },
        style: null,
        pronounButton:{
            top: 497,
            left: 203,
            width: 91,
            // height: 30,
            clippath: null
        }
    },
}

const HCPA = {
    '01' :{
        type: ImageType.avatar,
        image: HCP_A_01,
        positionType: PositionType.px,
        position: {
            left: 1057,
            top: 344,
            right: 229,
            bottom: 29,
        },
        style: null,
    },
    '01-2' :{
        type: ImageType.avatar,
        image: HCP_A_01,
        positionType: PositionType.px,
        position: {
            left: 759,
            top: 332,
            right: 280,
            bottom: -626,
        },
        style: null,
    },
    '02' :{
        type: ImageType.avatar,
        image: HCP_A_02,
        positionType: PositionType.px,
        position: {
            left: 759,
            top: 332,
            right: 280,
            bottom: -626,
        },
        style: null,
    },

}

const Gender4Scenes = {
    id: '02-04',
    patient: 4,
    scene: [{
        id: '02-04-001-01',
        type: SceneType.description,
        images:[
            BG['IW01'],
            HCP["03"],
            HCPA["01"]
        ],
        bubbleText: null,
        actions: [{
            nextId: '02-04-002-01',
            text: '001-01',
        }],
        hint: null,
        isBeginning: true,
    },{
        id: '02-04-002-01',
        type: SceneType.chat,
        images:[
            BG['IW02'],
            HCP['03-2'],
            HCPA["01-2"]

        ],
        bubbleText: false,
        actions: [{
            nextId: '02-04-003-01',
            text: '002-01',
            chatIcon: CHATICON.HCPA
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '02-04-003-01',
        type: SceneType.chat,
        images:[
            BG['IW03'],

        ],
        bubbleText: false,
        actions: [{
            nextId: '02-04-004-01',
            text: '003-01',
            chatIcon: CHATICON.ROS
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '02-04-004-01',
        type: SceneType.chat,
        images:[
            BG['IW02'],
            HCP['03-2'],
            HCPA["01-2"]

        ],
        bubbleText: false,
        actions: [{
            nextId: '02-04-005-01',
            text: '004-01',
            chatIcon: CHATICON.HCPA
        }],
        hint: null,
        isBeginning: false,
    },


        {
            id: '02-04-005-01',
            type: SceneType.selection,
            images:[
                BG['IW02'],
                HCP['03-3'],

            ],
            bubbleText: false,
            actions: [{
                nextId: '02-04-007-01',
                text: 'i01-q01',
                opt: 1,
                score: 0,
            }, {
                nextId: '02-04-006-02',
                text: 'i01-q02',
                opt: 2,
                score: 0,
            }],
            hint: 'i01-question',
            isBeginning: false,
        },{
            id: '02-04-006-02',
            type: SceneType.chat,
            images:[
                BG['IW03'],

            ],
            bubbleText: false,
            actions: [{
                nextId: END_GAME,
                text: 'i01-a02',
                chatIcon: CHATICON.ROS,
                gameEndLevel: END_LEVEL.WORSE
            }],
            hint: null,
            isBeginning: false,
        },


        {
            id: '02-04-007-01',
            type: SceneType.selection,
            images:[
                BG['IW02'],
                HCP['03-3'],
            ],
            bubbleText: false,
            actions: [{
                nextId: '02-04-008-01',
                text: 'i02-q01',
                opt: 1,
                score: 3,
            }, {
                nextId: '02-04-008-02',
                text: 'i02-q02',
                opt: 2,
                score: 2,
            }, {
                nextId: '02-04-008-03',
                text: 'i02-q03',
                opt: 3,
                score: 1,
            }, {
                nextId: '02-04-008-04',
                text: 'i02-q04',
                opt: 4,
                score: 0,
            }],
            hint: 'i02-question',
            isBeginning: false,
        },{
            id: '02-04-008-01',
            type: SceneType.chat,
            images:[
                BG['IW02'],
                HCPA_CENTER(HCP_A_02),

            ],
            bubbleText: false,
            actions: [{
                nextId: '02-04-009-01',
                text: "i02-a01",
                chatIcon: CHATICON.HCPA,
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '02-04-008-02',
            type: SceneType.chat,
            images:[
                BG['IW02'],
                HCPA_CENTER(HCP_A_02),

            ],
            bubbleText: false,
            actions: [{
                nextId: '02-04-009-01',
                text: "i02-a02",
                chatIcon: CHATICON.HCPA
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '02-04-008-03',
            type: SceneType.chat,
            images:[
                BG['IW02'],
                HCPA_CENTER(HCP_A_02),

            ],
            bubbleText: false,
            actions: [{
                nextId: '02-04-009-01',
                text: "i02-a03",
                chatIcon: CHATICON.HCPA
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '02-04-008-04',
            type: SceneType.chat,
            images:[
                BG['IW02'],
                HCPA_CENTER(HCP_A_03),

            ],
            bubbleText: false,
            actions: [{
                nextId: '02-04-009-01',
                text: "i02-a04",
                chatIcon: CHATICON.HCPA
            }],
            hint: null,
            isBeginning: false,
        },


        {
            id: '02-04-009-01',
            type: SceneType.selection,
            images:[
                BG['IW02'],
                HCP['03-3'],
            ],
            bubbleText: false,
            actions: [{
                nextId: '02-04-010-01',
                text: 'i03-q01',
                opt: 1,
                score: 3,
            }, {
                nextId: '02-04-010-02',
                text: 'i03-q02',
                opt: 2,
                score: 2,
            }, {
                nextId: '02-04-010-03',
                text: 'i03-q03',
                opt: 3,
                score: 1,
            }, {
                nextId: '02-04-010-04',
                text: 'i03-q04',
                opt: 4,
                score: 0,
            }],
            hint: 'i03-question',
            isBeginning: false,
        },{
            id: '02-04-010-01',
            type: SceneType.chat,
            images:[
                BG['IW02'],
                HCPA_CENTER(HCP_A_01),
            ],
            bubbleText: false,
            actions: [{
                nextId: '02-04-011-01',
                text: "i03-a01",
                chatIcon: CHATICON.HCPA
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '02-04-010-02',
            type: SceneType.chat,
            images:[
                BG['IW02'],
                HCPA_CENTER(HCP_A_01),
            ],
            bubbleText: false,
            actions: [{
                nextId: '02-04-011-01',
                text: "i03-a02",
                chatIcon: CHATICON.HCPA
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '02-04-010-03',
            type: SceneType.chat,
            images:[
                BG['IW02'],
                HCPA_CENTER(HCP_A_03),

            ],
            bubbleText: false,
            actions: [{
                nextId: '02-04-011-01',
                text: "i03-a03",
                chatIcon: CHATICON.HCPA
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '02-04-010-04',
            type: SceneType.chat,
            images:[
                BG['IW02'],
                HCPA_CENTER(HCP_A_03),

            ],
            bubbleText: false,
            actions: [{
                nextId: '02-04-011-01',
                text: "i03-a04",
                chatIcon: CHATICON.HCPA
            }],
            hint: null,
            isBeginning: false,
        },

        {
            id: '02-04-011-01',
            type: SceneType.selection,
            images:[
                BG['IW02'],
                HCP['03-3'],
            ],
            bubbleText: false,
            actions: [{
                nextId: '02-04-012-01',
                text: 'i04-q01',
                opt: 1,
                score: 3,
            }, {
                nextId: '02-04-012-02',
                text: 'i04-q02',
                opt: 2,
                score: 2,
            }, {
                nextId: '02-04-012-03',
                text: 'i04-q03',
                opt: 3,
                score: 1,
            }, {
                nextId: '02-04-012-04',
                text: 'i04-q04',
                opt: 4,
                score: 0,
            }],
            hint: 'i04-question',
            isBeginning: false,
        },{
            id: '02-04-012-01',
            type: SceneType.chat,
            images:[
                BG['IW02'],
                HCPA_CENTER(HCP_A_01),
            ],
            bubbleText: false,
            actions: [{
                nextId: END_GAME,
                text: "i04-a01",
                chatIcon: CHATICON.HCPA
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '02-04-012-02',
            type: SceneType.chat,
            images:[
                BG['IW02'],
                HCPA_CENTER(HCP_A_01),
            ],
            bubbleText: false,
            actions: [{
                nextId: END_GAME,
                text: "i04-a02",
                chatIcon: CHATICON.HCPA
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '02-04-012-03',
            type: SceneType.chat,
            images:[
                BG['IW02'],
                HCPA_CENTER(HCP_A_03),
            ],
            bubbleText: false,
            actions: [{
                nextId: END_GAME,
                text: "i04-a03",
                chatIcon: CHATICON.HCPA
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '02-04-012-04',
            type: SceneType.chat,
            images:[
                BG['IW03'],
            ],
            bubbleText: false,
            actions: [{
                nextId: END_GAME,
                text: "i04-a04",
                chatIcon: CHATICON.ROS,
                gameEndLevel: END_LEVEL.WORSE
            }],
            hint: null,
            isBeginning: false,
        },


    ]
}


export default Gender4Scenes