import StoryModel from "../../classes/storyModel";
import {PaddedPage} from "../../components/page";
import styled from "styled-components";
import {BackButton, NextButton} from "../../components/navButtons";
import {GAME_ROUTE} from "../../hooks/useGameRouter";
import {useContext} from "react";
import {BodyB1, TitleD3, TitleD4, TitleDA} from "../../../typography";
import HeaderContainer from "../../components/headerCtn";
import useResponsive from "../../../hook/useResponsive";
import BottomContainer from "../../components/bottomCtn";
import {device} from "../../../breakpoints";
import {DimContext} from "../../../App";
import {VertScroller} from "../../components/containers";
import ScrollDiv from "../../../pages/components/scrollDiv";
import {useTranslation} from "react-i18next";
import GaUtility from "../../../gaUtility";


const InfoCtn = styled.div`
  padding: ${props => props.ratio * 42}px ${props => props.ratio * 135}px 0 ${props => props.ratio * 49}px;
  display: flex;
  flex: 1;
  align-items: center;
`

const InfoScrollerCtn = styled(VertScroller)`
    margin-bottom: ${props => props.ratio * 57}px
`

const AvatarImg = styled.div`
  width: ${props => props.ratio * 236 || 1}px;
  margin-right: 64px;
  background-image: url(${props => props.img});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  align-self: stretch;
  
  ${device.tabletAndMobile}{
    margin-top: 36px;
    margin-right: 0;
    align-self: center;
  }
  
  ${device.onlyTablet}{
    width: 136px;
    margin-bottom: 48px;
    height: 368px;
  }
  ${device.onlyMobile}{
    height: 260px;
    width: 96px;
    margin-bottom: 24px;
  }
`
const IntroCtn = styled.div`
  flex: 1;
  text-align: left;
  white-space: pre-wrap;
`

const PatientInfoPageBase = styled(PaddedPage)`
  display: flex;
  background-color: #E5EBF4;
  flex-direction: column;
  align-items: center;
`

function PatientInfoPage() {
    const storyModel = new StoryModel();
    const dim = useContext(DimContext);
    const patient = storyModel.selectedPatient;

    const [device] = useResponsive();

    const {t:tP} = useTranslation('common', {keyPrefix: 'patient'})
    const {t:tT} = useTranslation('common', {keyPrefix: 'text'})
    const {t: tB} = useTranslation('common', {keyPrefix: 'button'})

    const onStartGame = (callback) => {
        GaUtility.gameStart()
        callback();
    }

    if (device.isMobile) {
        const Font = device.isPhone ? TitleD4: TitleD3;
        const DescFont = device.isPhone ? BodyB1: TitleDA;
        return (
            <ScrollDiv>
                <PatientInfoPageBase ratio={dim.ratio}>
                    <HeaderContainer hideLogo/>
                    <AvatarImg ratio={dim.ratio} img={patient.avatar}/>
                    <IntroCtn>
                        <Font>{`${tT('Hi, I am')}${patient.name}`}</Font>
                        <DescFont style={{marginTop: 16}}>{tP(patient.intro)}</DescFont>
                    </IntroCtn>

                    <BottomContainer
                        backgroundColor='#E5EBF4'
                        Back={<BackButton text={tB('Back')} />}
                        Next={<NextButton id="game_start" text={tB("Proceed to this story")}
                                          style={{maxWidth: 210, minWidth: 210}}
                                          route={GAME_ROUTE.story} onClick={onStartGame}/>}
                    />
                </PatientInfoPageBase>
            </ScrollDiv>
        )
    }

    return (
        <PatientInfoPageBase ratio={dim.ratio}>

            <HeaderContainer hideLogo/>
            <InfoScrollerCtn ratio={dim.ratio}>
                <InfoCtn ratio={dim.ratio}>
                    <AvatarImg ratio={dim.ratio} img={patient.avatar}/>
                    <IntroCtn>
                        <TitleD3>{`${tT('Hi, I am')}${patient.name}`}</TitleD3>
                        <TitleDA style={{marginTop: 16}}>{tP(patient.intro)}</TitleDA>
                    </IntroCtn>
                </InfoCtn>
            </InfoScrollerCtn>

            <div style={{display: 'flex', justifyContent: 'space-between', flex: 0, width: '100%'}}>
                <BackButton text={tB('Back')} />
                <NextButton id="game_start"
                            text={tB("Proceed to this story")}
                            route={GAME_ROUTE.story}
                onClick={onStartGame}/>
            </div>
        </PatientInfoPageBase>
    )

}

export default PatientInfoPage;