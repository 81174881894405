// import PageAnimation from "../game/fragments/pageAnimation";
import useGameRouter from "../game/hooks/useGameRouter";
import {useContext, useEffect, useRef, useState} from "react";
import GameRouter from "../game/components/gameRouter";
import styled, {keyframes, ThemeProvider} from "styled-components";
import {DimContext} from "../App";
import {isDev} from "../settings";
import {device} from "../breakpoints";
import useResponsive from "../hook/useResponsive";
import StoryModel from "../game/classes/storyModel";
import GaUtility from "../gaUtility";

const fadein = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`

const fadeout = keyframes`
  from {
    opacity: 1
  }
  to {
    opacity: 0
  }
`


const Base = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  ${device.tabletAndMobile} {
    align-items: flex-start;
  }
  
  :nth-child(1){
    opacity: 1;
    z-index: 2;
    animation: ${fadein} 0.25s;
  }
  
  :nth-child(2){
    opacity: 0;
    z-index: 1;
    cursor: not-allowed;
    animation: ${fadeout} 0.25s;
  }
`

/*const BaseIn = styled(Base)`
  opacity: 1;
  z-index: 2;
  animation: ${fadein} 0.25s;

`

const BaseOut = styled(Base)`
  opacity: 0;
  z-index: 1;
  cursor: not-allowed;
  animation: ${fadeout} 0.25s;
`*/


const Background = styled.div`
  width: 100%;
  height: 100%;
  background-color: #E5EBF4;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  min-height: 100vh;
  @media mobile, tablet, only screen and ${device.medium} {
    justify-content: flex-start;
  }
`

const Canvas = styled.div`
  position: relative;
  min-width: ${props => props.dim.width}px;
  min-height: ${props => props.dim.height}px;

  ${device.tabletAndMobile} {
    min-width: ${props => props.dim.gameWidth}px;

  }

  @media mobile, tablet, only screen and ${device.medium} {
    min-height: 100vh;
  }
`
export const ButtonFloat = styled.div`
  position: fixed;
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  background-image: linear-gradient(transparent, ${props => props.backgroundColor});
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 35;

  ${device.onlyTablet} {
    padding: 36px 24px;
  }
`
export const ButtonBlock = styled.div`
  position: relative;
  height: 48px;
  width: 100%;
  margin: 16px;

  ${device.onlyTablet} {
    margin: 36px 0 12px;
  }

  ${device.onlyMobile} {
    margin: 16px 0 0;
    background-color: ${props => props.backgroundColor}
  }
`


function Game() {

    const dim = useContext(DimContext)
    const [location,useRoute] = useGameRouter();
    const storyModel = new StoryModel();
    const pageRef = useRef(null)
    // console.log(dim);
    //prohibit right click
    useEffect(() => {
        // the handler for actually showing the prompt
        // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
        const alertUser = (event) => { // Unable to do custom message
            // console.log(event)
            event.preventDefault();
            event.returnValue = '';
        };


        // define a custom handler function
        // for the contextmenu event
        const handleContextMenu = (e) => {
            // prevent the right-click menu from appearing
            e.preventDefault()
        }
        useRoute.reset();
        storyModel.reset();

        GaUtility.pregameStart();
        window.addEventListener('beforeunload', alertUser);
        !isDev() && document.addEventListener("contextmenu", handleContextMenu)
        return () => {
            window.removeEventListener('beforeunload', alertUser);
            !isDev() && document.removeEventListener("contextmenu", handleContextMenu)
        }
    }, [])


    const [pages, setPages] = useState([]);
    const {route, ts, sceneId} = location
    const [device] = useResponsive();


    //Update Page when new page appears
    useEffect(() => {
        let list = [{route, ts, sceneId}, ...pages];
        list = list.slice(0, 2);
        setPages(list)
    }, [ts])

    return (
        <ThemeProvider theme={{ratio: device.isDesktop ? dim.ratio : 1}}>
            <Background key={dim.height + dim.width + dim.ratio} ref={pageRef}>
                <Canvas dim={dim}>

                    {pages[0] && <Base key={'b' + pages[0].ts}>
                        <GameRouter key={pages[0].ts} route={pages[0].route} sceneId={pages[0].sceneId}/>
                    </Base>}

                    {pages[1] && <Base key={'b' + pages[1].ts} onAnimationEnd={() => {
                        setPages(pages.slice(0, pages.length - 1))
                    }}>
                        <GameRouter key={pages[1].ts} route={pages[1].route} sceneId={pages[1].sceneId}/>

                    </Base>}
                </Canvas>
            </Background>
        </ThemeProvider>

    );
}

export default Game