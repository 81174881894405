import Survey, {SurveyPageStyle} from "./survey";

const SurveyMetaData = {
    numOfPage: 9,
    numOfQuestion: 7,
    startOfQuestion: 1,
}

const SurveyPage = [
    {
        pageNum: 0,
        text: 'Would you like to answer a short post-game survey to help the research team to improve the program?',
        style: SurveyPageStyle.CONFIRMATION
    },
    {
        questionNum: 1,
        pageNum: 1,
        list: [{
            value: 'Strongly Disagree',
            text: 'Strongly Disagree'
        }, {
            value: 'Disagree',
            text: 'Disagree'
        }, {
            value: 'Neither Agree Nor Disagree',
            text: 'Neither Agree Nor Disagree'
        }, {
            value: 'Agree',
            text: 'Agree'
        }, {
            value: 'Strongly Agree',
            text: 'Strongly Agree'
        }],
        question: 'I think I am able to apply the course contents at work.',
        style: SurveyPageStyle.RADIOFORM,
    },
    {
        questionNum: 2,
        pageNum: 2,
        list: [{
            value: 'Strongly Disagree',
            text: 'Strongly Disagree'
        }, {
            value: 'Disagree',
            text: 'Disagree'
        }, {
            value: 'Neither Agree Nor Disagree',
            text: 'Neither Agree Nor Disagree'
        }, {
            value: 'Agree',
            text: 'Agree'
        }, {
            value: 'Strongly Agree',
            text: 'Strongly Agree'
        }],
        question: 'I feel this online module provided me with what I need.',
        style: SurveyPageStyle.RADIOFORM,
    },
    {
        questionNum: 3,
        pageNum: 3,
        list: [{
            value: 'Strongly Disagree',
            text: 'Strongly Disagree'
        }, {
            value: 'Disagree',
            text: 'Disagree'
        }, {
            value: 'Neither Agree Nor Disagree',
            text: 'Neither Agree Nor Disagree'
        }, {
            value: 'Agree',
            text: 'Agree'
        }, {
            value: 'Strongly Agree',
            text: 'Strongly Agree'
        }],
        question: 'I am motivated to learn about inclusive care when I take this module.',
        style: SurveyPageStyle.RADIOFORM,
    },
    {
        questionNum: 4,
        pageNum: 4,
        list: [{
            value: 'Strongly Disagree',
            text: 'Strongly Disagree'
        }, {
            value: 'Disagree',
            text: 'Disagree'
        }, {
            value: 'Neither Agree Nor Disagree',
            text: 'Neither Agree Nor Disagree'
        }, {
            value: 'Agree',
            text: 'Agree'
        }, {
            value: 'Strongly Agree',
            text: 'Strongly Agree'
        }],
        question: 'I can derive new interpretations and ideas from the knowledge I have learned in my online classes.',
        style: SurveyPageStyle.RADIOFORM,
    },
    {
        questionNum: 5,
        pageNum: 5,
        list: [{
            value: 'Strongly Disagree',
            text: 'Strongly Disagree'
        }, {
            value: 'Disagree',
            text: 'Disagree'
        }, {
            value: 'Neither Agree Nor Disagree',
            text: 'Neither Agree Nor Disagree'
        }, {
            value: 'Agree',
            text: 'Agree'
        }, {
            value: 'Strongly Agree',
            text: 'Strongly Agree'
        }],
        question: 'I sometimes chose answers that I knew were the wrong options.',
        style: SurveyPageStyle.RADIOFORM,
    },
    {
        questionNum: 6,
        pageNum: 6,
        list: [{
            value: 'Strongly Disagree',
            text: 'Strongly Disagree'
        }, {
            value: 'Disagree',
            text: 'Disagree'
        }, {
            value: 'Neither Agree Nor Disagree',
            text: 'Neither Agree Nor Disagree'
        }, {
            value: 'Agree',
            text: 'Agree'
        }, {
            value: 'Strongly Agree',
            text: 'Strongly Agree'
        }],
        question: 'I developed the ability to communicate clearly about the subjects included in this module.',
        style: SurveyPageStyle.RADIOFORM,
    },
    /*{
        questionNum: 7,
        pageNum: 7,
        range:[1,10],
        spectrum:['Not At All Familiar','Very Familiar'],
        question: (topic) => `On a scale of 1 to 10, how would you rate your knowledge of ${topic} after finishing the program?`,
        style: SurveyPageStyle.RATING,
    },*/
    {
        questionNum: 7,
        pageNum: 7,
        textBox: {limit: 8000, multiLine: 8},
        question: 'Please let us know if there is any feedback or suggestions you would like to share with us.',
        style: SurveyPageStyle.INPUTBOX,
        isDone: true
    },
    {
        pageNum: 8,
        text: 'Thank you for your feedback!',
        style: SurveyPageStyle.CLOSE,
    }
]

export default function EndGameSurvey({isOpen, onClose, onEnd}) {

    return <Survey
        isOpen={isOpen}
        onClose={onClose}
        onEnd={onEnd}
        metaData={SurveyMetaData}
        pages={SurveyPage}
    />
}
// topic={topic}