import styled from "styled-components";
import {TitleBtn, TitleHLBold} from "../../typography";
import Logo from '../images/icon/Logo-blue.png';
import InstagramIcon from '../images/icon/socialMediaInstagram.png'
import TwitterIcon from '../images/icon/socialMediaTwitter.png'
import {useNavigate} from "react-router";
import {ROUTE} from "../../App";
import {device} from "../../breakpoints";
import {Base, BaseContent} from "../components/base";
import {useTranslation} from "react-i18next";


const FooterBase = styled(Base)`
  background-color: #425A7D;

`

const FooterCtn = styled(BaseContent)`
  width: 100%;
  padding: 64px 95px;
  display: flex;
  flex-direction: column;

  ${device.onlyTablet} {
    padding: 64px 36px;
  }

  ${device.onlyMobile} {
    padding: 56px 16px;
  }
`

const FooterInfoCtn = styled.div`
  padding-bottom: 52px;
  display: flex;
  gap: 64px;
  flex-direction: row;

  ${device.onlyTablet} {
    gap: 24px;
  }

  ${device.onlyMobile} {
    gap: 36px;
    flex-direction: column;
  }
`

const FooterInfoItem = styled.div`
  margin-right: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const FooterInfoTitle = styled(TitleHLBold)`
  color: white;
  margin-bottom: 6px;
`

const FooterInfoLink = styled(TitleBtn)`
  color: white;
  margin-bottom: 6px;
  cursor: pointer;
`
const FooterCopyrightCtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 80px;

  ${device.onlyMobile} {
    align-items: flex-start;
    flex-direction: column;
  }
`

const FooterCopyrightStatement = styled(TitleBtn)`
  padding-left: 24px;
  color: white;

  ${device.onlyMobile} {
    margin-top: 12px;
    padding-left: 0;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }
`

const SocialMediaCtn = styled.div`
  display: flex;
`

const SocialMediaIcon = styled.a`
  width: 25px;
  height: 25px;
  cursor: pointer;
  background: url(${props => props.icon}) no-repeat;
  background-size: contain;
  margin-right: 24px;

  &:last-of-type {
    margin-right: 0;
  }
`

const FooterStub = styled.div`
  flex: 1;

  ${device.tabletAndMobile} {
    display: none;
  }
`

function Footer() {
    const navigate = useNavigate();

    const {t} = useTranslation('common', {keyPrefix: 'tags'})
    return <FooterBase>
        <FooterCtn>
            <FooterInfoCtn>
                <FooterInfoItem>
                    <FooterInfoTitle>{t('Content')}</FooterInfoTitle>
                    {/*<FooterInfoLink>Interactive program</FooterInfoLink>*/}
                    <FooterInfoLink onClick={() => {
                        navigate(ROUTE.KNOWLEDGE);
                        window.scrollTo(0, 0)
                    }}>{t('Knowledge Hub')}</FooterInfoLink>
                    <FooterInfoLink onClick={() => {
                        navigate(ROUTE.ABOUTUS);
                        window.scrollTo(0, 0)
                    }}>{t('About Us')}</FooterInfoLink>
                </FooterInfoItem>
                <FooterStub/>
                <FooterInfoItem>
                    <FooterInfoTitle>{t('Social media')}</FooterInfoTitle>
                    <SocialMediaCtn>
                        <SocialMediaIcon href="https://www.instagram.com/pmchosenfamily/" target="_blank"
                                         icon={InstagramIcon}/>
                        <SocialMediaIcon href="https://twitter.com/PMChosenFamily" target="_blank" icon={TwitterIcon}/>
                    </SocialMediaCtn>
                </FooterInfoItem>
            </FooterInfoCtn>
            <FooterCopyrightCtn>
                <img alt="footer logo" onClick={() => {
                    navigate(ROUTE.FRONTPAGE);
                    window.scrollTo(0, 0)
                }} src={Logo} style={{cursor: 'pointer'}} height="88px"/>
                <FooterCopyrightStatement>{t('Copyright')}</FooterCopyrightStatement>

            </FooterCopyrightCtn>
        </FooterCtn>

    </FooterBase>
}

export default Footer