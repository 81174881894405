import styled from "styled-components";
import Landing from "./fragments/landing";
import Footer from "./fragments/footer";
import {FeedbackFragment, GameFragment, TopicFragment} from "./fragments/frontpageFrag";
import ScrollDiv from "./components/scrollDiv";
import {NAV_HEIGHT} from "./fragments/navigationBar";
import {useRef} from "react";

export const PageBase = styled.div`
  width: 100%;
  min-height: calc(100vh - ${NAV_HEIGHT}px);
  overflow: auto;
  position: relative;
  padding-top : ${NAV_HEIGHT}px;
  text-align: left;

`

function FrontPage() {
    const ref = useRef()
    return (
        <ScrollDiv>
            <PageBase ref={ref}>
                <Landing/>
                <TopicFragment/>
                <GameFragment/>
                <FeedbackFragment/>
                <Footer/>
            </PageBase>

        </ScrollDiv>
    )
}


export default FrontPage