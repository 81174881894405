import styled from "styled-components";
import {TitleSH1} from "../../typography";
import CheckIcon from '../images/icon/listCheckIcon.png'


const BackDrop = styled.div`
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props=>props.isFull ? 'rgba(36, 41, 47, 0.5)' : 'transparent'};
`

const StyledCtn = styled.div`
  padding: 6px 12px;
  border-radius: 8px;
  width: 218px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 2px #B6B5B9;
  background-color: white;
  z-index: 10000;
  position: ${props=>props.isFull? 'relative' : 'fixed'};
  top: ${props=>props.isFull? 'unset' : props.position.top || 0}px;
  left: ${props=>props.isFull? 'unset' : props.position.left || 0}px;
`

function DropDownCtn({isFull, list, val, position}){
    return (<StyledCtn isFull={isFull} position={position}>
        {list.map((item)=><DropDownItem key={item.val} text={item.text} onClick={item.onClick} isSelected={val===item.val}/>)}
    </StyledCtn>)
}


const StyledItem = styled.div`
  cursor: ${props=> props.isSelected? 'default' : 'pointer'};
  padding: 10px 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  
  &:hover{

    background-color: #EEEEEE;
  }
  
`

function DropDownItem({text, isSelected, onClick}){
    return(<StyledItem isSelected={isSelected} onClick={onClick}>
            <TitleSH1>{text}</TitleSH1>
        {isSelected && <img src={CheckIcon} width={16} height={16}/>}
        </StyledItem>
    )
}


function Dropdown({isOpen, isFull,onBackdropClicked, ...rest}){
    if(!isOpen) return null;
    return (<BackDrop isFull={isFull} onClick={onBackdropClicked}>
        <DropDownCtn isFull={isFull} {...rest}/>
    </BackDrop>)
}


export default Dropdown
