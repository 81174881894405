import {END_GAME, ImageType, PositionType, SceneType} from "../../../classes/scene";

import MAX01 from "../../../images/patient/max/MAX-01.png";
import MAX03 from "../../../images/patient/max/MAX-03.png";
import MAX04 from "../../../images/patient/max/MAX-04.png";
import MAX06 from "../../../images/patient/max/MAX-06.png";

import MAX08 from "../../../images/patient/max/MAX-08.png";


import EMMA01 from "../../../images/patient/max/MAX-E-01.png";
import EMMA03 from "../../../images/patient/max/MAX-E-03.png";
import EMMA02 from "../../../images/patient/max/MAX-E-02.png";

import HCP_0_03 from "../../../images/hcp/0/HCP-03.png";
import HCP_1_03 from "../../../images/hcp/1/HCP-03.png";
import HCP_2_03 from "../../../images/hcp/2/HCP-03.png";
import HCP_0_02 from "../../../images/hcp/0/HCP-02.png";
import HCP_1_02 from "../../../images/hcp/1/HCP-02.png";
import HCP_2_02 from "../../../images/hcp/2/HCP-02.png";

import {CHATICON} from "../storyGlobal";

import BG01 from "../../../images/background/BG-01.png";
import BG02 from "../../../images/background/BG-02.png";
import BG03 from "../../../images/background/BG-03.png";

import {END_LEVEL} from "../../../constants";
import HCP_0_05 from "../../../images/hcp/0/HCP-05.png";
import HCP_1_05 from "../../../images/hcp/1/HCP-05.png";
import HCP_2_05 from "../../../images/hcp/2/HCP-05.png";


const BG = {
    '01': {
        type: ImageType.background,
        image: BG01,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    '02': {
        type: ImageType.background,
        image: BG02,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    '03': {
        type: ImageType.background,
        image: BG03,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },

}


const MAX = {
    
    "08": {
        type: ImageType.avatar,
        image: MAX08,
        positionType: PositionType.px,
        position: {
            left: 252,
            top: 355,
            right: 852,
            bottom: 18,
        },
        style: null,
    },
}

const EMMA = {
    "01": {
        type: ImageType.avatar,
        image: EMMA01,
        positionType: PositionType.px,
        position: {
            left: 55,
            top: 203,
            right: 1222,
            bottom: -2,
        },
        style: null,
    },
}

const MAX_LEFT = (img) => {
    return (
        {
            type: ImageType.avatar,
            image: img,
            positionType: PositionType.px,
            position: {
                left: 268,
                top: 203,
                right: 613,
                bottom: -1036,
            },
            style: null,
        })
}

const EMMA_RIGHT = (img) => {
    return (
        {
            type: ImageType.avatar,
            image: img,
            positionType: PositionType.px,
            position: {
                left: 669,
                top: 203,
                right: 212,
                bottom: -1318.89,
            },
            style: null,
        })
}


const HCP = {
    '05' :{
        type: ImageType.avatar,
        image: [HCP_0_05, HCP_1_05, HCP_2_05],
        positionType: PositionType.px,
        position: {
            left: 806,
            top: 394,
            right: 361,
            bottom: 44.71,
        },
        style: null,
        pronounButton:{
            top: 214,
            left: 187,
            width: 44,
            // height: 30,
            clippath: null
        }
    },
    '02' :{
        type: ImageType.avatar,
        image: [HCP_0_02, HCP_1_02, HCP_2_02],
        positionType: PositionType.px,
        position: {
            left: 462,
            top: 187,
            right: 462,
            bottom: -876,
        },
        style: null,
        pronounButton:{
            top: 604,
            left: 242,
            width: 122,
            // height: 30,
            clippath: null
        }
    },
    
    
    '03' :{
        type: ImageType.avatar,
        image: [HCP_0_03, HCP_1_03, HCP_2_03],
        positionType: PositionType.px,
        position: {
            left: 333,
            top: 306,
            right: 945,
            bottom: 43,
        },
        style: null,
        pronounButton:{
            top: 225,
            left: 90,
            width: 47,
            // height: 30,
            clippath: null
        }
    },
    '03-2': {
        type: ImageType.avatar,
        image: [HCP_0_03, HCP_1_03, HCP_2_03],
        positionType: PositionType.px,
        position: {
            left: 498.76,
            top: 206,
            right: 501.6,
            bottom: -613,
        },
        style: null,
        pronounButton:{
            top: 502,
            left: 203,
            width: 94,
            // height: 30,
            clippath: null
        }
    },

}

const Relationship3Scenes = {
    id: '04-03',
    patient: 3,
    scene: [{
        id: '04-03-001-01',
        type: SceneType.description,
        images:[
            BG['01'],
            EMMA['01'],
            MAX['08'],
            HCP["05"]

        ],
        bubbleText: null,
        actions: [{
            nextId: '04-03-002-01',
            text: '001-01',
        }],
        hint: null,
        isBeginning: true,
    },{
        id: '04-03-002-01',
        type: SceneType.description,
        images:[
            BG['01'],
            EMMA['01'],
            MAX['08'],
            HCP["05"]

        ],
        bubbleText: false,
        actions: [{
            nextId: '04-03-003-01',
            text: '002-01',
        }],
        hint: null,
        isBeginning: false,
    },

        {
        id: '04-03-003-01',
        type: SceneType.selection,
        images:[
            BG['02'],
            HCP['02'],

        ],
        bubbleText: false,
        actions: [{
            nextId: '04-03-004-01',
            text: 'i01-q01',
            opt: 1,
            score: 3,
        }, {
            nextId: '04-03-004-02',
            text: 'i01-q02',
            opt: 2,
            score: 2,
        }, {
            nextId: '04-03-004-03',
            text: 'i01-q03',
            opt: 3,
            score: 1,
        }, {
            nextId: '04-03-004-04',
            text: 'i01-q04',
            opt: 4,
            score: 0,
        }],
        hint: 'i01-question',
        isBeginning: false,
    },{
        id: '04-03-004-01',
        type: SceneType.chat,
        images: [
            BG["03"],
            MAX_LEFT(MAX01),
            EMMA_RIGHT(EMMA01)
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-03-005-01',
            text: 'i01-a01',
            chatIcon: CHATICON.MAX
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '04-03-004-02',
        type: SceneType.chat,
        images: [
            BG["03"],
            MAX_LEFT(MAX03),
            EMMA_RIGHT(EMMA01)
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-03-005-01',
            text: 'i01-a02',
            chatIcon: CHATICON.MAX
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '04-03-004-03',
        type: SceneType.chat,
        images: [
            BG["03"],
            MAX_LEFT(MAX04),
            EMMA_RIGHT(EMMA01)
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-03-005-01',
            text: 'i01-a03',
            chatIcon: CHATICON.MAX
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '04-03-004-04',
        type: SceneType.chat,
        images: [
            BG["03"],
            MAX_LEFT(MAX06),
            EMMA_RIGHT(EMMA02)
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-03-005-01',
            text: 'i01-a04',
            chatIcon: CHATICON.MAX
        }],
        hint: null,
        isBeginning: false,
    },


        {
            id: '04-03-005-01',
            type: SceneType.selection,
            images:[
                BG['02'],
                HCP['02'],

            ],
            bubbleText: false,
            actions: [{
                nextId: '04-03-006-01',
                text: 'i02-q01',
                opt: 1,
                score: 3,
            }, {
                nextId: '04-03-006-02',
                text: 'i02-q02',
                opt: 2,
                score: 2,
            }, {
                nextId: '04-03-006-03',
                text: 'i02-q03',
                opt: 3,
                score: 1,
            }, {
                nextId: '04-03-006-04',
                text: 'i02-q04',
                opt: 4,
                score: 0,
            }],
            hint: 'i02-question',
            isBeginning: false,
        },{
            id: '04-03-006-01',
            type: SceneType.chat,
            images: [
                BG["03"],
                MAX_LEFT(MAX01),
                EMMA_RIGHT(EMMA01)
            ],
            bubbleText: null,
            actions: [{
                nextId: END_GAME,
                text: 'i02-a01',
                chatIcon: CHATICON.MAX,
                gameEndLevel: END_LEVEL.GREAT
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '04-03-006-02',
            type: SceneType.chat,
            images: [
                BG["03"],
                MAX_LEFT(MAX04),
                EMMA_RIGHT(EMMA02)
            ],
            bubbleText: null,
            actions: [{
                nextId: '04-03-007-01',
                text: 'i02-a02',
                chatIcon: CHATICON.MAX
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '04-03-006-03',
            type: SceneType.chat,
            images: [
                BG["03"],
                MAX_LEFT(MAX06),
                EMMA_RIGHT(EMMA03)
            ],
            bubbleText: null,
            actions: [{
                nextId: '04-03-007-01',
                text: 'i02-a03',
                chatIcon: CHATICON.MAX
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '04-03-006-04',
            type: SceneType.chat,
            images: [
                BG["03"],
                MAX_LEFT(MAX06),
                EMMA_RIGHT(EMMA03)
            ],
            bubbleText: null,
            actions: [{
                nextId: '04-03-007-01',
                text: 'i02-a04',
                chatIcon: CHATICON.MAX
            }],
            hint: null,
            isBeginning: false,
        },



        {
            id: '04-03-007-01',
            type: SceneType.selection,
            images:[
                BG['02'],
                HCP['02'],

            ],
            bubbleText: false,
            actions: [{
                nextId: '04-03-008-01',
                text: 'i03-q01',
                opt: 1,
                score: 3,
            }, {
                nextId: '04-03-008-02',
                text: 'i03-q02',
                opt: 2,
                score: 2,
            }, {
                nextId: '04-03-008-03',
                text: 'i03-q03',
                opt: 3,
                score: 1,
            }, {
                nextId: '04-03-008-04',
                text: 'i03-q04',
                opt: 4,
                score: 0,
            }],
            hint: 'i03-question',
            isBeginning: false,
        },{
            id: '04-03-008-01',
            type: SceneType.chat,
            images: [
                BG["03"],
                MAX_LEFT(MAX01),
                EMMA_RIGHT(EMMA01)
            ],
            bubbleText: null,
            actions: [{
                nextId: '04-03-009-01',
                text: 'i03-a01',
                chatIcon: CHATICON.MAX
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '04-03-008-02',
            type: SceneType.chat,
            images: [
                BG["03"],
                MAX_LEFT(MAX03),
                EMMA_RIGHT(EMMA02)
            ],
            bubbleText: null,
            actions: [{
                nextId: '04-03-009-01',
                text: 'i03-a02',
                chatIcon: CHATICON.MAX
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '04-03-008-03',
            type: SceneType.chat,
            images: [
                BG["03"],
                MAX_LEFT(MAX04),
                EMMA_RIGHT(EMMA02)
            ],
            bubbleText: null,
            actions: [{
                nextId: '04-03-009-01',
                text: 'i03-a03',
                chatIcon: CHATICON.MAX
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '04-03-008-04',
            type: SceneType.chat,
            images: [
                BG["03"],
                MAX_LEFT(MAX06),
                EMMA_RIGHT(EMMA03)
            ],
            bubbleText: null,
            actions: [{
                nextId: END_GAME,
                text: 'i03-a04',
                chatIcon: CHATICON.MAX,
                gameEndLevel: END_LEVEL.WORSE
            }],
            hint: null,
            isBeginning: false,
        },


        {
            id: '04-03-009-01',
            type: SceneType.selection,
            images:[
                BG['02'],
                HCP['02'],

            ],
            bubbleText: false,
            actions: [{
                nextId: '04-03-010-01',
                text: 'i04-q01',
                opt: 1,
                score: 3,
            }, {
                nextId: '04-03-010-02',
                text: 'i04-q02',
                opt: 2,
                score: 2,
            }, {
                nextId: '04-03-010-03',
                text: 'i04-q03',
                opt: 3,
                score: 1,
            }, {
                nextId: '04-03-010-04',
                text: 'i04-q04',
                opt: 4,
                score: 0,
            }],
            hint: '',
            isBeginning: false,
        },{
            id: '04-03-010-01',
            type: SceneType.chat,
            images: [
                BG["03"],
                MAX_LEFT(MAX01),
                EMMA_RIGHT(EMMA01)
            ],
            bubbleText: null,
            actions: [{
                nextId: END_GAME,
                text: 'i04-a01',
                chatIcon: CHATICON.MAX
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '04-03-010-02',
            type: SceneType.chat,
            images: [
                BG["03"],
                MAX_LEFT(MAX03),
                EMMA_RIGHT(EMMA02)
            ],
            bubbleText: null,
            actions: [{
                nextId: END_GAME,
                text: 'i04-a02',
                chatIcon: CHATICON.MAX
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '04-03-010-03',
            type: SceneType.chat,
            images: [
                BG["03"],
                MAX_LEFT(MAX04),
                EMMA_RIGHT(EMMA02)
            ],
            bubbleText: null,
            actions: [{
                nextId: END_GAME,
                text: 'i04-a03',
                chatIcon: CHATICON.MAX
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '04-03-010-04',
            type: SceneType.chat,
            images: [
                BG["03"],
                MAX_LEFT(MAX06),
                EMMA_RIGHT(EMMA03)
            ],
            bubbleText: null,
            actions: [{
                nextId: END_GAME,
                text: 'i04-a04',
                chatIcon: CHATICON.MAX
            }],
            hint: null,
            isBeginning: false,
        },

    ]
}


export default Relationship3Scenes