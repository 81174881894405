import BG05 from "../../../images/background/BG-05.png";
import BG07 from "../../../images/background/BG-07.png";
import BG12 from "../../../images/background/BG-12.png";


import CTN15sitsmile from "../../../images/patient/chetan/CTN-15-sit-smile-str8.png";
import CTN06 from "../../../images/patient/chetan/CTN-06.png";
import CTN05 from "../../../images/patient/chetan/CTN-05.png";
import {END_GAME, ImageType, PositionType, SceneType} from "../../../classes/scene";
import HCP_0_01 from "../../../images/hcp/0/HCP-01.png";
import HCP_1_01 from "../../../images/hcp/1/HCP-01.png";
import HCP_2_01 from "../../../images/hcp/2/HCP-01.png";
import BG02 from "../../../images/background/BG-02.png";
import BG03 from "../../../images/background/BG-03.png";
import BG04 from "../../../images/background/BG-04.png";
import CTN01smile from "../../../images/patient/chetan/CTN-01-smile.png";
import CTN11normal from "../../../images/patient/chetan/CTN-11-normal.png";
import CTN03annoy from "../../../images/patient/chetan/CTN-03-annoyed.png";
import CTN04angry from "../../../images/patient/chetan/CTN-04-angry.png";
import Item19 from "../../../images/background/Item-19.png";
import Item16 from "../../../images/background/Item-16.png";
import HCP_0_04 from "../../../images/hcp/0/HCP-04.png";
import HCP_1_04 from "../../../images/hcp/1/HCP-04.png";
import HCP_2_04 from "../../../images/hcp/2/HCP-04.png";
import HCP_0_07 from "../../../images/hcp/0/HCP-07.png";
import HCP_1_07 from "../../../images/hcp/1/HCP-07.png";
import HCP_2_07 from "../../../images/hcp/2/HCP-07.png";
import CTN12unsatisfied from "../../../images/patient/chetan/CTN-12-unsatisfied.png";
import {END_LEVEL} from "../../../constants";
import {CHATICON} from "../storyGlobal";

const POSITION = {
    searchHCP: {
        left: 550.31,
        top: 207,
        right: 262.77,
        bottom: -765,
    },
}

const BG = {
    "12" :{
        type: ImageType.background,
        image: BG12,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    "05" :{
        type: ImageType.background,
        image: BG05,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    '02': {
        type: ImageType.background,
        image: BG02,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    '04': {
        type: ImageType.background,
        image: BG04,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    '03': {
        type: ImageType.background,
        image: BG03,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    '07': {
        type: ImageType.background,
        image: BG07,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
}


const HCP = {
    '01': {
        type: ImageType.avatar,
        image: [HCP_0_01, HCP_1_01, HCP_2_01],
        positionType: PositionType.px,
        position: {
            left: 462,
            top: 187,
            right: 462,
            bottom: -876,
        },
        style: null,
    },
    '04': {
        type: ImageType.avatar,
        image: [HCP_0_04, HCP_1_04, HCP_2_04],
        positionType: PositionType.px,
        position: POSITION.searchHCP,
        style: null,
        pronounButton:{
            top: 558,
            left: 240,
            width: 105,
            // height: 30,
            clippath: 'polygon(0% 0%, 0% 30%, 60% 65%, 70% 100%, 100% 100%, 100% 0%)'
        }
    },
    '07': {
        type: ImageType.avatar,
        image: [HCP_0_07, HCP_1_07, HCP_2_07],
        positionType: PositionType.px,
        position: POSITION.searchHCP,
        style: null,
        pronounButton:{
            top: 558,
            left: 240,
            width: 105,
            // height: 30,
            clippath: 'polygon(0% 0%, 0% 30%, 60% 65%, 70% 100%, 100% 100%, 100% 0%)'
        }
    }
}


const CTN = {
    "15": {
        type: ImageType.avatar,
        image: CTN15sitsmile,
        positionType: PositionType.px,
        position: {
            left: 427,
            top: 418,
            right: 757,
            bottom: 48,
        },
        style: null,
    },
    "06": {
        type: ImageType.avatar,
        image: CTN06,
        positionType: PositionType.px,
        position: {
            left: 283,
            top: 394,
            right: 981,
            bottom: -82,
        },
        style: null,
    },
    "06-2": {
        type: ImageType.avatar,
        image: CTN06,
        positionType: PositionType.px,
        position: {
            left: 194,
            top: 311,
            right: 986,
            bottom: -227,
        },
        style: null,
    },
    "05": {
        type: ImageType.avatar,
        image: CTN05,
        positionType: PositionType.px,
        position: {
            left: 194,
            top: 311,
            right: 986,
            bottom: -227,
        },
        style: null,
    }
}

const CTN_CENTER = (img) => {
    return (
        {
            type: ImageType.avatar,
            image: img,
            positionType: PositionType.px,
            position: {
                left: 475,
                top: 212,
                right: 475,
                bottom: -749.65,
            },
            style: null,
        })

}

const ITEM = {
    '16': {
        type: ImageType.foreground,
        image: Item16,
        positionType: PositionType.px,
        position: {
            left: -342,
            top: 343,
            right: -61,
            bottom: -678,
        },
        style: null,
    },
    '19': {
        type: ImageType.foreground,
        image: Item19,
        positionType: PositionType.px,
        position: {
            left: -282,
            top: 345,
            right: -261,
            bottom: -435,
        },
        style: null,
    },
}

const Gender1Scenes = {
    id: '02-01',
    patient: 1,
    scene: [{
        id: '02-01-000-01',
        type: SceneType.description,
        images: [
            BG["12"],
            CTN["15"]
        ],
        bubbleText: null,
        actions: [{
            nextId: '02-01-001-01',
            text: '000-01'
        }],
        hint: null,
        isBeginning: true,
    },{
        id: '02-01-001-01',
        type: SceneType.description,
        images: [
            BG["05"],
            CTN["06"]
        ],
        bubbleText: null,
        actions: [{
            nextId: '02-01-002-01',
            text: '001-01'
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '02-01-002-01',
        type: SceneType.selection,
        images: [
            BG["04"],
            HCP['01']
        ],
        bubbleText: null,
        actions: [{
            nextId: '02-01-003-01',
            text:'i01-q01',
            opt: 1,
            score: 3,
        }, {
            nextId: '02-01-003-02',
            text:'i01-q02',
            opt: 2,
            score: 2,
        }, {
            nextId: '02-01-003-03',
            text:'i01-q03',
            opt: 3,
            score: 1,
        }, {
            nextId: '02-01-003-04',
            text:'i01-q04',
            opt: 4,
            score: 0,
        }],
        hint: 'i01-question',
        isBeginning: false,
    }, {
        id: '02-01-003-01',
        type: SceneType.chat,
        images: [
            BG["03"],
            CTN_CENTER(CTN01smile),
            ITEM["19"]
        ],
        bubbleText: null,
        actions: [{
            nextId: '02-01-004-01',
            text: 'i01-a01',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '02-01-003-02',
        type: SceneType.chat,
        images: [
            BG["03"],
            CTN_CENTER(CTN11normal),
            ITEM["19"]
        ],
        bubbleText: null,
        actions: [{
            nextId: '02-01-004-02',
            text: 'i01-a02',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '02-01-003-03',
        type: SceneType.chat,
        images: [
            BG["03"],
            CTN_CENTER(CTN03annoy),
            ITEM["19"]
        ],
        bubbleText: null,
        actions: [{
            nextId: '02-01-004-03',
            text: 'i01-a03',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '02-01-003-04',
        type: SceneType.chat,
        images: [
            BG["03"],
            CTN_CENTER(CTN04angry),
            ITEM["19"]
        ],
        bubbleText: null,
        actions: [{
            nextId: '02-01-004-04',
            text: 'i01-a04',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '02-01-004-01',
        type: SceneType.cutscene,
        images: [
            BG["03"],
            CTN_CENTER(CTN01smile),
            ITEM["19"]
        ],
        bubbleText: [{text: 'i01-a01-b'}],
        actions: [{
            nextId: '02-01-005-01',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '02-01-004-02',
        type: SceneType.cutscene,
        images: [
            BG["03"],
            CTN_CENTER(CTN11normal),
            ITEM["19"]
        ],
        bubbleText: [{text: 'i01-a02-b'}],
        actions: [{
            nextId: '02-01-005-01',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '02-01-004-03',
        type: SceneType.cutscene,
        images: [
            BG["03"],
            CTN_CENTER(CTN03annoy),
            ITEM["19"]
        ],
        bubbleText: [{text: 'i01-a03-b'}],
        actions: [{
            nextId: '02-01-005-01',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '02-01-004-04',
        type: SceneType.cutscene,
        images: [
            BG["03"],
            CTN_CENTER(CTN04angry),
            ITEM["19"]
        ],
        bubbleText: [{text: 'i01-a04-b'}],
        actions: [{
            nextId: '02-01-005-01',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '02-01-005-01',
        type: SceneType.selection,
        images: [
            BG["02"],
            HCP["07"],
            ITEM["16"]
        ],
        bubbleText: null,
        actions: [{
            nextId: '02-01-006-01',
            text: 'i02-q01',
            opt: 1,
            score: 3,
        }, {
            nextId: '02-01-006-02',
            text: 'i02-q02',
            opt: 2,
            score: 2,
        }, {
            nextId: '02-01-006-03',
            text: 'i02-q03',
            opt: 3,
            score: 1,
        }, {
            nextId: '02-01-006-04',
            text: 'i02-q04',
            opt: 4,
            score: 0,
        }],
        hint: 'i02-question',
        isBeginning: false,
    },{
        id: '02-01-006-01',
        type: SceneType.chat,
        images: [
            BG["03"],
            CTN_CENTER(CTN01smile),
            ITEM["19"]
        ],
        bubbleText: null,
        actions: [{
            nextId: '02-01-007-01',
            text: 'i02-a01',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '02-01-006-02',
        type: SceneType.chat,
        images: [
            BG["03"],
            CTN_CENTER(CTN11normal),
            ITEM["19"]
        ],
        bubbleText: null,
        actions: [{
            nextId: '02-01-007-01',
            text: 'i02-a02',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '02-01-006-03',
        type: SceneType.chat,
        images: [
            BG["03"],
            CTN_CENTER(CTN12unsatisfied),
            ITEM["19"]
        ],
        bubbleText: null,
        actions: [{
            nextId: '02-01-007-01',
            text: 'i02-a03',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '02-01-006-04',
        type: SceneType.chat,
        images: [
            BG["03"],
            CTN_CENTER(CTN04angry),
            ITEM["19"]
        ],
        bubbleText: null,
        actions: [{
            nextId: '02-01-007-01',
            text: 'i02-a04',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '02-01-007-01',
        type: SceneType.selection,
        images: [
            BG["04"],
            HCP['01']
        ],
        bubbleText: null,
        actions: [{
            nextId: '02-01-008-01',
            text: 'i03-q01',
            opt: 1,
            score: 3,
        }, {
            nextId: '02-01-008-02',
            text: 'i03-q02',
            opt: 2,
            score: 2,
        }, {
            nextId: '02-01-008-03',
            text: 'i03-q03',
            opt: 3,
            score: 1,
        }, {
            nextId: '02-01-008-04',
            text: 'i03-q04',
            opt: 4,
            score: 0,
        }],
        hint: 'i03-question',
        isBeginning: false,
    },{
        id: '02-01-008-01',
        type: SceneType.chat,
        images: [
            BG["03"],
            CTN_CENTER(CTN01smile),
            ITEM["19"]
        ],
        bubbleText: null,
        actions: [{
            nextId: '02-01-009-01',
            text: 'i03-a01',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '02-01-008-02',
        type: SceneType.chat,
        images: [
            BG["03"],
            CTN_CENTER(CTN11normal),
            ITEM["19"]
        ],
        bubbleText: null,
        actions: [{
            nextId: '02-01-009-01',
            text: 'i03-a02',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '02-01-008-03',
        type: SceneType.chat,
        images: [
            BG["03"],
            CTN_CENTER(CTN12unsatisfied),
            ITEM["19"]
        ],
        bubbleText: null,
        actions: [{
            nextId: '02-01-009-01',
            text: 'i03-a03',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '02-01-008-04',
        type: SceneType.chat,
        images: [
            BG["03"],
            CTN_CENTER(CTN04angry),
            ITEM["19"]
        ],
        bubbleText: null,
        actions: [{
            nextId: '02-01-008-04-02',
            text: 'i03-a04',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '02-01-008-04-02',
        type: SceneType.description,
        images: [
            BG["07"],
            CTN["06-2"],
        ],
        bubbleText: null,
        actions: [{
            nextId: END_GAME,
            text: 'i03-a04-02',
            gameEndLevel: END_LEVEL.WORSE,
        }],
        hint: null,
        isBeginning: false,
    },/*{
        id: '02-01-009-04-02',
        type: SceneType.description,
        images: [
            BG["07"],
            CTN["05"]
        ],
        bubbleText: null,
        actions: [{
            nextId: END_GAME,
            text: 'Chetan ended the conversation and stepped out of the office.',
        }],
        hint: null,
        isBeginning: false,
    },*/{
        id: '02-01-009-01',
        type: SceneType.selection,
        images: [
            BG["04"],
            HCP['01']
        ],
        bubbleText: null,
        actions: [{
            nextId: '02-01-010-01',
            text: 'i04-q01',
            opt: 1,
            score: 3,
        }, {
            nextId: '02-01-010-02',
            text: 'i04-q02',
            opt: 2,
            score: 2,
        }, {
            nextId: '02-01-010-03',
            text: 'i04-q03',
            opt: 3,
            score: 1,
        }, {
            nextId: '02-01-010-04',
            text: 'i04-q04',
            opt: 4,
            score: 0,
        }],
        hint: 'i04-question',
        isBeginning: false,
    },{
        id: '02-01-010-01',
        type: SceneType.chat,
        images: [
            BG["03"],
            CTN_CENTER(CTN01smile),
            ITEM["19"]
        ],
        bubbleText: null,
        actions: [{
            nextId: '02-01-010-01-02',
            text: 'i04-a01',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '02-01-010-02',
        type: SceneType.chat,
        images: [
            BG["03"],
            CTN_CENTER(CTN11normal),
            ITEM["19"]
        ],
        bubbleText: null,
        actions: [{
            nextId: '02-01-010-02-02',
            text: 'i04-a02',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '02-01-010-03',
        type: SceneType.chat,
        images: [
            BG["03"],
            CTN_CENTER(CTN12unsatisfied),
            ITEM["19"]
        ],
        bubbleText: null,
        actions: [{
            nextId: '02-01-010-03-02',
            text: 'i04-a03',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '02-01-010-04',
        type: SceneType.chat,
        images: [
            BG["03"],
            CTN_CENTER(CTN04angry),
            ITEM["19"]
        ],
        bubbleText: null,
        actions: [{
            nextId: '02-01-010-04-02',
            text: 'i04-a04',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '02-01-010-01-02',
        type: SceneType.cutscene,
        images: [
            BG["03"],
            CTN_CENTER(CTN01smile),
            ITEM["19"]
        ],
        bubbleText: [{text: 'i04-a01-b'}],
        actions: [{
            nextId: END_GAME,
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '02-01-010-02-02',
        type: SceneType.cutscene,
        images: [
            BG["03"],
            CTN_CENTER(CTN11normal),
            ITEM["19"]
        ],
        bubbleText: [{text: 'i04-a02-b'}],
        actions: [{
            nextId: END_GAME,
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '02-01-010-03-02',
        type: SceneType.cutscene,
        images: [
            BG["03"],
            CTN_CENTER(CTN03annoy),
            ITEM["19"]
        ],
        bubbleText: [{text: 'i04-a03-b'}],
        actions: [{
            nextId: END_GAME,
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '02-01-010-04-02',
        type: SceneType.cutscene,
        images: [
            BG["03"],
            CTN_CENTER(CTN04angry),
            ITEM["19"]
        ],
        bubbleText: [{text: 'i04-a04-b'}],
        actions: [{
            nextId: END_GAME,
        }],
        hint: null,
        isBeginning: false,
    },
    ]
};

export default Gender1Scenes