import styled from "styled-components";


export const Base = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
`

export const BaseContent = styled.div`
  max-width: 1440px;
  width: 100%;
`