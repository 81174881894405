import CloseButton from "../../pages/components/closeButton";
import PoweredByImg from "../images/misc/poweredBy.png";
import {breakpoints, deviceMin} from "../../breakpoints";
import {useContext} from "react";
import styled from "styled-components";
import {DimContext} from "../../App";

const PoweredBy = styled.img`
  top: 18px;
  right: 34px;
  z-index: 25;
`

const HeaderCtn = styled.div`
  position: relative;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //box-sizing: border-box;
  width: 100%;
  
  
  @media only screen and ${deviceMin.medium} {
    position: absolute;
    top: ${props=> 18 * props.ratio}px;
    left: ${props=> 34 * props.ratio}px;
    right: ${props=> 34 * props.ratio}px;
    height: ${props=> 48* props.ratio}px;
    width: auto;
  }
  
`
// onClick=() => setIsCloseModalOpen(true)
function HeaderContainer({hideClose, hideLogo}){
    const dim = useContext(DimContext);
    return <HeaderCtn  ratio={dim.ratio}>
        {hideClose ? <div/>: <CloseButton/>}
        {hideLogo ? <div/> : <a target="_blank" rel="noreferrer" href="https://pmchosenfamily.ca">
            <PoweredBy src={PoweredByImg} height={`${40 * (breakpoints.medium >=  document.documentElement.clientWidth ? 1 : dim.ratio)}px`}/>
        </a>}
    </HeaderCtn>
}

export default HeaderContainer