import {PaddedPage} from "../../components/page";
import {BodyB1, TitleD3, TitleD4Bold, TitleHL} from "../../../typography";
import {BackButton, NextButton} from "../../components/navButtons";
import useGameRouter, {GAME_ROUTE} from "../../hooks/useGameRouter";
import styled from "styled-components";
import NameCardImg from '../../images/misc/nameCard.png'
import {useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import StoryModel from "../../classes/storyModel";
import HeaderContainer from "../../components/headerCtn";
import useResponsive from "../../../hook/useResponsive";
import {device} from "../../../breakpoints";
import BottomContainer from "../../components/bottomCtn";
import {DimContext} from "../../../App";
import ScrollDiv from "../../../pages/components/scrollDiv";
import {useTranslation} from "react-i18next";
import GaUtility from "../../../gaUtility";

const PatientCtn = styled.div`
  display: flex;
  align-items: flex-start;
  
  ${device.tabletAndMobile}{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    max-width: 592px;
    width: 100%;
  }
  ${device.onlyMobile}{
    gap: 13px;
    max-width: 360px;
  }
`

const PatientCard = styled.div`
  flex: 1;
  margin-right: 24px;

  &:last-child {
    margin-right: 0;
  }
  ${device.tabletAndMobile}{
    margin: 0;
  }
`

const PatientImg = styled.div`
  width: 100%;
  height: ${props => (props.height || 1) + (props.disabled ? 0 : 0)}px;
  background-color: #F6F8FA;
  background-image: url(${props => props.image});
  background-size: cover;
  border-radius: 16px;
  cursor: ${props => props.disabled ? 'normal' : 'pointer'}; 
  border: 8px solid ${props => props.isSelected? '#0969DA' : (props.disabled ? '#F6F8FA' : '#EFEEF1')};
  // border: 8px solid #E4E2E7;
  margin-bottom: 16px;
  display: block;
  background-repeat: no-repeat;

  &:hover {
    border-color: ${props => props.isSelected? '#0969DA' : (props.disabled ? '#F6F8FA' : '#E4E2E7')};
  }

  &:active {
    border-color: ${props => props.isSelected? '#0969DA' :(props.disabled ? '#F6F8FA' : '#0969DA')};
  }
  ${device.onlyMobile}{
    margin-bottom: 8px;
  }
`
const PatientName = styled(TitleD3)`
  display: flex;
  align-items: center;
`
const PatientPhoneName = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.35px;
  color: black;
`

const NameCard = styled.div`
  height: 37px;
  width: 37px;
  margin-left: 16px;
  background-image: url(${NameCardImg});
  background-size: contain;
  ${device.onlyMobile}{
    height: 21px;
    width: 21px;
    margin-left: 8px;
    
  }
`

const TitleVertCtn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;

  ${device.onlyTablet} {
    margin: 36px 0;
  }
  
  ${device.onlyMobile}{
    margin: 24px 0;
  }
`

function PatientSelectionPage() {
    const [device] = useResponsive();
    const dim = useContext(DimContext);
    const [, useRoute] = useGameRouter();
    const [patientList, setList] = useState([]);
    const [width, setWidth] = useState(1);
    const storyModel = new StoryModel();
    const ref = useRef(null)

    const [selectedPatientId, setSelectedPatientId] = useState(null)
    const [selectedPatientName, setSelectedPatientName] = useState(" ");

    const {t} = useTranslation('pregame', {keyPrefix: 'select_patient_page'})
    const {t: tB} = useTranslation('common', {keyPrefix: 'button'})

    useEffect(() => {
        const storyModel = new StoryModel();
        setList(storyModel.getPatientSelectionList());
    }, [])

    useLayoutEffect(() => {
        if (ref.current) {
            setWidth(ref.current.getBoundingClientRect().width);
        }
    })


    if(device.isMobile){
        const Font = device.isTablet ? TitleD3 : TitleD4Bold
        const DescFont = device.isTablet ? TitleHL : BodyB1
        const CardFont = device.isTablet ? PatientName:PatientPhoneName;
        return (
            <ScrollDiv>
                <PaddedPage style={{
                    display: 'flex',
                    backgroundColor: '#E5EBF4',
                    flexDirection: 'column',
                    alignItems: 'center'
                }} ratio={dim.ratio}>
                    <HeaderContainer hideLogo/>
                    <TitleVertCtn>
                        <Font>{t('select_patient')}</Font>
                        <DescFont style={{marginTop: 16}}>{t('select_patient_desc')}</DescFont>
                    </TitleVertCtn>
                    <PatientCtn>
                        {patientList.map((p,i) =>
                            <PatientCard key={p.id} ref={ref} className={selectedPatientId === p.id ? "patient_selected" : ""} name={p.name}>
                                <PatientImg
                                    onClick={() => {
                                        if (!p.isDisabled) {
                                            if(selectedPatientId === p.id){
                                                setSelectedPatientId(null);
                                                setSelectedPatientName(" ");
                                            } else {
                                                setSelectedPatientId(p.id)
                                                setSelectedPatientName(p.name);
                                            }
                                        }
                                    }}
                                    disabled={p.isDisabled} image={p.profilePic} height={width} isSelected={p.id=== selectedPatientId}/>
                                <CardFont>
                                    {p.name}
                                    <NameCard/>
                                </CardFont>
                            </PatientCard>)}
                    </PatientCtn>
                    <BottomContainer
                        backgroundColor='#E5EBF4'
                        Back={<BackButton text={tB('Back')} />}
                        Next={<NextButton id={selectedPatientId ? "patient_select_next" : ""} text={tB('Next')}
                                          name={selectedPatientName}
                                          route={GAME_ROUTE.patientInfo}
                                          isDisabled={!selectedPatientId}
                                          onClick={(callback)=>{
                            if(selectedPatientId){
                                storyModel.patientSelected(selectedPatientId);
                                GaUtility.pushPatient(selectedPatientName)
                                callback && callback()
                            }
                        }}/>}
                    />
                </PaddedPage>
            </ScrollDiv>)
    }

    return (
        <PaddedPage style={{
            display: 'flex',
            backgroundColor: '#E5EBF4',
            flexDirection: 'column',
            justifyContent: 'space-between'
        }} ratio={dim.ratio}>
            <HeaderContainer hideLogo/>
            <TitleVertCtn>
                <TitleD3>{t('select_patient')}</TitleD3>
                <TitleHL style={{marginTop: 16}}>{t('select_patient_desc')}</TitleHL>
            </TitleVertCtn>
            <PatientCtn>
                {patientList.map((p,i) =>
                    <PatientCard key={p.id} ref={ref} >
                        <PatientImg id="patient_ctn"
                                    className="patient_img"
                                    name={p.name}
                            onClick={() => {
                                if (!p.isDisabled) {
                                    storyModel.patientSelected(p.id);
                                    GaUtility.pushPatient(p.name)
                                    useRoute.changeRoute(GAME_ROUTE.patientInfo)
                                }
                            }}
                            disabled={p.isDisabled} image={p.profilePic} height={width}/>
                        <PatientName>
                            {p.name}
                            <NameCard/>
                        </PatientName>
                    </PatientCard>)}

            </PatientCtn>


            <div style={{display: 'flex', justifyContent: 'flex-start', flex: 0}}>
                <BackButton text={tB('Back')} />
            </div>

        </PaddedPage>)
}


export default PatientSelectionPage