import {END_GAME, ImageType, PositionType, SceneType} from "../../../classes/scene";

import ROS01 from "../../../images/patient/ross/ROS-01.png";
import ROS02 from "../../../images/patient/ross/ROS-02.png";
import ROS03 from "../../../images/patient/ross/ROS-03.png";
import ROS04 from "../../../images/patient/ross/ROS-04.png";
import ROS05 from "../../../images/patient/ross/ROS-05.png";
import ROS06 from "../../../images/patient/ross/ROS-06.png";
import ROS07 from "../../../images/patient/ross/ROS-07.png";



import HCP_0_03 from "../../../images/hcp/0/HCP-03.png";
import HCP_1_03 from "../../../images/hcp/1/HCP-03.png";
import HCP_2_03 from "../../../images/hcp/2/HCP-03.png";

import {CHATICON} from "../storyGlobal";

import BG08 from "../../../images/background/BG-08.png";
import BG09 from "../../../images/background/BG-09.png";
import BG10 from "../../../images/background/BG-10.png";
import BG14 from "../../../images/background/BG-14.png";
import BG15 from "../../../images/background/BG-15.png";

import Item03 from "../../../images/background/Item-03.png";
import {END_LEVEL} from "../../../constants";


const BG = {
    '08': {
        type: ImageType.background,
        image: BG08,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },

    '09': {
        type: ImageType.background,
        image: BG09,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },


    '10': {
        type: ImageType.background,
        image: BG10,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    '14': {
        type: ImageType.background,
        image: BG14,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    '15': {
        type: ImageType.background,
        image: BG15,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
}


const ROS = {
    "07": {
        type: ImageType.avatar,
        image: ROS07,
        positionType: PositionType.px,
        position: {
            left: 445,
            top: 377,
            right: 658,
            bottom: -242,
        },
        style: null,
    },
    "07-2": {
        type: ImageType.avatar,
        image: ROS07,
        positionType: PositionType.px,
        position: {
            left: 279,
            top: 388,
            right: 851,
            bottom: -197,
        },
        style: null,
    },
    "01F": {
        type: ImageType.avatar,
        image: ROS01,
        positionType: PositionType.px,
        position: {
            left: 866,
            top: 328,
            right: 219,
            bottom: -230,
            isFlip: true
        },
        style: null,
    },
}

const ITEM = {
    '03': {
        type: ImageType.foreground,
        image: Item03,
        positionType: PositionType.px,
        position: {
            left: 87,
            top: 599,
            right: 733,
            bottom: 41,
        },
        style: null,
    },
    '03-2': {
        type: ImageType.foreground,
        image: Item03,
        positionType: PositionType.px,
        position: {
            left: 94,
            top: 765,
            right: 96,
            bottom: -437,
        },
        style: null,
    },
}




const ROS_CENTER = (img) => {
    return (
        {
            type: ImageType.avatar,
            image: img,
            positionType: PositionType.px,
            position: {
                left: 454,
                top: 211,
                right: 232,
                bottom: -940,
            },
            style: null,
        })
}



const HCP = {
    '03' :{
        type: ImageType.avatar,
        image: [HCP_0_03, HCP_1_03, HCP_2_03],
        positionType: PositionType.px,
        position: {
            left: 333,
            top: 306,
            right: 945,
            bottom: 43,
        },
        style: null,
        pronounButton:{
            top: 225,
            left: 90,
            width: 47,
            // height: 30,
            clippath: null
        }
    },
    '03-2': {
        type: ImageType.avatar,
        image: [HCP_0_03, HCP_1_03, HCP_2_03],
        positionType: PositionType.px,
        position: {
            left: 498.76,
            top: 206,
            right: 501.6,
            bottom: -613,
        },
        style: null,
        pronounButton:{
            top: 502,
            left: 203,
            width: 94,
            // height: 30,
            clippath: null
        }
    },

}

const Pronoun4Scenes = {
    id: '01-04',
    patient: 4,
    scene: [{
        id: '01-04-001-01',
        type: SceneType.description,
        images:[
            BG['09'],
            ROS['07'],

        ],
        bubbleText: null,
        actions: [{
            nextId: '01-04-002-01',
            text: '001-01',
        }],
        hint: null,
        isBeginning: true,
    },{
        id: '01-04-002-01',
        type: SceneType.chat,
        images:[
            BG['10'],
            HCP['03'],
            ITEM['03'],
            ROS['01F'],

        ],
        bubbleText: false,
        actions: [{
            nextId: '01-04-003-01',
            text: '002-01',
            chatIcon: CHATICON.ROS
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-04-003-01',
        type: SceneType.selection,
        images:[
            BG['14'],
            HCP['03-2'],
            ITEM['03-2'],

        ],
        bubbleText: false,
        actions: [{
            nextId: '01-04-004-01',
            text: (t,args) => t(`i01-q01`, {name: args.name, pronoun: args.pronoun}),
            opt: 1,
            score: 3,
        }, {
            nextId: '01-04-004-02',
            text: (t,args) => t(`i01-q02`, {name: args.name, pronoun: args.pronoun}),
            opt: 2,
            score: 2,
        }, {
            nextId: '01-04-004-03',
            text: (t,args) => t(`i01-q03`, {name: args.name, pronoun: args.pronoun}),
            opt: 3,
            score: 1,
        }, {
            nextId: '01-04-004-04',
            text: (t,args) => t(`i01-q04`, {name: args.name, pronoun: args.pronoun}),
            opt: 4,
            score: 0,
        }],
        hint: 'i01-question',
        isBeginning: false,
    },{
        id: '01-04-004-01',
        type: SceneType.chat,
        images:[
            BG['15'],
            ROS_CENTER(ROS01)

        ],
        bubbleText: false,
        actions: [{
            nextId: '01-04-005-01',
            text: "i01-a01",
            chatIcon: CHATICON.ROS
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-04-004-02',
        type: SceneType.chat,
        images:[
            BG['15'],
            ROS_CENTER(ROS03)

        ],
        bubbleText: false,
        actions: [{
            nextId: '01-04-005-01',
            text: "i01-a02",
            chatIcon: CHATICON.ROS
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-04-004-03',
        type: SceneType.chat,
        images:[
            BG['15'],
            ROS_CENTER(ROS04)

        ],
        bubbleText: false,
        actions: [{
            nextId: '01-04-005-01',
            text: "i01-a03",
            chatIcon: CHATICON.ROS
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-04-004-04',
        type: SceneType.chat,
        images:[
            BG['15'],
            ROS_CENTER(ROS04)

        ],
        bubbleText: false,
        actions: [{
            nextId: '01-04-005-01',
            text: "i01-a04",
            chatIcon: CHATICON.ROS
        }],
        hint: null,
        isBeginning: false,
    },


        {
            id: '01-04-005-01',
            type: SceneType.selection,
            images:[
                BG['14'],
                HCP['03-2'],
                ITEM['03-2'],

            ],
            bubbleText: false,
            actions: [{
                nextId: '01-04-006-01',
                text: 'i02-q01',
                opt: 1,
                score: 3,
            }, {
                nextId: '01-04-006-02',
                text: 'i02-q02',
                opt: 2,
                score: 2,
            }, {
                nextId: '01-04-006-03',
                text: 'i02-q03',
                opt: 3,
                score: 1,
            }, {
                nextId: '01-04-006-04',
                text: 'i02-q04',
                opt: 4,
                score: 0,
            }],
            hint: 'i02-question',
            isBeginning: false,
        },{
            id: '01-04-006-01',
            type: SceneType.chat,
            images:[
                BG['15'],
                ROS_CENTER(ROS02)

            ],
            bubbleText: false,
            actions: [{
                nextId: '01-04-007-01',
                text: "i02-a01",
                chatIcon: CHATICON.ROS
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '01-04-006-02',
            type: SceneType.chat,
            images:[
                BG['15'],
                ROS_CENTER(ROS03)

            ],
            bubbleText: false,
            actions: [{
                nextId: '01-04-007-01',
                text: "i02-a02",
                chatIcon: CHATICON.ROS
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '01-04-006-03',
            type: SceneType.chat,
            images:[
                BG['15'],
                ROS_CENTER(ROS04)

            ],
            bubbleText: false,
            actions: [{
                nextId: '01-04-007-01',
                text: "i02-a03",
                chatIcon: CHATICON.ROS
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '01-04-006-04',
            type: SceneType.chat,
            images:[
                BG['15'],
                ROS_CENTER(ROS05)

            ],
            bubbleText: false,
            actions: [{
                nextId: '01-04-007-01',
                text: "i02-a04",
                chatIcon: CHATICON.ROS
            }],
            hint: null,
            isBeginning: false,
        },


        {
            id: '01-04-007-01',
            type: SceneType.selection,
            images:[
                BG['14'],
                HCP['03-2'],
                ITEM['03-2'],

            ],
            bubbleText: false,
            actions: [{
                nextId: '01-04-008-01',
                text: 'i03-q01',
                opt: 1,
                score: 3,
            }, {
                nextId: '01-04-008-02',
                text: 'i03-q02',
                opt: 2,
                score: 2,
            }, {
                nextId: '01-04-008-03',
                text: 'i03-q03',
                opt: 3,
                score: 1,
            }, {
                nextId: '01-04-008-04',
                text: 'i03-q04',
                opt: 4,
                score: 0,
            }],
            hint: 'i03-question',
            isBeginning: false,
        },{
            id: '01-04-008-01',
            type: SceneType.chat,
            images:[
                BG['15'],
                ROS_CENTER(ROS02)

            ],
            bubbleText: false,
            actions: [{
                nextId: END_GAME,
                text: "i03-a01",
                chatIcon: CHATICON.ROS,
                gameEndLevel: END_LEVEL.GREAT
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '01-04-008-02',
            type: SceneType.chat,
            images:[
                BG['15'],
                ROS_CENTER(ROS06)

            ],
            bubbleText: false,
            actions: [{
                nextId: '01-04-009-01',
                text: "i03-a02",
                chatIcon: CHATICON.ROS
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '01-04-008-03',
            type: SceneType.chat,
            images:[
                BG['15'],
                ROS_CENTER(ROS06)

            ],
            bubbleText: false,
            actions: [{
                nextId: '01-04-009-01',
                text: "i03-a03",
                chatIcon: CHATICON.ROS
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '01-04-008-04',
            type: SceneType.chat,
            images:[
                BG['15'],
                ROS_CENTER(ROS05)

            ],
            bubbleText: false,
            actions: [{
                nextId: '01-04-009-01',
                text: "i03-a04",
                chatIcon: CHATICON.ROS
            }],
            hint: null,
            isBeginning: false,
        },


        {
            id: '01-04-009-01',
            type: SceneType.selection,
            images:[
                BG['14'],
                HCP['03-2'],
                ITEM['03-2'],

            ],
            bubbleText: false,
            actions: [{
                nextId: '01-04-010-01',
                text: 'i04-q01',
                opt: 1,
                score: 3,
            }, {
                nextId: '01-04-010-02',
                text: 'i04-q02',
                opt: 2,
                score: 2,
            }, {
                nextId: '01-04-010-03',
                text: 'i04-q03',
                opt: 3,
                score: 1,
            }, {
                nextId: '01-04-010-04',
                text: 'i04-q04',
                opt: 4,
                score: 0,
            }],
            hint: 'i04-question',
            isBeginning: false,
        },{
            id: '01-04-010-01',
            type: SceneType.chat,
            images:[
                BG['15'],
                ROS_CENTER(ROS01)

            ],
            bubbleText: false,
            actions: [{
                nextId: END_GAME,
                text: "i04-a01",
                chatIcon: CHATICON.ROS
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '01-04-010-02',
            type: SceneType.chat,
            images:[
                BG['15'],
                ROS_CENTER(ROS03)

            ],
            bubbleText: false,
            actions: [{
                nextId: END_GAME,
                text: "i04-a02",
                chatIcon: CHATICON.ROS
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '01-04-010-03',
            type: SceneType.chat,
            images:[
                BG['15'],
                ROS_CENTER(ROS06)

            ],
            bubbleText: false,
            actions: [{
                nextId: END_GAME,
                text: "i04-a03",
                chatIcon: CHATICON.ROS
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '01-04-010-04',
            type: SceneType.chat,
            images:[
                BG['15'],
                ROS_CENTER(ROS06)

            ],
            bubbleText: false,
            actions: [{
                nextId: '01-04-010-04-02',
                text: "i04-a04",
                chatIcon: CHATICON.ROS
            }],
            hint: null,
            isBeginning: false,
        },{
            id: '01-04-010-04-02',
            type: SceneType.description,
            images:[
                BG['08'],
                ROS["07-2"]

            ],
            bubbleText: false,
            actions: [{
                nextId: END_GAME,
                text: "i04-a04-02",
                gameEndLevel: END_LEVEL.WORSE
            }],
            hint: null,
            isBeginning: false,
        }


    ]
}


export default Pronoun4Scenes