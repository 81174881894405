import {END_GAME, ImageType, PositionType, SceneType} from "../../../classes/scene";

import BG09 from "../../../images/background/BG-09.png";
import BG07 from "../../../images/background/BG-07.png";
import BG10 from "../../../images/background/BG-10.png";
import BG14 from "../../../images/background/BG-14.png";
import BG15 from "../../../images/background/BG-15.png";

import CTN01smile from "../../../images/patient/chetan/CTN-01-smile.png";
import CTN11normal from "../../../images/patient/chetan/CTN-11-normal.png";
import CTN03annoy from "../../../images/patient/chetan/CTN-03-annoyed.png";
import CTN04angry from "../../../images/patient/chetan/CTN-04-angry.png";
import CTN06 from "../../../images/patient/chetan/CTN-06.png";

import Item19 from "../../../images/background/Item-19.png";
import Item16 from "../../../images/background/Item-16.png";
import Item03 from "../../../images/background/Item-03.png";

import HCP_0_03 from "../../../images/hcp/0/HCP-03.png";
import HCP_1_03 from "../../../images/hcp/1/HCP-03.png";
import HCP_2_03 from "../../../images/hcp/2/HCP-03.png";

import CTN12unsatisfied from "../../../images/patient/chetan/CTN-12-unsatisfied.png";
import {END_LEVEL} from "../../../constants";
import {CHATICON} from "../storyGlobal";

/*
const POSITION = {
    searchHCP: {
        left: 550.31,
        top: 207,
        right: 262.77,
        bottom: -765,
    },
}
*/

const BG = {
    "07" :{
        type: ImageType.background,
        image: BG07,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    "09" :{
        type: ImageType.background,
        image: BG09,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    "10" :{
        type: ImageType.background,
        image: BG10,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    "14" :{
        type: ImageType.background,
        image: BG14,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    "15" :{
        type: ImageType.background,
        image: BG15,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
}


const HCP = {
    '03-2': {
        type: ImageType.avatar,
        image: [HCP_0_03, HCP_1_03, HCP_2_03],
        positionType: PositionType.px,
        position: {
            left: 498.76,
            top: 206,
            right: 501.6,
            bottom: -613,
        },
        style: null,
        pronounButton:{
            top: 502,
            left: 203,
            width: 94,
            // height: 30,
            clippath: null
        }
    },
    '03': {
        type: ImageType.avatar,
        image: [HCP_0_03, HCP_1_03, HCP_2_03],
        positionType: PositionType.px,
        position: {
            left: 333,
            top: 306,
            right: 945,
            bottom: 43,
        },
        style: null,
        pronounButton:{
            top: 228,
            left: 92,
            width: 44,
            // height: 30,
            clippath: null
        }
    },
}


const CTN = {
    "06": {
        type: ImageType.avatar,
        image: CTN06,
        positionType: PositionType.px,
        position: {
            left: 583,
            top: 414,
            right: 666,
            bottom: -142,
        },
        style: null,
    },
    "06-2": {
        type: ImageType.avatar,
        image: CTN06,
        positionType: PositionType.px,
        position: {
            left: 194,
            top: 311,
            right: 986,
            bottom: -227,
        },
        style: null,
    },
    "01": {
        type: ImageType.avatar,
        image: CTN01smile,
        positionType: PositionType.px,
        position: {
            left: 939,
            top: 347,
            right: 288,
            bottom: -133,
        },
        style: null,
    }
}

const CTN_CENTER = (img) => {
    return (
        {
            type: ImageType.avatar,
            image: img,
            positionType: PositionType.px,
            position: {
                left: 475,
                top: 212,
                right: 475,
                bottom: -749.65,
            },
            style: null,
        })

}

const ITEM = {
    '03': {
        type: ImageType.foreground,
        image: Item03,
        positionType: PositionType.px,
        position: {
            left: 87,
            top: 599,
            right: 733,
            bottom: 41,
        },
        style: null,
    },
    '03-2': {
        type: ImageType.foreground,
        image: Item03,
        positionType: PositionType.px,
        position: {
            left: 94,
            top: 765,
            right: 96,
            bottom: -437,
        },
        style: null,
    },
    '16': {
        type: ImageType.foreground,
        image: Item16,
        positionType: PositionType.px,
        position: {
            left: -342,
            top: 343,
            right: -61,
            bottom: -678,
        },
        style: null,
    },
    '19': {
        type: ImageType.foreground,
        image: Item19,
        positionType: PositionType.px,
        position: {
            left: -282,
            top: 345,
            right: -261,
            bottom: -435,
        },
        style: null,
    },
}

const Relationship1Scenes = {
    id: '04-01',
    patient: 1,
    scene: [{
        id: '04-01-000-01',
        type: SceneType.description,
        images: [
            BG["09"],
            CTN["06"],
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-01-001-01',
            text: '000-01'
        }],
        hint: null,
        isBeginning: true,
    },{
        id: '04-01-001-01',
        type: SceneType.chat,
        images: [
            BG["10"],
            HCP["03"],
            CTN["01"],
            ITEM["03"],
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-01-002-01',
            text: '001-01',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '04-01-002-01',
        type: SceneType.selection,
        images: [
            BG["14"],
            HCP["03-2"],

            ITEM["03-2"],
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-01-003-01',
            text: 'i01-q01',
            opt: 1,
            score: 3,
        }, {
            nextId: '04-01-003-02',
            text: 'i01-q02',
            opt: 2,
            score: 2,
        }, {
            nextId: '04-01-003-03',
            text: 'i01-q03',
            opt: 3,
            score: 1,
        }, {
            nextId: '04-01-003-04',
            text: 'i01-q04',
            opt: 4,
            score: 0,
        }],
        hint: 'i01-question',
        isBeginning: false,
    },{
        id: '04-01-003-01',
        type: SceneType.chat,
        images: [
            BG["15"],
            CTN_CENTER(CTN01smile),
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-01-004-01',
            text: 'i01-a01',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '04-01-003-02',
        type: SceneType.chat,
        images: [
            BG["15"],
            CTN_CENTER(CTN11normal),
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-01-004-01',
            text: 'i01-a02',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '04-01-003-03',
        type: SceneType.chat,
        images: [
            BG["15"],
            CTN_CENTER(CTN03annoy),
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-01-004-01',
            text: 'i01-a03',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '04-01-003-04',
        type: SceneType.chat,
        images: [
            BG["15"],
            CTN_CENTER(CTN04angry),
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-01-004-01',
            text: 'i01-a04',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '04-01-004-01',
        type: SceneType.selection,
        images: [
            BG["14"],
            HCP["03-2"],

            ITEM["03-2"],
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-01-005-01',
            text: 'i02-q01',
            opt: 1,
            score: 3,
        }, {
            nextId: '04-01-005-02',
            text: 'i02-q02',
            opt: 2,
            score: 2,
        }, {
            nextId: '04-01-005-03',
            text: 'i02-q03',
            opt: 3,
            score: 1,
        }, {
            nextId: '04-01-005-04',
            text: 'i02-q04',
            opt: 4,
            score: 0,
        }],
        hint: 'i02-question',
        isBeginning: false,
    },{
        id: '04-01-005-01',
        type: SceneType.chat,
        images: [
            BG["15"],
            CTN_CENTER(CTN01smile),
        ],
        bubbleText: null,
        actions: [{
            nextId: END_GAME,
            text: 'i02-a01',
            chatIcon: CHATICON.CTN,
            gameEndLevel: END_LEVEL.GREAT,
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '04-01-005-02',
        type: SceneType.chat,
        images: [
            BG["15"],
            CTN_CENTER(CTN11normal),
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-01-006-01',
            text: 'i02-a02',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '04-01-005-03',
        type: SceneType.chat,
        images: [
            BG["15"],
            CTN_CENTER(CTN03annoy),
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-01-006-01',
            text: 'i02-a03',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '04-01-005-04',
        type: SceneType.chat,
        images: [
            BG["15"],
            CTN_CENTER(CTN04angry),
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-01-006-01',
            text: 'i02-a04',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '04-01-006-01',
        type: SceneType.selection,
        images: [
            BG["14"],
            HCP["03-2"],

            ITEM["03-2"],
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-01-007-01',
            text: 'i03-q01',
            opt: 1,
            score: 3,
        }, {
            nextId: '04-01-007-02',
            text: 'i03-q02',
            opt: 2,
            score: 2,
        }, {
            nextId: '04-01-007-03',
            text: 'i03-q03',
            opt: 3,
            score: 1,
        }, {
            nextId: '04-01-007-04',
            text: 'i03-q04',
            opt: 4,
            score: 0,
        }],
        hint: 'i03-question',
        isBeginning: false,
    },{
        id: '04-01-007-01',
        type: SceneType.chat,
        images: [
            BG["15"],
            CTN_CENTER(CTN01smile),
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-01-008-01',
            text: 'i03-a01',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '04-01-007-02',
        type: SceneType.chat,
        images: [
            BG["15"],
            CTN_CENTER(CTN11normal),
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-01-008-01',
            text: 'i03-a02',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '04-01-007-03',
        type: SceneType.chat,
        images: [
            BG["15"],
            CTN_CENTER(CTN12unsatisfied),
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-01-008-01',
            text: 'i03-a03',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '04-01-007-04',
        type: SceneType.chat,
        images: [
            BG["15"],
            CTN_CENTER(CTN04angry),
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-01-007-04-02',
            text: 'i03-a04',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '04-01-007-04-02',
        type: SceneType.cutscene,
        images: [
            BG["15"],
            CTN_CENTER(CTN04angry),
        ],
        bubbleText: [{ text:'i03-a04-b' }],
        actions: [{
            nextId: '04-01-008-01',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '04-01-008-01',
        type: SceneType.selection,
        images: [
            BG["14"],
            HCP["03-2"],

            ITEM["03-2"],
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-01-009-01',
            text: 'i04-q01',
            opt: 1,
            score: 3,
        }, {
            nextId: '04-01-009-02',
            text: 'i04-q02',
            opt: 2,
            score: 2,
        }, {
            nextId: '04-01-009-03',
            text: 'i04-q03',
            opt: 3,
            score: 1,
        }, {
            nextId: '04-01-009-04',
            text: 'i04-q04',
            opt: 4,
            score: 0,
        }],
        hint: 'i04-question',
        isBeginning: false,
    },{
        id: '04-01-009-01',
        type: SceneType.chat,
        images: [
            BG["15"],
            CTN_CENTER(CTN01smile),
        ],
        bubbleText: null,
        actions: [{
            nextId: END_GAME,
            text: 'i04-a01',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '04-01-009-02',
        type: SceneType.chat,
        images: [
            BG["15"],
            CTN_CENTER(CTN11normal),
        ],
        bubbleText: null,
        actions: [{
            nextId: END_GAME,
            text: 'i04-a02',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '04-01-009-03',
        type: SceneType.chat,
        images: [
            BG["15"],
            CTN_CENTER(CTN12unsatisfied),
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-01-009-03-02',
            text: 'i04-a03',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    }, {
        id: '04-01-009-04',
        type: SceneType.chat,
        images: [
            BG["15"],
            CTN_CENTER(CTN04angry),
        ],
        bubbleText: null,
        actions: [{
            nextId: '04-01-009-04-02',
            text: 'i04-a04',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '04-01-009-03-02',
        type: SceneType.cutscene,
        images: [
            BG["15"],
            CTN_CENTER(CTN04angry),
        ],
        bubbleText: [{text: 'i04-a03-b'}],
        actions: [{
            nextId: END_GAME,
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '04-01-009-04-02',
        type: SceneType.cutscene,
        images: [
            BG["07"],
            CTN["06-2"]
        ],
        bubbleText: null,
        actions: [{
            nextId: END_GAME,
            text: 'i04-a04-02',
            chatIcon: CHATICON.CTN
        }],
        hint: null,
        isBeginning: false,
    },

    ]
};

export default Relationship1Scenes