import {device} from "../../breakpoints";
import {useContext} from "react";
import styled from "styled-components";
import {DimContext} from "../../App";


const BottomCtn = styled.div`
  position: fixed;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // box-sizing: border-box;
  bottom: 0;
  left: 0;
  right: 0;

  height: 96px;
  
  padding: 24px 44px 24px 24px;
  background-image: ${props=> props.backgroundColor? `linear-gradient(transparent, ${props.backgroundColor})`: 'transparent'};
  
  ${device.onlyMobile}{
    padding: 16px 36px 16px 16px;
    height: 80px;
  }
`

const BottomBlock = styled.div`
  position: relative;
  height: 72px;
  width: 100%;
`

function BottomContainer({Back, Next, backgroundColor}){
    const dim = useContext(DimContext);
    return <>
            <BottomCtn  ratio={dim.ratio} backgroundColor={backgroundColor}>
                {Back || <div/>}
                <div style={{width: 16}}/>
                {Next || <div/>}
            </BottomCtn>
            <BottomBlock/>
        </>
}

export default BottomContainer