import styled, {css} from "styled-components";
import {ImageType, PositionType} from "../../classes/scene";
import {useContext} from "react";
import {DimContext} from "../../../App";


const STANDARD_FONT = {
    width: 44,
    heightRatio: 30 / 44,
    fontSize: 12,
    letterSpacing: -1.2,
}

function generatePronounButton(position, content) {
    if (!position || !content) return '';
    const height = position.width * STANDARD_FONT.heightRatio;
    const fontSize = STANDARD_FONT.fontSize * position.width / STANDARD_FONT.width
    const letterSpacing = STANDARD_FONT.letterSpacing * position.width / STANDARD_FONT.width
    return `&:after {
    content:"${content}";
    position: absolute;
    font-size: ${fontSize}px;
    line-height: ${fontSize}px;
    letter-spacing: ${letterSpacing}px;
    text-align: center;
    top: ${position.top}px;
    left: ${position.left}px;
    width: ${position.width}px;
    height: ${height}px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${position.clippath ? `clip-path:${position.clippath};` : ''}
    }
    `
}

const StyledImage = styled.div`
  ${(props) => {
    let cssRet = `
      position: absolute;
      background: url(${props.image}) no-repeat;
      background-size: contain;
      `;

    switch (props.positionType) {
      case PositionType.fullscreen:
        cssRet += `
          top:  ${props.position?.top !== undefined ? `${props.position.top}px` : 0};
          left: ${props.position?.left !== undefined ? `${props.position.left}px` : 0};
          right: ${props.position?.right !== undefined ? `${props.position.right}px` : 0};
          bottom: ${props.position?.bottom !== undefined ? `${props.position.bottom}px` : 0};
        `;
        break;
      case PositionType.px:
      default:
        cssRet += `
          ${props.position?.top !== undefined ? `top:${props.position.top}px;` : ''}
          ${props.position?.bottom !== undefined ? `bottom:${props.position.bottom}px;` : ''}
          ${props.position?.left !== undefined ? `left:${props.position.left}px;` : ''}
          ${props.position?.right !== undefined ? `right:${props.position.right}px;` : ''}
          ${props.position?.height !== undefined ? `height:${props.position.height}px;` : ''}
          ${props.position?.width !== undefined ? `width:${props.position.width}px;` : ''}
        `;
        break;

    }
    if(props.position?.zIndex){
        cssRet += `z-index: ${props.position.zIndex};`
    } else {
      switch (props.type) {
        case ImageType.background:
          cssRet += `
          z-index: 5;
      background-size: cover;
        `;
          break;
        case ImageType.avatar:
          cssRet += `
          z-index: 10;
        `;
          break;
        case ImageType.foreground:
        default:
          cssRet += `
          z-index: 15;
        `;
          break;
      }
        
    }
    if(props.position?.isFlip){
      cssRet += `-webkit-transform: scaleX(-1); transform: scaleX(-1);`
    }
    
    if (props.pronounButton) {
      cssRet += generatePronounButton(props.pronounButton, props.pronounButtonContent)
    }

    return css`${cssRet}`
  }}
`

function scalePosition(pos, dim) {
    const ret = {...pos}
    !isNaN(ret.top) && (ret.top *= dim.ratio);
    !isNaN(ret.bottom) && (ret.bottom *= dim.ratio);
    !isNaN(ret.left) && (ret.left *= dim.ratio);
    !isNaN(ret.right) && (ret.right *= dim.ratio);

    !isNaN(ret.height) && (ret.height *= dim.ratio);
    !isNaN(ret.width) && (ret.width *= dim.ratio);
    return ret;
}

function scalePronounButton(pos, dim) {
    if (!pos) return null
    const ret = {...pos};
    ret.top *= dim.ratio;
    ret.left *= dim.ratio;
    ret.width *= dim.ratio;
    return ret;
}


function Image({image, avatarId, pronounButtonContent}) {
    const dim = useContext(DimContext);
    const position = scalePosition(image.position, dim)
    const pronounButton = scalePronounButton(image.pronounButton, dim)
    return <StyledImage image={image.isRole ? image.image[avatarId] : image.image}
                        type={image.type}
                        style={image.style}
                        position={position}
                        positionType={image.positionType}
                        pronounButton={pronounButton}
                        pronounButtonContent={pronounButtonContent}/>

}

export default Image;