import styled from "styled-components";
import {TitleBtn} from "../../typography";
import StartButtonImg from "../images/icon/start-button.png";
import StartButtonImgH from "../images/icon/start-button-hover.png";
import {device} from "../../breakpoints";
import {useNavigate} from "react-router";
import {ROUTE} from "../../App";
import {useTranslation} from "react-i18next";

const Start = styled(TitleBtn).attrs({as: "button"})`
  width: 158.71px;
  height: 48px;
  background-image: url(${StartButtonImg});
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;

  &:hover {
    background-image: url(${StartButtonImgH});

  }

  ${device.onlyMobile} {
    ${props => props.isNav && 'display: none;'}
  }
`

export function StartButton({style, isNav}) {

    const {t} = useTranslation('common', {keyPrefix: 'button'})
    const navigate = useNavigate()
    return <Start id={isNav ? 'nav-start' : 'front-start'}
                  style={style}
                  isNav={isNav}
                  onClick={(e) => {
                      navigate(ROUTE.GAME)
                  }}>{t('Get Started')}</Start>
}