import {breakpoints} from "../breakpoints";

function useResponsive(){
    let width = window.innerWidth;
    const device ={
        isPhone: width < breakpoints.small,
        isTablet: (width >= breakpoints.small && width < breakpoints.medium),
        isMobile: width < breakpoints.medium,
        isDesktop:  width >= breakpoints.medium
    }
    return [device]
}

export default useResponsive;