export const breakpoints = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',

    small: 768,
    medium: 1200,
}

export const device = {
    mobileS: `(min-width: ${breakpoints.mobileS})`,
    mobileM: `(min-width: ${breakpoints.mobileM})`,
    mobileL: `(min-width: ${breakpoints.mobileL})`,
    tablet: `(min-width: ${breakpoints.tablet})`,
    laptop: `(min-width: ${breakpoints.laptop})`,
    laptopL: `(min-width: ${breakpoints.laptopL})`,
    desktop: `(min-width: ${breakpoints.desktop})`,
    desktopL: `(min-width: ${breakpoints.desktop})`,

    small:`(max-width: ${breakpoints.small-1}px)`,
    medium:`(max-width: ${breakpoints.medium-1}px)`,

    onlyMobile: `@media only screen and (max-width: ${breakpoints.small-1}px)`,
    onlyTablet: `@media only screen and (max-width: ${breakpoints.medium-1}px) and (min-width: ${breakpoints.small}px)`,
    tabletAndMobile: `@media only screen and (max-width: ${breakpoints.medium-1}px)`,
};
export const deviceMin = {
    mobileS: `(min-width: ${breakpoints.mobileS})`,
    mobileM: `(min-width: ${breakpoints.mobileM})`,
    mobileL: `(min-width: ${breakpoints.mobileL})`,
    tablet: `(min-width: ${breakpoints.tablet})`,
    laptop: `(min-width: ${breakpoints.laptop})`,
    laptopL: `(min-width: ${breakpoints.laptopL})`,
    desktop: `(min-width: ${breakpoints.desktop})`,
    desktopL: `(min-width: ${breakpoints.desktop})`,

    small:`(min-width: ${breakpoints.small}px)`,
    medium:`(min-width: ${breakpoints.medium}px)`,

    onlyDesktop: `@media only screen and (min-width: ${breakpoints.medium}px)`
};