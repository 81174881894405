export const END_LEVEL = {
    GREAT: 0,
    GOOD: 1,
    BAD: 2,
    WORSE: 3,
}
export const DIMENSION = {
    width: 1512,
    height: 982,
    gameWidth: 1512,
    hRatio:1,
    vRatio:1,
}