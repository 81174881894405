import styled from "styled-components";
import {TitleD4, TitleSH1} from "../../typography";
import {AffirmButton, DenyButton} from "./buttons";


const BackDrop = styled.div`
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(36, 41, 47, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalBase = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  margin: 16px;
  text-align: left;
  background-color: white;
  border-radius: 16px;
`

const ButtonCtn = styled.div`
  display: flex;
  
  &:not(:last-child) { //NOT WORKING
    margin-right: 8px;
  }
  
`

function Modal({pos, posAction, neg, negAction, title, desc, isOpen}){
    if(!isOpen){
        return null;
    }
    return (
        <BackDrop>
            <ModalBase>
                <TitleD4>{title}</TitleD4>
                <TitleSH1 style={{margin: '16px 0 24px'}}>{desc}</TitleSH1>
                <ButtonCtn>
                    {pos && posAction && <AffirmButton text={pos} onClick={posAction} style={{marginRight:8}}/>}
                    {neg && negAction && <DenyButton text={neg} onClick={negAction}/>}
                </ButtonCtn>
            </ModalBase>
        </BackDrop>
    )
}

export default Modal;