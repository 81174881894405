import Modal from "../../pages/components/modal";
import StoryModel from "../classes/storyModel";
import {ROUTE} from "../../App";
import {useState} from "react";
import {useNavigate} from "react-router";
import useGameRouter from "../hooks/useGameRouter";
import {useTranslation} from "react-i18next";

function ExitModal({isOpen, onCancel}){

    const [, useRoute] = useGameRouter();
    const navigate = useNavigate()
    const {t} = useTranslation('common', {keyPrefix: "closeModal"})
    const exitGame = () => {
        const storyModel = new StoryModel();
        storyModel.reset();
        useRoute.reset();
        navigate(ROUTE.FRONTPAGE, {replace: true})
        //clear data, navigate in callback
    }

    return <Modal
        isOpen={isOpen}
        title={t('title')}
        desc={t('desc')}
        pos={t('confirm')}
        neg={t('cancel')}
        posAction={exitGame}
        negAction={onCancel}
    />

}

export default ExitModal