
import useResponsive from "../hook/useResponsive";
import {useTranslation} from "react-i18next";

function Contact(){
    const [device] = useResponsive();
    const {t} = useTranslation('common')
    return <></>

}

export default Contact