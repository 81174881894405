import styled from "styled-components";

const InputCtn = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`
const InputField = styled.input`
  width: 100%;
  height: ${props=> props.rows > 1 ? 'auto': '48px'};
  margin: 8px 0;
  border-radius: 8px;
  color: #2E2D2E;
  border: 1.5px solid ${props => props.isError ? 'red' : '#807F7F'};
  padding: ${props=> props.rows > 1 ? '16px': 0} 16px;
  font-size: 16px;
  line-height: 19.36px;
  font-weight: 400;
`
export const InputSubMsg = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
  letter-spacing: -0.8px;
  color: #5B5A5C;
`
const WordLimit = styled.span`
  text-align: right;
  float: right;
  color: ${props => props.isAtLimit ? 'red' : 'inherit'};
`
export const ErrorMsg = styled.span`
  color: ${props => props.isError ? 'red' :  'inherit'};
`

export function Input({value, onChange, limit, error, width, type, style, isTextArea, rows}) {
    return (
        <InputCtn style={{width: width || '100%', ...style}}>
            <InputField style={{width: width || '100%', maxWidth: width || '100%'}}
                        isError={!!error}
                        value={value}
                        as={isTextArea? 'textarea' : 'input'}
                        rows={rows || 1}
                        type={type || 'text'}
                        onChange={(e) => {
                            let res = e.target.value;
                            if (!limit || res.length <= limit) {
                                onChange(res);
                            } else {
                                res = res.substr(0, limit)
                                onChange(res)
                            }
                        }
                        }/>
            {(limit || error) && <InputSubMsg>
                {error && <ErrorMsg isError={!!error}>{error}</ErrorMsg>}
                {limit && <WordLimit isAtLimit={value.length === limit}>{`${value.length}/${limit}`}</WordLimit>}
            </InputSubMsg>}

        </InputCtn>)
}