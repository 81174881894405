import styled from "styled-components";


const VertCtn = styled.div`
    display: flex;
    flex-direction: column;
`

const VertScroller = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 0;
`

export {VertCtn, VertScroller}