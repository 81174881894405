import {useState} from "react";
import {ContentBase} from "../components/surveyModal";
import {Input} from "../../pages/components/input";
import Navigation from "../components/navigation";
import PageFooter from "../components/pageFooter";
import {SurveyTitle} from "../survey";

export function InputPage({onBack, onNext, page, defaultAns, numOfQuestion}) {
    const [ans, setAns] = useState(defaultAns);
    //https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/50387233#50387233
    //ResizeObserver loop completed with undelivered notifications. <- safely ignored
    return <>
        <ContentBase>
            <SurveyTitle>{page.question}</SurveyTitle>
            <Input style={{width: 'unset', flex: 1}} onChange={(val) => setAns([val])} value={ans[0]}
                   isTextArea={page.textBox.multiLine} rows={page.textBox.multiLine}
                   limit={page.textBox.limit}/>

            <Navigation onBack={onBack} onNext={() => onNext(ans, page.pageNum, page.questionNum)}
                        isNextDisabled={!ans[0]}
                        isDone={page.isDone}/>
        </ContentBase>
        <PageFooter text={`Question ${page.questionNum} of ${numOfQuestion}`}/>
    </>

}