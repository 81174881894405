import {PageBase} from "./frontPage";
import ScrollDiv from "./components/scrollDiv";
import {Trans, useTranslation} from "react-i18next";
import styled from "styled-components";
import {PageHeader} from "./knowledgeHub";
import {BodyB1, BodyB2, TitleSH1} from "../typography";
import Footer from "./fragments/footer";
import {device} from "../breakpoints";

const AboutUsPageBase = styled(PageBase)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AboutUsContentCtn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px 0;
  flex: 1;
  max-width: 700px;
  
  ${device.tabletAndMobile}{
    max-width: 100vw;
    padding: 36px 24px;
  }
  ${device.onlyMobile}{
    padding: 36px 16px;
  }
`
const Intro = styled(TitleSH1)`
  white-space: pre-wrap;
  color: #5B5A5C
`

const Link = styled.a`
  cursor: pointer;
  color: #0969DA;
  text-decoration-line: none;
`

const Section = styled.div`
  padding: 16px 0;
  width: 100%;
  box-shadow: 0 1px #E4E2E7;
`

const SectionTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: -0.6px;
  text-align: left;
  margin-bottom: 6px;
`
const SectionSubTitle = styled(TitleSH1)`
  text-align: left;
  margin-bottom: 6px;
`

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 80px;

`

const Group = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
`

const GroupTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.6px;
  text-align: center;
  white-space: pre-wrap;
`

const GroupItem = styled(BodyB1)`
  text-align: center;
  margin-top: 16px;

  &:first-child {
    margin-bottom: 16px;
  }
`

const GroupItemShort = styled(GroupItem)`
  margin-top: 8px;
`

const GroupItemEngCtn = styled.div`
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 350px;
  width: 350px;
`

const GroupType = {
    regular: 'regular',
    small: 'small',
    engineer: 'engineer',
}

const GroupList = [
    {
        title: 'Senior Project Lead & Principal Editor',
        member: ['Christian Schulz-Quach'],
        type: GroupType.regular
    }, {
        title: 'Section Editors',
        member: ['Marie-Anne Archambault-Grenier', 'Margo Kennedy', 'Brendan Lyver'],
        type: GroupType.regular
    }, {
        title: 'Content Authors',
        member: ['Jennifer Croke', 'Margo Kennedy', 'Brendan Lyver', 'Samantha Scime', 'Christian Schulz-Quach', 'Gilla Shapiro'],
        type: GroupType.regular
    }, {
        title: 'Our Patient Partners',
        member: ['Ross Harrison', 'Catherine Jones', 'Kimiko Tobimatsu'],
        type: GroupType.small
    }, {
        title: 'Topic Consultants',
        member: ['Tori Anderson', 'Tristan Bilash', 'Shawn Hercules', 'Lauren Squires'],
        type: GroupType.small
    }, {
        title: 'Princess Margaret Cancer Education Specialist',
        member: ['Erin Walker'],
        type: GroupType.small
    }, {
        title: 'UHN e-Learning Specialist',
        member: ['Magali Julien'],
        type: GroupType.small
    }, {
        title: 'OCAD Designers',
        member: ['Jessy Zhang', 'Elli Huang'],
        type: GroupType.small
    }, {
        title: 'Product and Engineers',
        member: [['Yichi Chen', 'Product Manager'], ['Gordon Liu', 'Consultant'], ['Yen-Ting(Mark) Chen', 'Full-Stack Engineer'], ['Noa Kim', 'Product Designer'], ['Vicki Lo', 'Product Designer']],
        type: GroupType.engineer
    }
]

const FundList = [
    {
        title: 'Princess Margaret Cancer Centre Young Leaders Program Grant & UHN Cancer Experience Seed Fund Grant',
        member: [],
        type: GroupType.regular
    },
    {
        title: 'Principal Investigators',
        member: ['Jennifer Croke', 'Christian Schulz-Quach', 'Samantha Scime', 'Gilla Shapiro'],
        type: GroupType.regular
    },
    {
        title: 'Princess Margaret Cancer FoundationDepartment of Supportive Care, Head',
        member: ['Dr Camilla Zimmermann'],
        type: GroupType.regular
    },
    {
        title: 'Private Donor Fundraising for Dr Christian Schulz-Quach’s lab',
        member: [],
        type: GroupType.regular
    }
]

const GroupItemEngDash = styled.div`
  flex: 1;
  height: 1px;
  border-top: 1px dashed #E4E2E7;
  margin: 0 6px;
`

function GroupItemEng({name, position}) {
    return <GroupItemEngCtn>
        <TitleSH1>{name}</TitleSH1>
        <GroupItemEngDash/>
        <BodyB2>{position}</BodyB2>
    </GroupItemEngCtn>
}

function SectionList({list}) {
    const {t: tA} = useTranslation('common',{keyPrefix: "aboutus"})
    return <SectionContent>
        {list.map(group =>
            <Group key={group.title}>
                <GroupTitle>{tA(group.title)}</GroupTitle>
                {group.member.map(member => {
                    switch (group.type) {
                        case GroupType.regular:
                            return <GroupItem key={member}>{member}</GroupItem>;
                        case  GroupType.small:
                            return <GroupItemShort key={member}>{member}</GroupItemShort>;
                        case  GroupType.engineer:
                            return <GroupItemEng key={member[0]} name={member[0]} position={tA(member[1])}/>;
                        default:
                            return null;
                    }
                })}
            </Group>
        )}
    </SectionContent>
}


function AboutUs() {
    const {t} = useTranslation('common')
    const {t: tA} = useTranslation('common',{keyPrefix: "aboutus"})
    return (
        <ScrollDiv>
            <AboutUsPageBase>
                <PageHeader title={t('tags.About Us')}/>
                <AboutUsContentCtn>
                    <Section>
                        <Intro><Trans i18nKey={"common:aboutus.intro"}
                                      components={{
                                          1:<Link href="https://www.uhn.ca/PrincessMargaret/PatientsFamilies/Specialized_Program_Services/Pages/sexual_gender_diversity.aspx" target="_blank"/>,
                                          2:<Link href="https://www.uhn.ca/PrincessMargaret/Health_Professionals/Programs_Departments/Department_Supportive_Care/" target="_blank"/>,
                                          3:<Link href="https://www.uhn.ca/PrincessMargaret" target="_blank"/>,
                                          4:<Link href="https://www.uhn.ca/PrincessMargaret/PatientsFamilies/Specialized_Program_Services/Pages/sexual_gender_diversity.aspx" target="_blank"/>,
                                      }}
                        /></Intro>
                    </Section>

                    <Section>
                        <SectionTitle>{tA('Contributors')}</SectionTitle>
                        <SectionList list={GroupList}/>
                    </Section>

                    <Section>
                        <SectionTitle>{tA('Funding')}</SectionTitle>
                        <SectionSubTitle>{tA('This project was funded by:')}</SectionSubTitle>
                        <SectionList list={FundList}/>
                        <SectionSubTitle><Trans
                            i18nKey={"common:aboutus.We would also like to acknowledge all members of the Sexual and Gender Diversity in Cancer Care Program"}
                            components={{
                                1: <Link href="https://pmchosenfamily.ca/" target="_blank"/>
                            }}
                        /></SectionSubTitle>

                    </Section>

                </AboutUsContentCtn>
                <Footer/>
            </AboutUsPageBase>
        </ScrollDiv>
    )
}

export default AboutUs