//store score, if it goes below certain threshhold, trigger bad end
import {END_LEVEL} from "../constants";

class ScoreBoard {
    #score = 0;
    #maxScore = 0;
    #gameEndLevel;
    #finalPercent = 0;

    constructor(score) {
        this.score = score || 0;
    }

    setEndLevel = (endLevel) => {
        this.#gameEndLevel = endLevel
        switch(this.#gameEndLevel){
            case END_LEVEL.WORSE:
                this.#finalPercent = 25;
                break;
            case END_LEVEL.BAD:
                this.#finalPercent = 50;
                break;
            case END_LEVEL.GOOD:
                this.#finalPercent = 75;
                break;
            case END_LEVEL.GREAT:
                this.#finalPercent = 100;
                break;
            default:
        }
    }

    scoreChange = (action, maxScore) => {
        if(this.#gameEndLevel){
            return;
        }
        if(action.gameEndLevel){
            this.setEndLevel(action.gameEndLevel)
        }else {
            this.#score += action.score;
            this.#maxScore += maxScore;
            this.#finalPercent = Math.round(this.#score/this.#maxScore*100)
        }
        // console.log(this.#score, this.#maxScore);
    }


    getEndingLevel = () => {
        if (this.#gameEndLevel){
            return this.#gameEndLevel;
        }
        const percent = this.getScorePercent();

        if(percent <= 25){
            return END_LEVEL.WORSE;
        } else if (percent <= 50) {
            return END_LEVEL.BAD;
        } else if (percent <= 75) {
            return END_LEVEL.GOOD;
        }
        return END_LEVEL.GREAT
    }

    getScorePercent = () => {
        return this.#finalPercent;
    }

    getScore = () => {
        return this.getScorePercent();
    }

    reset = () => {
        this.#score = 0;
        this.#maxScore = 0;
        this.#finalPercent = 0;
        this.#gameEndLevel = undefined;
    }
}


export default ScoreBoard