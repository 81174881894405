import styled from "styled-components";
import {breakpoints, device} from "../../breakpoints";
import {useContext, useLayoutEffect, useRef, useState} from "react";
import {DimContext} from "../../App";
import i18next from "i18next";
import GlobeIcon from "../images/icon/globe.png";
import {TitleBtn} from "../../typography";
import ListDownArrow from "../images/icon/listDownArrow.png";
import Dropdown from "./dropDown";

const LangButtonBase = styled.div`
  height: 48px;
  border-radius: 50px;
  border: 1px solid #B6B5B9;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  cursor: pointer;


  ${props => !props.isNav && 'margin-bottom: 16px;'}
  
  &:hover {
    background-color: #E6E5E9;
  }

  ${device.onlyMobile} {
    ${props => props.isNav && 'display: none;'}
  }
`
const LanguageList = [{
    text: "English",
    val: "en"
}, {
    text: "French",
    val: "fr"
}]

export default function LanguageButton({isNav, style}) {
    const dim = useContext(DimContext);
    const [position, setPosition] = useState({top: 0, left: 0})
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    let locale = i18next.language;
    let language;
    switch (locale) {
        case 'fr':
            language = 'Français'
            break;
        case 'en':
        default:
            language = 'English'
            break;
    }
    const ref = useRef();
    useLayoutEffect(() => {
        const rect = ref.current.getBoundingClientRect();
        setPosition({top: rect.top + rect.height + 8, left: rect.left})
    }, [dim.screenWidth])
    let list = LanguageList.map(lang => {
        const ret = {...lang};
        ret.onClick = () => {
            if (locale !== ret.val) {
                i18next.changeLanguage(ret.val).finally()
            }
        }
        return ret;
    });

    return <LangButtonBase isNav={isNav} style={style} ref={ref} onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <img alt="language" src={GlobeIcon} height={24} width={24}/>
        <TitleBtn style={{margin: '0 6px'}}>{language}</TitleBtn>
        <img alt="open selection" src={ListDownArrow} height={24} width={24}/>
        <Dropdown isOpen={isMenuOpen} isFull={dim.screenWidth < breakpoints.small}
                  onBackdropClicked={() => setIsMenuOpen(false)} list={list} val={locale}
                  position={position}/>
    </LangButtonBase>
}