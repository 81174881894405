import {useNavigate, useParams} from "react-router";
import {PageBase} from "./frontPage";
import styled from "styled-components";
import {BodyB2, TitleD2, TitleD3, TitleHL, TitleSH1} from "../typography";
import {HUB_CONTENT_DICT} from "./data/knowledgeHub";
import ArrowRight from './images/icon/arrowRight.png'
import {ROUTE} from "../App";
import {device} from "../breakpoints";
import useResponsive from "../hook/useResponsive";
import Footer from "./fragments/footer";
import {useEffect} from "react";
import ScrollDiv from "./components/scrollDiv";


const ArticleBase = styled(PageBase)`
  display: flex;
  align-items: center;
  flex-direction: column;

`

const ArticleCtn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 700px;
  padding: 100px 0 64px;

  ${device.tabletAndMobile} {
    width: 100%;
  }
  ${device.onlyTablet} {
    padding: 36px 24px;
  }

  ${device.onlyMobile} {
    padding: 36px 16px;
  }
`

const Routing = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 36px;

  ${device.onlyMobile} {
    margin-bottom: 0;
  }
`

const ArrowRightIcon = styled.div`
  width: 24px;
  height: 24px;
  background: url(${ArrowRight}) no-repeat;
  background-size: contain;
  margin: 0 4px;
`


const InfoCtn = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 0px #E4E2E7;
  margin-bottom: 36px;
`

const InfoTitle = styled(TitleD2)`
  font-weight: 700;
  cursor: pointer;

  &:hover {
    text-decoration-line: underline;
  }
`;


const InfoPhoneTitle = styled(TitleD3)`
  font-weight: 700;
  cursor: pointer;

  &:hover {
    text-decoration-line: underline;
  }
`;

const InfoBrief = styled(TitleHL)`
  margin-top: 12px;
  margin-bottom: 12px;
`

const InfoPhoneBrief = styled(TitleSH1)`
  margin-top: 12px;
  margin-bottom: 12px;
`

const InfoPostInfoCtn = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`

const InfoPostIcon = styled.div`
  width: 36px;
  height: 36px;
  background: url(${props => props.icon}) no-repeat;
  background-size: contain;
  border-radius: 50%;
  border: 1px solid #E4E2E7;
`

const InfoPoster = styled(BodyB2)`
  margin: 0 12px;
  white-space: nowrap;
`

const InfoPostDate = styled(BodyB2)`
  margin: 0 0 0 12px;
  white-space: nowrap;
`
const ArticleContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;


  div {
    margin-bottom: 36px;

  }

  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;

    letter-spacing: -0.6px;
    color: #2E2D2E;
    margin: 0 0 16px 0;
  }

  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.6px;

    color: #2E2D2E;
    margin: 0 0 16px 0;
  }

  h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.6px;

    color: #2E2D2E;
    margin: 0 0 16px 0;
  }

  ul {
    margin: 0;
    padding-inline-start: 24px;
  }

  li {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #2E2D2E;

  }

  a {
    color: #2E2D2E;
  }

  p {

    margin-top: 16px;
    margin-bottom: 0;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #2E2D2E;
  }
`

function CategoryInfo({info}) {
    const {title, brief, poster: {icon, name}, date} = info //PMCFIcon, PMChosenFamily Team, May 30, 2023
    const [device] = useResponsive();
    const Title = device.isPhone ? InfoPhoneTitle : InfoTitle
    const Brief = device.isPhone ? InfoPhoneBrief : InfoBrief
    return (
        <InfoCtn>
            <Title>{title}</Title>
            <Brief>{brief}</Brief>
            <InfoPostInfoCtn>
                <InfoPostIcon icon={icon}/>
                <InfoPoster>{name}</InfoPoster>
                <BodyB2>•</BodyB2>
                <InfoPostDate>{date}</InfoPostDate>
            </InfoPostInfoCtn>
        </InfoCtn>
    )
}


function Article() {
    const navigate = useNavigate();
    const {category} = useParams();
    const data = HUB_CONTENT_DICT[category]

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return (
        <ScrollDiv>
            <ArticleBase>
                <ArticleCtn>
                    <Routing>
                        <TitleSH1 style={{cursor: 'pointer'}} onClick={() => navigate(ROUTE.KNOWLEDGE)}>Knowledge
                            Hub</TitleSH1>
                        <ArrowRightIcon/>
                        <TitleSH1 style={{color: '#0969DA'}}>{data.title}</TitleSH1>
                    </Routing>
                    <CategoryInfo info={data}/>
                    <ArticleContent>
                        {data.articleDom}
                    </ArticleContent>
                </ArticleCtn>
                <Footer/>
            </ArticleBase>
        </ScrollDiv>
    )
}

export default Article