
import PMCFIcon from '../images/icon/PMCFIcon.png';
import PronounDom from '../data/doc/pronouns'
import GenderDom from '../data/doc/gender'
import SexualityDom from '../data/doc/sexuality'
import RelationshipDom from '../data/doc/relationship'

// TODO: merge with the one in the game?????? depends on if going blog mode
export const HUB_CONTENT_DATA = [
    {
        id: 0,
        title: 'Pronouns',
        brief: 'New terms in this module to integrate into story boards: non binary, “preferred” pronouns, heteronormative, cisgender, gender spectrum',
        poster: {icon: PMCFIcon,name: 'PMChosenFamily Team'},
        date: 'May 30, 2023',
        category: 'pronouns',
        articleDom: PronounDom,
    },
    {
        id: 1,
        title: 'Gender',
        brief: 'New terms in this module to integrate into story boards: cisgender, transgender, sex assigned at birth, gender fluid, intersectionality',
        poster: {icon: PMCFIcon,name: 'PMChosenFamily Team'},
        date: 'May 30, 2023',
        category: 'gender',
        articleDom: GenderDom,
    },
    {
        id: 2,
        title: 'Sexual Orientation',
        brief: 'New terms in this module to integrate into story boards: homophobia, biphobia, transphobia, ally, queer',
        poster: {icon: PMCFIcon,name: 'PMChosenFamily Team'},
        date: 'May 30, 2023',
        category: 'sexual-orientation',
        articleDom: SexualityDom,
    },
    {
        id: 3,
        title: 'Relationship',
        brief: 'New terms in this module to integrate into story boards: non binary, “preferred” pronouns, heteronormative, cisgender, gender spectrum',
        poster: {icon: PMCFIcon,name: 'PMChosenFamily Team'},
        date: 'May 30, 2023',
        category: 'relationship',
        articleDom: RelationshipDom,
    },
]

function HCArrToDict(){
    const ret = {};
    HUB_CONTENT_DATA.forEach(data=>{
        ret[data.category] = {...data};
    })
    return ret;
}

export const HUB_CONTENT_DICT = HCArrToDict();


