import styled from "styled-components";
import BlueCheck from "../../game/images/misc/blueCheck.png";
import {useLayoutEffect, useRef, useState} from "react";
import {ContentBase} from "../components/surveyModal";
import {BodyB1, TitleSH1} from "../../typography";
import Navigation from "../components/navigation";
import PageFooter from "../components/pageFooter";
import {Input} from "../../pages/components/input";
import {SurveyContent, SurveyTitle} from "../survey";
import ScrollDiv from "../../pages/components/scrollDiv";

const CheckCircle = styled.div`
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 40px;
    background: ${props => props.checked ? `url(${BlueCheck})` : 'white'};
    background-size: contain;
    // box-sizing: border-box;
    border: ${props => props.checked ? 'none' : '2.5px solid #6E7781'};
    margin: 0 16px;
`
const SelectionCtn = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`
const SelectionTextCtn = styled.div`
    margin-right: 16px;
    width: 100%;
`
const SelectionOuterCtn = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
`

function Selection({isSelected, item, onClick, inputValue, onInputChange}) {
    // console.log(item);
    const {text, value, fillInLimit} = item;
    return <SelectionOuterCtn>
        <SelectionCtn onClick={() => onClick(value)}>
            <CheckCircle checked={isSelected}/>
            <SelectionTextCtn>
                {typeof (text) === 'string' ?
                    <BodyB1>{text}</BodyB1> :
                    text.map((t, index) => <BodyB1 key={t} style={index % 2 ? {
                        color: "#D9D9D9",
                        display: 'inline'
                    } : {display: 'inline'}} as="span">{t}</BodyB1>)}

            </SelectionTextCtn>
        </SelectionCtn>
        {fillInLimit && isSelected &&
            <Input style={{width: 'unset', flex: 1, margin: '0 16px 0 56px'}} onChange={onInputChange}
                   value={inputValue}
                   limit={fillInLimit}/>}
    </SelectionOuterCtn>

}

export function RadioPage({onBack, onNext, page, defaultAns, numOfQuestion}) {
    // console.log(defaultAns)
    const [ans, setAns] = useState(defaultAns);
    const contentRef = useRef();
    const outerRef = useRef();
    const [contentScrollHeight, setContentScrollHeight] = useState(0)
    const [contentClientHeight, setContentClientHeight] = useState(0)
    useLayoutEffect(() => {

        if (contentRef?.current) setContentScrollHeight(contentRef.current.scrollHeight)
        if (outerRef?.current) setContentClientHeight(outerRef.current.clientHeight)
    }, [contentRef]);
    //console.log(ans, defaultAns)
    const changeAns = (index) => (val) => {
        const ret = [...ans]
        ret[index] = val
        setAns(ret)
    }
    const isAns1Needed = ans[0] && page.list.find(item => item.value === ans[0] && item.fillInLimit)
    // console.log(contentClientHeight, contentScrollHeight)
    return <>
        <ContentBase>
            <SurveyTitle>{page.question}</SurveyTitle>
            <div style={{ overflow:'auto'}}  ref={outerRef}>
                <ScrollDiv key={contentScrollHeight}
                           heightVal={{scrollHeight: contentScrollHeight, clientHeight: contentClientHeight > contentScrollHeight? contentScrollHeight: contentClientHeight}}>
                    <SurveyContent ref={contentRef}>
                        {page.list.map(item => <Selection
                            key={item.value}
                            isSelected={item.value === ans[0]}
                            item={item}
                            onClick={changeAns(0)}
                            inputValue={ans[1]}
                            onInputChange={changeAns(1)}

                        />)}
                    </SurveyContent>
                </ScrollDiv>

            </div>
            <Navigation onBack={onBack} onNext={() => onNext(ans, page.pageNum, page.questionNum)}
                        isNextDisabled={!ans[0] || (isAns1Needed && !ans[1])}
                        isDone={page.isDone}/>
        </ContentBase>

        <PageFooter text={`Question ${page.questionNum} of ${numOfQuestion}`}/>
    </>
}