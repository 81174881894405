import styled from "styled-components";
import {TitleD4} from "../../../typography";
import Item14 from '../../images/background/Item-14.png'
import {useContext} from "react";
import useResponsive from "../../../hook/useResponsive";
import {DimContext} from "../../../App";
import {useTranslation} from "react-i18next";
import StoryModel from "../../classes/storyModel";

const StyledBubble = styled(TitleD4)`
  background-image: url(${Item14});
  background-repeat: no-repeat;
  background-position: center;
  padding: 0 ${props=> props.ratio * 49}px 0 ${props=> props.ratio * 107}px;
  position: absolute;
  top:${props=> props.ratio * (props.position.top || 0)}px;
  right:${props=> props.ratio * (props.position.right || 0)}px;
  bottom:${props=> props.ratio * (props.position.bottom || 0)}px; 
  left:${props=> props.ratio * (props.position.left || 0)}px;
  z-index: 25;
  background-size: contain;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
`

const StyledBubbleText = styled.div`
  text-align: left;
  width: 100%;
`

function BubbleText({bubble}){
    const dim = useContext(DimContext)
    const [device] = useResponsive();
    const storyModel = new StoryModel();
    const {t} = useTranslation('game', {keyPrefix:storyModel.getCurrentStoryLineId()})
    return <StyledBubble ratio={dim.ratio}
        position={bubble.position}
        positionType={bubble.positionType}>
        <StyledBubbleText>
            {device.isMobile ? '...' : t(bubble.text)}
        </StyledBubbleText>
    </StyledBubble>
}

export default BubbleText