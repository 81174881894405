import Role from "./role";
import StoryMap from "./storyMap";
import {PATIENT_DATA} from "../data/patient";
import Patient from "./patient";
import ScoreBoard from "./scoreboard";
import {isQuickStart} from "../../settings";
import {CATEGORY_INFO_TYPE, CATEGORY_INFO_TYPE_TO_BIT, categoryDocs} from "../data/category";

// Model for keep track of the story

class StoryModel {
    role;
    #scoreBoard;
    storyMap;
    patients;
    selectedPatient;

    constructor() {
        if(StoryModel._instance){
            return StoryModel._instance;
        }
        StoryModel._instance = this;
        this.patients = PATIENT_DATA.map(pd=>{
            return new Patient(pd);
        }) //TODO: decide in dictionary or array
        this.#scoreBoard= new ScoreBoard()
        this.role = new Role();
        this.storyMap = new StoryMap();

        if(isQuickStart){
            this.categorySelected(CATEGORY_INFO_TYPE.relationship);
            this.patientSelected(1);
        }
    }

    reset = () => {
        this.role = new Role();
        this.storyMap.reset();
        this.#scoreBoard.reset();
        if(isQuickStart){
            this.categorySelected(CATEGORY_INFO_TYPE.relationship);
            this.patientSelected(1);
        }
    }

//------------------
    categorySelected = (category) =>{
        this.storyMap.category = category;
    }

    getSelectedCategory = () =>{
        return this.storyMap.category;
    }

    getSelectedCategoryTitle = () =>{
        return categoryDocs[this.storyMap.category].title;
    }
    getSelectedCategoryBit = () =>{
        return CATEGORY_INFO_TYPE_TO_BIT[this.storyMap.category];
    }

//-----------------
    getRole = () => {
        return this.role
    }

    setRole = (role) => {
        this.role.name = role.name;
        this.role.pronoun = role.pronoun;
        this.role.avatar = role.avatar
    }
//-----------------
    getPatientSelectionList = ()=>{
        return this.patients.map(p=>p.getSelectionData())
    }

    patientSelected = (id) => {
        this.selectedPatient = this.patients.find(p => p.id === id)
        this.storyMap.patientId = this.selectedPatient.id;
    }

    getPatientSelected = () =>{
        return this.selectedPatient.id
    }
    getPatientSelectedName = () =>{
        return this.selectedPatient.name
    }

    getInitialSceneId = () => {
        return this.storyMap.getBeginningId();
    }

//------------------
    getScene = (id) => {
        return this.storyMap.getScene(id);
    }

    sceneActionSelected = (action, maxScore) => {
        this.#scoreBoard.scoreChange(action, maxScore)
    }

    setEndLevel = (endLevel) => {
        this.#scoreBoard.setEndLevel(endLevel)
    }

    getEnding = () => {
        return this.#scoreBoard.getEndingLevel()
    }

    getScore = ()=>{
        return this.#scoreBoard.getScore()
    }

    getCurrentStoryLineId = () =>{
        return this.storyMap.currentStoryLineId
    }

}

export default StoryModel;