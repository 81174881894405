import {StoryBase} from "./story";
import styled from "styled-components";
import BG11 from '../../images/background/BG-11.png'
import End01 from '../../images/background/Game_EndScreen_01.png'
import End02 from '../../images/background/Game_EndScreen_02.png'
import End03 from '../../images/background/Game_EndScreen_03.png'
import End04 from '../../images/background/Game_EndScreen_04.png'
import StripeImg from '../../images/background/Game_EndScreen_06.png'
import StoryModel from "../../classes/storyModel";
import {EMOTION_TYPE} from "../../classes/patient";
import {BodyB1, TitleD3, TitleD4, TitleHL} from "../../../typography";
import {categoryDocs} from "../../data/category";
import {END_LEVEL} from "../../constants";
import {useNavigate} from "react-router";
import {DimContext, ROUTE} from "../../../App";
import useGameRouter from "../../hooks/useGameRouter";
import {AffirmButton, BUTTON_TYPE} from "../../../pages/components/buttons";
import {useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import {ButtonBlock, ButtonFloat} from "../../../pages/game";
import HeaderContainer from "../../components/headerCtn";
import useResponsive from "../../../hook/useResponsive";
import {device} from "../../../breakpoints";
import ScrollDiv from "../../../pages/components/scrollDiv";
import {Trans, useTranslation} from "react-i18next";
import GaUtility from "../../../gaUtility";
import {COOKIES_FLAG, FLAG_ENDGAME_SURVEY_DONE} from "../../../constants";
import Familiarity from "../../components/familiarity";
import EndGameSurvey from "../../../survey/endgameSurvey";
import {isDev} from "../../../settings";
import {updateEndgameSurvey} from "../../../firebase";
import storage, {STORAGE_TYPE} from "../../../storage";


const EndGameMobileBase = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  background-color: rgb(229, 235, 244);
`

const EndGamePhoneBannerCtn = styled.div`
  margin-top: 24px;
  margin-bottom: 16px;
  height: ${props => props.dim.gameWidth}px;
  width: ${props => props.dim.gameWidth}px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;

`

const EndGamePhoneBannerBG = styled.div`
  height: ${props => props.dim.gameWidth}px;
  width: ${props => props.dim.gameWidth}px;
  position: absolute;
  background-color: ${props => props.color};
  top: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;

`

const Banner = styled.div`
    position: relative;
    width: ${props => props.ratio * 322}px;
    min-width: ${props => props.ratio * 322}px;
    height: ${props => props.ratio * 586}px;
    background-image: url(${props => props.banner});
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    border-radius: 0 0 ${props => props.ratio * 146}px ${props => props.ratio * 146}px;
    
    ${device.onlyMobile} {
        width: ${props => props.ratio * 197}px;
        min-width: ${props => props.ratio * 197}px;
        height: ${props => props.dim.gameWidth - 3 - 2}px;
        border-radius: 0;
    }
`


const EndGameBase = styled(StoryBase)`
  background-image: url(${BG11});
  background-size: cover;
  padding: ${props => props.ratio * 135}px ${props => props.ratio * 225}px ${props => props.ratio * 135}px ${props => props.ratio * 224}px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  // box-sizing: border-box;
  background-color: initial;

  ${device.tabletAndMobile} {

    //&::after {
    //  background-color: rgb(229, 235, 244);
    //}
  }

  ${device.onlyTablet} {
    margin-top: 24px;
    padding-left: ${props => props.ratio * 24.75}px;
    padding-right: ${props => props.ratio * 29}px;
    padding-top: ${props => props.ratio * 114}px;
  }

`

const Face = styled.img`
  position: absolute;
  left: ${props => props.ratio * (props.position?.left || 0)}px;
  width: ${props => props.ratio * (props.position?.width || 0)}px;
  top: ${props => props.ratio * (props.position?.top || 0)}px;

  ${device.onlyMobile} {
    top: ${props => props.ratio * (props.position?.mobile?.top || 0)}px;
    left: ${props => props.ratio * (props.position?.mobile?.left || 0)}px;
    width: ${props => props.ratio * (props.position?.mobile?.width || 0)}px;
  }
`

const Stripe = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(${StripeImg}) no-repeat;
  background-size: contain;
  height: ${props => props.height}px;
`

const TextBox = styled.div`
  margin-left: ${props => props.ratio * 102}px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  white-space: pre-wrap;

  ${device.onlyTablet} {
    margin-left: ${props => props.ratio * 36}px;
  }

  ${device.onlyMobile} {
    margin-left: 0;
  }
`

const WordLink = styled.span`
  color: #0969DA;
  cursor: pointer;
`


const endgameMsg = {
    [END_LEVEL.GREAT]: 'great',
    [END_LEVEL.GOOD]: 'good',
    [END_LEVEL.BAD]: 'bad',
    [END_LEVEL.WORSE]: 'worse',

}

function getEndBanner(level) {
    switch (level) {
        case END_LEVEL.GREAT:
            return End01;
        case END_LEVEL.GOOD:
            return End02;
        case END_LEVEL.BAD:
            return End03;
        default:
            return End04;
    }
}

function getEndBannerColor(level) {
    switch (level) {
        case END_LEVEL.GREAT:
            return '#FBE6EF';
        case END_LEVEL.GOOD:
            return '#FFFDE6';
        case END_LEVEL.BAD:
            return '#DCDAED';
        default:
            return '#B4B6B8';
    }
}

function getEmotion(level) {
    switch (level) {
        case END_LEVEL.GREAT:
            return EMOTION_TYPE.HAPPY;
        case END_LEVEL.GOOD:
            return EMOTION_TYPE.SMILE;
        case END_LEVEL.BAD:
            return EMOTION_TYPE.NORMAL;
        default:
            return EMOTION_TYPE.UNSATISFIED;
    }
}

function useBaseDim(sizeRatio){
    const baseRef = useRef();
    const [baseDim, setBaseDim] = useState({ratio:1})

    useLayoutEffect(() => {
        if(baseRef?.current){
            const TABLET_SIZE = 1200;
            const PADDING= 24*2
            //not really TODO: implement other screen, now only for tablet size
            console.log(baseRef.current.clientWidth, window.innerWidth)
            setBaseDim({ratio: baseRef.current.clientWidth / (TABLET_SIZE-PADDING)});
        }
    }, [baseRef, sizeRatio]);
// console.log(sizeRatio, baseDim)
    return [baseRef, baseDim]
}

function Endgame() {

    const storyModel = new StoryModel();
    const endLevel = storyModel.getEnding()
    const patient = storyModel.selectedPatient;
    const banner = getEndBanner(endLevel)
    const face = patient.emotionsImg[getEmotion(endLevel)]
    const facePosition = patient.endgamePosition;
    const cDoc = categoryDocs[storyModel.getSelectedCategory()];
    const navigate = useNavigate();
    const [, useRoute] = useGameRouter();

    const [familiarity, setFamiliarity] = useState(0)

    const {t: tEGM, i18n} = useTranslation('common', {keyPrefix: 'endgameMsg'})
    const {t: tT} = useTranslation('common', {keyPrefix: 'tags'})
    const {t: tF} = useTranslation('common', {keyPrefix: 'familiarity'})
    const {t: tB} = useTranslation('common', {keyPrefix: 'button'})
    const {t: tTT} = useTranslation('common', {keyPrefix: 'toolTips'})

    const familiarityTitle = tF('On a scale of 1 to 10, how would you rate your knowledge of topic after finishing the program?', {topic: tT(storyModel.getSelectedCategoryTitle())})

    const [isSurveyOpen, setIsSurveyOpen] = useState(isDev() ||
        !(
        ((storage.get(COOKIES_FLAG, STORAGE_TYPE.INT) || 0) & (FLAG_ENDGAME_SURVEY_DONE << ((2**4) ** (storyModel.getPatientSelected() -1)) << storyModel.getSelectedCategoryBit()))
        || i18n.language === 'fr'))

    const exitEndGame = (route) => {
        storyModel.reset();
        useRoute.reset();
        navigate(route, {replace: true})
        //clear data, navigate in callback
    }

    const dim = useContext(DimContext);
    const [baseRef, baseDim] = useBaseDim(dim.ratio);
    const [device] = useResponsive();

    useEffect(() => {
        GaUtility.gameEnd(storyModel.getScore())
        GaUtility.exitGameCalled = false
        const exitGameEvent = (event) =>{
            event.preventDefault();
            event.returnValue = '';
            GaUtility.onExitEndGame()
        }
        window.addEventListener('beforeunload',exitGameEvent)
        return () => {
            GaUtility.onExitEndGame();
            window.removeEventListener('beforeunload', exitGameEvent)
        }
    }, [])
    if (!cDoc) {
        return null;
    }


    const onFamiliarityChange = (val) =>{
        setFamiliarity(val);
        GaUtility.onEndgameFamiliarityChange(val);
    }

    const onSurveyEnd = (ans) => {
        const setSurveyCookie = ()=> storage.set(COOKIES_FLAG,
            (storage.get(COOKIES_FLAG, STORAGE_TYPE.INT) || 0) |
            (FLAG_ENDGAME_SURVEY_DONE << ((2**4) ** (storyModel.getPatientSelected() -1)) << storyModel.getSelectedCategoryBit()))
        if(ans){
            //connect to database
            const user_id = storage.get('gaui');
            ans.push( storyModel.getSelectedCategoryTitle()+'-'+storyModel.getPatientSelectedName());
            updateEndgameSurvey(ans, user_id, setSurveyCookie, 'endgame')
        } else {
            setSurveyCookie()
        }
        setIsSurveyOpen(false)

    }

    const BackToHomeButton = () =>(
        <AffirmButton isDisabled={!familiarity} type={device.isPhone ? BUTTON_TYPE.STORY_NEXT : null} onClick={() => exitEndGame(ROUTE.FRONTPAGE)}
                      text={tB("Back to homepage")} title={familiarity ? "":tTT("Back to homepage tips")}/>)


    if (device.isPhone) {
        const ratio = document.documentElement.clientWidth / 375
        const stripeBaseHeight = 27 * (375 / 708);
        return <ScrollDiv>

            <EndGameMobileBase ref={baseRef}>
                <HeaderContainer hideClose/>
                <EndGameSurvey isOpen={isSurveyOpen}
                               onClose={()=>onSurveyEnd(false)}
                               onEnd={onSurveyEnd}
                />
                <EndGamePhoneBannerCtn dim={dim}>
                    <EndGamePhoneBannerBG dim={dim} color={getEndBannerColor(endLevel)}>
                        <Banner banner={banner} ratio={ratio} dim={dim}>
                            <Face ratio={ratio} position={facePosition} src={face} width={`${ratio * 197}px`}
                                  bannerHeight={stripeBaseHeight}/>
                        </Banner>
                        <Stripe height={stripeBaseHeight * ratio}/>
                    </EndGamePhoneBannerBG>
                </EndGamePhoneBannerCtn>

                <TextBox ratio={dim.ratio}>
                    <TitleHL style={{marginBottom: 16, marginTop: 16}}>
                        {tEGM('title', {topic: tT(cDoc.name)})}
                    </TitleHL>
                    <BodyB1 style={{marginBottom: 16}}>
                        {tEGM(endgameMsg[endLevel])}
                    </BodyB1>
                    <Familiarity value={familiarity}
                                 style={{marginTop: 16}}
                                 title={familiarityTitle}
                                 onClick={onFamiliarityChange}
                    />
                    <BodyB1 style={{marginBottom: 16}}>
                        <Trans
                            i18nKey="common:endgameMsg.learn_more"
                            values={{topic: tT(cDoc.name)}}
                            components={{
                                1: <WordLink onClick={() => exitEndGame(`${ROUTE.KNOWLEDGE}/${cDoc.hubURL}`)}/>,
                                2: <WordLink onClick={() => exitEndGame(ROUTE.KNOWLEDGE)}/>
                            }}
                        />
                    </BodyB1>
                    <ButtonFloat backgroundColor={'rgb(229, 235, 244)'}>
                        <BackToHomeButton/>
                    </ButtonFloat>
                    <ButtonBlock backgroundColor={'rgb(229, 235, 244)'}/>
                </TextBox>
            </EndGameMobileBase>
        </ScrollDiv>
    }


    if (device.isTablet) {
        return <EndGameMobileBase>
            <HeaderContainer hideClose/>
            <EndGameSurvey isOpen={isSurveyOpen}
                           onClose={()=>onSurveyEnd(false)}
                           onEnd={onSurveyEnd}
            />
            <EndGameBase ref={baseRef} ratio={baseDim.ratio}>
                <Banner banner={banner} ratio={baseDim.ratio} dim={dim}>
                    <Face ratio={baseDim.ratio} position={facePosition} src={face}/>
                </Banner>
                <TextBox ratio={baseDim.ratio}>
                    <TitleD4 style={{marginBottom: 8, marginTop: 24}}>
                        {tEGM('title', {topic: tT(cDoc.name)})}
                    </TitleD4>
                    <BodyB1>
                        {tEGM(endgameMsg[endLevel])}
                    </BodyB1>
                    <Familiarity value={familiarity}
                                 style={{marginTop: 16}}
                                 title={familiarityTitle}
                                 onClick={onFamiliarityChange}
                    />
                    <BodyB1 style={{marginBottom: 16}}>
                        <Trans
                            i18nKey="common:endgameMsg.learn_more"
                            values={{topic: tT(cDoc.name)}}
                            components={{
                                1: <WordLink onClick={() => exitEndGame(`${ROUTE.KNOWLEDGE}/${cDoc.hubURL}`)}/>,
                                2: <WordLink onClick={() => exitEndGame(ROUTE.KNOWLEDGE)}/>
                            }}
                        />
                    </BodyB1>
                    <BackToHomeButton/>
                </TextBox>
            </EndGameBase>
        </EndGameMobileBase>
    }

    return <EndGameBase ref={baseRef} ratio={dim.ratio}>
        <HeaderContainer hideClose/>
        <EndGameSurvey isOpen={isSurveyOpen}
                       onClose={()=>onSurveyEnd(false)}
                       onEnd={onSurveyEnd}
        />
        <Banner banner={banner} ratio={dim.ratio} dim={dim}>
            <Face ratio={dim.ratio} position={facePosition} src={face}/>
        </Banner>
        <TextBox ratio={dim.ratio}>
            <TitleD3 style={{marginBottom: 16}}>
                {tEGM('title', {topic: tT(cDoc.name)})}
            </TitleD3>
            <TitleHL style={{marginBottom: 16}}>
                {tEGM(endgameMsg[endLevel])}
            </TitleHL>
            <Familiarity value={familiarity}
                         style={{marginTop: 16}}
                         title={familiarityTitle}
                         onClick={onFamiliarityChange}
            />
            <TitleHL style={{marginBottom: 16}}>
                <Trans
                    i18nKey="common:endgameMsg.learn_more"
                    values={{topic: tT(cDoc.name)}}
                    components={{
                        1: <WordLink onClick={() => exitEndGame(`${ROUTE.KNOWLEDGE}/${cDoc.hubURL}`)}/>,
                        2: <WordLink onClick={() => exitEndGame(ROUTE.KNOWLEDGE)}/>
                    }}
                />
            </TitleHL>
            <BackToHomeButton/>
        </TextBox>
    </EndGameBase>
}

export default Endgame