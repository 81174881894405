import {END_GAME, ImageType, PositionType, SceneType} from "../../../classes/scene";



import MAX08 from "../../../images/patient/max/MAX-08.png";
import MAX09 from "../../../images/patient/max/MAX-09.png";
import MAX11 from "../../../images/patient/max/MAX-11.png";

import HCP_0_03 from "../../../images/hcp/0/HCP-03.png";
import HCP_1_03 from "../../../images/hcp/1/HCP-03.png";
import HCP_2_03 from "../../../images/hcp/2/HCP-03.png";

import {CHATICON} from "../storyGlobal";

import BGER01 from "../../../images/background/BG-ER-01.png";
import BGER02 from "../../../images/background/BG-ER-02.png";
import BGER03 from "../../../images/background/BG-ER-03.png";
import {END_LEVEL} from "../../../constants";


const BG = {
    'ER01': {
        type: ImageType.background,
        image: BGER01,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    'ER02': {
        type: ImageType.background,
        image: BGER02,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
    'ER03': {
        type: ImageType.background,
        image: BGER03,
        positionType: PositionType.fullscreen,
        position: null,
        style: null,
    },
}


const MAX = {
    "08": {
        type: ImageType.avatar,
        image: MAX08,
        positionType: PositionType.px,
        position: {
            left: 434,
            top: 278,
            right: 757,
            bottom: 225,
        },
        style: null,
    },
}


const MAX_SIT = (img) => {
    return (
        {
            type: ImageType.avatar,
            image: img,
            positionType: PositionType.px,
            position: {
                left: 401,
                top: 293,
                right: 526,
                bottom: -229,
            },
            style: null,
        })

}


const HCP = {
    '03' :{
        type: ImageType.avatar,
        image: [HCP_0_03, HCP_1_03, HCP_2_03],
        positionType: PositionType.px,
        position: {
            left: 899,
            top: 264,
            right: 369,
            bottom: 44.71,
        },
        style: null,
        pronounButton:{
            top: 237,
            left: 96,
            width: 44,
            // height: 30,
            clippath: null
        }
    },
    '03-2' :{
        type: ImageType.avatar,
        image: [HCP_0_03, HCP_1_03, HCP_2_03],
        positionType: PositionType.px,
        position: {
            left: 461,
            top: 166,
            right: 462,
            bottom: -791,
        },
        style: null,
        pronounButton:{
            top: 582,
            left: 233,
            width: 108,
            // height: 30,
            clippath: null
        }
    },

}

const Pronoun3Scenes = {
    id: '01-03',
    patient: 3,
    scene: [{
        id: '01-03-001-01',
        type: SceneType.description,
        images:[
            BG['ER01'],
            MAX['08'],

        ],
        bubbleText: null,
        actions: [{
            nextId: '01-03-002-01',
            text: '001-01',
        }],
        hint: null,
        isBeginning: true,
    },{
        id: '01-03-002-01',
        type: SceneType.description,
        images:[
            BG['ER01'],
            MAX['08'],
            HCP['03']

        ],
        bubbleText: false,
        actions: [{
            nextId: '01-03-003-01',
            text: '002-01',
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-03-003-01',
        type: SceneType.selection,
        images:[
            BG['ER02'],
            HCP['03-2']

        ],
        bubbleText: false,
        actions: [{
            nextId: '01-03-004-01',
            text: (t,args) => t(`i01-q01`, {name: args.name, pronoun: args.pronoun}),
            opt: 1,
            score: 3,
        }, {
            nextId: '01-03-004-02',
            text: (t,args) => t(`i01-q02`, {name: args.name, pronoun: args.pronoun}),
            opt: 2,
            score: 2,
        }, {
            nextId: '01-03-004-03',
            text: (t,args) => t(`i01-q03`, {name: args.name, pronoun: args.pronoun}),
            opt: 3,
            score: 1,
        }, {
            nextId: '01-03-004-04',
            text: (t,args) => t(`i01-q04`, {name: args.name, pronoun: args.pronoun}),
            opt: 4,
            score: 0,
        }],
        hint: 'i01-question',
        isBeginning: false,
    },{
        id: '01-03-004-01',
        type: SceneType.chat,
        images: [
            BG["ER03"],
            MAX_SIT(MAX08),
        ],
        bubbleText: null,
        actions: [{
            nextId: '01-03-005-01',
            text: 'i01-a01',
            chatIcon: CHATICON.MAX
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-03-004-02',
        type: SceneType.chat,
        images: [
            BG["ER03"],
            MAX_SIT(MAX09),
        ],
        bubbleText: null,
        actions: [{
            nextId: '01-03-005-01',
            text: 'i01-a02',
            chatIcon: CHATICON.MAX
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-03-004-03',
        type: SceneType.chat,
        images: [
            BG["ER03"],
            MAX_SIT(MAX11),
        ],
        bubbleText: null,
        actions: [{
            nextId: '01-03-005-01',
            text: 'i01-a03',
            chatIcon: CHATICON.MAX
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-03-004-04',
        type: SceneType.chat,
        images: [
            BG["ER03"],
            MAX_SIT(MAX11),
        ],
        bubbleText: null,
        actions: [{
            nextId: '01-03-005-01',
            text: 'i01-a04',
            chatIcon: CHATICON.MAX
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-03-005-01',
        type: SceneType.selection,
        images:[
            BG['ER02'],
            HCP['03-2']

        ],
        bubbleText: false,
        actions: [{
            nextId: '01-03-006-01',
            text: 'i02-q01',
            opt: 1,
            score: 3,
        }, {
            nextId: '01-03-006-02',
            text: 'i02-q02',
            opt: 2,
            score: 2,
        }, {
            nextId: '01-03-006-03',
            text: 'i02-q03',
            opt: 3,
            score: 1,
        }, {
            nextId: '01-03-006-04',
            text: 'i02-q04',
            opt: 4,
            score: 0,
        }],
        hint: 'i02-question',
        isBeginning: false,
    },{
        id: '01-03-006-01',
        type: SceneType.chat,
        images: [
            BG["ER03"],
            MAX_SIT(MAX08),
        ],
        bubbleText: null,
        actions: [{
            nextId: END_GAME,
            text: 'i02-a01',
            chatIcon: CHATICON.MAX,
            gameEndLevel: END_LEVEL.GREAT
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-03-006-02',
        type: SceneType.chat,
        images: [
            BG["ER03"],
            MAX_SIT(MAX09),
        ],
        bubbleText: null,
        actions: [{
            nextId: '01-03-007-01',
            text: 'i02-a02',
            chatIcon: CHATICON.MAX
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-03-006-03',
        type: SceneType.chat,
        images: [
            BG["ER03"],
            MAX_SIT(MAX11),
        ],
        bubbleText: null,
        actions: [{
            nextId: '01-03-007-01',
            text: 'i02-a03',
            chatIcon: CHATICON.MAX
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-03-006-04',
        type: SceneType.chat,
        images: [
            BG["ER03"],
            MAX_SIT(MAX11),
        ],
        bubbleText: null,
        actions: [{
            nextId: '01-03-007-01',
            text: 'i02-a04',
            chatIcon: CHATICON.MAX
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-03-007-01',
        type: SceneType.selection,
        images:[
            BG['ER02'],
            HCP['03-2']

        ],
        bubbleText: false,
        actions: [{
            nextId: '01-03-008-01',
            text: 'i03-q01',
            opt: 1,
            score: 3,
        }, {
            nextId: '01-03-008-02',
            text: 'i03-q02',
            opt: 2,
            score: 2,
        }, {
            nextId: '01-03-008-03',
            text: 'i03-q03',
            opt: 3,
            score: 1,
        }, {
            nextId: '01-03-008-04',
            text: 'i03-q04',
            opt: 4,
            score: 0,
        }],
        hint: 'i03-question',
        isBeginning: false,
    },{
        id: '01-03-008-01',
        type: SceneType.chat,
        images: [
            BG["ER03"],
            MAX_SIT(MAX08),
        ],
        bubbleText: null,
        actions: [{
            nextId: '01-03-009-01',
            text: 'i03-a01',
            chatIcon: CHATICON.MAX
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-03-008-02',
        type: SceneType.chat,
        images: [
            BG["ER03"],
            MAX_SIT(MAX09),
        ],
        bubbleText: null,
        actions: [{
            nextId: '01-03-009-01',
            text: 'i03-a02',
            chatIcon: CHATICON.MAX
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-03-008-03',
        type: SceneType.chat,
        images: [
            BG["ER03"],
            MAX_SIT(MAX11),
        ],
        bubbleText: null,
        actions: [{
            nextId: '01-03-009-01',
            text: 'i03-a03',
            chatIcon: CHATICON.MAX
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-03-008-04',
        type: SceneType.chat,
        images: [
            BG["ER03"],
            MAX_SIT(MAX11),
        ],
        bubbleText: null,
        actions: [{
            nextId: '01-03-009-01',
            text: 'i03-a04',
            chatIcon: CHATICON.MAX
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-03-009-01',
        type: SceneType.selection,
        images:[
            BG['ER02'],
            HCP['03-2']

        ],
        bubbleText: false,
        actions: [{
            nextId: '01-03-010-01',
            text: 'i04-q01',
            opt: 1,
            score: 3,
        }, {
            nextId: '01-03-010-02',
            text: 'i04-q02',
            opt: 2,
            score: 2,
        }, {
            nextId: '01-03-010-03',
            text: 'i04-q03',
            opt: 3,
            score: 1,
        }, {
            nextId: '01-03-010-04',
            text: 'i04-q04',
            opt: 4,
            score: 0,
        }],
        hint: 'i04-question',
        isBeginning: false,
    },{
        id: '01-03-010-01',
        type: SceneType.chat,
        images: [
            BG["ER03"],
            MAX_SIT(MAX08),
        ],
        bubbleText: null,
        actions: [{
            nextId: END_GAME,
            text: 'i04-a01',
            chatIcon: CHATICON.MAX
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-03-010-02',
        type: SceneType.chat,
        images: [
            BG["ER03"],
            MAX_SIT(MAX09),
        ],
        bubbleText: null,
        actions: [{
            nextId: END_GAME,
            text: 'i04-a02',
            chatIcon: CHATICON.MAX
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-03-010-03',
        type: SceneType.chat,
        images: [
            BG["ER03"],
            MAX_SIT(MAX11),
        ],
        bubbleText: null,
        actions: [{
            nextId: END_GAME,
            text: 'i04-a03',
            chatIcon: CHATICON.MAX
        }],
        hint: null,
        isBeginning: false,
    },{
        id: '01-03-010-04',
        type: SceneType.chat,
        images: [
            BG["ER03"],
            MAX_SIT(MAX11),
        ],
        bubbleText: null,
        actions: [{
            nextId: END_GAME,
            text: 'i04-a04',
            chatIcon: CHATICON.MAX
        }],
        hint: null,
        isBeginning: false,
    },


    ]
}


export default Pronoun3Scenes