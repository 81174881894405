import styled from "styled-components";
import Stripe from "../images/stripe.png";
import {BodyB1} from "../../typography";

const PageFooterCtn = styled.div`
    display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 64px;
  min-height: 64px;
`

const PageFooterRainbow = styled.img`
  width: 100%;
`

const PageFooterContent = styled.div`
    display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #E5EBF4;
  border-radius: 0 0 16px 16px;
`

export default function PageFooter({text}){
    return <PageFooterCtn>
        <PageFooterRainbow src={Stripe}/>
        <PageFooterContent>
            <BodyB1>{text}</BodyB1>
        </PageFooterContent>
    </PageFooterCtn>
}
